import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { NavLink, useParams } from "react-router-dom";
import Input from "../components/Input";
import Dropdown from "../components/Dropdown";
import ManPower from "../Partials/DailyLog/ManPower";
import Equipment from "../Partials/DailyLog/Equipment";
import { useDispatch, useSelector } from "react-redux";
import {
  AlbumListing,
  GetDeliveryCompanies,
  GetLogsCategories,
  TradeListing,
  UpdateDailyLog,
} from "app/redux/actions";
import Deliveries from "../Partials/DailyLog/Deliveries";
import Notes from "../Partials/DailyLog/Notes";
import Photos from "../Partials/DailyLog/Photos";
import {
  CreateDailyLogs,
  DeleteDailyLog,
  GetContractorCompanies,
  GetDailyLogListing,
  GetDailyLogLocation,
  GetEquipments,
} from "app/redux/actions";
import useImageUpload from "hooks/useImageUpload";
import { notifyError, notifySuccess, validateForm } from "utilities";
import ConsentModel from "components/ConsentModel";
import EquipmentsModal from "components/EquipmentsModal";
import CompaniesModal from "components/CompaniesModal";
const DailyLog = (props) => {
  const params = useParams();
  const dispatch = useDispatch();
  const [Tabs, setTabs] = useState(null);
  const [currentTab, setCurrentTab] = useState(1);
  const [searchTerm, setSearch] = useState("");
  const [companies, setCompanies] = useState();
  const [location, setLocations] = useState();
  const [deliveryCompanies, setDeliveryCompanies] = useState();
  const [equipments, setEquipments] = useState();
  const [Listing, setListing] = useState();
  const [meta, setMeta] = useState();
  const [album, setalbum] = useState();
  const [trade, setTrade] = useState();
  const [isValid, setIsValid] = useState(false);
  const [deleteActive, setdeleteActive] = useState(false);
  const [DeleteData, setDeleteData] = useState();
  const [errors, setErrors] = useState({});
  const [loader, setLoader] = useState(false);
  const [DataLoader, setDataLoader] = useState(false);
  const [ModalActive, setModalActive] = useState(false);
  const [ModalView, setModalView] = useState(false);
  const [isEditing, setIsEditing] = useState({});
  const [page, setPage] = useState(1);
  const [equipmentActive, setEquipmentModal] = useState(false);
  const [CompanyModal, setCompanyModal] = useState(false);
  const ProjectId = useSelector((state) => state.navigation.projectId);
  const [state, setState] = useState({
    company_id: "",
    workers: "",
    working_hours: "",
    total_hours: "",
    location: "",
    comments: "",
    equipment_id: "",
    idle_hours: "",
    operating_hours: "",
    cost_code: "",
    inspection_time: "",
    album_id: "",
    trade_id: "",
    delivery_time: "",
    tracking_number: "",
    description: "",
    delivery_company_id: "",
  });
  const { handleImageUpload, imageLoader, Images, removeFile, setImages } =
    useImageUpload();
  const resetState = () => {
    setState({
      ...state,
      company_id: "",
      workers: "",
      working_hours: "",
      total_hours: "",
      location: "",
      comments: "",
      equipment_id: "",
      idle_hours: "",
      operating_hours: "",
      cost_code: "",
      inspection_time: "",
      album_id: "",
      trade_id: "",
      delivery_time: "",
      tracking_number: "",
      description: "",
      delivery_company_id: "",
    });
    setIsEditing({ editing: false, id: null });
  };
  useEffect(() => {
    props.set_Value({
      title: props.title,
      subTitle: props.subTitle,
      withLogs: props.withLog,
      withSettings: props.withSettings,
      rootClassName: props.rootClassName,
      mainClassName: props.mainClassName,
    });
    dispatch(GetLogsCategories()).then((res) => {
      setTabs(res);
    });
    dispatch(GetContractorCompanies()).then((res) => {
      setCompanies(res);
    });
    dispatch(GetDailyLogLocation()).then((res) => {
      setLocations(res);
    });
    dispatch(GetEquipments()).then((res) => {
      setEquipments(res);
    });
    dispatch(GetDeliveryCompanies()).then((res) => {
      setDeliveryCompanies(res);
    });
    dispatch(AlbumListing()).then((res) => {
      const data = res?.map((item) => ({ label: item.name, value: item.id }));
      setalbum(data);
    });
    dispatch(TradeListing()).then((res) => {
      const data = res?.map((item) => ({ label: item.name, value: item.id }));
      setTrade(data);
    });
  }, []);
  const handleSearch = (e) => {
    setSearch(e.target.value);
  };
  const GetListing = () => {
    setDataLoader(true);
    dispatch(
      GetDailyLogListing({ list_id: currentTab, project_id: ProjectId }, page)
    )
      .then((res) => {
        setListing(res.data);
        setMeta(res.meta);
        setDataLoader(false);
      })
      .catch((err) => {
        setDataLoader(false);
      });
  };
  useEffect(() => {
    GetListing();
  }, [currentTab]);

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const handleLocation = (e) => {
    setState(prev => ({...prev, location:e}));
  };

  const handleSelectChange = (prop, e) => {
    setState({ ...state, [prop]: e.value });
  };
  useEffect(() => {
    if (isValid && Object.keys(errors).length === 0) {
      const payload = { ...state, files: Images, list_id: currentTab };
      setDataLoader(true);
      if (isEditing.editing && isEditing.id) {
        dispatch(UpdateDailyLog(isEditing.id, payload))
          .then((res) => {
            resetState();
            ModalHide();
            setImages([]);
            GetListing();
            notifySuccess("Updated Successfully!");
            setIsEditing({ editing: false, id: null });
            setDataLoader(false);
          })
          .catch((err) => {
            setDataLoader(false);
            notifyError(err?.response?.data?.message);
          });
      } else {
        dispatch(CreateDailyLogs(ProjectId, payload))
          .then((res) => {
            resetState();
            ModalHide();
            setImages([]);
            GetListing();
            notifySuccess("Added Successfully!");
            setDataLoader(false);
          })
          .catch((err) => {
            setDataLoader(false);
            notifyError(err?.response?.data?.message);
          });
      }
    }
  }, [errors]);
  const handleCreate = () => {
    setErrors(validateForm(state, currentTab));
    setIsValid(true);
  };
  const handleDelete = (data) => {
    setDeleteData(data);
    setdeleteActive(true);
    setIsEditing({ editing: false, id: null });
  };
  const Submit = () => {
    setLoader(true);
    dispatch(DeleteDailyLog(DeleteData.id))
      .then((res) => {
        GetListing();
        setDeleteData(null);
        setdeleteActive(false);
        setModalView(false);
        notifySuccess("Successfully Deleted");
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
      });
  };
  const fetchData = () => {
    if (currentTab) {
      if (searchTerm?.length) setPage(0);
      setPage((prev) => prev + 1);
      dispatch(
        GetDailyLogListing(
          {
            title: searchTerm ?? "",
            list_id: currentTab,
            project_id: ProjectId,
          },
          page + 1
        )
      )
        .then((res) => {
          setMeta(res.meta);
          setListing([...Listing, ...res.data]);
        })
        .catch((err) => {
          notifyError("Error getting data!");
        });
    }
  };
  useEffect(() => {
    if (searchTerm) setListing([]);
    setPage(0);
    setDataLoader(true);
    const delayDebounceFn = setTimeout(() => {
      if (currentTab) {
        setPage((prev) => prev + 1);
        dispatch(
          GetDailyLogListing(
            {
              title: searchTerm ?? "",
              list_id: currentTab,
              project_id: ProjectId,
            },
            page
          )
        )
          .then((res) => {
            setMeta(res.meta);
            setListing(res.data);
            setDataLoader(false);
          })
          .catch((err) => {
            setDataLoader(false);
            notifyError("Error getting data!");
          });
      }
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);
  const ModalHide = () => {
    setModalActive(false);
  };
  const ModalShow = () => {
    setModalActive(true);
  };
  const handleViewImage = () => {
    setModalView((prev) => !prev);
  };

  const handleEdit = (data) => {
    setIsEditing({ editing: true, id: data.id });
    setState({
      company_id: data?.company?.id || "",
      workers: data?.workers || "",
      working_hours: data?.working_hours || "",
      total_hours: data?.total_hours || "",
      location: data?.location || "",
      comments: data?.comments || "",
      equipment_id: data?.equipment?.id || "",
      idle_hours: data?.idle_hours || "",
      operating_hours: data?.operating_hours || "",
      cost_code: data?.cost_code || "",
      inspection_time: data?.inspection_time || "",
      album_id: data?.album_id || "",
      trade_id: data?.trade_id || "",
      delivery_time: data?.delivery_time || "",
      tracking_number: data?.tracking_number || "",
      description: data?.description || "",
      delivery_company_id: data?.delivery_company?.id || "",
    });
    setImages(data?.files || []);
  };
  const cancelEdit = () => {
    setIsEditing({ editing: false, id: null });
    setImages([]);
    resetState();
  };

  const hideEquipmentModal = () => {
    setEquipmentModal(false);
  };
  const hideCompaniesModal = () => {
    setCompanyModal(false);
  };


  return (
    <>
      <div className="top pb-20">
        <div className="tabs">
          <NavLink
            to={`/admin/project/${params?.id}/tool/daily-log`}
            className="tab"
          >
            List
          </NavLink>
          <NavLink
            to={`/admin/project/${params?.id}/tool/daily-log/calendars`}
            className="tab"
          >
            Calender
          </NavLink>
        </div>
        {/* <div className="options d-flex">
          <Dropdown btn dropdownItems={["Export document"]}>
            EXPORT
          </Dropdown>
          <button className="ms-10 btn btn-contained-2">EMAIL</button>
        </div> */}
      </div>

      <div className="card">
        <div className="card-head d-flex justify-content-between">
          <div className="d-flex">
            <Input
              rootClassName="m-0"
              type="search"
              placeholder="Search..."
              onChange={handleSearch}
            />
            {/* <Dropdown rootClassName="mx-20" btn dropdownItems={["Export document"]}>
              ADD FILTERS
            </Dropdown>
            <button className="btn btn-primary-text">CLEAR ALL</button> */}
          </div>
          {/* <div>
            <div className="cal-nav">
              <button className="prev">
                <img src="/assets/vectors/select-arrow.svg" alt="arrow" />
              </button>

              <Dropdown rootClassName="mx-10" btn dropdownItems={["Export document"]}>
                TUE Feb 8,2022
              </Dropdown>
              <button className="next">
                <img src="/assets/vectors/select-arrow.svg" alt="arrow" />
              </button>
            </div>
          </div> */}
          <div>
            <button
              className="ms-10 btn btn-contained-2"
              onClick={() => setEquipmentModal((prev) => !prev)}
            >
              Add Equipments
            </button>
            <button
              className="ms-10 btn btn-contained-2"
              onClick={() => setCompanyModal((prev) => !prev)}
            >
              Add Delivery Company
            </button>
          </div>
        </div>
        <div className="card-body p-0">
          <div className="card-left">
            <div className="card-nav">
              {Tabs?.map((el, idx) => {
                const { title, id } = el;
                return (
                  <div
                    key={"body-nav-" + idx}
                    className={clsx("nav-item", currentTab === id && "active")}
                    onClick={() => {
                      setCurrentTab(id);
                      setIsEditing({ editing: false, id: null });
                      setErrors({});
                      setIsValid(false);
                    }}
                  >
                    {title}
                  </div>
                );
              })}
            </div>
          </div>
          <div className={`card-right ${currentTab == 5 && "pl-0"}`}>
            {currentTab == 1 && (
              <ManPower
                handleChange={handleChange}
                handlelocation={(e) => handleLocation(e)}
                Listing={Listing}
                handleCreate={handleCreate}
                state={state}
                companies={companies}
                location={location}
                dataLoader={DataLoader}
                loader={loader}
                meta={meta}
                deleteActive={deleteActive}
                setdeleteActive={setdeleteActive}
                errors={errors}
                handleImageUpload={handleImageUpload}
                imageLoader={imageLoader}
                Images={Images}
                removeFile={removeFile}
                deleteData={DeleteData}
                Submit={Submit}
                fetchData={fetchData}
                handleDelete={handleDelete}
                handleEdit={handleEdit}
                isEditing={isEditing}
                cancelEdit={cancelEdit}
                setIsEditing={setIsEditing}
                setImages={setImages}
              />
            )}
            {currentTab == 2 && (
              <Equipment
                handleChange={handleChange}
                handlelocation={(e) => handleLocation(e)}
                Listing={Listing}
                handleCreate={handleCreate}
                state={state}
                equipments={equipments}
                location={location}
                dataLoader={DataLoader}
                loader={loader}
                meta={meta}
                deleteActive={deleteActive}
                setdeleteActive={setdeleteActive}
                errors={errors}
                deleteData={DeleteData}
                Submit={Submit}
                fetchData={fetchData}
                handleDelete={handleDelete}
                setstate={setState}
                handleEdit={handleEdit}
                isEditing={isEditing}
                cancelEdit={cancelEdit}
                setIsEditing={setIsEditing}
                setImages={setImages}
              />
            )}
            {currentTab == 3 && (
              <Deliveries
                handleChange={handleChange}
                handlelocation={(e) => handleLocation(e)}
                Listing={Listing}
                handleCreate={handleCreate}
                state={state}
                deliveryCompanies={deliveryCompanies}
                location={location}
                dataLoader={DataLoader}
                loader={loader}
                meta={meta}
                deleteActive={deleteActive}
                setdeleteActive={setdeleteActive}
                errors={errors}
                deleteData={DeleteData}
                Submit={Submit}
                fetchData={fetchData}
                handleDelete={handleDelete}
                setstate={setState}
                handleImageUpload={handleImageUpload}
                imageLoader={imageLoader}
                Images={Images}
                removeFile={removeFile}
                handleEdit={handleEdit}
                isEditing={isEditing}
                cancelEdit={cancelEdit}
                setIsEditing={setIsEditing}
                setImages={setImages}
              />
            )}
            {currentTab == 4 && (
              <Notes
                handleChange={handleChange}
                handlelocation={(e) => handleLocation(e)}
                Listing={Listing}
                handleCreate={handleCreate}
                state={state}
                equipments={equipments}
                location={location}
                dataLoader={DataLoader}
                loader={loader}
                meta={meta}
                deleteActive={deleteActive}
                setdeleteActive={setdeleteActive}
                errors={errors}
                deleteData={DeleteData}
                Submit={Submit}
                fetchData={fetchData}
                handleDelete={handleDelete}
                setstate={setState}
                handleImageUpload={handleImageUpload}
                imageLoader={imageLoader}
                Images={Images}
                removeFile={removeFile}
                handleEdit={handleEdit}
                isEditing={isEditing}
                cancelEdit={cancelEdit}
                setIsEditing={setIsEditing}
                setImages={setImages}
              />
            )}
            {currentTab == 5 && (
              <Photos
                handleChange={handleChange}
                handlelocation={(e) => handleLocation(e)}
                Listing={Listing}
                handleCreate={handleCreate}
                state={state}
                equipments={equipments}
                location={location}
                dataLoader={DataLoader}
                loader={loader}
                meta={meta}
                deleteActive={deleteActive}
                setdeleteActive={setdeleteActive}
                errors={errors}
                deleteData={DeleteData}
                Submit={Submit}
                fetchData={fetchData}
                handleDelete={handleDelete}
                setstate={setState}
                trade={trade}
                album={album}
                handleSelectChange={handleSelectChange}
                handleImageUpload={handleImageUpload}
                imageLoader={imageLoader}
                Images={Images}
                removeFile={removeFile}
                ModalHide={ModalHide}
                ModalShow={ModalShow}
                handleViewImage={handleViewImage}
                ModalActive={ModalActive}
                ModalView={ModalView}
              />
            )}
          </div>
        </div>
      </div>
      <EquipmentsModal
        active={equipmentActive}
        hideModal={hideEquipmentModal}
        setEquipments={setEquipments}
      />
      <CompaniesModal
        active={CompanyModal}
        hideModal={hideCompaniesModal}
        setCompanies={setDeliveryCompanies}
      />
      {deleteActive && (
        <ConsentModel
          active={deleteActive}
          oncancel={() => setdeleteActive(false)}
          title={DeleteData?.company?.name || DeleteData?.equipment?.name}
          onsubmit={Submit}
          loader={loader}
        />
      )}
    </>
  );
};

export default DailyLog;
