import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import moment from "moment";
import { GetNotifications } from "app/redux/actions";
const Notification = (props) => {
  const dispatch = useDispatch();
  const [notifiationlisting, setNotifications] = useState(null);
  useEffect(() => {
      setNotifications(props.projectNotification);
  }, []);

  return (
    <div className="logs-container">
      <>
        <div className="main-title">
          <h3>Notifications</h3>
        </div>

        <div
          className="log-items"
          // style={{
          //   height: "300px",
          //   overflowY: "scroll",
          //   // overflow: 'hidden'
          // }}
        >
          {notifiationlisting !== null &&
            notifiationlisting?.data.length === 0 && <p>No Notification.</p>}
          {notifiationlisting !== null &&
            notifiationlisting.data.length > 0 &&
            notifiationlisting?.data?.map((el, idx) => {
              const { data, created_at } = el;
              return (
                <div key={"log-item" + idx} className="log-item">
                  <div className="d-flex justify-content-between">
                    <div className="me-10">
                      <div className="name fw-bold">{data?.message}</div>
                      <div className="desc fs-12">
                        {moment(created_at).format("DD-MMM-YYYY")}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </>
    </div>
  );
};

export default Notification;
