import React, { useState, useEffect } from "react";
import Modal from "./Modal";
import Input from "./Input";
import { useDispatch, useSelector } from "react-redux";
import {
  CreateProjectContracts,
  GetContractors,
  GetProjectContracts,
  UpdateProjectContract,
} from "app/redux/actions";
import ContractorsModel from "./ModelContractors";
import Select from "react-select";
import { ContractsValidation, notifyError, notifySuccess } from "utilities";
import { useParams } from "react-router";
import InputDate from "./InputDate";
import TextEditor from "./textEditor/TextEditor";
import moment from "moment";
import { NumberInput } from "./common/NumberInput";
import { deformatNumber } from "./common/Utilities";

import { FaPlus } from "react-icons/fa6";

const customStyles = {
  control: () => ({
    width: "100%",
    background: "#f9f9f9",
    marginBottom: "10px",
    display: "flex",
    borderRadius: "5px",
    border: "1px solid #dedee4",
    height: "48px",
    paddingLeft: "10px",
  }),
};
const ContractsModel = ({
  active,
  hideModal,
  titleText,
  setContracts,
  editData,
  projectMember,
  isTrue,
}) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [isLoadingContracts, setIsLoadingCOntracts] = useState(false);
  const [options, setOptions] = useState([]);
  const [isCOntractorValid, setIsContractorValid] = useState(false);
  const [contactsformValidation, setContactsFormValidation] = useState({});
  const [discription, setDiscription] = useState(editData?.description ?? "");

  const ContratorType = [
    { value: 14, label: "contractor" },
    { value: 15, label: "sub-contractor" },
  ];

  const memberData = projectMember?.map((item) => {
    return { value: item.id, label: item.name };
  });
  /* 
get project Contractos
*/
  const [ismodeContractors, setIsModelContractors] = useState(false);

  const [formState, setFormState] = useState({
    startDate: "",
    endDate: "",
  });
  const [data, setData] = useState({
    contractor: null,
    type: "",
    amount: "",
    signed_by: "",
  });

  useEffect(() => {
    if (editData) {
      const firstDate = new Date(editData?.start_date);
      const secondDate = new Date(editData?.end_date);

      setData({
        contractor: editData?.contractor?.id ?? null,
        type: editData?.type ?? "",
        amount: editData?.amount ?? null,
        signed_by: editData?.signed_by?.id ?? null,
      });
      setFormState({
        startDate: firstDate ?? "",
        endDate: secondDate ?? "",
      });
    }
  }, []);

  const handleContrators = (event) => {
    setData({ ...data, ["contractor"]: event.value });
  };

  const dateChangeHandler = (name, date) => {
    setFormState((prevState) => {
      return { ...prevState, [name]: date };
    });
  };

  const handleAssignedBy = (event) => {
    setData({ ...data, ["signed_by"]: event.value });
  };

  const handleContractorType = (event) => {
    setData({ ...data, ["type"]: event.label });
  };

  const handleAmountInput = (event) => {
    const { name, value } = event.target;
    setData({ ...data, amount: deformatNumber(value) });
  };

  const submitContractsForm = () => {
    setContactsFormValidation(
      ContractsValidation(data, formState, discription)
    );
    setIsContractorValid(true);
  };

  const handleCloseContractorM = () => {
    setIsModelContractors(false);
    setData({});
  };

  const getContractorListing = (defaultValue = null) => {
    dispatch(GetContractors())
      .then((res) => {
        if (res) {
          let items = [];
          res?.data?.map((data) => {
            items.push({
              value: data?.id,
              label: data?.name,
            });
          });
          setOptions([...items]);
          if (defaultValue) {
            setData({ ...data, contractor: defaultValue?.id });
          }
        }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    getContractorListing(null);
  }, []);

  useEffect(() => {
    if (isCOntractorValid && Object.keys(contactsformValidation).length === 0) {
      const payload = {
        contractor_id: data.contractor,
        project_id: parseInt(id),
        type: data.type,
        amount: data.amount,
        description: discription,
        start_date: moment(formState.startDate).format("YYYY-MM-DD"),
        end_date: moment(formState.endDate).format("YYYY-MM-DD"),
        signed_by: data.signed_by,
      };
      if (editData) {
        setIsLoadingCOntracts(true);
        dispatch(UpdateProjectContract(payload, editData.id))
          .then((res) => {
            if (res) {
              dispatch(GetProjectContracts({ project_id: id })).then((res) => {
                setContracts(res);
              });
            }
            notifySuccess("Contract Updated Successfully!");
            hideModal();
            setIsLoadingCOntracts(false);
          })
          .catch((error) => {
            notifyError("somthing went wrong");
            setIsLoadingCOntracts(false);
          });
      } else {
        setIsLoadingCOntracts(true);
        dispatch(CreateProjectContracts(payload))
          .then((res) => {
            dispatch(GetProjectContracts({ project_id: id })).then((res) => {
              setContracts(res);
            });
            notifySuccess("Contract Created Successfully");
            hideModal();
            setIsLoadingCOntracts(false);
          })
          .catch((error) => {
            notifyError("somthing went wrong");
            setIsLoadingCOntracts(false);
            hideModal();
          });
      }
    }
  }, [contactsformValidation]);

  const handleDiscriptionChange = (value) => {
    setDiscription(value);
  };

  const defaultvalue = editData
    ? { label: editData?.contractor?.name, value: editData?.contractor?.id }
    : { label: "Select Contractor", value: null };

  const typeDefaultValue = editData
    ? { label: data?.type }
    : { label: "Select Type", value: null };

  const defaulSignedby = editData
    ? { label: editData?.signed_by?.name, value: editData?.signed_by?.id }
    : { label: "Select Sign By", value: null };

  const handleAddNewContractor = () => {
    setIsModelContractors(true);
  };

  return (
    <div>
      <Modal
        active={active}
        hideModal={hideModal}
        submitText={titleText}
        submit={submitContractsForm}
        loader={isLoadingContracts}
      >
        <h4 className="mb-4" style={{ fontSize: "28px" }}>
          {titleText}
        </h4>

        <div className="custom-form-control mb-20">
          <div className=" d-flex align-items-baseline justify-content-between">
            <label>
              Contractors
              <span className="text-danger">*</span>
            </label>
            <div
              className=" d-flex align-items-center justify-content-center add-button-new"
              onClick={handleAddNewContractor}
            >
              <FaPlus
                size={24}
                style={{
                  fill: "white",
                }}
              />
            </div>
          </div>
          <Select
            className="basic-single"
            classNamePrefix="select"
            options={options}
            defaultValue={defaultvalue}
            value={options?.find((e) => e?.value === data.contractor) ?? null}
            onChange={(e) => handleContrators(e, "contractor")}
            styles={customStyles}
            placeholder="Select Contractor"
          />
          {contactsformValidation?.contractor && (
            <p className="error">{contactsformValidation?.contractor}</p>
          )}
        </div>
        <div className="custom-form-control mb-20">
          <label>
            Type
            <span className="text-danger">*</span>
          </label>

          <Select
            className="basic-single"
            classNamePrefix="select"
            label={"Type"}
            options={ContratorType}
            defaultValue={typeDefaultValue}
            value={ContratorType?.find((e) => e?.label === data.type ?? null)}
            onChange={(e) => handleContractorType(e)}
            styles={customStyles}
          />
          {contactsformValidation?.type && (
            <p className="error">{contactsformValidation?.type}</p>
          )}
        </div>
        <div className="custom-form-control mb-20">
          <label>
            Signed By
            <span className="text-danger">*</span>
          </label>
          <Select
            className="basic-single"
            classNamePrefix="select"
            options={memberData}
            defaultValue={defaulSignedby}
            value={memberData?.find((e) => e?.key === data.signed_by ?? null)}
            onChange={(e) => handleAssignedBy(e, "signed_by")}
            styles={customStyles}
          />
          {contactsformValidation?.signed_by && (
            <p className="error">{contactsformValidation?.signed_by}</p>
          )}
        </div>

        <NumberInput
          lable="Amount"
          placeholder="Enter Amount"
          value={data?.amount}
          required={true}
          onChange={handleAmountInput}
          name="amount"
          rootClassName="mb-20"
          errorMsg={contactsformValidation?.amount}
        />

        <div className="px-0 mt-30 select-wrapper custom-form-control">
          <label>Discription</label>
          <TextEditor
            overview={discription}
            changeOverview={handleDiscriptionChange}
          />
          {contactsformValidation?.discription && (
            <p
              className="error"
              style={{ color: "#dc3545", marginBottom: "10px" }}
            >
              {contactsformValidation?.discription}
            </p>
          )}
        </div>

        <div className="row mt-4">
          <div className="col-lg-6">
            <InputDate
              withInfo
              tooltipText={"Project Start Date"}
              selected={formState.startDate}
              label="Start Date*"
              onChange={(date) => dateChangeHandler("startDate", date)}
            />
            {contactsformValidation.startDate && (
              <p className="error mt-3">{contactsformValidation.startDate}</p>
            )}
          </div>
          <div className="col-lg-6">
            <InputDate
              withInfo
              tooltipText={"Project Completion Date"}
              selected={formState.endDate}
              label="End Date*"
              onChange={(date) => dateChangeHandler("endDate", date)}
            />
            {contactsformValidation.endDate && (
              <p className="error mt-3">{contactsformValidation.endDate}</p>
            )}
          </div>
        </div>
      </Modal>
      {ismodeContractors && (
        <ContractorsModel
          isActive={ismodeContractors}
          isClose={handleCloseContractorM}
          updateContractor={getContractorListing}
        />
      )}
    </div>
  );
};

export default ContractsModel;
