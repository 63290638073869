import React, { useState, useEffect } from "react";
import Modal from "../components/Modal";
import moment from "moment";
import InputDate from "../components/InputDate";
import Input from "../components/Input";
import Collapse from "../components/Collapse";
import Checkbox from "./Checkbox";
import { useDispatch, useSelector } from "react-redux";
import { CreateDrawing, EditDrawings, GetDrawings, GetDrawingSets, ImageUpload } from "app/redux/actions";
import { checkValidation, notifyError, notifySuccess } from "utilities";
import CreateableSelect from "../components/CreateableSelect";
import useImageUpload from "hooks/useImageUpload";
const createOption = (label) => ({
  label,
  value: label.toLowerCase().replace(/\W/g, ""),
});
const CreateDrawingModal = (props) => {
  const dispatch = useDispatch();
  const [dataLoader, setDataLoader] = useState(false);
  const [DrawingOptions, setDrawingOptions] = useState([]);
  const projectId = useSelector((state) => state.navigation.projectId);
  const [formState, setFormState] = useState({
    drawingDate: props?.editData?.drawing_date ? new Date(props?.editData?.drawing_date) : "",
    receivedDate: props?.editData?.recieved_date ? new Date(props?.editData?.recieved_date) : "",
  });
  const [values, setValues] = useState({
    title: props?.editData?.title || "",
    revision: props?.editData?.revision || "",
    lang: props?.editData?.language || "",
    containRev: false,
    fileName: false,
    category: props?.editData?.category_id || null,
    set: props?.editData?.set || "",
  });
  const [DrawingSet, setDrawingSet] = useState("");
  const [FormErrors, setErrors] = useState({});
  const [isValid, setIsValid] = useState(false);
  const { Drop, dragEnter, dragLeave, dragOver, handleImageUpload, Images, imageLoader, removeFile } =
    useImageUpload(props?.editData?.files);
  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };
  const handleSetChange = (event) => {
    setValues({ ...values, set: event.value });
  };
  const dateChangeHandler = (name, date) => {
    setFormState((prevState) => {
      return { ...prevState, [name]: date };
    });
  };
  useEffect(() => {
    if (projectId) {
      dispatch(GetDrawingSets(projectId))
        .then((res) => {
          const data = res?.map((item) => {
            return { label: item, value: item };
          });
          setDrawingOptions(data);
        })
        .catch((err) => notifyError("Error Retrieving sets!"));
    }
  }, [projectId]);
  useEffect(() => {
    if (Object.keys(FormErrors).length === 0 && isValid) {
      setDataLoader(true);
      const data = {
        title: values.title,
        revision: values.revision,
        drawing_date: moment(formState.drawingDate).format("YYYY-MM-DD hh:mm:ss"),
        recieved_date: moment(formState.receivedDate).format("YYYY-MM-DD hh:mm:ss"),
        set: values.set,
        language: values.lang,
        files: Images,
        category_id: values.category,
      };
      if (props.editData) {
        dispatch(EditDrawings(props.editData.id, data))
          .then((res) => {
            setDataLoader(false);
            if (res) {
              dispatch(GetDrawings({ project_id: projectId, category_id: values.category }, 1)).then(
                (res) => {
                  props.setDrawingData(res.data);
                  props.setPage(0);
                  props.hideModal();
                  notifySuccess("Drawing Updated Successfully!");
                }
              );
            }
          })
          .catch((err) => {
            setDataLoader(false);
            notifyError(err?.response?.data?.message ?? "Error", 5000);
          });
      } else {
        dispatch(CreateDrawing(projectId, data))
          .then((res) => {
            if (res) {
              props.setactivecategory(values.category);
              dispatch(
                GetDrawings({
                  project_id: projectId,
                  category_id: values.category,
                })
              );
              notifySuccess("Drawing Created Successfully!");
              setDataLoader(false);
              props.hideModal();
            }
          })
          .catch((err) => {
            setDataLoader(false);
            notifyError(err?.response?.data?.message ?? "Error", 5000);
          });
      }
    }
  }, [FormErrors]);

  const createProject = () => {
    setErrors(checkValidation({ ...values, ...formState, DrawingSet, Images }));
    setIsValid(true);
  };

  const handleCreateOption = (e) => {
    const newOption = createOption(e);
    setDrawingOptions([...DrawingOptions, newOption]);
    setValues({ ...values, set: e });
    notifySuccess("Set created successfully", 1000);
  };
  const defaultvalue = props.editData
    ? { label: values.set, value: values.set }
    : { label: "Select/Create Set", value: null };
  return (
    <Modal
      active={props.isModalActive}
      hideModal={props.hideModal}
      submit={createProject}
      loader={dataLoader}
    >
      {props?.editData ? <h4>Edit Drawings</h4> : <h4>Upload Drawings</h4>}
      <Input
        noHelper
        rootClassName="mt-20"
        icon="/assets/vectors/clip-dark.svg"
        labelClassName="fs-16"
        withInfo
        id="drawing-images"
        type="file"
        placeholder="Attach file or Drag &amp; Drop"
        onChange={handleImageUpload("drawings")}
        onDragOver={dragOver}
        onDragEnter={dragEnter}
        onDragLeave={dragLeave}
        onDrop={Drop}
        errorMsg={FormErrors.Images}
        multiple_image={Images}
        imageLoader={imageLoader}
        removeFile={removeFile}
        multiple
      />
      <Input
        label="Title"
        placeholder="Enter Title"
        onChange={handleChange("title")}
        errorMsg={FormErrors.title}
        defaultValue={values.title}
        inputCapitalize={"inputCapitalize"}
      />
      <CreateableSelect
        className="basic-single"
        classNamePrefix="select"
        isSearchable={true}
        options={DrawingOptions}
        defaultValue={defaultvalue}
        placeholder={"Select or Create Set by typing and press enter"}
        onChange={(e) => handleSetChange(e)}
        validation={FormErrors.DrawingSet}
        loadingMessage={() => "Fetching Set"}
        onCreateOption={handleCreateOption}
        value={values.set && { label: values.set, value: values.set }}
      />
      <div className="container-fluid px-0">
        <div className="row">
          <div className="col-lg-6">
            <InputDate
              rootClassName="mb-20"
              selected={formState.drawingDate}
              label="Default Drawing Date*"
              onChange={(date) => dateChangeHandler("drawingDate", date)}
              errorMsg={FormErrors.drawingDate}
            />
          </div>
          <div className="col-lg-6">
            <InputDate
              rootClassName="mb-20"
              selected={formState.receivedDate}
              label="Default Received Date*"
              onChange={(date) => dateChangeHandler("receivedDate", date)}
              errorMsg={FormErrors.receivedDate}
            />
          </div>
        </div>
      </div>

      <hr className="mb-20" />

      <Collapse title="Advanced Options">
        <div className="container-fluid mt-20 px-0">
          <div className="row">
            <div className="col-lg-12 custom-form-control">
              <label>Select Category</label>
              <select onChange={handleChange("category")} defaultValue={values.category}>
                <option value="">Select Category</option>
                {props.category &&
                  props.category?.map((item) => {
                    return (
                      <option key={item.title} value={item.id}>
                        {item.title}
                      </option>
                    );
                  })}
              </select>
              {FormErrors.category && <p className="error-msg">{FormErrors.category}</p>}
            </div>
            <div className="col-lg-6">
              <Input
                label="Default Revision"
                placeholder="Enter"
                onChange={handleChange("revision")}
                errorMsg={FormErrors.revision}
                defaultValue={values.revision}
              />
            </div>
            <div className="col-lg-6 custom-form-control">
              <label>Drawing Language</label>
              <select onChange={handleChange("lang")} defaultValue={values.lang}>
                <option value="">Select Drawing Language</option>
                <option value="English">English</option>
                <option value="French">French</option>
              </select>
              {FormErrors.lang && <p className="error-msg">{FormErrors.lang}</p>}
            </div>
          </div>
        </div>

        <div className="fw-bold">Drawing Number</div>
        <div className="d-flex mt-20">
          <Checkbox label="Contains Revision" onChange={handleChange("containRev")} />
          <Checkbox rootClassName="ms-20" label="Get From Filename" onChange={handleChange("fileName")} />
        </div>
      </Collapse>
    </Modal>
  );
};

export default CreateDrawingModal;
