import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router";
import { Link, NavLink } from "react-router-dom";
import clsx from "clsx";
import Input from "../components/Input";
import Dropdown from "../components/Dropdown";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "react-redux";
import { DeleteDrawings, GetDrawings, GetDrawingsCategories } from "app/redux/actions";
import CreateDrawingModal from "components/CreateDrawingModal";
import { notifyError } from "utilities";
import moment from "moment";
import Loader from "components/Loader";
import ConsentModel from "components/ConsentModel";
const Drawings = (props) => {
  const history = useHistory();
  const [listingTypeState, setListingTypeState] = useState("list");
  const [isModalActive, setIsModalActive] = useState(false);
  const [loader, setLoader] = useState(false);
  const dispatch = useDispatch();
  const projectId = useSelector((state) => state.navigation.projectId);
  const [page, setPage] = useState(1);
  const [meta, setMeta] = useState(null);
  const [category, setCategory] = useState(null);
  const [drawingData, setDrawingData] = useState([]);
  const [activeCategory, setActiveCategory] = useState(null);
  const [searchTerm, setSearch] = useState("");
  const [editData, setEditData] = useState();
  const [deleteData, setDeleteData] = useState();
  const [isDeleteActive, setisDeleteActive] = useState(false);
  const [LoadinState, setLoadinState] = useState(false);
  const [gridDrop, setgridDrop] = useState({ isShown: false, id: null });
  const ref = useRef();
  const buttonRef = useRef();
  const changeListingType = (type) => {
    setListingTypeState(type);
  };

  const showModal = () => {
    setEditData(null);
    setIsModalActive(true);
    setSearch("");
  };

  const hideModal = () => {
    setIsModalActive(false);
  };

  useEffect(() => {
    props.set_Value({
      title: props.title,
      withSettings: props.withSettings,
      rootClassName: props.rootClassName,
      mainClassName: props.mainClassName,
    });
    dispatch(GetDrawingsCategories())
      .then((res) => {
        setCategory(res.data);
        setActiveCategory(res.data[0].id);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    setPage(1);
    if (activeCategory) {
      setLoader(true);
      dispatch(
        GetDrawings(
          {
            project_id: projectId,
            category_id: activeCategory,
          },
          page
        )
      )
        .then((res) => {
          setMeta(res.meta);
          setDrawingData(res.data);
          setLoader(false);
        })
        .catch((err) => {
          notifyError("Error getting data!");
          setLoader(false);
        });
    }
  }, [activeCategory, projectId]);

  const fetchData = () => {
    if (activeCategory) {
      if (searchTerm?.length) setPage(0);
      setPage((prev) => prev + 1);
      dispatch(
        GetDrawings(
          {
            title: searchTerm ?? "",
            project_id: projectId,
            category_id: activeCategory,
          },
          page + 1
        )
      )
        .then((res) => {
          setMeta(res.meta);
          setDrawingData([...drawingData, ...res.data]);
        })
        .catch((err) => {
          notifyError("Error getting data!");
        });
    }
  };
  useEffect(() => {
    if (searchTerm) setDrawingData([]);
    setPage(0);
    setLoader(true);
    const delayDebounceFn = setTimeout(() => {
      if (activeCategory) {
        setPage((prev) => prev + 1);
        dispatch(
          GetDrawings(
            {
              title: searchTerm ?? "",
              project_id: projectId,
              category_id: activeCategory,
            },
            page
          )
        )
          .then((res) => {
            setMeta(res.meta);
            setDrawingData(res.data);
            setLoader(false);
          })
          .catch((err) => {
            setLoader(false);
            notifyError("Error getting data!");
          });
      }
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  const handleDelete = () => {
    setLoadinState(true);
    dispatch(DeleteDrawings(deleteData.id))
      .then((res) => {
        setDrawingData([]);
        setisDeleteActive(false);
        setLoadinState(false);
        if (res) {
          if (activeCategory) {
            dispatch(
              GetDrawings(
                {
                  project_id: projectId,
                  category_id: activeCategory,
                },
                page
              )
            )
              .then((res) => {
                setMeta(res.meta);
                setDrawingData(res.data);
                setLoader(false);
              })
              .catch((err) => {
                notifyError("Error getting data!");
                setLoader(false);
              });
          }
        }
      })
      .catch((err) => {
        notifyError(err.response.data.message ?? "Error deleting drawing");
        setisDeleteActive(false);
        setLoadinState(false);
      });
  };
  const handleEdit = (data) => {
    setEditData(data);
    setIsModalActive(true);
  };
  React.useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target) && !buttonRef.current.contains(event.target)) {
        setgridDrop({ isShown: false, id: null });
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

  const toggleDropdown = (id) => {
    if (gridDrop.isShown == true && gridDrop.id) {
      setgridDrop({ isShown: false, id: null });
    } else {
      setgridDrop({ isShown: true, id: id });
    }
  };

  return (
    <>
      {isModalActive && (
        <CreateDrawingModal
          activecategory={activeCategory}
          setactivecategory={setActiveCategory}
          hideModal={hideModal}
          isModalActive={isModalActive}
          category={category}
          editData={editData}
          setDrawingData={setDrawingData}
          setPage={setPage}
        />
      )}

      <div className="top pb-20">
        <div className="tabs">
          <NavLink to="/drawings" className="tab active">
            Current Drawings
          </NavLink>
          {/* <NavLink to="/drawing-sets" className="tab">
            Drawing Sets
          </NavLink> */}
        </div>
        <div className="options d-flex align-items-center">
          {/* <Checkbox rootClassName="me-10" inputEnd small bold label="Subscribe: " /> */}

          {/* <Dropdown btn dropdownItems={["Export document"]}>
            EXPORT
          </Dropdown> */}

          <button onClick={showModal} className="ms-10 btn btn-primary-outlined">
            <img className="me-10" src="/assets/vectors/upload-btn.svg" alt="add" />
            UPLOAD
          </button>
        </div>
      </div>

      <div className="card">
        <div className="card-head d-flex justify-content-between">
          <div className="d-flex">
            <Input
              rootClassName="m-0"
              type="search"
              placeholder="Search..."
              onChange={(e) => setSearch(e.target.value)}
              value={searchTerm}
            />
            {/* <Dropdown rootClassName="mx-20" btn dropdownItems={["Export document"]}>
              ADD FILTERS
            </Dropdown>
            <button className="btn btn-primary-text">CLEAR ALL</button> */}
          </div>
          <div className="more-filters" style={{cursor: "pointer"}}>
            {[
              {
                type: "list",
                icon: "/assets/vectors/list-check.svg",
              },
              {
                type: "grid",
                icon: "/assets/vectors/grid.svg",
              },
            ].map((el, idx) => {
              const { type, icon } = el;

              return (
                <img
                  key={"opt" + idx}
                  className={clsx({
                    active: type === listingTypeState,
                  })}
                  onClick={() => {
                    changeListingType(type);
                  }}
                  src={icon}
                  alt={type}
                />
              );
            })}
          </div>
        </div>
        <div className="card-body p-0 d-flex">
          <div className="card-left">
            <div className="card-nav">
              {category?.map((el, idx) => {
                const { title, id } = el;
                return (
                  <div
                    key={"body-nav-" + idx}
                    className={`nav-item ${id == activeCategory && "active"}`}
                    onClick={() => setActiveCategory(id)}
                  >
                    {title}
                  </div>
                );
              })}
            </div>

            {/* <div className="sets">
              {[
                {
                  head: "Permit set",
                  status: "Ready for Review",
                  desc: "26 sheets require your attention",
                },
                {
                  head: "Permit set",
                  status: "Ready for Review",
                  desc: "1 sheets require your attention",
                },
              ].map((el, idx) => {
                const { head, status, desc } = el;

                return (
                  <div key={"set" + idx} className="set">
                    <div className="set-head d-flex align-items-center justify-content-between">
                      <div className="fs-13 fw-bold">{head}</div>
                      <img src="/assets/vectors/delete-table.svg" alt="delete" />
                    </div>
                    <div className="set-body">
                      <div className="fs-13">{status}</div>
                      <div className="fs-12 mt-1 text-light-1">{desc}</div>
                    </div>
                    <div className="set-foot d-flex justify-content-center">
                      <button className="btn text-center btn-primary-text fs-12">CLICK HERE TO REVIEW</button>
                    </div>
                  </div>
                );
              })}
            </div> */}

            {/* <div className="reports">
              <div className="fw-bold mb-20">DRAWING REPORTS</div>
              {[
                {
                  name: "All Sets and Revisions",
                },
                {
                  name: "Sketches",
                },
                {
                  name: "Deleted Drawing Revisions",
                },
                {
                  name: "Measurements",
                },
              ].map((el, idx) => {
                const { name } = el;

                return (
                  <div key={"report" + idx} className="report">
                    <div className="fs-14 fw-bold">{name}</div>
                  </div>
                );
              })}
            </div> */}
          </div>
          <div className="card-right">
            <h4 className="fs-20 mb-20">{category && category[activeCategory - 1]?.title}</h4>

            {listingTypeState === "list" && (
              <InfiniteScroll
                dataLength={drawingData?.length}
                next={fetchData}
                hasMore={meta && meta?.total > drawingData?.length}
                loader={loader ? <Loader /> : null}
                height={"100%"}
                style={{ overflow: "scroll", overflowX: "hidden", maxHeight: "550px" }}
                className="infinite-scroll w-100"
              >
                <div className="table-container">
                  <table>
                    <thead>
                      <tr>
                        <th>Drawing No.</th>
                        <th>Drawing Title</th>
                        <th>Revision</th>
                        <th>Drawing Date</th>
                        <th>Received Date</th>
                        <th>Set</th>
                        <th className="text-center">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {loader ? (
                        <tr>
                          <td colSpan={"8"}>
                            <Loader size={30} />
                          </td>
                        </tr>
                      ) : drawingData.length ? (
                        drawingData.map((el, idx) => {
                          const { id, number, title, drawing_date, recieved_date, revision, set } = el;

                          return (
                            <tr key={"drawing-list-item" + idx}>
                              {/* <td>
                                <Checkbox />
                              </td> */}
                              <td>
                                <a
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    history.push(`/admin/project/${projectId}/tool/drawings/${id}`)
                                  }
                                >
                                  {number}
                                </a>
                              </td>
                              <td>{title}</td>
                              <td>{revision}</td>
                              <td>{moment(drawing_date).format("DD-MM-YYYY")}</td>
                              <td>{moment(recieved_date).format("DD-MM-YYYY")}</td>
                              <td>{set}</td>
                              <td>
                                <div className="d-flex text-center">
                                  <button
                                    className="btn btn-contained-2 me-10"
                                    style={{ height: 40 }}
                                    onClick={() =>
                                      history.push(`/admin/project/${projectId}/tool/drawings/${id}`)
                                    }
                                  >
                                    <img className="me-10" src="/assets/vectors/eye.svg" alt="edit" />
                                  </button>
                                  <button
                                    className="btn btn-contained-2 me-10"
                                    style={{ height: 40 }}
                                    onClick={() => handleEdit(el)}
                                  >
                                    <img className="me-10" src="/assets/vectors/edit-table.svg" alt="edit" />
                                  </button>
                                  <button
                                    className="btn btn-contained-2 ms-10"
                                    style={{ height: 40 }}
                                    onClick={() => {
                                      setDeleteData(el);
                                      setisDeleteActive(true);
                                    }}
                                  >
                                    <img
                                      className="ms-10"
                                      src="/assets/vectors/delete-table.svg"
                                      alt="delete"
                                    />
                                  </button>
                                  {/* <button className="btn btn-contained-2 me-10">INFO</button>
                                  <button className="btn btn-contained-2 ms-10">OPEN</button> */}
                                </div>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan={"8"} style={{ padding: "20px" }}>
                            No Record found
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </InfiniteScroll>
            )}

            {listingTypeState === "grid" && (
              <InfiniteScroll
                dataLength={drawingData?.length}
                next={fetchData}
                hasMore={meta && meta?.total > drawingData?.length}
                loader={loader ? null : <Loader />}
                scrollThreshold={0.5}
                height={550}
                style={{ overflow: "scroll", overflowX: "hidden" }}
              >
                <div className="drawing-grid container-fluid px-0">
                  <div className="row g-4">
                    {loader ? (
                      <p style={{ padding: "20px" }}>
                        <Loader size={36} />
                      </p>
                    ) : !drawingData?.length ? (
                      <p className="heading">No Record Found</p>
                    ) : (
                      drawingData.map((el, idx) => {
                        const { name, revision, title, files, id } = el;
                        return (
                          <div key={"grid-item" + idx} className="col">
                            <div className="drawing-grid-item">
                              <div className="position-relative">
                                <img
                                  src="/assets/img/more.png"
                                  style={{
                                    width: 20,
                                    height: 20,
                                    display: "block",
                                    marginLeft: "auto",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => toggleDropdown(id)}
                                  ref={buttonRef}
                                />
                                {gridDrop?.id == id && gridDrop?.isShown ? (
                                  <div className="dropdown_div" ref={ref}>
                                    <button onClick={() => handleEdit(el)}>Edit</button>
                                    <button
                                      onClick={() => {
                                        setDeleteData(el);
                                        setisDeleteActive(true);
                                        setgridDrop({ isShown: false, id: null });
                                      }}
                                    >
                                      Delete
                                    </button>
                                  </div>
                                ) : null}
                              </div>
                              <Link to={`/admin/project/${projectId}/tool/drawings/${id}`}>
                                <img src={files[0]?.file ?? "/assets/img/no-image.png"} alt="drawing" />
                                <div className="mt-10 d-flex justify-content-between">
                                  <div className="fw-bold fs-13">{name}</div>
                                  <div className="fs-13">Rev. {revision}</div>
                                </div>
                              </Link>
                              <div className="fs-14 mt-2">{title}</div>
                            </div>
                          </div>
                        );
                      })
                    )}
                  </div>
                </div>
              </InfiniteScroll>
            )}
          </div>
        </div>
      </div>
      <ConsentModel
        title={deleteData?.title}
        active={isDeleteActive}
        oncancel={setisDeleteActive}
        onsubmit={handleDelete}
        loader={LoadinState}
      />
    </>
  );
};

export default Drawings;
