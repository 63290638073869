import React, { useState, useEffect } from "react";
import { CreateContrators, FetchContractorTypes } from "app/redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { ContractorsValidation, notifyError, notifySuccess } from "utilities";
import Input from "./Input";
import Modal from "./Modal";

import PhoneNumberInput from "./common/PhoneNumberInput";
import SelectDropdown from "./SelectDropdown";
import { dropDownCustomStyles } from "./common/DropDownStyle";
const ContractorsModel = (props) => {
  const dispatch = useDispatch();
  const [isValid, setIsvalid] = useState(false);
  const [formValidation, setFormValidation] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const userLocation = useSelector((state) => state?.AuthReducer?.location);

  const [contractorsTypes, setContractorTypes] = useState([]);

  const [values, setValues] = useState({
    name: "",
    location: userLocation?.location?.city,
    contact: "",
    type: "",
  });

  const handleChange = (name) => (event) => {
    setValues({
      ...values,
      [name]: event.target.value ? event.target.value : event.value,
    });
  };

  const handlePhoneInput = (value, name) => {
    setValues({ ...values, [name]: value });
  };

  const handleTypeChange = (event) => {
    setValues({ ...values, type: event?.value });
  };

  useEffect(() => {
    dispatch(FetchContractorTypes())
      .then((res) => {
        const updatedTypes = res.data.map((type) => ({
          ...type,
          value: type.id,
          label: type.title,
        }));
        setContractorTypes(updatedTypes);
      })
      .catch((error) => {});
  }, []);

  const formSubmit = (event) => {
    event?.preventDefault();
    setFormValidation(ContractorsValidation(values));
    setIsvalid(true);
  };

  useEffect(() => {
    if (isValid && Object.keys(formValidation).length === 0) {
      const payload = {
        name: values?.name,
        location: values?.location,
        contact_no: values?.contact,
        contractor_type_id: values?.type,
      };
      setIsLoading(true);
      dispatch(CreateContrators(payload))
        .then((res) => {
          notifySuccess("Contractor Created Successfully.");
          props.updateContractor(res.data);
          setIsLoading(false);
          setValues({
            name: "",
            location: "",
            contact: "",
            type: "",
          });
          props.isClose();
        })
        .catch((err) => {
          notifyError("something went wrong");
          setIsLoading(false);
        });
    }
  }, [formValidation]);

  const hideModel = () => {
    props.isClose();
  };

  const defaultvalue = props?.editData
    ? { label: values.type, value: props?.editData?.type?.id }
    : { label: "Select Type", value: null };

  return (
    <Modal
      active={props.isActive}
      submit={formSubmit}
      hideModal={hideModel}
      loader={isLoading}
    >
      <h4 className="mb-4" style={{ fontSize: "20px" }}>
        ADD CONTRACTOR OR SUB-CONTRACTOS
      </h4>
      <Input
        rootClassName="mb-20"
        label="Name"
        placeholder="Enter Name"
        onChange={handleChange("name")}
        errorMsg={formValidation.name}
      />

      <SelectDropdown
        className="basic-single"
        classNamePrefix="select"
        isLoading={false}
        isSearchable={true}
        options={contractorsTypes ?? []}
        styles={dropDownCustomStyles}
        defaultValue={defaultvalue}
        placeholder={"Select Role"}
        onChange={(e) => handleTypeChange(e)}
        validation={formValidation?.type}
        loadingMessage={() => "Fetching Roles"}
        label={"Roles"}
      />

      <PhoneNumberInput
        lable="Mobile Number"
        name="mobileNo"
        placeholder="Enter Mobile Number"
        errorMsg={formValidation.contact}
        value={values.contact}
        required={true}
        onChange={(value) => handlePhoneInput(value, "contact")}
        rootClassName="mb-20"
      />
      <Input
        label="Location"
        type="text"
        placeholder="Enter Location"
        onChange={handleChange("location")}
        errorMsg={formValidation.location}
        defaultValue={values?.location}
      />
    </Modal>
  );
};

export default ContractorsModel;
