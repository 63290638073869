import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { notifyError, notifySuccess, validateEmail } from "utilities";
import { forgotPassword } from "app/redux/actions";

const ForgotPassword = () => {

  const history = useHistory();
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const dispatch = useDispatch();
  
  const handleSubmit = () => {
    
    if (email && validateEmail(email)) {
      dispatch(forgotPassword({ email: email }))
        .then((res) => {
          notifySuccess(res.payload.message);
          setTimeout(() => {
            history.push("/auth/login");
          }, 500);

        })
        .catch((err) => {
          notifyError(err.payload.response.data.message);
        });
    } else {
      setEmailError("Please provide a valid Email");
    }
  };
  return (
    <div className="form">
      <div className="custom-form-control">
        <label htmlFor="email">Email</label>
        <input
          type="email"
          placeholder="Enter Your Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        {emailError && <p className="error">{emailError}</p>}
      </div>
      <div className="bottom">
        <button className="btn btn-primary btn-auth" onClick={handleSubmit}>
          SEND
        </button>
      </div>
    </div>
  );
};

export default ForgotPassword;
