import React from "react";
import Modal from "../components/Modal";
import { useHistory } from "react-router";
import { useSelector } from "react-redux";

const InvoiceTypes = ({isOpen, isClose}) => {
  const history = useHistory();
  const projectId = useSelector((state) => state.navigation.projectId);
    const selectType = (type) => {
      if(type === 'po'){
        history.push(`/admin/project/${projectId}/tool/invoices/create`);
      }
      if(type === 'co'){
        history.push(`/admin/project/${projectId}/tool/invoices/change-invoice`);
      }
      if(type === 'pr'){
        history.push(`/admin/project/${projectId}/tool/invoices/paymentRequest`)
      }
    }

    // model box ui
    return (
        <Modal
          active={isOpen}
          hideModal={isClose}
        >
          {(
            <h4 style={{ paddingBottom: "15px" }}>Select Type</h4>
          )}

          <div className="container-fluid mt-20 px-0">
            <div className="row">
              <div className="col-lg-12 custom-form-control">
                {/* <label>Select Type</label> */}
                <button className="ms-10 btn btn-primary-outlined"
                    label="Purchase Order"
                    onClick={() => selectType('po')}
                >Purchase Order</button>
                <button className="ms-10 btn btn-primary-outlined"
                    label="Purchase Order"
                    onClick={() => selectType('co')}
                >Change Order</button>
                <button className="ms-10 btn btn-primary-outlined"
                    label="Purchase Order"
                    style={{marginTop: "22px"}}
                    onClick={() => selectType('pr')}
                >Payment Request</button>
              </div>
            </div>
          </div>
        </Modal>
      );
};

export default InvoiceTypes;