import React, { useEffect, useState } from "react";
import { contractorDetail, contractsdetail } from "app/redux/actions";
import Loader from "components/Loader";
import { useDispatch } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";

const SubContractor = (props) => {
  const { id, contractorId } = useParams();
  const dispatch = useDispatch();

  const history = useHistory();
  const { pathname } = history.location;

  useEffect(() => {
    props.set_Value({
      title: props.title,
      rootClassName: props.rootClassName,
      mainClassName: props.mainClassName,
    });
    subContractorDetails();
    contractsDetails();
  }, []);

  const [contractor, setContractor] = useState(null);
  const [loader, setLoader] = useState(false);

  const subContractorDetails = () => {
    if (id && contractorId) {
      setLoader(true);
      dispatch(contractorDetail(id, contractorId))
        .then((res) => {
          setContractor(res.data);
          setLoader(false);
        })
        .catch((error) => {
          console.log(error);
          setLoader(false);
        });
    }
  };

  const [contractsData, setContractsData] = useState();

  const contractsDetails = () => {
    if (
      pathname.includes(`/admin/project/${id}/exportStatment/sub-contractor`)
    ) {
      setLoader(true);
      dispatch(contractsdetail(id))
        .then((res) => {
          setContractsData(res);
          setLoader(false);
        })
        .catch((error) => {
          console.log(error);
          setLoader(false);
        });
    }
  };

  return (
    <div>
      {/* Bread Crumbs */}
      <div className="top pb-20" style={{ borderBottom: "none" }}>
        <div className="breadcrumb">
          <Link to={`/admin/project/${id}`} className="breadcrumb-item">
            Dashboard
          </Link>
          <Link
            to={`/admin/project/${id}/exportStatment`}
            className="breadcrumb-item"
          >
            Export Statment
          </Link>
          <div className="breadcrumb-item">Sub Contractor</div>
        </div>
      </div>
      {/* End Bread Crumbs */}
      {/* Export Section */}
      <div className="top pb-20 mb-20 mt-20" style={{ borderBottom: "none" }}>
        <div className="section-title">
          <h4>Sub Contractor</h4>
        </div>
        <div className="options d-flex align-items-right">
          {/* <button
            className="ms-10 btn btn-primary"
            style={{ background: "#FFF2D9", color: "#E79436" }}
          >
            EXPORT
          </button> */}
        </div>
      </div>
      {/* End Export Section */}
      {/* Sub Contractor Section */}
      <div>
        {pathname.includes(
          `/admin/project/${id}/exportStatment/sub-contractor/${contractorId}`
        ) ? (
          <>
            <h4 className="mb-3">Vesta-OH LLC (Kitchen Cabinet & C.T)</h4>
            <div className="table-container">
              <div className="section m-20">
                <div className="section-title mb-10">
                  <h4>Contracts</h4>
                </div>
                <div className="row m-2 table-heading invoice__details">
                  <div className="col-md-3">
                    <label className="headings">Contractor Name:</label>
                    <div>{contractor?.name}</div>
                  </div>
                  <div className="col-md-3">
                    <label className="headings">Contact Number:</label>
                    <div>{contractor?.contact_no}</div>
                  </div>
                  <div className="col-md-3">
                    <label className="headings">Located At:</label>
                    <div>{contractor?.location}</div>
                  </div>
                </div>
                <div className="table-container">
                  <table className="team">
                    <thead>
                      <tr>
                        <th colSpan={2}>Start Date</th>
                        <th className="text-center" colSpan={4}>
                          Description
                        </th>
                        <th className="text-center">Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      {loader ? (
                        <tr>
                          <td colSpan={5}>
                            <Loader />
                          </td>
                        </tr>
                      ) : contractor === null ? (
                        <tr>
                          <td>No Record Found</td>
                        </tr>
                      ) : (
                        <>
                          {contractor?.contracts?.map((data, index) => {
                            const date = new Date(data?.start_date);
                            return (
                              <tr key={index}>
                                <td colSpan={2}>
                                  {date?.toLocaleDateString()}
                                </td>
                                <td
                                  className="text-center"
                                  colSpan={4}
                                  dangerouslySetInnerHTML={{
                                    __html: data?.description,
                                  }}
                                />
                                <td className="text-center">${data.amount}</td>
                              </tr>
                            );
                          })}
                        </>
                      )}
                      <tr>
                        <td
                          style={{
                            textAlign: "center",
                            color: "#2F5597",
                            fontWeight: "bold",
                          }}
                          colSpan={6}
                        >
                          Total Contract
                        </td>
                        <td style={{ textAlign: "center", fontWeight: "bold" }}>
                          ${contractor?.contractsTotal}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              {/* Payment Reciveing */}
              <div className="section m-20">
                <div className="section-title mb-10">
                  <h4>Payment</h4>
                </div>
                <div className="table-container">
                  <table className="team">
                    <thead>
                      <tr>
                        <th colSpan={2}>Date</th>
                        <th className="text-center" colSpan={4}>
                          Description
                        </th>
                        <th className="text-center">Paid Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td colSpan={2}>10/10/2022</td>
                        <td className="text-center" colSpan={4}>
                          Its Test Description 12
                        </td>
                        <td className="text-center">$ 100</td>
                      </tr>
                      <tr>
                        <td
                          style={{ textAlign: "center", fontWeight: "bold" }}
                          colSpan={6}
                        >
                          Total Paid
                        </td>
                        <td style={{ textAlign: "center", fontWeight: "bold" }}>
                          $ 1000 -
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            textAlign: "center",
                            color: "red",
                            fontWeight: "bold",
                          }}
                          colSpan={6}
                        >
                          Balance Receivable
                        </td>
                        <td
                          style={{
                            textAlign: "center",
                            color: "red",
                            fontWeight: "bold",
                          }}
                        >
                          $ 1000 -
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </>
        ) :
          pathname.includes(
            `/admin/project/${id}/exportStatment/sub-contractor`
          ) ? (
          <>
            {loader ? (
              <tr>
                <td colSpan={5}>
                  <Loader />
                </td>
              </tr>
            ) : contractsData === undefined ? (
              <tr>
                <td>No Record Found</td>
              </tr>
            ) : (
              <>
                {contractsData?.map((contract, index) => {
                  return (
                    <>
                      {contract?.contract?.map((data, index) => {
                        const date = new Date(data?.start_date);
                        return (
                          <>
                            <h4 className="mb-3 mt-4">{data?.contractor?.name}</h4>
                            <div className="table-container">
                              <div className="section m-20">
                                <div className="section-title mb-10">
                                  <h4>Contracts</h4>
                                </div>
                                <>
                                  <div className="table-container mt-4">
                                    <table className="team">
                                      <thead>
                                        <tr>
                                          <th colSpan={2}>Start Date</th>
                                          <th
                                            className="text-center"
                                            colSpan={4}
                                          >
                                            Description
                                          </th>
                                          <th className="text-center">
                                            Amount
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr key={index}>
                                          <td colSpan={2}>
                                            {date?.toLocaleDateString()}
                                          </td>
                                          <td
                                            className="text-center"
                                            colSpan={4}
                                            dangerouslySetInnerHTML={{
                                              __html: data?.description,
                                            }}
                                          />
                                          <td className="text-center">
                                            ${data.amount}
                                          </td>
                                        </tr>
                                        <tr>
                                          <td
                                            style={{
                                              textAlign: "center",
                                              color: "#2F5597",
                                              fontWeight: "bold",
                                            }}
                                            colSpan={6}
                                          >
                                            Total Contract
                                          </td>
                                          <td
                                            style={{
                                              textAlign: "center",
                                              fontWeight: "bold",
                                            }}
                                          >
                                            ${contractor?.contractsTotal}
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </>
                              </div>
                            </div>
                          </>
                        );
                      })}
                    </>
                  );
                })}
              </>
            )}
          </>
        ) : null}
      </div>
      {/* End Sub Contractor Section */}
    </div>
  );
};
export default SubContractor;
