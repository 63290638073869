import { LOGOUT, PROJECT_FILTER, TOOLS_FILTER } from "../constants/index";

const initial_state = {
  projectId: sessionStorage.getItem("ProjectState") || null,
  toolsId: sessionStorage.getItem("ToolState") || null,
};

const Navigation = (state = initial_state, action) => {
  switch (action.type) {
    case PROJECT_FILTER:
      sessionStorage.setItem("ProjectState", action.payload);
      return {
        ...state,
        projectId: action.payload,
      };
    case TOOLS_FILTER:
      sessionStorage.setItem("ToolState", action.payload);
      return {
        ...state,
        toolsId: action.payload,
      };
    case LOGOUT:
      sessionStorage.removeItem("ProjectState");
      sessionStorage.removeItem("ToolState");
      return {
        ...state,
        toolsId: null,
        projectId: null,
      };
    default:
      return state;
  }
};

export default Navigation;
