import Signin from "../screens/Signin";
import Verification from "../screens/Verification";
import Signup from "../screens/Signup";
import ForgotPassword from "../screens/ForgotPassword";
import ResetPassword from "screens/ResetPassword";

var routes = [
  {
    path: "/login",
    layout: "/auth",
    name: "Login Page",
    mini: "LP",
    component: Signin,
  },
  {
    path: "/signup",
    layout: "/auth",
    name: "SignUp Page",
    mini: "SP",
    component: Signup,
  },
  {
    path: "/forgot-password",
    layout: "/auth",
    name: "Forgot Password Page",
    mini: "FPP",
    component: ForgotPassword,
  },
  {
    path: "/verification",
    layout: "/auth",
    name: "Verification Page",
    mini: "VP",
    component: Verification,
  },
  {
    path: "/reset-password",
    layout: "/auth",
    name: "Reset Password Page",
    mini: "RPP",
    component: ResetPassword,
  },
];

export default routes;
