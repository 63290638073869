import { isEmpty } from "lodash";
import React from "react";
import Loader from "./Loader";
const ChangeHistory = ({ data, loader }) => {
  return (
    <div className="card todochangehistory-card" style={{ marginBottom: "0px"}}>
      <div className="card-header" style={{ textAlign: "left" }}>
        <h3 className="card-heading" style={{ textAlign: "center" }}>Change History</h3>
      </div>
      <div className="card-body">
        <div className="row">
          {loader ? (
            <tr style={{ textAlign: "center" }}>
              <td colSpan={12}>
              <Loader />
              </td>
            </tr>
            
          ) : isEmpty(data) ? (
            <p>No Change History</p>
          ) : (
            data?.map((data) => (
              <>
                <div className="col-md-12" style={{ fontSize: "18px" }}>
                  <h4>{data?.date ?? "You Have No Change History"}</h4>
                </div>

                <div className="row table-heading">
                  <div className="col-md-5 headings">Action By:</div>
                  <div className="col-md-7 small-text headings">Changed:</div>
                </div>
                <div className="row table-description">
                  <div className="col-md-5" style={{ fontSize: "14px" }}>
                    {data?.action_by ?? "-"}
                  </div>
                  <div className="col-md-7 text-date" style={{ fontSize: "14px" }}>
                    {data?.changed}
                  </div>
                </div>
                <div className="row  table-heading">
                  <div className="col-md-5 headings">From:</div>
                  <div className="col-md-7 small-text headings">To:</div>
                </div>
                <div className="row table-description">
                  <div className="col-md-5" style={{ fontSize: "14px" }}>
                    {data?.from}
                  </div>
                  <div className="col-md-7 text-date" style={{ fontSize: "14px" }}>
                    {data?.to}
                  </div>
                </div>
                <hr className="horizntal-row" />
              </>
            ))
          )}
        </div>
      </div>
    </div>
  );
};

export default ChangeHistory;
