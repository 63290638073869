import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { notifyError } from "utilities";
import { MeetingDetail } from "app/redux/actions";
import { useParams } from "react-router";
import { useSelector } from "react-redux";
import Loader from "components/Loader";
import { Link } from "react-router-dom";
import moment from "moment";
const MeetingsDetails = (props) => {
  const [loader, setLoader] = useState(false);
  const projectId = useSelector((state) => state.navigation.projectId);
  const { id } = useParams();
  const dispatch = useDispatch();
  const [detail, setDetail] = useState();
  const getMeetingDetails = () => {
    dispatch(MeetingDetail(projectId, id))
      .then((res) => {
        setDetail(res.data);
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
        notifyError(err?.error?.response?.data?.message ?? "Error", 5000);
      });
  };
  useEffect(() => {
    props.set_Value({
      title: props.title,
      withSettings: props.withSettings,
      rootClassName: props.rootClassName,
      mainClassName: props.mainClassName,
    });
    setLoader(true);
    getMeetingDetails();
  }, []);
  return (
    <>
      <div className="top pb-20" style={{ borderBottom: "none" }}>
        <div className="breadcrumb">
          <Link to={`/admin/project/${projectId}/tool/meetings`} className="breadcrumb-item">
            Meetings
          </Link>
          <div className="breadcrumb-item">Meetings Details</div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-8">
          <div className="card-left m-10">
            <div className="table-container">
              <div className="row m-2 table-heading">
                <div className="col-md-3 headings">Number:</div>
                <div className="col-md-3 headings">Title:</div>
                <div className="col-md-3 headings">Loacation:</div>
                <div className="col-md-3 headings">Obsolet:</div>
              </div>
              <div className="row m-2 table-description">
                <div className="col-md-3">{detail?.number}</div>
                <div className="col-md-3">{detail?.title}</div>
                <div className="col-md-3">{detail?.location}</div>
                <div className="col-md-3">No</div>
              </div>
              <div className="row m-2 table-heading">
                <div className="col-md-3 headings">Meeting Link:</div>
                <div className="col-md-3 headings">Meeting Date:</div>
                <div className="col-md-3 headings">Start Time:</div>
                <div className="col-md-3 headings">End Time:</div>
              </div>
              <div className="row m-2 table-description">
                <div className="col-md-3" style={{ overflow: "hidden"}}>{detail?.meeting_link}</div>
                <div className="col-md-3">{moment(detail?.meeting_date).format("YYYY-MM-DD")}</div>
                <div className="col-md-3">{detail?.start_time}</div>
                <div className="col-md-3">{detail?.end_time}</div>
              </div>
              <div className="row m-2 table-heading">
                <div className="col-md-3 headings">Attendess:</div>
                <div className="row table-description">
                  {detail?.attendees.map((value) => (
                    <div className="col-md-3" key={value.id}>
                      {value?.name}
                    </div>
                  ))}
                </div>
              </div>
              <div className="row m-2 table-heading">
                <div className="col-md-12 headings">
                  <label className="headings mb-3 d-block">Attachments:</label>
                  <div className="d-flex flex-wrap imageLoader">
                    {loader ? (
                      <Loader size={36} />
                    ) : (
                      detail?.attachements?.map((img, idx) => (
                        <img
                          key={idx}
                          src={img?.file ?? img?.url ?? img}
                          style={{
                            padding: "5px",
                            maxWidth: "350px",
                            height: "350px",
                          }}
                        />
                      ))
                    )}
                  </div>
                </div>
                {/* <div className="row" style={{marginTop: "30px"}}>
              <div className="col-md-3">
                <button className="btn btn-primary m-10">EDIT</button>
              </div>
            </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default MeetingsDetails;
