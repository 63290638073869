import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { notifyError } from "utilities";
import { GeneratePDF, GetInvoiceDetail } from "app/redux/actions";
import { useParams } from "react-router";
import Dropdown from "../components/Dropdown";
import Loader from "components/Loader";
import { Link } from "react-router-dom";
import ExportSignatureModal from "components/ExportSignatureModal";

const InvoiceDetails = (props) => {
  const [loader, setLoader] = useState(false);
  const { id } = useParams();
  const projectId = useSelector((state) => state.navigation.projectId);
  const [invoiceDetail, setInvoiceDetail] = useState();
  const dispatch = useDispatch();
  const getInvoiceDetail = () => {
    dispatch(GetInvoiceDetail({ project_id: projectId }, {invoice_id: id}))
      .then((res) => {
        setInvoiceDetail(res?.data);
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
        notifyError(err?.error?.response?.data?.message ?? "Error", 5000);
      });
  };
  useEffect(() => {
    props.set_Value({
      title: props.title,
      rootClassName: props.rootClassName,
      mainClassName: props.mainClassName,
    });
    getInvoiceDetail();
  }, []);

  const handleExport = () => {
    const payload = {
      projectId: projectId,
      invoiceId: invoiceDetail?.id,
    };
    dispatch(GeneratePDF(payload))
      .then((res) => {
        window.open(res?.link, "_blank");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // e-signatures
  const [isExportModalActive, setSignatures] = useState(false);
  const showExportModal = () => {
    setSignatures(true);
  };

  const hideExportModal = () => {
    setSignatures(false);
  };


  return (
    <>
    { isExportModalActive && (
        <ExportSignatureModal 
          active={isExportModalActive}
          hideModal={hideExportModal}
          setSignatures={setSignatures}
          titleText={"Export"}
        />
      )}
      <div className="top pb-20" style={{ borderBottom: "none" }}>
        <div className="breadcrumb">
          <Link
            to={`/admin/project/${projectId}/tool/invoices`}
            className="breadcrumb-item"
          >
            Invoices
          </Link>
          <div className="breadcrumb-item">Invoice Details</div>
        </div>
        <div className="options d-flex align-items-right">
          <button
            // onClick={handleExport}
            onClick={showExportModal}
            className="ms-10 btn btn-primary"
            style={{ background: "#FFF2D9", color: "#E79436" }}
          >
            EXPORT
          </button>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12">
          <div className="card-left m-10">
            <div className="table-container">
              <div className="row m-2 table-heading invoice__details">
                <div className="col-md-4">
                  <label className="headings">Invoice ID</label>
                  <div>{invoiceDetail?.number}</div>
                </div>
                <div className="offset-md-3 col-md-4">
                  <label className="headings">Sales Person</label>
                  <div>{invoiceDetail?.sales_person_id?.name}</div>
                </div>
              </div>
              <form className="form m-10 project-detail-form mt-4">
                <div className="table-container invoice_details_table">
                  <table className="">
                    <thead>
                      <tr>
                        <td>
                          <h4 className="card-heading text-start">
                            Description
                          </h4>
                        </td>
                        <td>
                          <h4 className="card-heading text-start">Quantity</h4>
                        </td>
                        <td>
                          <h4 className="card-heading text-start">
                            Selling Price
                          </h4>
                        </td>
                        <td>
                          <h4 className="card-heading text-start">Tax Type</h4>
                        </td>
                        <td>
                          <h4 className="card-heading text-start">Tax Value</h4>
                        </td>
                        <td>
                          <h4 className="text-start card-heading">Amount</h4>
                        </td>
                      </tr>
                    </thead>
                    <tbody>
                      {invoiceDetail?.items?.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td>{item?.name}</td>
                            <td>{item?.qty}</td>
                            <td>{item?.rate}</td>
                            <td>{item?.tax_type}</td>
                            <td>{item?.tax}</td>
                            <td>{item?.amount}</td>
                          </tr>
                        );
                      })}
                      <tr className="grand_total_row ">
                        <td colSpan="4"></td>
                        <td>
                          Discount{" "}
                          {invoiceDetail?.total?.discount_type == "percentage"
                            ? "(%)"
                            : "($)"}
                        </td>
                        <td>{invoiceDetail?.total?.discount_value}</td>
                      </tr>
                      <tr className="grand_total_row ">
                        <td colSpan="4"></td>
                        <td>Shipping Cost</td>
                        <td>{invoiceDetail?.total?.shipping_cost}</td>
                      </tr>
                      <tr className="grand_total_row">
                        <td colSpan="4"></td>
                        <td>Adjustment</td>
                        <td>{invoiceDetail?.total?.adjustment}</td>
                      </tr>
                      <tr className="grand_total_row">
                        <td colSpan="4"></td>
                        <td>Total Amount</td>
                        <td>{invoiceDetail?.total?.total_amount}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </form>
              <div className="row m-2 table-heading invoice__details">
                <div className="col-md-12 ">
                  <label className="headings">Attachments</label>
                  <div className="imageLoader">
                    {loader ? (
                      <tr>
                        <td>
                          <Loader size={36} />
                        </td>
                      </tr>
                    ) : (
                      invoiceDetail?.attachments?.map((img, index) => (
                        <img
                          src={img?.url ?? img?.ref}
                          key={index}
                          style={{
                            maxWidth: "350px",
                            margin: "5px",
                            height: "430px",
                            objectFit: "cover",
                          }}
                        />
                      ))
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default InvoiceDetails;
