import React from "react";
import Select from "react-select";

const SelectDropdown = ({ label, validation, ...rest }) => {
  return (
    <div className="custom-form-control mb-4">
      {label && (
        <label htmlFor="">
          {label} <span style={{ color: "red" }}>*</span>
        </label>
      )}
      <Select {...rest} />
      {validation && <p className="error-msg">{validation}</p>}
    </div>
  );
};

export default SelectDropdown;
