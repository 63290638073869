import React from "react";
import Select from "react-select";
const CustomSelector = ({ title, data, changehandler, ...rest }) => {
  return (
    <>
      <label>{title}</label>
      <Select
        isMulti
        style={{ background: "#f1f1f1f1" }}
        className="basic-multi-select"
        classNamePrefix="select"
        options={data}
        onChange={(e) => changehandler(e)}
        {...rest}
      />
    </>
  );
};
export default CustomSelector;
