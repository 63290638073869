import { LOGOUT } from "app/redux/constants";
import React, { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";

import Dropdown from "../components/Dropdown";

const UserInfo = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.AuthReducer.user);
  const history = useHistory();
  const handleLogout = (e) => {
    e.preventDefault();
    dispatch({ type: LOGOUT });
    sessionStorage.removeItem("ProjectState");
    sessionStorage.removeItem("ToolState");
    history.push("/auth/login");
  };

  const handleSettings = (e, element) => {
    e.preventDefault();
    if (element === "Settings") {
      history.push("/admin/settings");
    } else if (element === "Logout") {
      handleLogout(e);
    } else if (element === "Dashboard") {
      history.push("/admin/dashboard");
    }
  };
  return (
    <Dropdown
      dropdownItems={["Settings", "Logout", "Dashboard"].map((el, idx) => {
        return (
          <Link key={"user-info" + idx} onClick={(e) => handleSettings(e, el)}>
            {el}
          </Link>
        );
      })}
    >
      <div className="user-info">
        <div className="img-placeholder">
          {user?.first_name[0] + user?.last_name[0]}
        </div>
        <div
          className="fw-bold"
          style={{
            textTransform: "capitalize",
          }}
        >
          {user?.first_name + " " + user?.last_name}
        </div>
      </div>
    </Dropdown>
  );
};

export default UserInfo;
