import React from "react";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import moment from "moment";
const DailyLog = ({ logMinimal }) => {
  const Companies = useSelector((state) => state.Project.allCompanies);
  return (
    <div className="logs-container" style={{
      height: '300px !important'
    }}>
      <div className="main-title">
        <h3>Recent Updates</h3>
        {/* <div>
          <button className="btn btn-primary-text fs-12">FILTER</button>
          <button className="btn btn-contained-2 fs-12 ms-20" onClick={() => <Redirect to="/daily-logs" />}>
            VIEW ALL
          </button>
        </div> */}
      </div>

      <div
        className="log-items"
        // style={{
        //   height: "250px",
        //   overflowY: "scroll",
        //   // overflow: 'hidden'
        // }}
      >
        {Companies?.map((company) => {
          return company?.logs?.map((el, idx) => {
            const { title, description, created_at } = el;
            return (
              <div key={"log-item" + idx} className="log-item">
                <div className="d-flex justify-content-between">
                  <div className="me-10">
                    <div className="name fw-bold">{title}</div>
                    <div className="desc fs-12">{description}</div>
                  </div>
                  <div className="text-light-1 text-right fs-12">
                    {moment(created_at).format("DD-MMM-YYYY")}
                  </div>
                </div>
              </div>
            );
          });
        })}
      </div>
    </div>
  );
};

export default DailyLog;
