import React from "react";
import clsx from "clsx";
import Loader from "./Loader";

const Modal = ({ active, hideModal, children, submit, loader, submitText, hideFooter, photo_Modal }) => {
  return (
    <>
      <div onClick={hideModal} className={clsx("custom-modal-overlay", { active })}></div>
      <div className={clsx("custom-modal", { active, photo_Modal })}>
        <div className="wrap">
          <div className="modal-body">{children}</div>
          {hideFooter ? null : (
            <div className="modal-foot my-30 d-flex justify-content-end">
              <button onClick={hideModal} className="btn btn-cancel me-20">
                CANCEL
              </button>
              {!loader ? (
                <button onClick={submit} className="btn btn-primary text-uppercase">
                  {submitText ?? "PROCESS"}
                </button>
              ) : (
                <button className="btn btn-primary">
                  <Loader />
                </button>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Modal;
