import React, { useRef, useState } from "react";
import Modal from "./Modal";
import { Swiper, SwiperSlide } from "swiper/react";
import { saveAs } from "file-saver";
import { EffectCards, EffectCoverflow } from "swiper";

const PhotoViewModal = ({ isModalActive, hideModal, data, handleDelete }) => {
  const [active, setActive] = useState(0);
  const nextref = useRef();
  const prevref = useRef();
  return (
    <Modal active={isModalActive} hideModal={hideModal} hideFooter photo_Modal>
      <div className="modal__header d-flex justify-content-between">
        <h4>Information</h4>
        <div className="modal_buttons">
          <button onClick={() => saveAs(data?.files[active]?.file || data?.files[active]?.url)}>
            <img src="/assets/vectors/download.svg" alt="download" />
          </button>
          <button>
            <img src="/assets/vectors/delete-table.svg" alt="delete" onClick={() => handleDelete(data)} />
          </button>
          <button>
            <img src="/assets/vectors/cross-icon.svg" alt="close" onClick={hideModal} />
          </button>
        </div>
      </div>

      <div className="slider_section">
        <div className="slider_inner position-relative">
          <Swiper
            onSlideChange={(e) => setActive(e.activeIndex)}
            onInit={(swiper) => {
              function update() {
                swiper.params.navigation.prevEl = prevref.current;
                swiper.params.navigation.nextEl = nextref.current;
                swiper.navigation.init();
                swiper.navigation.update();
              }
              update();
              setTimeout(update, 1000);
            }}
            navigation={{
              prevEl: prevref.current,
              nextEl: nextref.current,
            }}
            slidesPerView={1}
          >
            {data?.files?.map((el, idx) => {
              return (
                <SwiperSlide key={"photo" + idx}>
                  <img src={el?.file || el?.url} alt={el.title} style={{ objectFit: "cover" }} />
                </SwiperSlide>
              );
            })}
          </Swiper>
          <div className="slider_nav">
            <img src="/assets/vectors/arrow_right.svg" alt="" className="left-arrow" ref={prevref} />
            <img src="/assets/vectors/arrow_right.svg" alt="" ref={nextref} />
          </div>
        </div>
        <div className="row">
          <div className="col-md-4">
            <span>Uploaded On:</span>
            <p>{data?.uploaded_on}</p>
          </div>
          <div className="col-md-4">
            <span>Uploaded By:</span>
            <p>{data?.uploaded_by}</p>
          </div>
          <div className="col-md-4">
            <span>Album:</span>
            <p>{data?.album_id?.name}</p>
          </div>
          <div className="col-md-4">
            <span>Trade:</span>
            <p>{data?.trade_id?.name}</p>
          </div>
          <div className="col-md-4">
            <span>File Name</span>
            <p>{data?.files[active]?.title?.replace(/\.(png|jpg|jpeg|gif)$/, "")}</p>
          </div>
          <div className="col-md-4">
            <span>Location:</span>
            <p>{data?.location}</p>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default PhotoViewModal;
