import React, { useEffect, useState } from "react";
import { Chart as ChartJS, ArcElement, Tooltip } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import Collapse from "../components/Collapse";
import isEmpty from "../utilities/is-empty";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteContracts,
  DeleteMember,
  GetProjectContracts,
  GetProjectMembers,
  ProjectOverview,
} from "app/redux/actions";
import { useParams, useHistory, Link } from "react-router-dom";
import MemberModal from "../components/MemberModal";
import Loader from "components/Loader";
import moment from "moment";
import { notifyError, notifySuccess } from "utilities";
import ConsentModel from "components/ConsentModel";
import ContractsModel from "components/ContractsModel";
import ExportType from "components/ExportType";
import { NumberFormatter } from "components/common/Utilities";
ChartJS.register(ArcElement, Tooltip);
const chartOptions = {
  legen: { display: false },
};
const ProjectDetails = ({ set_Value }) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const history = useHistory();
  const [isModalActive, setModalActive] = useState(false);
  const [team, setTeam] = useState();
  const [overview, setOverview] = useState();
  const [loader, setLoader] = useState(false);
  const [deleteloader, setDeleteLoader] = useState(false);
  const [deleteActive, setdeleteActive] = useState(false);
  const [DeleteData, setDeleteData] = useState();
  const [editData, setEditData] = useState();
  const getTeamMembers = () => {
    dispatch(GetProjectMembers(id))
      .then((res) => {
        setLoader(false);
        setTeam(res);
      })
      .catch((err) => {
        setLoader(false);
      });
  };
  const [contracts, setContracts] = useState([]);

  const projectId = useSelector((state) => state.navigation.projectId);

  const getProjectContracts = () => {
    dispatch(GetProjectContracts({ project_id: id }))
      .then((res) => {
        setLoader(false);
        setContracts(res);
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
      });
  };
  useEffect(() => {
    setLoader(true);
    getTeamMembers();
    getProjectContracts();
    dispatch(ProjectOverview(id))
      .then((res) => {
        setLoader(false);
        setOverview(res);
        set_Value({
          withLogs: true,
          withSettings: true,
          rootClassName: "project",
          title: res?.project?.name,
          subTitle: res?.project?.address,
        });
      })
      .catch((err) => {
        setLoader(false);
      });
  }, [id]);

  const handleModalHide = () => {
    setModalActive(false);
  };
  const handleShowModal = () => {
    setModalActive(true);
    setEditData(null);
  };
  const handleDelete = (data) => {
    setdeleteActive(true);
    setDeleteData(data);
  };
  const deleteMember = () => {
    setDeleteLoader(true);
    dispatch(DeleteMember(DeleteData?.id))
      .then((res) => {
        setDeleteLoader(false);
        getTeamMembers();
        setdeleteActive(false);
      })
      .catch((err) => {
        setDeleteLoader(false);
        notifyError(
          err?.response?.data?.message ?? "Error Deleting Member",
          5000
        );
      });
  };

  const handleEdit = (data) => {
    setEditData(data);
    setModalActive(true);
  };

  /*
  ADDING CONTRACTOR FUNCTIONALITY
  */

  const [isActiveContractor, setIsActiveContractor] = useState(false);
  const showContractorModel = () => {
    setIsActiveContractor(true);
    setContractEditData(null);
  };

  // e-signatures
  const [isExportModalActive, setSignatures] = useState(false);
  const showExportModal = () => {
    setSignatures(true);
  };

  const hideContractorModel = () => {
    setIsActiveContractor(false);
  };

  const hideExportModal = () => {
    setSignatures(false);
  };

  const [isContractDelete, setIsContractDelete] = useState(false);
  const [contractData, setContractData] = useState(null);
  const [typeName, setTypeName] = useState(null);

  const handleDeleteContract = (contract, name) => {
    setIsContractDelete(true);
    setContractData(contract);
    setTypeName(name);
  };

  const deleteContract = () => {
    const payload = {
      contract_id: contractData?.id,
      project_id: id,
    };
    setDeleteLoader(true);
    dispatch(deleteContracts(payload))
      .then((res) => {
        notifySuccess("Contract Deleted Successfully");
        setIsContractDelete(false);
        setDeleteLoader(false);
        getProjectContracts();
      })
      .catch((error) => {
        notifyError(error ?? "error");
        setDeleteLoader(false);
        console.log(error);
      });
  };
  const [contractEditData, setContractEditData] = useState(null);
  const handleEditContract = (contract) => {
    setContractEditData(contract);
    setIsActiveContractor(true);
  };

  return (
    <>
      {isModalActive && (
        <MemberModal
          active={isModalActive}
          hideModal={handleModalHide}
          setTeam={setTeam}
          editData={editData}
          setEditData={setEditData}
          titleText={editData ? "Update Member" : "Add New Member"}
        />
      )}
      {isActiveContractor && (
        <ContractsModel
          active={isActiveContractor}
          hideModal={hideContractorModel}
          setContracts={setContracts}
          editData={contractEditData}
          projectMember={team}
          setEditData={setContractEditData}
          titleText={contractEditData ? "Update Contractor" : "Add Contractor"}
        />
      )}
      {isExportModalActive && (
        <ExportType
          active={isExportModalActive}
          hideModal={hideExportModal}
          setSignatures={setSignatures}
          titleText={"Export"}
        />
      )}
      {deleteActive && (
        <ConsentModel
          active={deleteActive}
          title={DeleteData?.name}
          onsubmit={deleteMember}
          oncancel={() => {
            setdeleteActive(false);
          }}
          loader={deleteloader}
        />
      )}
      {isContractDelete && (
        <ConsentModel
          active={isContractDelete}
          onsubmit={deleteContract}
          oncancel={() => {
            setIsContractDelete(false);
          }}
          loader={deleteloader}
          title={contractData?.contractor?.name}
        />
      )}
      <div className="section" style={{ position: "relative" }}>
        <div className="section-title mb-10">
          <h4>PROJECT TEAM</h4>
          <Link
            style={{
              position: "absolute",
              right: "250px",
            }}
            to={`/admin/project/${id}/exportStatment`}
            className="btn btn-primary-outlined"
          >
            STATEMENT
          </Link>
          {/* <Link
          style={{
            position: "absolute",
            right: "250px",
          }}
          to={`/admin/project/${projectId}/cover-letter`}
          className="ms-10 btn btn-primary-outlined"
        >
          Statement
        </Link> */}
          <button
            className="btn btn-primary-outlined"
            onClick={handleShowModal}
          >
            <img className="me-10" src="/assets/vectors/add.svg" alt="add" />
            ADD NEW MEMBERS
          </button>
        </div>

        <div className="table-container">
          <table className="team">
            <thead>
              <tr>
                <th>Role</th>
                <th>Name</th>
                <th>Email</th>
                <th>Office</th>
                <th>Mobile</th>
                <th className="text-center sticky-column-header">Action</th>
              </tr>
            </thead>
            <tbody>
              {loader ? (
                <Loader />
              ) : !team?.length ? (
                <tr>
                  <td>No Record Found</td>
                </tr>
              ) : (
                team?.map((el, idx) => {
                  const { role, name, email, office, mobile } = el;
                  return (
                    <tr key={"table-item" + idx}>
                      <td>{role.title}</td>
                      <td>{name}</td>
                      <td>{email}</td>
                      <td>{office || "-"}</td>
                      <td>{mobile || "-"}</td>
                      <td className="text-center sticky-column-body">
                        <button
                          className="button-unstyled"
                          onClick={() => handleEdit(el)}
                        >
                          <img
                            className="me-10"
                            src="/assets/vectors/edit-table.svg"
                            alt="edit"
                          />
                        </button>
                        <button
                          className="button-unstyled"
                          onClick={() => handleDelete(el)}
                        >
                          <img
                            className="ms-10"
                            src="/assets/vectors/delete-table.svg"
                            alt="delete"
                          />
                        </button>
                      </td>
                    </tr>
                  );
                })
              )}
            </tbody>
          </table>
        </div>
      </div>

      <div className="section">
        <div className="section-title mb-10">
          <h4>CONTRACTORS</h4>
          <button
            className="btn btn-primary-outlined"
            onClick={showContractorModel}
          >
            <img className="me-10" src="/assets/vectors/add.svg" alt="add" />
            ADD CONTRACTOR
          </button>
        </div>

        <div className="table-container">
          <table className="team">
            <thead>
              <tr>
                <th>Contractor Name</th>
                <th className="text-center">Mobile</th>
                <th className="text-center">Amount</th>
                <th className="text-center">Location</th>
                <th className="text-center">Type</th>
                <th className="text-center sticky-column-header">Action</th>
              </tr>
            </thead>
            <tbody>
              {loader ? (
                <Loader />
              ) : !contracts?.length ? (
                <tr>
                  <td>No Record Found</td>
                </tr>
              ) : (
                contracts?.map((contract, idx) => {
                  const { contractor, type, amount } = contract;
                  return (
                    <tr key={`table-item-${idx}`}>
                      <td>{contractor?.name}</td>
                      <td className="text-center">
                        {contractor?.contact_no || "-"}
                      </td>
                      <td className="text-center">
                        ${NumberFormatter(amount) || "0"}
                      </td>
                      <td className="text-center">{contractor?.location}</td>
                      <td className="text-center">{type}</td>
                      <td className="text-center sticky-column-body">
                        <button
                          className="button-unstyled"
                          onClick={() => handleEditContract(contract)}
                        >
                          <img
                            className="me-10"
                            src="/assets/vectors/edit-table.svg"
                            alt="edit"
                          />
                        </button>
                        <button
                          className="button-unstyled"
                          onClick={() =>
                            handleDeleteContract(contract, "contract")
                          }
                        >
                          <img
                            className="ms-10"
                            src="/assets/vectors/delete-table.svg"
                            alt="delete"
                          />
                        </button>
                      </td>
                    </tr>
                  );
                })
              )}
            </tbody>
          </table>
        </div>
      </div>

      <div className="section">
        <div className="section-title mb-20">
          <h4>PROJECT OVERVIEW</h4>
        </div>

        <div className="cards project_overview_card">
          <div className="container-fluid px-0">
            <div className="row">
              {overview?.overview?.map((el, idx) => {
                const { title } = el;
                const chartData = {
                  labels: ["Overdue", "Next 7 Days", "> 7 Days"],
                  datasets: [
                    {
                      label: "# of Votes",
                      data: [
                        el?.overdue === 0 ? 0 : el?.overdue,
                        el?.next_7_days === 0 ? 0 : el?.next_7_days,
                        el?.greater_than_7_days === 0
                          ? 0
                          : el?.greater_than_7_days,
                      ],
                      backgroundColor: ["#F84949", "#F6BB21", "#346979"],
                      borderColor: [
                        "rgba(255, 255, 255, 1)",
                        "rgba(255, 255, 255, 1)",
                        "rgba(255, 255, 255, 1)",
                      ],
                      borderWidth: 4,
                    },
                  ],
                };

                return (
                  <div key={"project-card" + idx} className="col-lg-6 col-xl-4">
                    <div className="card">
                      <div className="card-body">
                        <div className="d-flex justify-content-between w-100">
                          <h4>{title}</h4>
                          <button className="btn btn-primary-text">
                            VIEW ALL
                          </button>
                        </div>
                        <div className="chart-container">
                          {el?.overdue === 0 &&
                          el?.next_7_days === 0 &&
                          el?.greater_than_7_days === 0 ? (
                            "No data available"
                          ) : (
                            <Doughnut options={chartOptions} data={chartData} />
                          )}
                        </div>
                        <Legend
                          overdue={el?.overdue}
                          nextSeven={el?.next_7_days}
                          greaterThanSeven={el?.greater_than_7_days}
                        />
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>

      <div className="section">
        <div className="section-title mb-20">
          <h4>MY OPEN ITEMS</h4>
        </div>

        <div className="table-container">
          <table className="open-items">
            <thead>
              <tr>
                <th>Item Type</th>
                <th>Details</th>
                <th>Status</th>
                <th>Due Date</th>
              </tr>
            </thead>
            <tbody>
              {isEmpty([]) ? (
                <tr className="empty">
                  <td colSpan={5}>No Open Items</td>
                </tr>
              ) : (
                <>
                  {[].map((el, idx) => {
                    const { type, details, status, dueDate } = el;

                    return (
                      <tr key={"table-item" + idx}>
                        <td>{type}</td>
                        <td>{details}</td>
                        <td>{status}</td>
                        <td>{dueDate}</td>
                      </tr>
                    );
                  })}
                </>
              )}
            </tbody>
          </table>
        </div>
      </div>

      <div className="section bx">
        <Collapse title="RECENTLY CHANGED ITEMS"></Collapse>
      </div>

      <div className="section">
        <Collapse title="TODAY'S SCHEDULE">
          <div className="table-container">
            <table className="schedule">
              <thead>
                <tr>
                  <th></th>
                  <th className="text-center">#</th>
                  <th>Name</th>
                  <th>Resource</th>
                  <th>Start</th>
                  <th>Finish</th>
                  <th>%</th>
                </tr>
              </thead>
              <tbody>
                {loader ? (
                  <Loader />
                ) : !overview?.todaySchedules?.length ? (
                  <tr>
                    <td>No Record Found</td>
                  </tr>
                ) : (
                  overview?.todaySchedules?.map((el, idx) => {
                    const {
                      num,
                      name,
                      resource,
                      start_date,
                      end_date,
                      percent,
                    } = el;
                    return (
                      <tr key={"table-item" + idx}>
                        <td>
                          <img
                            src="/assets/vectors/calender.svg"
                            alt="calender"
                          />
                        </td>
                        <td className="text-center">{num || "-"}</td>
                        <td>{name}</td>
                        <td>{resource}</td>
                        <td>{start_date}</td>
                        <td>{end_date}</td>
                        <td>{percent}</td>
                      </tr>
                    );
                  })
                )}
              </tbody>
            </table>
          </div>
        </Collapse>
      </div>

      <div className="section bx">
        <Collapse title="PROJECT MILESTONES">
          <div className="table-container">
            <table className="schedule">
              <thead>
                <tr>
                  <th></th>
                  <th className="text-center">#</th>
                  <th>Name</th>
                  <th>Resource</th>
                  <th>Start</th>
                  <th>Finish</th>
                  <th>%</th>
                </tr>
              </thead>
              <tbody>
                {loader ? (
                  <Loader />
                ) : !overview?.mileStones?.length ? (
                  <tr>
                    <td>No Record Found</td>
                  </tr>
                ) : (
                  overview?.mileStones?.map((el, idx) => {
                    const {
                      num,
                      task,
                      resource,
                      start_date,
                      end_date,
                      percent,
                    } = el;
                    return (
                      <tr key={"table-item" + idx}>
                        <td>
                          <img
                            src="/assets/vectors/calender.svg"
                            alt="calender"
                          />
                        </td>
                        <td className="text-center">{idx + 1 || "-"}</td>
                        <td>{task}</td>
                        <td>{resource}</td>
                        <td>{moment(start_date).format("DD/MM/YYYY")}</td>
                        <td>{moment(end_date).format("DD/MM/YYYY")}</td>
                        <td>{percent}</td>
                      </tr>
                    );
                  })
                )}
              </tbody>
            </table>
          </div>
        </Collapse>
      </div>
    </>
  );
};

const Legend = ({ overdue, nextSeven, greaterThanSeven }) => {
  return (
    <div className="do-legend w-100">
      <div className="overdue">
        <div className="clr"></div>
        <div className="text">Overdue {overdue && <>({overdue})</>}</div>
      </div>
      <div className="nextSeven">
        <div className="clr"></div>
        <div className="text">
          Next 7 Days {nextSeven && <>({nextSeven})</>}
        </div>
      </div>
      <div className="greaterThanSeven">
        <div className="clr"></div>
        <div className="text">
          &gt; 7 Days {greaterThanSeven && <>({greaterThanSeven})</>}
        </div>
      </div>
    </div>
  );
};

export default ProjectDetails;
