import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import Select from "../components/select/select";
import Option from "../components/select/option";
import { useSelector } from "react-redux";
import ContactModal from "../components/ContactModal";

const AppBar = () => {
  const history = useHistory();
  const [contactModal, setContactModal] = useState(false);
  const handleModalHide = () => {
    setContactModal(false);
  };

  const user = useSelector((state) => state.AuthReducer.user);
  return (
    <div className="appbar">
      <div className="section header">
        <div className="page-container">
          <div className="d-flex justify-content-between align-items-center">
            <div className="contact">
              <a className="me-50" href="tel:+65 3158 5747">
                <img src="/assets/vectors/call.svg" alt="call" /> +65 3158 5747
              </a>
              <a href="mailto:incodm@gmail.com">
                <img src="/assets/vectors/email.svg" alt="email" />
                incodm@gmail.com
              </a>
            </div>
            <div className="language">
              <Select rootClassName="mb-0" placeholder="USA (English)">
                <Option value="USA (English)">USA (English)</Option>
                <Option value="UK (English)">UK (English)</Option>
              </Select>
            </div>
          </div>
        </div>
      </div>
      <div className="section navbar">
        <div className="page-container pt-30">
          <Link className="logo" to="/">
            <img src="./assets/vectors/builderLogo.png" alt="logo" style={{width: "170px"}}/>
          </Link>
          <div className="nav-container">
            <div className="nav">
              <Link to="/products">Products</Link>
              <Link to="/solution">Solution</Link>
              <Link to="/service">Customer Stories</Link>
              <Link to="/resources">Resources</Link>
            </div>
            <div className="btns">
              <button
                className="btn btn-primary p-2"
                style={{ width: 42, height: 42 }}
              >
                <img src="./assets/vectors/magnifier.svg" alt="magnifier" />
              </button>
              <button
                className="btn btn-primary"
                onClick={() => setContactModal(true)}
              >
                REQUEST A DEMO
              </button>
              {user ? (
                <button
                  className="btn btn-primary-outlined no-min-width"
                  onClick={() => history.push("/admin/dashboard")}
                >
                  DASHBOARD
                </button>
              ) : (
                <Link
                  to={"/auth/login"}
                  className="btn btn-primary-outlined no-min-width"
                >
                  LOGIN
                </Link>
              )}
            </div>
          </div>
        </div>
      </div>
      {contactModal && (
        <ContactModal
          active={contactModal}
          hideModal={handleModalHide}
          titleText={"Conatct us"}
        />
      )}
    </div>
  );
};
export default AppBar;
