import { ProjectStatementExport } from "app/redux/actions";
import Loader from "components/Loader";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import Dropdown from "../components/Dropdown";

import { Link, useParams } from "react-router-dom";

const ExportStatment = (props) => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const [statementData, setStatementData] = useState();
  const [loader, setLoader] = useState(false);

  const statementListing = () => {
    setLoader(true);
    const payload = {
    }
    dispatch(ProjectStatementExport(payload, id))
      .then((res) => {
        setStatementData(res.data);
        setLoader(false);
      })
      .catch((error) => {
        console.log(error);
        setLoader(false);
      });
  };
  useEffect(() => {
    props.set_Value({
      title: props.title,
      rootClassName: props.rootClassName,
      mainClassName: props.mainClassName,
    });
    statementListing();
  }, []);

  const handlePdf = (event) => {
    const payload = {
      type: "pdf"
    }
    dispatch(ProjectStatementExport(payload, id))
      .then((res) => {
        window.open(res?.link, "_blank");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleXlsx = (event) => {
    const payload = {
    }
    dispatch(ProjectStatementExport(payload, id))
      .then((res) => {
        window.open(res?.link, "_blank");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      {/* Bread Crumbs */}
      <div className="top pb-20" style={{ borderBottom: "none" }}>
        <div className="breadcrumb">
          <Link to={`/admin/project/${id}`} className="breadcrumb-item">
            Dashboard
          </Link>
          <div className="breadcrumb-item">Export Statment</div>
        </div>
      </div>
      {/* End Bread Crumbs */}
      {/* Export Section */}
      <div className="top pb-20 mb-20 mt-20" style={{ borderBottom: "none" }}>
        <div className="section-title">
          <h4>Cover Letter</h4>
        </div>
        <div className="options align-items-right">
          <Dropdown

          rootClassName="mx-10 customClassName"
            btn
            dropdownItems={["PDF", "XLSX"]?.map((el, idx) => {
              return (
                <Link
                className="customLinkClass"
                  key={idx}
                  onClick={
                    el == "PDF"
                      ? (e) => handlePdf(e)
                      : null || el == "XLSX"
                      ? (e) => handleXlsx(e)
                      : null
                  }
                >
                  {el}
                </Link>
              );
            })}
          >
            <div className="user-info">
              <div className="fw-bold" style={{color: 'white'}}>EXPORT</div>
            </div>
          </Dropdown>
          {/* <button
            className="ms-10 btn btn-primary"
            style={{ background: "#FFF2D9", color: "#E79436" }}
            onMouseEnter={() => onMouseChange()}
            onMouseLeave={() => onCloseButton()}
          >
            EXPORT
          </button>
          {activeButton === true && (
            <>
              <button
                className="ms-10 btn btn-primary"
                style={{ background: "#FFF2D9", color: "#E79436" }}
              >
                PDF
              </button>
              <button
                className="ms-10 btn btn-primary"
                style={{ background: "#FFF2D9", color: "#E79436" }}
              >
                XLSX
              </button>
            </>
          )} */}
        </div>
      </div>
      {/* End Export Section */}
      {/* Cover Letter Section */}
      <div className="row">
        <div className="col-md-12">
          <div className="card-left m-10">
            <div className="table-container">
              <div className="row m-2 table-heading invoice__details">
                <div className="col-md-3">
                  <label className="headings">Project Name</label>
                  <div>{statementData?.project?.name}</div>
                </div>
                {/* <div className="col-md-3">
                  <label className="headings">Contract Name</label>
                  <div>{statementData?.project?.name}</div>
                </div> */}
                <div className="col-md-3">
                  <label className="headings">Owner</label>
                  <div>{statementData?.project?.owner}</div>
                </div>
                <div className="col-md-3">
                  <label className="headings">Job Site Address</label>
                  <div>{statementData?.project?.jobSite}</div>
                </div>
              </div>
              <div
                className="form mt-10 mb-10"
                style={{ marginLeft: "10px", marginRight: "35px" }}
              >
                <div className="table-container invoice_details_table form m-10 mt-4">
                  <table className="">
                    <thead>
                      <tr>
                        <th>
                          <h4 className="card-heading text-start">
                            Site Construction Date
                          </h4>
                        </th>
                        <th>
                          <h4 className="card-heading">Date</h4>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {loader ? (
                        <tr>
                          <td colSpan={5}>
                            <Loader />
                          </td>
                        </tr>
                      ) : statementData == undefined ? (
                        <tr>
                          <td>No Record Found</td>
                        </tr>
                      ) : (
                        <>
                          <tr>
                            <td>Work Start Date</td>
                            <td className="">
                              {statementData?.project?.start_date}
                            </td>
                          </tr>
                          <tr>
                            <td>Work End Date</td>
                            <td className="">
                              {statementData?.project?.completion_date}
                            </td>
                          </tr>
                          <tr>
                            <td>Duration</td>
                            <td className="">{`${statementData?.project?.duration}-days`}</td>
                          </tr>
                        </>
                      )}
                    </tbody>
                  </table>
                </div>
                <div className="m-10 mt-20 mb-20">
                  <h5>Income and Expense Summary</h5>
                </div>
                <div className="table-container invoice_details_table form m-10 mt-4">
                  <table className="">
                    <thead>
                      <tr>
                        <th>
                          <h4 className="card-heading text-start">NO</h4>
                        </th>
                        <th>
                          <h4 className="card-heading">Group</h4>
                        </th>
                        <th colSpan={3}>
                          <h4 className="card-heading">Description</h4>
                        </th>
                        <th>
                          <h4 className="card-heading">Amount</h4>
                        </th>
                        <th>
                          <h4 className="card-heading">Final Amount</h4>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {loader ? (
                        <tr>
                          <td colSpan={6}>
                            <Loader />
                          </td>
                        </tr>
                      ) : statementData == undefined ? (
                        <tr>
                          <td>No Record Found</td>
                        </tr>
                      ) : (
                        <>
                          <tr>
                            <td rowSpan={4}>1</td>
                            <td rowSpan={4}>
                              <Link
                                to={`/admin/project/${id}/exportStatment/owner`}
                                onClick={localStorage.setItem(
                                  "contracts",
                                  JSON.stringify(statementData)
                                )}
                              >
                                Owner
                              </Link>
                            </td>
                            <td colSpan={3}>Orginal Contract</td>
                            <td>{`$${statementData?.owner?.originalContracts}`}</td>
                            <td
                              rowSpan={4}
                            >{`$${statementData?.owner?.finalAmount}`}</td>
                          </tr>
                          <tr>
                            <td colSpan={3}>Change of Order</td>
                            <td>{`$${statementData?.owner?.changeOrders}`}</td>
                          </tr>
                          <tr>
                            <td colSpan={3}>
                              Permit and Other fee Reimbursement
                            </td>
                            <td>{`$${"0"}`}</td>
                          </tr>
                          <tr>
                            <td colSpan={3}>
                              Total Contract Amount including CO and Fees
                            </td>
                            <td>{`$${statementData?.owner?.originalContracts}`}</td>
                          </tr>
                          <tr>
                            <td rowSpan={2}>2</td>
                            <td rowSpan={2}>A/R</td>
                            <td colSpan={4}>
                              Total Received Amount up to date
                            </td>
                            <td>{`$${statementData?.owner?.amountRecieved}`}</td>
                          </tr>
                          <tr>
                            <td colSpan={4}>Balance Receivable</td>
                            <td>{`$${statementData?.owner?.amountRecieved}`}</td>
                          </tr>
                          <tr>
                            <td
                              rowSpan={
                                statementData?.subContractors?.contractsDetail
                                  .length + 2
                              }
                            >
                              3
                            </td>

                            <td
                              rowSpan={
                                statementData?.subContractors?.contractsDetail
                                  .length + 2
                              }
                            >
                              <Link
                                to={`/admin/project/${id}/exportStatment/sub-contractor`}
                              >
                                Sub Contractor
                              </Link>
                            </td>
                            <td style={{ fontWeight: "bold" }} colSpan={2}>
                              Subcontractor
                            </td>
                            <td style={{ fontWeight: "bold" }}>Contact</td>
                            <td style={{ color: "red", fontWeight: "bold" }}>
                              Payment
                            </td>
                            <td
                              style={{ color: "#0070C0", fontWeight: "bold" }}
                            >
                              Balance
                            </td>
                          </tr>
                          {statementData?.subContractors?.contractsDetail?.map(
                            (data, index) => {
                              return (
                                <tr key={index}>
                                  <td colSpan={2}>
                                    <Link
                                      to={`/admin/project/${id}/exportStatment/sub-contractor/${data?.contractor_id}`}
                                    >
                                      {data?.sub_contractor}
                                    </Link>
                                  </td>
                                  <td>{`$${data.contract_amount}`}</td>
                                  <td style={{ color: "red" }}>{`$${
                                    data.payment ?? "0"
                                  }`}</td>
                                  <td
                                    style={{ color: "#0070C0" }}
                                  >{`$${data?.balance}`}</td>
                                </tr>
                              );
                            }
                          )}
                          <tr>
                            <td colSpan={2}>Total</td>
                            <td
                              style={{ color: "#0070C0" }}
                            >{`$${statementData?.subContractors?.contractTotal}`}</td>
                            <td
                              style={{ color: "#0070C0" }}
                            >{`$${statementData?.subContractors?.contractPyamentTotal}`}</td>
                            <td
                              style={{ color: "#0070C0" }}
                            >{`$${statementData?.subContractors?.contractBalanceTotal}`}</td>
                          </tr>
                          <tr>
                            <td rowSpan={4}>4</td>
                            <td rowSpan={4}>Other Expense</td>
                            <td colSpan={4}>
                              <Link
                                to={`/admin/project/${id}/exportStatment/labor`}
                              >
                                Labor
                              </Link>
                            </td>
                            <td>{`$${statementData?.otherExpense.labour}`}</td>
                          </tr>
                          <tr>
                            <td colSpan={4}>
                              <Link
                                to={`/admin/project/${id}/exportStatment/vendor`}
                                onClick={localStorage.setItem(
                                  "vendorData",
                                  JSON.stringify(statementData?.otherExpense)
                                )}
                              >
                                Vendor and Other Expense
                              </Link>
                            </td>
                            <td>{`$${statementData?.otherExpense.vendors}`}</td>
                          </tr>
                          <tr>
                            <td colSpan={4}>
                              <Link
                                to={`/admin/project/${id}/exportStatment/material`}
                                onClick={localStorage.setItem(
                                  "vendorData",
                                  JSON.stringify(statementData?.otherExpense)
                                )}
                              >
                                Materials
                              </Link>
                            </td>
                            <td>{`$${statementData?.otherExpense.material}`}</td>
                          </tr>
                          <tr>
                            <td colSpan={4}>Total Other Expensive</td>
                            <td>{`$${statementData?.otherExpense.total}`}</td>
                          </tr>
                          <tr>
                            <td rowSpan={4}>5</td>
                            <td rowSpan={5}>JD Profit</td>
                            <td colSpan={4} className="text-end">
                              Gross Income
                            </td>
                            <td>{`$${statementData?.jdProfit?.grossIncome}`}</td>
                          </tr>
                          <tr>
                            <td colSpan={4} className="text-end">
                              Total Expense (Sub-Con + Labor + Vendor +
                              Material)
                            </td>
                            <td>{`$${statementData?.jdProfit?.totalExpense}`}</td>
                          </tr>
                          <tr>
                            <td className="text-end" colSpan={4}>
                              Net Income (Gross Income + Total Expense)
                            </td>
                            <td>{`$${statementData?.jdProfit?.netIncome}`}</td>
                          </tr>
                          <tr>
                            <td className="text-end" colSpan={4}>
                              JD Profit
                            </td>
                            <td
                              style={{ backgroundColor: "#FFC000" }}
                            >{`$${statementData?.jdProfit?.netIncome}`}</td>
                          </tr>
                        </>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Cover Letter Section */}
    </>
  );
};

export default ExportStatment;
