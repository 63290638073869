import Loader from "components/Loader";
import React from "react";
import Input from "../../components/Input";
import TimeSelect from "../../components/TimeSelect";
import InfiniteScroll from "react-infinite-scroll-component";
import moment from "moment";
const Deliveries = ({
  state,
  Listing,
  fetchData,
  deliveryCompanies,
  dataLoader,
  meta,
  errors,
  handleDelete,
  handleChange,
  handleCreate,
  setstate,
  Images,
  handleImageUpload,
  imageLoader,
  removeFile,
  loader,
  handleEdit,
  isEditing,
  cancelEdit,
  setIsEditing,
  setImages,
}) => {
  return (
    <>
      <h4 className="fs-20 mb-20">Deliveries</h4>
      <InfiniteScroll
        dataLength={Listing?.length ?? 0}
        next={fetchData}
        hasMore={meta && meta?.total > Listing?.length}
        loader={loader ? <Loader /> : null}
        height={"100%"}
        style={{ overflow: "scroll", overflowX: "hidden", maxHeight: "550px" }}
        className="infinite-scroll w-100"
      >
        <div className="table-container dailylog_table">
          <table>
            <thead>
              <tr>
                <th>Time</th>
                <th>Delivery From</th>
                <th>Tracking Number</th>
                <th>Description</th>
                <th>Comments</th>
                <th>Attachement</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {!isEditing.editing && dataLoader ? (
                <tr>
                  <td colSpan={7}>
                    <Loader />
                  </td>
                </tr>
              ) : (
                Listing?.map((item) => (
                  <>
                    {isEditing?.id != item?.id ? (
                      <tr>
                        <td>{moment(item?.delivery_time, "hh:mm a").format("hh:mm a")}</td>
                        <td>{item?.delivery_company?.name}</td>
                        <td>{item?.tracking_number}</td>
                        <td>{item?.description}</td>
                        <td>{item?.comments}</td>
                        <td>
                          {item?.files?.length
                            ? item?.files?.map((image, index) => (
                                <img
                                  src={image?.file ?? image?.url}
                                  alt={image?.title}
                                  style={{ width: 50, height: 50 }}
                                  key={index}
                                />
                              ))
                            : "No Attachement"}
                        </td>
                        <td>
                          <button
                            className="btn btn-contained-2 me-10 create__btn"
                            onClick={() => handleEdit(item)}
                          >
                            <img className="me-0" src="/assets/vectors/edit-table.svg" alt="edit" />
                          </button>
                          <button
                            className="btn btn-contained-2 ms-10 create__btn"
                            onClick={() => handleDelete(item)}
                          >
                            <img className="me-0" src="/assets/vectors/delete-table.svg" alt="delete" />
                          </button>
                        </td>
                      </tr>
                    ) : (
                      <tr key={item?.id}>
                        <td>
                          <TimeSelect
                            style={{ width: "100%" }}
                            showSecond={false}
                            use12Hours={true}
                            placeholder={"00:00"}
                            name="delivery_time"
                            onChange={(e) => setstate({ ...state, delivery_time: e.format("HH:mm:ss") })}
                            value={state.delivery_time ? moment(state.delivery_time, "HH:mm:ss") : undefined}
                          />
                          {errors?.delivery_time && <p className="error">{errors?.delivery_time}</p>}
                        </td>
                        <td style={{ minWidth: "200px" }}>
                          <select
                            name="delivery_company_id"
                            onChange={handleChange}
                            value={state.delivery_company_id}
                          >
                            <option value="">Select Delievery Company</option>
                            {deliveryCompanies?.map((item) => (
                              <option value={item.id} key={item.id}>
                                {item.name}
                              </option>
                            ))}
                          </select>
                          {errors?.delivery_company_id && (
                            <p className="error">{errors?.delivery_company_id}</p>
                          )}
                        </td>
                        <td>
                          <Input
                            placeholder="Enter Tracking Number"
                            onChange={handleChange}
                            name="tracking_number"
                            errorMsg={errors.tracking_number}
                            value={state.tracking_number}
                          />
                        </td>
                        <td>
                          <Input
                            placeholder="Write Description"
                            onChange={handleChange}
                            name="description"
                            value={state.description}
                          />
                        </td>
                        <td>
                          <Input
                            placeholder="Add Comments"
                            onChange={handleChange}
                            name="comments"
                            value={state.comments}
                          />
                        </td>
                        <td style={{ width: "100px", minWidth: "none" }}>
                          {Images?.length ? (
                            Images?.map((item, index) => (
                              <div className="position-relative d-inline-block" key={index}>
                                <button
                                  onClick={() => removeFile(item.id || item)}
                                  style={{
                                    background: "transparent",
                                    border: 0,
                                    position: "absolute",
                                    top: 2,
                                    right: 0,
                                  }}
                                >
                                  <img src="/assets/vectors/close-icon.svg" alt="" />
                                </button>
                                <img
                                  src={item.file || item.url}
                                  style={{ width: 50, height: 50, objectFit: "cover" }}
                                />
                              </div>
                            ))
                          ) : imageLoader ? (
                            <Loader />
                          ) : (
                            <>
                              <label htmlFor="file" className="d-flex me-20">
                                <img className="clip" src="/assets/vectors/clip.svg" alt="clip" />
                                <div className="text">Attach File(s)</div>
                              </label>
                              <input
                                id="file"
                                className="d-none"
                                type="file"
                                onChange={handleImageUpload("Deliveries")}
                              />
                            </>
                          )}
                        </td>
                        <td>
                          <div className="d-flex align-items-center">
                            {dataLoader ? (
                              <button className="btn sm btn-primary create__btn">
                                <Loader />
                              </button>
                            ) : (
                              <button
                                className="btn sm btn-primary create__btn"
                                onClick={() => handleCreate()}
                              >
                                <img
                                  src="/assets/vectors/check-mark.png"
                                  alt="submit"
                                  style={{ maxWidth: 24 }}
                                />
                              </button>
                            )}
                            <button
                              className="btn sm create__btn ms-2 cancelEdit"
                              onClick={() => cancelEdit()}
                            >
                              <img
                                src="/assets/vectors/close-icon.png"
                                alt="cancel"
                                style={{ maxWidth: 24 }}
                              />
                            </button>
                          </div>
                        </td>
                      </tr>
                    )}
                  </>
                ))
              )}
              <tr>
                <td>
                  <TimeSelect
                    style={{ width: "100%" }}
                    showSecond={false}
                    use12Hours={true}
                    placeholder={"00:00"}
                    name="delivery_time"
                    onChange={(e) => setstate({ ...state, delivery_time: e.format("HH:mm:ss") })}
                  />
                  {errors?.delivery_time && <p className="error">{errors?.delivery_time}</p>}
                </td>
                <td style={{ minWidth: "200px" }}>
                  <select
                    name="delivery_company_id"
                    onChange={handleChange}
                    value={!isEditing.editing ? state.delivery_company_id : ""}
                  >
                    <option value="">Select Delievery Company</option>
                    {deliveryCompanies?.map((item) => (
                      <option value={item.id} key={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                  {errors?.delivery_company_id && <p className="error">{errors?.delivery_company_id}</p>}
                </td>
                <td>
                  <Input
                    placeholder="Enter Tracking Number"
                    onChange={handleChange}
                    name="tracking_number"
                    errorMsg={errors.tracking_number}
                    value={!isEditing.editing ? state.tracking_number : ""}
                  />
                </td>
                <td>
                  <Input
                    placeholder="Write Description"
                    onChange={handleChange}
                    name="description"
                    value={!isEditing.editing ? state.description : ""}
                  />
                </td>
                <td>
                  <Input
                    placeholder="Add Comments"
                    onChange={handleChange}
                    name="comments"
                    value={!isEditing.editing ? state.comments : ""}
                  />
                </td>
                <td style={{ width: "100px", minWidth: "none" }}>
                  {Images?.length && !isEditing.editing ? (
                    Images?.map((item, index) => (
                      <div className="position-relative d-inline-block" key={index}>
                        <button
                          onClick={() => removeFile(item.id || item)}
                          style={{
                            background: "transparent",
                            border: 0,
                            position: "absolute",
                            top: 2,
                            right: 0,
                          }}
                        >
                          <img src="/assets/vectors/close-icon.svg" alt="" />
                        </button>
                        <img
                          src={item.file || item.url}
                          style={{ width: 50, height: 50, objectFit: "cover" }}
                        />
                      </div>
                    ))
                  ) : !isEditing.editing && imageLoader ? (
                    <Loader />
                  ) : (
                    <>
                      <label
                        htmlFor="file"
                        className="d-flex me-20"
                        onClick={() => {
                          setIsEditing({ editing: false, id: null });
                          setImages([]);
                        }}
                      >
                        <img className="clip" src="/assets/vectors/clip.svg" alt="clip" />
                        <div className="text">Attach File(s)</div>
                      </label>
                      <input
                        id="file"
                        className="d-none"
                        type="file"
                        onChange={handleImageUpload("Deliveries")}
                      />
                    </>
                  )}
                </td>
                <td>
                  {isEditing.editing !== true && dataLoader ? (
                    <button className="btn sm btn-primary create__btn">
                      <Loader />
                    </button>
                  ) : (
                    <button className="btn sm btn-primary create__btn" onClick={() => handleCreate()}>
                      +
                    </button>
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </InfiniteScroll>
    </>
  );
};

export default Deliveries;
