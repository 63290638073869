import React, { useState } from "react";
import Modal from "../components/Modal";
import SelectDropdown from "./SelectDropdown";
import Input from "../components/Input";
const customStyles = {
  control: () => ({
    width: "100%",
    background: "#f9f9f9",
    display: "flex",
    borderRadius: "5px",
    border: "1px solid #dedee4",
    height: "48px",
    paddingLeft: "10px",
  }),
};
const ModalPhotoUpload = ({
  isModalActive,
  hideModal,
  state,
  location,
  dataLoader,
  errors,
  handleChange,
  Images,
  handleImageUpload,
  imageLoader,
  removeFile,
  handleCreate,
  trade,
  album,
  handleSelectChange,
  handlelocation,
}) => {
  return (
    <Modal
      active={isModalActive}
      hideModal={hideModal}
      submit={handleCreate}
      loader={dataLoader}
      submitText={"Upload"}
    >
      <h4>Upload Photos</h4>
      <Input
        label="Attachments"
        rootClassName="mt-20"
        icon="/assets/vectors/clip-dark.svg"
        labelClassName="fs-16"
        withInfo
        tooltipText={"Attach file"}
        id="todo-images"
        multiple_image={Images}
        type="file"
        imageLoader={imageLoader}
        placeholder="Attach file or Drag &amp; Drop"
        onChange={handleImageUpload("dailyLog-photo")}
        removeFile={removeFile}
        errorMsg={errors?.file}
        multiple
      />
      <div className="custom-form-control mt-4">
        <label htmlFor="lastName">Location</label>
        <Input
          type="location"
          name="location"
          placeholder="Enter location"
          locationChange={handlelocation}
          errorMsg={errors.location}
          value={state.location}
        />
        {/* <select onChange={handleChange} name="location" value={state?.location}>
          <option value="">Select Location</option>
          {location &&
            location?.map((item, index) => {
              return (
                <option value={item} key={index}>
                  {item}
                </option>
              );
            })}
        </select>
        {errors?.location && <p className="error">{errors.location}</p>} */}
      </div>
      <br />
      <SelectDropdown
        className="basic-single"
        classNamePrefix="select"
        styles={customStyles}
        label={"Album"}
        options={album}
        placeholder={"Select Album"}
        onChange={(e) => handleSelectChange("album_id", e)}
        validation={errors?.album}
        value={{
          label: album?.filter((e) => e.value === state.album_id)[0]?.label,
          value: state.album_id,
        }}
      />
      <SelectDropdown
        className="basic-single"
        classNamePrefix="select"
        options={trade}
        styles={customStyles}
        label={"Trade"}
        placeholder={"Select Trade"}
        onChange={(e) => handleSelectChange("trade_id", e)}
        validation={errors?.trade}
        value={{
          label: trade?.filter((e) => e.value === state.trade_id)[0]?.label,
          value: state.trade_id,
        }}
      />
    </Modal>
  );
};

export default ModalPhotoUpload;
