import React, { useState } from "react";
import Modal from "../components/Modal";
import Input from "../components/Input";
import { useDispatch, useSelector } from "react-redux";
import useImageUpload from "hooks/useImageUpload";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import FontPicker from "font-picker-react";
import { EsignatureValidation, notifyError, notifySuccess } from "utilities";
import { useParams } from "react-router";
import { CreateEsinature, ImageUpload, SignatureListing } from "app/redux/actions";
import SignatureCanvas from 'react-signature-canvas';
import { useRef } from 'react';


const EsignatureModal = (props) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [isValid, setIsValid] = useState(false);
  const [dataLoader, SetLoadingData] = useState(false);
  const [formError, setformError] = useState({});
  const [isEdit, setisEdit] = useState(false);
  const [imageURL, setImageURL] = useState(null);

  const projectId = useSelector((state) => state.navigation.projectId);
  const [fontstyle, setFontStyle] = useState({
    activeFontFamily: "Open Sans",
  });


  const sigCanvas = useRef();

  const [values, setValues] = useState({
    name: "",
    description: "",
    attachments: [],
    fontText: "",
    signature: []
  });

  const {
    Drop,
    dragEnter,
    dragLeave,
    dragOver,
    handleImageUpload,
    Images,
    imageLoader,
    removeFile,
  } = useImageUpload(values.attachments);

  React.useEffect(() => {
    if (isValid && Object.keys(formError).length === 0) {
      SetLoadingData(true);
      const imagesData = values.signature;
      if(values.signature.length > 0){
        let payload = {
          name: values.name,
          description: values.description,
          style: fontstyle.activeFontFamily,
          signature: values.signature,
          signature_text: values.fontText,
        };
        if (isEdit && id) {
          SetLoadingData(false);
        } else {
          if (projectId) {
            dispatch(CreateEsinature({ payload, projectId: projectId }))
              .then((res) => {
                props.setloader(true);
                SetLoadingData(false);
                notifySuccess("Sucessfully Created.");
                props.hideModal();
                dispatch(
                  SignatureListing({
                    project_id: projectId,
                  })
                )
                  .then((res) => {
                    props.setloader(false);
                    props.setSignaturelisting(res);
                  })
                  .catch((err) => {
                    props.setloader(false);
                  });
              })
              .catch((err) => {
                SetLoadingData(false);
                notifyError(err?.response?.data?.message ?? "Error");
              });
          }
        }
      } else {
        SetLoadingData(false);
        notifyError("Please draw & upload your signature");
      }
    }
  }, [formError]);

  const submitHandler = (e) => {
    e.preventDefault();
    setformError(EsignatureValidation(values, fontstyle));
    setIsValid(true);
  };

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const create = () => {
    const URL = sigCanvas.current.getTrimmedCanvas().toDataURL("image/png");
    const dataURLtoFile = (dataurl, filename) => {
      var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      
      return new File([u8arr], filename, {type: mime});
      };

    let file = dataURLtoFile(URL, 'image.png');
    if(file.size <= 81){
      notifyError("Draw your singature before uploading");
    } else {
      let formdata = new FormData();
      formdata.append('file', file);
      formdata.append('type', 'signature-sketch/image');

      dispatch(ImageUpload(formdata))
        .then((res) => {
          setValues({...values, signature: [res.payload.data.file]});
          notifySuccess("Image uploaded successfully");
        })
        .catch((err) => {
          notifyError("Error uploading!");
      });
    }
  };

  const clearSignature = () => {
    sigCanvas.current.clear();
    setValues({...values, signature: []});
  }

  return (
    <Modal
      active={props.isModalActive}
      hideModal={props.hideModal}
      submitText={props?.editData ? "Update" : "CREATE SIGNATURE"}
      loader={dataLoader}
      submit={submitHandler}
    >
      <h4 className="mb-4" style={{ fontSize: "28px", fontWeight: "700" }}>
        {props?.editData ? "Edit E-signature" : "Create E-signature"}
      </h4>
      <Tabs defaultIndex={0} transition={"fade"}>
        <TabList>
          <Tab>UPLOAD</Tab>
          {/* <Tab>TYPE</Tab> */}
        </TabList>
        <TabPanel>
          <Input
            label="Name"
            rootClassName="mt-20"
            labelClassName="fs-16"
            type="text"
            tooltipText={"Enter name"}
            placeholder="Enter Name"
            withInfo
            inputCapitalize={"inputCapitalize"}
            defaultValue={values?.name}
            onChange={handleChange("name")}
            errorMsg={formError.name}
          />
          <Input
            label="Description"
            rootClassName="mt-20"
            icon="/assets/vectors/clip-dark.svg"
            labelClassName="fs-16"
            withInfo
            tooltipText={"Enter description"}
            placeholder="Right Here"
            textarea
            defaultValue={values?.description}
            errorMsg={formError.description}
            onChange={handleChange("description")}
          />
          {/* <Input
            type="file"
            label="Attachments Invoice"
            rootClassName="mt-20"
            icon="/assets/vectors/clip-dark.svg"
            labelClassName="fs-16"
            withInfo
            tooltipText={"Attach file"}
            id="esignature-image"
            placeholder="Attach file or Drag &amp; Drop"
            onChange={handleImageUpload("esignature-image")}
            onDragOver={dragOver}
            onDragEnter={dragEnter}
            onDragLeave={dragLeave}
            onDrop={Drop}
            multiple_image={Images}
            imageLoader={imageLoader}
            removeFile={removeFile}
            errorMsg={formError.attachments}
          /> */}
          <label>Draw Signature</label>
          <div className="modal" style={{width: "100%",
            border: "1px solid lightgrey",
            backgroundColor: "white",
            boxSizing: "border-box"}}>
            <div className="sigPadContainer">
              <SignatureCanvas penColor="black" canvasProps={{ className: "sigCanvas" }} ref={sigCanvas} />
            </div>
            <div className="modal__bottom" style={{textAlign: "right", marginTop: "10px"}}>
              <button onClick={create} className="btn btn-primary modal__bottom" style={{textAlign: "left", marginTop: "10px", marginRight: "10px"}}>Upload</button>
              <button className="btn btn-primary" onClick={clearSignature}>Clear</button>
              <br />
              {
                imageURL && (
                  <>
                    <img src={imageURL} alt="signature" className="signature" />
                  </>
                )
              }
            </div>
          </div>
        </TabPanel>
        {/* <TabPanel>
          <Input
            label="Name"
            rootClassName="mt-20"
            labelClassName="fs-16"
            type="text"
            tooltipText={"Enter title"}
            placeholder="Enter Name"
            withInfo
            inputCapitalize={"inputCapitalize"}
            defaultValue={values?.name}
            errorMsg={formError.name}
            onChange={handleChange("name")}
          />
          <Input
            label="Description"
            rootClassName="mt-20"
            icon="/assets/vectors/clip-dark.svg"
            labelClassName="fs-16"
            withInfo
            tooltipText={"Enter description"}
            placeholder="Right Here"
            textarea
            defaultValue={values?.description}
            errorMsg={formError.description}
            onChange={handleChange("description")}
          />
          <label>Change Style</label>
          <FontPicker
            apiKey="AIzaSyCB2a3h0FRKQs2YwuOm8sP9IFFd-uinI_A"
            activeFontFamily={fontstyle.activeFontFamily}
            onChange={(nextFont) =>
              setFontStyle({
                activeFontFamily: nextFont.family,
              })
            }
          />
          <Input
            className="apply-font"
            rootClassName="mt-20"
            labelClassName="fs-16"
            type="text"
            tooltipText={"Write Here"}
            placeholder="Write Here"
            withInfo
            onChange={handleChange("fontText")}
            errorMsg={formError.fontText}
          />
        </TabPanel> */}
      </Tabs>
    </Modal>
  );
};

export default EsignatureModal;
