import React from "react";
import { NumberFormatter, handleNegativeInput } from "./Utilities";
import clsx from "clsx";

export const NumberInput = ({
  lable,
  required,
  value,
  placeholder,
  onChange,
  name,
  rootClassName,
  errorMsg,
}) => {
  return (
    <div className={clsx("custom-form-control", rootClassName)}>
      <label>
        {lable}
        {required && <span className="text-danger">*</span>}
      </label>
      <input
        type="text"
        className="w-100 px-1 number-input"
        value={NumberFormatter(value)}
        onKeyDown={handleNegativeInput}
        name={name}
        placeholder={placeholder}
        onChange={(e) => onChange(e)}
      />
      {errorMsg && <p className="error">{errorMsg}</p>}
    </div>
  );
};
