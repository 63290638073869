import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { notifyError, notifySuccess, validateEmail } from "utilities";
import { GetCompanyType, SignUp } from "app/redux/actions";
import Loader from "components/Loader";
import { useSelector } from "react-redux";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";

const Signup = () => {
  const [LoadingData, SetLoadingData] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useSelector((state) => state.AuthReducer.location);
  const [values, setValues] = useState({
    firstName: "",
    lastName: "",
    country_Code: "",
    phoneNo: "",
    email: "",
    companyName: "",
    companyType: "",
    password: "",
    confirmPassword: "",
  });
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [formError, setformError] = useState({});
  const [isValid, setisValid] = useState(false);
  const [passShow, setPassShow] = useState(false);
  const CompanyType = useSelector((state) => state.AuthReducer.companyType);
  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };
  // check for valid form and submit
  useEffect(() => {
    if (Object.keys(formError).length === 0 && isValid) {
      if (isValid && Object.keys(formError).length === 0) {
        SetLoadingData(true);
        let payload = {
          email: values.email,
          password: values.password,
          confirmed: values.confirmPassword,
          first_name: values.firstName,
          last_name: values.lastName,
          company_name: values.companyName,
          company_type: values.companyType,
          telephone: phoneNumber,
        };
        dispatch(SignUp(payload))
          .then((res) => {
            SetLoadingData(false);
            if (res?.payload) {
              notifySuccess("User Registered successfully!");
              history.push("/auth/verification");
            } else {
              notifyError("Server Error!");
            }
          })
          .catch((err) => {
            notifyError(err?.error?.response?.data?.message ?? "Error", 5000);
            SetLoadingData(false);
          });
      }
    }
  }, [formError]);
  // get companies on mount
  useEffect(() => {
    dispatch(GetCompanyType());
  }, []);
  const validate = (values) => {
    let errors = {};
    if (!values.email) {
      errors.email = "Email address required";
    } else if (!validateEmail(values.email)) {
      errors.email = "Please provide a valid Email";
    }
    if (!values.password) {
      errors.password = "Password required";
    }
    if (!values.firstName) {
      errors.firstName = "First Name required";
    }
    if (!values.lastName) {
      errors.lastName = "Last Name required";
    }
    if (!phoneNumber) {
      errors.phoneNo = "Please provide a valid Phone Number";
    }
    if (!values.companyName) {
      errors.companyName = "Company Name required";
    }
    if (!values.companyType) {
      errors.companyType = "Company Type required";
    }
    // if (!values.confirmPassword || values.confirmPassword !== values.password) {
    //   errors.confirmPassword = "Passwords did not match";
    // }
    if (Object.keys(errors).length > 0) {
      notifyError("Please fill all required fields");
    }
    setisValid(false);
    return errors;
  };
  const submitHandler = (e) => {
    e.preventDefault();
    setformError(validate(values));
    setisValid(true);
  };
  return (
    <div className="form">
      <div className="container-fluid px-0">
        <div className="row">
          <div className="col-6">
            <div className="custom-form-control">
              <label htmlFor="firstName">First Name</label>
              <input
                type="text"
                value={values.firstName}
                placeholder="Enter Your first name"
                onChange={handleChange("firstName")}
                style={{ textTransform: "capitalize" }}
              />
              {formError.firstName && (
                <p className="error">{formError.firstName}</p>
              )}
            </div>
          </div>
          <div className="col-6">
            <div className="custom-form-control">
              <label htmlFor="lastName">Last Name</label>
              <input
                type="text"
                value={values.lastName}
                placeholder="Enter Your last name"
                onChange={handleChange("lastName")}
                style={{ textTransform: "capitalize" }}
              />
              {formError.lastName && (
                <p className="error">{formError.lastName}</p>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="custom-form-control">
        <label htmlFor="number">Mobile Number</label>
        <div className="phone_number_input">
          <PhoneInput
            placeholder="Enter phone number"
            international
            countryCallingCodeEditable={false}
            defaultCountry={location?.location?.country?.code}
            value={phoneNumber}
            onChange={setPhoneNumber}
          />
        </div>
        {formError.phoneNo && <p className="error">{formError.phoneNo}</p>}
      </div>
      <div className="custom-form-control">
        <label htmlFor="email">Work Email</label>
        <input
          type="email"
          value={values.email}
          placeholder="Enter Email"
          onChange={handleChange("email")}
        />
        {formError.email && <p className="error">{formError.email}</p>}
      </div>
      <div className="container-fluid px-0">
        <div className="row">
          <div className="col-6">
            <div className="custom-form-control">
              <label htmlFor="companyName">Company Name</label>
              <input
                type="text"
                value={values.companyName}
                placeholder="Enter company name"
                onChange={handleChange("companyName")}
              />
              {formError.companyName && (
                <p className="error">{formError.companyName}</p>
              )}
            </div>
          </div>
          <div className="col-6">
            <div className="custom-form-control">
              <label htmlFor="lastName">Company Type</label>
              <select onChange={handleChange("companyType")}>
                <option value="">Select company type</option>
                {CompanyType?.length > 0 &&
                  CompanyType?.map((item, index) => {
                    return (
                      <option value={item.id} key={index}>
                        {item.title}
                      </option>
                    );
                  })}
              </select>
              {formError.companyType && (
                <p className="error">{formError.companyType}</p>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="custom-form-control">
        <label htmlFor="password">Password</label>
        <input
          id="password"
          type={passShow ? "text" : "password"}
          value={values.password}
          placeholder="***************"
          onChange={handleChange("password")}
        />
        <img
          className="pwd-icon"
          src="/assets/vectors/pwd-show.svg"
          alt="show"
          onClick={() => setPassShow(!passShow)}
        />
        {formError.password && <p className="error">{formError.password}</p>}
      </div>
      {/* <div className="custom-form-control">
        <label htmlFor="password">Confirm Password</label>
        <input
          id="confirmpassword"
          type={confirmShow ? "text" : "password"}
          value={values.confirmPassword}
          placeholder="***************"
          onChange={handleChange("confirmPassword")}
        />
        <img
          className="pwd-icon"
          src="/assets/vectors/pwd-show.svg"
          alt="show"
          onClick={() => setConfirmShow(!confirmShow)}
        />
        {formError.confirmPassword && <p className="error">Password did not match</p>}
      </div> */}

      <div className="bottom">
        {LoadingData ? (
          <div className="btn btn-primary btn-auth">
            <Loader />
          </div>
        ) : (
          <button className="btn btn-primary btn-auth" onClick={submitHandler}>
            SIGN UP
          </button>
        )}
      </div>
    </div>
  );
};

export default Signup;
