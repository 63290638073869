import { GetMeetings } from "app/redux/actions";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { notifyError, notifySuccess } from "utilities";
import Dropdown from "../components/Dropdown";
import Input from "../components/Input";
import isEmpty from "../utilities/is-empty";
import ConsentModel from "components/ConsentModel";
import { DeleteMeeting } from "app/redux/actions";
import Loader from "components/Loader";
import InfiniteScroll from "react-infinite-scroll-component";

const Meetings = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const projectId = useSelector((state) => state.navigation.projectId);
  const [meeting, setMeettings] = useState();
  const [loader, setLoader] = useState(false);
  const [Dataloader, setDataLoader] = useState(false);
  const [deleteloader, setDeleteLoader] = useState(false);
  const [deleteActive, setdeleteActive] = useState(false);
  const [DeleteData, setDeleteData] = useState();
  const [page, setPage] = useState(1);
  const [meta, setMeta] = useState(null);
  const [searchTerm, setSearch] = useState("");
  const getMeetingListing = () => {
    if (projectId) {
      dispatch(GetMeetings({ project_id: projectId }, page))
        .then((res) => {
          setMeettings(res.res.data);
          setMeta(res.res.meta);
        })
        .catch((err) => {
          notifyError(err?.error?.response?.data?.message ?? "Error", 5000);
        });
    }
  };
  const handleView = (id) => {
    history.push(`/admin/project/${projectId}/tool/meetings/${id}`);
  };
  const handleEdit = (id) => {
    history.push(`/admin/project/${projectId}/tool/meetings/${id}/edit`);
  };
  // handle invoice delete 
  const handleDelete = (data) => {
    setdeleteActive(true);
    setDeleteData(data);
  };
  
  const deleteMeeting = () => {
    setDeleteLoader(true);
    dispatch(DeleteMeeting(projectId, DeleteData.id))
      .then((res) => {
        getMeetingListing();
        setDeleteLoader(false);
        setdeleteActive(false);
        notifySuccess("Meeting deleted successfully");
      })
      .catch((err) => {
        setDeleteLoader(false);
        notifyError(err?.response?.data?.message ?? "Error Deleting Meeting Id", 5000);
      });
  };
  //
  
  useEffect(() => {
    props.set_Value({
      title: props.title,
      withSettings: props.withSettings,
      rootClassName: props.rootClassName,
      mainClassName: props.mainClassName,
    });
    getMeetingListing();
  }, []);

  // search meeting
  useEffect(() => {
    if (searchTerm?.length) setMeettings([]);
    setPage(0);
    setLoader(true);
    const delayDebounceFn = setTimeout(() => {
      setPage((prev) => prev + 1);
      dispatch(
        GetMeetings(
          {
            title: searchTerm ?? "",
            project_id: projectId,
          },
          page
        )
      )
        .then((res) => {
          setMeettings(res.res.data);
          setMeta(res.res.meta);
          setLoader(false);
        })
        .catch((err) => {
          setLoader(false);
          notifyError(err?.error?.response?.data?.message ?? "Error", 5000);
        });
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  const fetchData = () => {
    if (searchTerm?.length) setPage(0);
    setDataLoader(true);
    setPage((prev) => prev + 1);
    dispatch(
      GetMeetings(
        {
          title: searchTerm ?? "",
          project_id: projectId,
        },
        page + 1
      )
    )
      .then((res) => {
        setMeta(res.res.meta);
        setMeettings([...meeting, ...res.res.data]);
        setDataLoader(false);
      })
      .catch((err) => {
        setDataLoader(false);
        notifyError(err?.error?.response?.data?.message ?? "Error", 5000);
      });
  };
  return (
    <>
      {deleteActive && (
        <div className="px-0 mt-30">
          <ConsentModel
            active={deleteActive}
            id={DeleteData?.id}
            onsubmit={deleteMeeting}
            oncancel={() => setdeleteActive(false)}
            loader={deleteloader}
            title={DeleteData.title}
          />
        </div>
      )}
      <div className="d-flex align-items-center justify-content-between">
        <div className="tabs">
          <div className="tab active">Current Meetings</div>
          {/* <div className="tab">All Meetings</div>
          <div className="tab">Recycle Bin</div> */}
        </div>
        <div>
          <button
            className="me-10 btn btn-primary-outlined"
            onClick={() => history.push(`/admin/project/${projectId}/tool/meetings/create`)}
          >
            <img src="/assets/vectors/add.svg" alt="add" />
            CREATE MEETING
          </button>

          {/* <button className="me-10 btn btn-contained-2">
            <img src="/assets/vectors/add.svg" alt="add" />
            CREATE MEETING FROM TEMPLATE
          </button> */}
        </div>
      </div>

      <div className="card mt-20">
        <div className="card-head d-flex">
          <Input
            rootClassName="m-0"
            type="search"
            placeholder="Search..."
            onChange={(e) => setSearch(e.target.value)}
            value={searchTerm}
          />
          {/* <Dropdown rootClassName="mx-10" btn dropdownItems={["Export document"]}>
            ALL FILTER
          </Dropdown> */}
        </div>
        <div className="card-body d-inline-block w-100">
          <InfiniteScroll
            dataLength={meeting?.length ?? 0}
            next={fetchData}
            hasMore={meta && meta?.total > meeting?.length}
            loader={Dataloader ? <Loader /> : null}
            height={"100%"}
            style={{ overflow: "scroll", overflowX: "hidden", maxHeight: "550px" }}
            className="infinite-scroll w-100"
          >
            <div className="table-container w-100">
              <table className="meetings">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Title</th>
                    <th>Date</th>
                    <th>Time</th>
                    <th className="text-center">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {loader ? (
                    <tr>
                      <td colSpan={4}>
                        <Loader />
                      </td>
                    </tr>
                  ) : isEmpty(meeting) ? (
                    <tr>
                      <td>No Records Found</td>
                    </tr>
                  ) : (
                    <>
                      {meeting.map((el, idx) => {
                        return (
                          <tr key={"table-item" + idx}>
                            <td>{el.number}</td>
                            <td className="fw-bold">{el.title}</td>
                            <td>{el.meeting_date}</td>
                            <td>{el.start_time}</td>
                            <td className="text-center">
                              <img
                                className="me-10"
                                src="/assets/vectors/eye.svg"
                                alt="eye"
                                style={{ cursor: "pointer" }}
                                onClick={() => handleView(el.id)}
                              />
                              <img
                                className="me-10"
                                src="/assets/vectors/edit-table.svg"
                                alt="edit-table"
                                style={{ cursor: "pointer" }}
                                onClick={() => handleEdit(el.id)}
                              />
                              <img
                                className="m"
                                src="/assets/vectors/delete-table.svg"
                                alt="delete-table"
                                style={{ cursor: "pointer" }}
                                onClick={() => handleDelete(el)}
                              />
                            </td>
                          </tr>
                        );
                      })}
                    </>
                  )}
                </tbody>
              </table>
            </div>
          </InfiniteScroll>
        </div>
      </div>
    </>
  );
};

export default Meetings;
