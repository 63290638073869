import { deleteInvoicePaymentRequest, GetInvoicePaymentList, GetProjectContracts, InvoiceListing } from "app/redux/actions";
import ConsentModel from "components/ConsentModel";
import Input from "components/Input";
import Loader from "components/Loader";
import Modal from "components/Modal";
import PaymentRequestModel from "components/PaymentRequestModel";
import SelectDropdown from "components/SelectDropdown";
import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router";
import { notifyError, notifySuccess } from "utilities";
import isEmpty from "utilities/is-empty";

const InvoicePaymentRequest = (props) => {
  const dispatch = useDispatch();
  const [paymentLoader, isPaymentLoder] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [requestListing, setRequestListing] = useState([]);
  const { id } = useParams();
  const history = useHistory();

  useEffect(() => {
    props.set_Value({
      title: props.title,
      rootClassName: props.rootClassName,
      mainClassName: props.mainClassName,
    });
  }, []);

  const recentId = localStorage.getItem("recnetCreatedInvoiceId");

  const [invoiceDropDown, setInvvoiceDropDown] = useState();

  const GetInvoiceListing = () => {
    const projectId = parseInt(id);
    dispatch(InvoiceListing({ project_id: projectId }))
      .then((res) => {
        if (res) {
          const data = res?.data?.map((item) => {
            return { value: item.id, label: item.number };
          });
          setInvvoiceDropDown(data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getInvoicePaymentListing = () => {
    const payload = {
      invoice_id: recentId,
    };
    isPaymentLoder(true);
    dispatch(GetInvoicePaymentList(payload))
      .then((res) => {
        setRequestListing(res);
        isPaymentLoder(false);
      })
      .catch((error) => {
        console.log(error);
        isPaymentLoder(false);
      });
  };

  useEffect(() => {
    getInvoicePaymentListing();
    GetInvoiceListing();
  }, []);
  const [selectedId, setSelectedId] = useState();

  const handleinvoiceDataChange = (e) => {
    setSelectedId(e.value);
    localStorage.setItem("recnetCreatedInvoiceId", e.value)
    const invoiceId = e.value;
    const payload = {
      invoice_id: invoiceId,
    };
    dispatch(GetInvoicePaymentList(payload))
      .then((res) => {
        setRequestListing(res);
        isPaymentLoder(false);
      })
      .catch((error) => {
        console.log(error);
        isPaymentLoder(false);
      });
  };

  const paymentModal = () => {
    setModalVisible(true);
  };

  const handleCloseModel = () => {
    setModalVisible(false);
  }
const [deleteActive, isDeleteActive] = useState(false);
const [deletedData, setDeleteData] = useState();
const [deleteLoader, setDeleteLoader] = useState(false);

  const handleDelete = (data) =>{
    setDeleteData(data);
    isDeleteActive(true);
  }
  const deletePaymentMethond = () => {
    const payload = {
      projectId: parseInt(id),
      invoiceId: deletedData.invoice_id,
      id: deletedData?.id
    }
    setDeleteLoader(true)
    dispatch(deleteInvoicePaymentRequest(payload))
    .then((res) => {
      getInvoicePaymentListing();
      notifySuccess("Item Deleted Successfully!");
      setDeleteLoader(false);
      isDeleteActive(false)
    })
    .catch((error) => {
      notifyError("somthing went wrong");
      setDeleteLoader(false);
    })
  }
  return (
    <>
    {deleteActive &&
        <ConsentModel
          active={deleteActive}
          id={deletedData?.id}
          onsubmit={deletePaymentMethond}
          oncancel={() => isDeleteActive(false)}
          loader={deleteLoader}
          title={deletedData?.id}
        />
      }

      {modalVisible && (
        <PaymentRequestModel
          active={modalVisible}
          hideModal={handleCloseModel}
        //   submitText={titleText}
        paymentLisitng={getInvoicePaymentListing}
        projectId={id}
        titleText={"Add Payment Request"}
        />
      )}
      <div className="top" style={{ borderBottom: "none" }}>
        <div className="tabs"></div>
        <div className="options d-flex">
          <button
            className="ms-10 btn btn-primary-outlined"
            onClick={paymentModal}
          >
            <img src="/assets/vectors/add.svg" alt="add" />
            NEW REQUEST
          </button>
        </div>
      </div>
      <div>
        <SelectDropdown
          className="basic-single"
          classNamePrefix="select"
          isSearchable={true}
          options={invoiceDropDown}
          placeholder={"Select Invoice"}
          onChange={(e) => handleinvoiceDataChange(e)}
          loadingMessage={() => "Fetching Roles"}
          label={"Invoice Listing"}
        />
      </div>
      <div className="main">
        <div className="listing mt-20">
          <div className="table-container">
            <table>
              <thead>
                <tr>
                  <th style={{ maxWidth: "7%" }}>Request#</th>
                  <th className="text-center" style={{ maxWidth: "7%" }}>
                    Amount
                  </th>
                  <th className="text-center" style={{ maxWidth: "10%" }}>
                    Notes
                  </th>
                  <th className="text-center" style={{ maxWidth: "10%" }}>
                    Created_At
                  </th>
                  <th className="text-center" style={{ maxWidth: "10%" }}>
                    Updated_At
                  </th>
                  <th style={{ maxWidth: "10%" }} className="text-center">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {paymentLoader ? (
                  <tr>
                    <td colSpan={4}>
                      <Loader />
                    </td>
                  </tr>
                ) : isEmpty(requestListing?.data) ? (
                  <tr>
                    <td>No Records Found</td>
                  </tr>
                ) : (
                  <>
                    {requestListing?.data.map((request, index) => {
                      const createdAt = new Date(request?.created_at);
                      const updatedAt = new Date(request?.updated_at);
                      return (
                        <tr key={index}>
                          <td style={{ width: "25%", maxWidth: "25%" }}>
                            <a
                              style={{ cursor: "pointer" }}
                              onClick={() => history.push(`/admin/project/${id}/tool/invoices/${request?.invoice_id}/paymentRequest/${request.id}`)}
                            >
                              {request?.id}
                            </a>
                          </td>
                          <td
                            className="text-center"
                            style={{ width: "10%", maxWidth: "15%" }}
                          >
                            {request?.amount}
                          </td>
                          <td
                            className="text-center"
                            style={{ width: "10%", maxWidth: "15%" }}
                            dangerouslySetInnerHTML={{
                              __html: request?.notes
                            }}
                          >
                          </td>
                          <td
                            className="text-center"
                            style={{ width: "10%", maxWidth: "15%" }}
                          >
                            {createdAt.toLocaleDateString()}
                          </td>
                          <td
                            className="text-center"
                            style={{ width: "10%", maxWidth: "15%" }}
                          >
                            {updatedAt.toLocaleDateString()}
                          </td>
                          <td
                            className="text-center"
                            style={{ width: "20%", maxWidth: "20%" }}
                          >
                            {/* <img
                              className="me-10"
                              src="/assets/vectors/edit-table.svg"
                              alt="edit-table"
                              style={{ cursor: "pointer" }}
                            /> */}
                            <img
                              className="me-10"
                              src="/assets/vectors/delete-table.svg"
                              alt="delete-table"
                              style={{ cursor: "pointer" }}
                                onClick={() => handleDelete(request)}
                            />
                          </td>
                        </tr>
                      );
                    })}
                  </>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {/* <Modal
        isModalActive={modalVisible}
      >
        <h4 className="mb-4" style={{ fontSize: "28px" }}>
          Payment Request
        </h4>
        <Input
          rootClassName="mb-20"
          label="Name"
          placeholder="Enter Name"
        />
        <Input
          rootClassName="mb-20"
          label="Email ID"
          placeholder="Enter Email ID"
        />
        <Input
          rootClassName="mb-20"
          label="Ofiice Phone Number"
          placeholder="Enter Ofiice Phone Number"
          type="number"
        />
      </Modal> */}
    </>
  );
};

export default InvoicePaymentRequest;
