import React from "react";
import Footer from "./Footer";
const AppFooter = () => {
  return (
    <>
      <div className="section landing-footer">
        <div className="main">
          <div className="page-container">
            <div className="container-fluid px-0">
              <div className="row g-xxl-5">
                <div className="col-2">
                  <div className="logo">
                    <img
                      style={{ maxWidth: 120 }}
                      className="d-block w-100"
                      src="./assets/vectors/builderLogo.png"
                      alt="logo"
                    />
                  </div>
                </div>
                <div className="col-2">
                  <h4>Products</h4>

                  <div className="links">
                    <a href="#0">Platform Overview</a>
                    <a href="#0">Analytics</a>
                    <a href="#0">App Marketplace</a>
                    <a href="#0">BIM</a>
                    <a href="#0">Design Cooperation</a>
                  </div>
                </div>
                <div className="col-2">
                  <h4>Solutions</h4>

                  <div className="links">
                    <a href="#0">Main Contractors</a>
                    <a href="#0">Owners</a>
                    <a href="#0">Subcontractors</a>
                  </div>
                </div>
                <div className="col-2">
                  <h4>Events</h4>

                  <div className="links">
                    <a href="#0">Groundbreak</a>
                    <a href="#0">Webinars</a>
                  </div>
                </div>
                <div className="col-2">
                  <h4>Resources</h4>

                  <div className="links">
                    <a href="#0">Resource Center</a>
                    <a href="#0">eBooks</a>
                    <a href="#0">Community</a>
                    <a href="#0">Job Industry News</a>
                    <a href="#0">Support</a>
                  </div>
                </div>
                <div className="col-2">
                  <h4>Company</h4>

                  <div className="links">
                    <a href="#0">About</a>
                    <a href="#0">Blog</a>
                    <a href="#0">Careers</a>
                    <a href="#0">Investors</a>
                    <a href="#0">Legal</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer className="mt-0" />
    </>
  );
};

export default AppFooter;
