import React, { useState } from "react";
import Modal from "./Modal";
import Input from "./Input";
import { useDispatch } from "react-redux";
import { CreateCompanies } from "app/redux/actions";
import { notifySuccess } from "utilities";
const CompaniesModal = ({ active, hideModal, setCompanies }) => {
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [loader, setLoader] = useState(false);
  const [nameError, setNameError] = useState("");
  const [addressError, setAddressError] = useState(false);
  const dispatch = useDispatch();
  const handleSubmit = (e) => {
    setLoader(true);
    e.preventDefault();
    if (name && name.length > 3 && address) {
      dispatch(CreateCompanies({ name: name, address: address }))
        .then((res) => {
          setCompanies(res);
          setLoader(false);
          hideModal();
          notifySuccess("Equipment created successfully");
        })
        .catch((err) => {
          setLoader(false);
        });
    } else {
      setLoader(false);
      if (!name) setNameError("Please enter Equipment Name");
      if (!address) setAddressError("Please enter company address");
    }
  };
  return (
    <Modal active={active} hideModal={hideModal} submit={handleSubmit} loader={loader}>
      <h4 className="mb-4">Add Delivery Company</h4>
      <Input
        label="Name"
        placeholder="Enter Company name"
        onChange={(e) => setName(e.target.value)}
        inputCapitalize={"inputCapitalize"}
        errorMsg={nameError}
      />
      <Input
        label="Company Address"
        placeholder="Enter Company Address"
        onChange={(e) => setAddress(e.target.value)}
        inputCapitalize={"inputCapitalize"}
        errorMsg={addressError}
        isLocation
      />
    </Modal>
  );
};

export default CompaniesModal;
