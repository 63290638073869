import { Login } from "app/redux/actions";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link, Redirect, useHistory } from "react-router-dom";
import Loader from "../components/Loader";
import { notifyError, notifySuccess, validateEmail } from "../utilities/index";

const Signin = () => {
  const [LoadingData, SetLoadingData] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [show, setShow] = useState(false);
  const history = useHistory();

  const dispatch = useDispatch();
  const [values, setValues] = useState({
    email: "",
    password: "",
  });
  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const submitHandler = (e) => {
    e.preventDefault();
    if (!values.email) {
      setEmailError("Email Address required");
    }
    if (!values.password) {
      setPasswordError("Password required");
    }

    if (values.email && values.password) {
      if (validateEmail(values.email)) {
        setEmailError("");
        setPasswordError("");
        SetLoadingData(true);
        let payload = {
          email: values.email,
          password: values.password,
        };
        dispatch(Login(payload))
          .then((res) => {
            if (res?.payload) {
              notifySuccess("Logged in successfully!");
              SetLoadingData(false);
              if (res.payload.data.user.status) {
                return history.push("/admin/dashboard");
              } else {
                return history.push("/auth/verification");
              }
            }
          })
          .catch((err) => {
            notifyError(err?.error?.response?.data?.message ?? "Error", 5000);
            SetLoadingData(false);
          });
      } else {
        setEmailError("Please provide a valid Email");
      }
    } else {
      notifyError("Please fill all required fields");
    }
  };

  return (
    <div className="form">
      <div className="custom-form-control">
        <label htmlFor="email">Email</label>
        <input
          type="email"
          placeholder="Enter Your Email"
          onChange={handleChange("email")}
        />
        {emailError && <p className="error">{emailError}</p>}
      </div>
      <div className="custom-form-control">
        <label htmlFor="password">Password</label>
        <input
          id="password"
          type={show ? "text" : "password"}
          placeholder="***************"
          onChange={handleChange("password")}
        />
        <img
          className="pwd-icon"
          src="/assets/vectors/pwd-show.svg"
          alt="show"
          onClick={() => setShow((prev) => !prev)}
        />
        {passwordError && <p className="error">{passwordError}</p>}
      </div>
      <div className="d-flex justify-content-between">
        <label className="custom-checkbox-container">
          Remember Me
          <input type="checkbox" />
          <span className="checkmark"></span>
        </label>
        <Link className="unstyled" to="/auth/forgot-password">
          Forgot Password ?
        </Link>
      </div>
      <div className="bottom">
        <div className="d-flex justify-content-between align-items-center">
          {!LoadingData ? (
            <button
              className="btn btn-primary btn-auth"
              onClick={submitHandler}
            >
              SIGN IN
            </button>
          ) : (
            <div className="btn btn-primary btn-auth">
              <Loader />
            </div>
          )}

          <div>
            Already have an account ?<Link to="/auth/signup"> Sign Up</Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signin;
