import React, { useState } from "react";
import Modal from "./Modal";
import Input from "./Input";
import { useDispatch } from "react-redux";
import { CreateEquipment } from "app/redux/actions";
import { notifySuccess } from "utilities";
const EquipmentsModal = ({ active, hideModal, setEquipments }) => {
  const [name, setName] = useState("");
  const [loader, setLoader] = useState(false);
  const [nameError, setNameError] = useState("");
  const dispatch = useDispatch();
  const handleSubmit = (e) => {
    setLoader(true);
    e.preventDefault();
    if (name && name.length > 3) {
      dispatch(CreateEquipment({ name: name }))
        .then((res) => {
          setEquipments(res);
          setLoader(false);
          hideModal();
          notifySuccess("Equipment created successfully");
        })
        .catch((err) => {
          setLoader(false);
        });
    } else {
      setNameError("Please enter Equipment Name");
    }
  };
  return (
    <Modal active={active} hideModal={hideModal} submit={handleSubmit} loader={loader}>
      <h4 className="mb-4">Add Equipment</h4>
      <Input
        label="Equipment"
        placeholder="Enter Equipment name"
        onChange={(e) => setName(e.target.value)}
        inputCapitalize={"inputCapitalize"}
        errorMsg={nameError}
      />
    </Modal>
  );
};

export default EquipmentsModal;
