import React from "react";
const VideoModal = ({ click }) => {
  return (
    <div className="Video_Modal" onClick={() => click(false)}>
      <div className="video-inner">
        <button
          style={{
            position: "absolute",
            top: "-22px",
            right: "0",
            background: "none",
            border: "none",
            cursor: "pointer",
          }}
        >
          <img src="/assets/vectors/cross.svg" style={{ filter: " brightness(10.5)" }} />
        </button>
        <iframe
          src="https://www.youtube.com/embed/vmHoGicPQQQ"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
          style={{ width: "100%" }}
        ></iframe>
      </div>
    </div>
  );
};

export default VideoModal;
