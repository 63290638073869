import React from "react";
import { useEffect } from "react";
import { Link, useParams } from "react-router-dom";


const Labor = (props) => {
  useEffect(() => {
    props.set_Value({
      title: props.title,
      rootClassName: props.rootClassName,
      mainClassName: props.mainClassName,
    });
  }, []);
  const { id } = useParams();

  return (
    <>
    {/* Bread Crumbs */}
    <div className="top pb-20" style={{ borderBottom: "none" }}>
        <div className="breadcrumb">
          <Link to={`/admin/project/${id}`} className="breadcrumb-item">
            Dashboard
          </Link>
          <Link to={`/admin/project/${id}/exportStatment`} className="breadcrumb-item">
            Export Statment
          </Link>
          <div className="breadcrumb-item">Labor</div>
        </div>
      </div>
      {/* End Bread Crumbs */}
      {/* Export Section */}
      <div className="top pb-20 mb-20 mt-20" style={{ borderBottom: "none" }}>
        <div className="section-title">
          <h4>Project Statment</h4>
        </div>
        <div className="options d-flex align-items-right">
          <button
            className="ms-10 btn btn-primary"
            style={{ background: "#FFF2D9", color: "#E79436" }}
          >
            EXPORT
          </button>
        </div>
      </div>
      {/* End Export Section */}
      {/* Sub Contractor Section */}
      <div className="section">
        <div className="section-title mb-10">
          <h4>Labor</h4>
        </div>
        <div className="table-container">
          <table className="team">
            <thead>
              <tr>
                <th colSpan={2}>NO</th>
                <th className="text-center" colSpan={4}>Week Period</th>
                <th className="text-center">Amount</th>
              </tr>
            </thead>
            <tbody>
                    <tr>
                      <td colSpan={2}>10/10/2022</td>
                      <td className="text-center" colSpan={4}>
                        1/7/2022 - 1/10/2022
                      </td>
                      <td className="text-center">$ 100</td>
                    </tr>
                    <tr>
                      <td colSpan={2}>10/10/2022</td>
                      <td className="text-center" colSpan={4}>
                      1/7/2022 - 1/10/2022
                      </td>
                      <td className="text-center">$ 100</td>
                    </tr>
                    <tr>
                        <td style={{ textAlign: "center", color: "#2F5597", fontWeight: "bold"}} colSpan={6}>Total</td>
                        <td style={{ textAlign: "center", fontWeight:"bold"}}>$ 1000 -</td>
                    </tr>
            </tbody>
          </table>
        </div>
      </div>
      {/* End Sub Contractor Section */}
    </>
  );
};


export default Labor;
