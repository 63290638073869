import React, { Fragment, useState } from "react";
import { useEffect } from "react";
import InvoiceCards from "components/InvoiceCard";
import { useHistory } from "react-router";
import { useSelector } from "react-redux";
import { DeleteInvoice, InvoiceListing } from "app/redux/actions";
import { useDispatch } from "react-redux";
import { notifyError, notifySuccess } from "utilities";
import ConsentModel from "components/ConsentModel";
import Loader from "components/Loader";
import isEmpty from "utilities/is-empty";
import { Link } from "react-router-dom";
import InvoiceTypes from "components/InvoiceTypes";

const Invoice = (props) => {
  const invoiceData = {
    title: "total Invoice",
    value: "$140.000",
  };
  const projectId = useSelector((state) => state.navigation.projectId);
  const [invoiceListing, setInvoiceListing] = useState();
  const [page, setPage] = useState(1);
  const history = useHistory();
  const dispatch = useDispatch();
  const [deleteActive, setdeleteActive] = useState(false);
  const [DeleteData, setDeleteData] = useState();
  const [deleteloader, setDeleteLoader] = useState(false);
  const [loader, setLoader] = useState(false);

  const handleDelete = (data) => {
    setdeleteActive(true);
    setDeleteData(data);
  };

  const getInvoiceListing = () => {
    setLoader(true);
    dispatch(InvoiceListing({ project_id: projectId }))
      .then((res) => {
        setInvoiceListing(res);
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
        notifyError(err?.error?.response?.data?.message ?? "Error", 5000);
      });
  };
  const deleteInvoice = () => {
    setDeleteLoader(true);
    dispatch(DeleteInvoice(projectId, DeleteData.id))
      .then((res) => {
        dispatch(InvoiceListing({ project_id: projectId }))
          .then((res) => {
            setInvoiceListing(res);
          })
          .catch((err) => {
            // setLoader(false);
            notifyError(err?.error?.response?.data?.message ?? "Error", 5000);
          });
        setDeleteLoader(false);
        setdeleteActive(false);
        notifySuccess("Invoice deleted successfully");
      })
      .catch((err) => {
        setDeleteLoader(false);
        notifyError(
          err?.response?.data?.message ?? "Error Deleting Meeting Id",
          5000
        );
      });
  };
  useEffect(() => {
    props.set_Value({
      title: props.title,
      rootClassName: props.rootClassName,
      mainClassName: props.mainClassName,
    });
    getInvoiceListing();
  }, []);

  const handleEdit = (id) => {
    history.push(`/admin/project/${projectId}/tool/invoices/${id}/edit`);
  };
  
  const [isModelOpen, setIsModelOpne] = useState(false);
  const handleShow = () => {
    setIsModelOpne(true);
  };

  const handleCloseModel = () => {
    setIsModelOpne(false);
  }

  return (
    <>
      {deleteActive && (
        <div className="px-0 mt-30">
          <ConsentModel
            active={deleteActive}
            id={DeleteData?.id}
            onsubmit={deleteInvoice}
            oncancel={() => setdeleteActive(false)}
            loader={deleteloader}
            title={DeleteData.number}
          />
        </div>
      )}
      <div className="top" style={{ borderBottom: "none" }}>
        <div className="tabs"></div>
        <div className="options d-flex">
          <button
            className="ms-10 btn btn-primary-outlined"
            onClick={handleShow}
          >
            <img src="/assets/vectors/add.svg" alt="add" />
            NEW INVOICE
          </button>
        </div>
      </div>
      <InvoiceTypes isOpen={isModelOpen}
      isClose={handleCloseModel} />
      <div className="container-fluid">
        <div className="row">
          {invoiceListing &&
            Object.entries(invoiceListing)?.map(([key, value]) => (
              <Fragment key={key}>
                {key !== "data" && (
                  <div className="col-md-4 bordered_card" key={key}>
                    <div className="card invoice-card">
                      <div className="card-block">
                        <h6 className="m-b-20">{key}</h6>
                        <h2 className="text-left">
                          <span>{value}</span>
                        </h2>
                      </div>
                    </div>
                  </div>
                )}
              </Fragment>
            ))}
        </div>
      </div>
      <div className="main">
        <div className="listing mt-20">
          <div className="table-container">
            <table>
              <thead>
                <tr>
                  <th style={{ maxWidth: "7%" }}>Invoice#</th>
                  <th style={{ maxWidth: "10%" }}>Type</th>
                  <th style={{ maxWidth: "10%" }}>Total Amount</th>
                  <th style={{ maxWidth: "10%" }}>Sales Person</th>
                  <th style={{ maxWidth: "10%" }}>Attachments</th>
                  <th style={{ maxWidth: "10%" }}>Invoice Status</th>
                  <th style={{ maxWidth: "10%" }} className="text-center">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {loader ? (
                  <tr>
                    <td colSpan={4}>
                      <Loader />
                    </td>
                  </tr>
                ) : isEmpty(invoiceListing?.data) ? (
                  <tr>
                    <td>No Records Found</td>
                  </tr>
                ) : (
                  <>
                    {invoiceListing?.data?.map((item, idx) => {
                      const { id } = item;
                      return (
                        <tr key={idx}>
                          <td style={{ width: "25%", maxWidth: "25%" }}>
                            <a
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                history.push(
                                  `/admin/project/${projectId}/tool/invoices/${id}`
                                )
                              }
                            >
                              {item?.number}
                            </a>
                          </td>
                          <td style={{ width: "10%", maxWidth: "15%" }}>
                            {item?.type === 'PO' ? 'Purchase Order' : 'Change Order'}
                          </td>
                          <td style={{ width: "10%", maxWidth: "15%" }}>
                            {item?.total?.total_amount}
                          </td>
                          <td style={{ width: "10%", maxWidth: "15%" }}>
                            {item?.sales_person?.name}
                          </td>
                          <td style={{ width: "10%", maxWidth: "15%" }}>
                            <img
                              style={{
                                width: "100px",
                                maxHeight: "50px",
                                objectFit: "contain",
                              }}
                              src={
                                item?.attachments[item?.attachments.length - 1]
                                  ?.url
                              }
                            />
                          </td>
                          <td
                            style={{
                              width: "10%",
                              maxWidth: "15%",
                              color: "#E79436",
                            }}
                          >
                            Pending
                          </td>
                          <td
                            className="text-center"
                            style={{ width: "20%", maxWidth: "20%" }}
                          >
                            <Link
                              to={{
                                pathname: `/admin/project/${projectId}/tool/invoices/${id}/edit`,
                                state: { item },
                              }}
                            >
                              <img
                                className="me-10"
                                src="/assets/vectors/edit-table.svg"
                                alt="edit-table"
                                style={{ cursor: "pointer" }}
                              />
                            </Link>
                            <img
                              className="me-10"
                              src="/assets/vectors/delete-table.svg"
                              alt="delete-table"
                              style={{ cursor: "pointer" }}
                              onClick={() => handleDelete(item)}
                            />
                          </td>
                        </tr>
                      );
                    })}
                  </>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default Invoice;
