import { GetIncidentDetail } from "app/redux/actions";
import Loader from "components/Loader";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";

const IncidentDetail = (props) => {
  const { id, incidentId } = useParams();
  const dispatch = useDispatch();
  const [incidentDetail, setIncidentDetail] = useState(null);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    props.set_Value({
      title: props.title,
      rootClassName: props.rootClassName,
      mainClassName: props.mainClassName,
    });
    getIncidentDetailListing();
  }, []);

  const getIncidentDetailListing = () => {
    setLoader(true);
    dispatch(GetIncidentDetail(id, incidentId))
      .then((res) => {
        setIncidentDetail(res.data);
        setLoader(false);
      })
      .catch((error) => {
        console.log(error);
        setLoader(false);
      });
  };
  return (
    <>
      <div className="top pb-20" style={{ borderBottom: "none" }}>
        <div className="breadcrumb">
          <Link
            to={`/admin/project/${id}/tool/Incidents`}
            className="breadcrumb-item"
          >
            Incidents
          </Link>
          <div className="breadcrumb-item">Incident Details</div>
        </div>
        <div className="options d-flex align-items-right"></div>
      </div>

      <div className="row">
        <div className="col-md-12">
          <div className="card-left m-10">
            <div className="table-container">
              <div className="row m-2 table-heading invoice__details">
                <div className="col-md-4">
                  <label className="headings">Incident ID</label>
                  <div>{incidentDetail?.case_number}</div>
                </div>
                <div className="offset-md-3 col-md-4">
                  <label className="headings">Employee:</label>
                  <div>{incidentDetail?.employee_name}</div>
                </div>
              </div>
              <form className="form m-10 project-detail-form mt-4">
                <div className="table-container invoice_details_table">
                  <table className="">
                    <thead>
                      <tr>
                        <td>
                          <h4 className="card-heading text-start">Job Title</h4>
                        </td>
                        <td>
                          <h4 className="card-heading text-start">Location</h4>
                        </td>
                        <td>
                          <h4 className="card-heading text-start">
                            Leave Days
                          </h4>
                        </td>
                        <td>
                          <h4 className="card-heading text-start">Case Type</h4>
                        </td>
                        <td>
                          <h4 className="card-heading text-start">
                            Injury Date
                          </h4>
                        </td>
                        <td>
                          <h4 className="text-start card-heading">
                            Type Of Illness
                          </h4>
                        </td>
                      </tr>
                    </thead>
                    <tbody>
                      {loader ? (
                        <tr>
                          <td colSpan={6}>
                            <Loader size={36} />
                          </td>
                        </tr>
                      ) : (
                        <tr>
                          <td>{incidentDetail?.job_title}</td>
                          <td>{incidentDetail?.location}</td>
                          <td>{incidentDetail?.leave_days}</td>
                          <td>{incidentDetail?.case_type}</td>
                          <td>{incidentDetail?.date_of_injury}</td>
                          <td>{incidentDetail?.type_of_illness}</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default IncidentDetail;
