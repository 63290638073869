import Input from "../../components/Input";
import React from "react";
import Loader from "components/Loader";
import InfiniteScroll from "react-infinite-scroll-component";

const customStyles = {
  control: () => ({
    width: "100%",
    background: "#f9f9f9",
    display: "flex",
    borderRadius: "5px",
    border: "1px solid #dedee4",
    height: "48px",
    paddingLeft: "10px",
    marginBottom: 0,
  }),
};
const Notes = ({
  state,
  Listing,
  fetchData,
  deliveryCompanies,
  dataLoader,
  meta,
  errors,
  handleDelete,
  handleChange,
  handleCreate,
  Images,
  handleImageUpload,
  imageLoader,
  removeFile,
  loader,
  location,
  handleEdit,
  isEditing,
  cancelEdit,
  setIsEditing,
  setImages,
  handlelocation
}) => {
  return (
    <>
      <h4 className="fs-20 mb-20">Notes</h4>
      <InfiniteScroll
        dataLength={Listing?.length ?? 0}
        next={fetchData}
        hasMore={meta && meta?.total > Listing?.length}
        loader={loader ? <Loader /> : null}
        height={"100%"}
        style={{ overflow: "scroll", overflowX: "hidden", maxHeight: "550px" }}
        className="infinite-scroll w-100"
      >
        <div className="table-container dailylog_table">
          <table>
            <thead>
              <tr>
                <th className="td-lg">Location</th>
                <th className="td-lg">Comments</th>
                <th>Attachments</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {dataLoader ? (
                <tr>
                  <td colSpan={4}>
                    <Loader />
                  </td>
                </tr>
              ) : (
                Listing?.map((item, index) =>
                  isEditing.id !== item.id ? (
                    <tr key={index}>
                      <td>{item.location}</td>
                      <td>{item.comments}</td>
                      <td>
                        {item?.files?.length
                          ? item?.files?.map((image) => (
                              <img
                                src={image?.file ?? image?.url}
                                alt={image?.title}
                                style={{ width: 50, height: 50 }}
                                key={image?.id}
                              />
                            ))
                          : "No Attachement"}
                      </td>
                      <td>
                        <button
                          className="btn btn-contained-2 me-10 create__btn"
                          onClick={() => handleEdit(item)}
                        >
                          <img
                            className="me-0"
                            src="/assets/vectors/edit-table.svg"
                            alt="edit"
                          />
                        </button>
                        <button
                          className="btn btn-contained-2 ms-10 create__btn"
                          onClick={() => handleDelete(item)}
                        >
                          <img
                            className="me-0"
                            src="/assets/vectors/delete-table.svg"
                            alt="delete"
                          />
                        </button>
                      </td>
                    </tr>
                  ) : (
                    <tr key={index}>
                      <td className="td-lg">
                        {/* <select
                          className="w-100"
                          value={state.location}
                          onChange={handleChange}
                          name="location"
                        >
                          <option value="">select Location</option>
                          {location?.map((item) => (
                            <option value={item}>{item}</option>
                          ))}
                        </select> */}
                        <Input
                          type="location"
                          name="location"
                          placeholder="Enter location"
                          locationChange={handlelocation}
                          errorMsg={errors.location}
                          value={state.location}
                        />
                      </td>
                      <td className="td-lg">
                        <Input
                          placeholder="Add Comments"
                          name="comments"
                          onChange={handleChange}
                          value={state.comments}
                          errorMsg={errors.comments}
                        />
                      </td>
                      <td style={{ width: "100px", minWidth: "none" }}>
                        {Images?.length ? (
                          Images?.map((item, idx) => (
                            <div
                              className="position-relative d-inline-block"
                              key={idx}
                            >
                              <button
                                onClick={() => removeFile(item.id || item)}
                                style={{
                                  background: "transparent",
                                  border: 0,
                                  position: "absolute",
                                  top: 2,
                                  right: 0,
                                }}
                              >
                                <img
                                  src="/assets/vectors/close-icon.svg"
                                  alt=""
                                />
                              </button>
                              <img
                                src={item.file || item.url}
                                style={{
                                  width: 50,
                                  height: 50,
                                  objectFit: "cover",
                                }}
                              />
                            </div>
                          ))
                        ) : imageLoader ? (
                          <Loader />
                        ) : (
                          <>
                            <label
                              htmlFor="file"
                              className="d-flex me-20"
                              onClick={() => {
                                setIsEditing({ editing: false, id: null });
                                setImages([]);
                              }}
                            >
                              <img
                                className="clip"
                                src="/assets/vectors/clip.svg"
                                alt="clip"
                              />
                              <div className="text">Attach File(s)</div>
                            </label>
                            <input
                              id="file"
                              className="d-none"
                              type="file"
                              onChange={handleImageUpload("Notes")}
                            />
                          </>
                        )}
                      </td>
                      <td>
                        <div className="d-flex align-items-center">
                          {dataLoader ? (
                            <button className="btn sm btn-primary create__btn">
                              <Loader />
                            </button>
                          ) : (
                            <button
                              className="btn sm btn-primary create__btn"
                              onClick={() => handleCreate()}
                            >
                              <img
                                src="/assets/vectors/check-mark.png"
                                alt="submit"
                                style={{ maxWidth: 24 }}
                              />
                            </button>
                          )}
                          <button
                            className="btn sm create__btn ms-2 cancelEdit"
                            onClick={() => cancelEdit()}
                          >
                            <img
                              src="/assets/vectors/close-icon.png"
                              alt="cancel"
                              style={{ maxWidth: 24 }}
                            />
                          </button>
                        </div>
                      </td>
                    </tr>
                  )
                )
              )}
              <tr>
                <td className="td-lg">
                  {/* <select
                    className="w-100"
                    onChange={handleChange}
                    name="location"
                    value={!isEditing.editing ? state.location : ""}
                  >
                    <option value="">select Location</option>
                    {location?.map((item) => (
                      <option value={item} key={item}>
                        {item}
                      </option>
                    ))}
                  </select> */}
                  <Input
                    type="location"
                    name="location"
                    placeholder="Enter location"
                    locationChange={handlelocation}
                    errorMsg={errors.location}
                    value={!isEditing.editing ? state.location : ""}
                  />
                  {errors.location && (
                    <p className="error">{errors.location}</p>
                  )}
                </td>
                <td className="td-lg">
                  <Input
                    placeholder="Add Comments"
                    name="comments"
                    onChange={handleChange}
                    errorMsg={errors.comments}
                    value={!isEditing.editing ? state.comments : ""}
                  />
                </td>
                <td style={{ width: "100px", minWidth: "none" }}>
                  {Images?.length && !isEditing.editing ? (
                    Images?.map((item, idx) => (
                      <div
                        className="position-relative d-inline-block"
                        key={idx}
                      >
                        <button
                          onClick={() => removeFile(item.id || item)}
                          style={{
                            background: "transparent",
                            border: 0,
                            position: "absolute",
                            top: 2,
                            right: 0,
                          }}
                        >
                          <img src="/assets/vectors/close-icon.svg" alt="" />
                        </button>
                        <img
                          src={item.file || item.url}
                          style={{ width: 50, height: 50, objectFit: "cover" }}
                        />
                      </div>
                    ))
                  ) : !isEditing.editing && imageLoader ? (
                    <Loader />
                  ) : (
                    <>
                      <label htmlFor="file" className="d-flex me-20">
                        <img
                          className="clip"
                          src="/assets/vectors/clip.svg"
                          alt="clip"
                        />
                        <div className="text">Attach File(s)</div>
                      </label>
                      <input
                        id="file"
                        className="d-none"
                        type="file"
                        onChange={handleImageUpload("Notes")}
                      />
                    </>
                  )}
                </td>
                <td>
                  {dataLoader ? (
                    <button className="btn sm btn-primary create__btn">
                      <Loader />
                    </button>
                  ) : (
                    <button
                      className="btn sm btn-primary create__btn"
                      onClick={() => handleCreate()}
                    >
                      +
                    </button>
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </InfiniteScroll>
    </>
  );
};

export default Notes;
