import React from "react";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
const TextEditor = ({overview,changeOverview}) => { 
  return (
    <>
       <CKEditor 
        editor={ ClassicEditor }
        data={overview}
        onChange={ ( event, editor ) => {
            const data = editor.getData();
            changeOverview(data)
        } }
      />
    </>
  );
};
export default TextEditor;
