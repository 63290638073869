import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import Input from "../components/Input";
import Dropdown from "../components/Dropdown";
import { useDispatch, useSelector } from "react-redux";
import { notifyError, notifySuccess } from "utilities";
import { ToDoListing, DeleteToDo } from "app/redux/actions";
import TodoModal from "../components/TodoModal";
import moment from "moment";
import ConsentModel from "components/ConsentModel";
import { isEmpty } from "lodash";
import InfiniteScroll from "react-infinite-scroll-component";
import Loader from "components/Loader";

const ToDo = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const projectId = useSelector((state) => state.navigation.projectId);
  const [todo, setToDo] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [editData, setEditData] = useState(null);
  const [activeCategory, setActiveCategory] = useState(null);
  const [deleteActive, setdeleteActive] = useState(false);
  const [DeleteData, setDeleteData] = useState();
  const [page, setPage] = useState(1);
  const [meta, setMeta] = useState(null);
  const [deleteloader, setDeleteLoader] = useState(false);
  const [loader, setLoader] = useState(false);
  const [searchTerm, setSearch] = useState("");
  const [dataLoader, setDataLoader] = useState(false);

  const getToDoListing = () => {
    setPage(0);
    setLoader(true);
    if (projectId) {
      dispatch(ToDoListing({ project_id: projectId }, page))
        .then((res) => {
          setToDo(res.data);
          setLoader(false);
        })
        .catch((err) => {
          setLoader(false);
          notifyError(err?.error?.response?.data?.message ?? "Error", 5000);
        });
    }
  };

  const handleDelete = (data) => {
    setdeleteActive(true);
    setDeleteData(data);
  };

  const deleteToDo = () => {
    setDeleteLoader(true);
    dispatch(DeleteToDo(projectId, DeleteData.id))
      .then((res) => {
        getToDoListing();
        setDeleteLoader(false);
        setdeleteActive(false);
        notifySuccess("Todo deleted successfully");
      })
      .catch((err) => {
        setDeleteLoader(false);
        notifyError(err?.response?.data?.message ?? "Error Deleting Meeting Id", 5000);
      });
  };

  const handleEdit = (data) => {
    setEditData(data);
    setModalVisible(true);
  };

  useEffect(() => {
    props.set_Value({
      title: props.title,
      withSettings: props.withSettings,
      subTitle: props.subTitle,
      withLogs: props.withLog,
    });
    getToDoListing();
  }, []);

  const showModal = () => {
    setEditData(null);
    setModalVisible(true);
  };

  const hideModal = () => {
    setModalVisible(false);
  };

  const fetchData = () => {
    if (searchTerm?.length) setPage(0);
    setDataLoader(true);
    setPage((prev) => prev + 1);
    dispatch(
      ToDoListing(
        {
          title: searchTerm ?? "",
          project_id: projectId,
        },
        page + 1
      )
    )
      .then((res) => {
        setMeta(res.meta);
        setToDo([...todo, ...res.data]);
        setDataLoader(false);
      })
      .catch((err) => {
        setDataLoader(false);
        notifyError("Error getting data!");
      });
  };
  useEffect(() => {
    setLoader(true);
    if (searchTerm) {
      setToDo([]);
      setPage(0);
    }
    const delayDebounceFn = setTimeout(() => {
      setPage((prev) => prev + 1);
      dispatch(
        ToDoListing(
          {
            title: searchTerm ?? "",
            project_id: projectId,
          },
          page
        )
      )
        .then((res) => {
          setMeta(res.meta);
          setToDo(res.data);
          setLoader(false);
        })
        .catch((err) => {
          setLoader(false);
          notifyError("Error getting data!");
        });
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  return (
    <>
      {deleteActive && (
        <div className="px-0 mt-30">
          <ConsentModel
            active={deleteActive}
            id={DeleteData?.id}
            onsubmit={deleteToDo}
            oncancel={() => setdeleteActive(false)}
            loader={deleteloader}
            title={DeleteData.title}
          />
        </div>
      )}
      {modalVisible && (
        <TodoModal
          activecategory={activeCategory}
          setactivecategory={setActiveCategory}
          hideModal={hideModal}
          isModalActive={modalVisible}
          editData={editData}
          setToDo={setToDo}
          setPage={setPage}
          getToDoListing={getToDoListing}
        />
      )}
      <div className="top">
        <div className="tabs"></div>
        <div className="options d-flex">
          {/* <Dropdown btn dropdownItems={["Export document"]}>
            EXPORT
          </Dropdown> */}
          <button onClick={showModal} className="ms-10 btn btn-primary-outlined">
            CREATE TODO
          </button>
        </div>
      </div>
      <div className="main">
        <div className="filter">
          <div className="search">
            <Input
              rootClassName="mb-0 "
              type="search"
              placeholder="Search"
              onChange={(e) => setSearch(e.target.value)}
            />
            {/* <Dropdown btn dropdownItems={["filter 1"]}>
              ADD FILTERS
            </Dropdown> */}
          </div>
        </div>
        <div className="listing mt-20">
          <InfiniteScroll
            dataLength={todo?.length}
            next={fetchData}
            hasMore={meta && meta?.total > todo?.length}
            loader={dataLoader ? <Loader /> : null}
            height={"100%"}
            style={{ overflow: "scroll", overflowX: "hidden", maxHeight: "550px" }}
            className="infinite-scroll w-100"
          >
            <div className="table-container">
              <table>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Title</th>
                    <th>Description</th>
                    <th>Assigne</th>
                    <th>Due Date</th>
                    <th>Status</th>
                    <th>Category</th>
                    <th className="text-center">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {loader ? (
                    <tr>
                      <td colSpan={8}>
                        <Loader />
                      </td>
                    </tr>
                  ) : isEmpty(todo) ? (
                    <tr>
                      <td colSpan={8}>No record found</td>
                    </tr>
                  ) : (
                    <>
                      {todo.map((tli, idx) => {
                        const { id, title, description } = tli;
                        return (
                          <tr key={"table-item" + idx}>
                            <td style={{ width: "4%", maxWidth: "4%" }}>{id}</td>
                            <td style={{ width: "13%", maxWidth: "13%" }}>{title}</td>
                            <td style={{ width: "40%", maxWidth: "40%" }}>{description}</td>
                            <td style={{ width: "10%", maxWidth: "10%" }}>{tli?.assignee.name}</td>
                            <td style={{ width: "10%", maxWidth: "10%" }}>
                              {moment(tli?.due_date).format("DD-MM-YYYY")}
                            </td>
                            <td style={{ width: "5%", maxWidth: "5%" }}>{tli?.status}</td>
                            <td style={{ width: "5%", maxWidth: "5%" }}>{tli?.category?.title ?? "-"}</td>
                            <td className="text-center" style={{ width: "20%", maxWidth: "20%" }}>
                              <img
                                className="me-10"
                                src="/assets/vectors/eye.svg"
                                alt="eye"
                                style={{ cursor: "pointer" }}
                                onClick={() => history.push(`/admin/project/${projectId}/tool/todos/${id}`)}
                              />
                              <img
                                className="me-10"
                                src="/assets/vectors/edit-table.svg"
                                alt="edit-table"
                                style={{ cursor: "pointer" }}
                                onClick={() => handleEdit(tli)}
                              />
                              <img
                                className="me-10"
                                src="/assets/vectors/delete-table.svg"
                                alt="delete-table"
                                style={{ cursor: "pointer" }}
                                onClick={() => handleDelete(tli)}
                              />
                            </td>
                          </tr>
                        );
                      })}
                    </>
                  )}
                </tbody>
              </table>
            </div>
          </InfiniteScroll>
        </div>
      </div>
    </>
  );
};

export default ToDo;
