import React, { useState, useEffect } from "react";
import Modal from "./Modal";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { formFieldsValidation, formValidation, notifyError, notifySuccess } from "utilities";
import { useParams } from "react-router";
import Input from "../components/Input";
import TextEditor from "./textEditor/TextEditor";
import { CreateStages, CreateTypes, GetStages, GetTypes } from "app/redux/actions";

const customStyles = {
  control: () => ({
    width: "100%",
    background: "#f9f9f9",
    marginBottom: "10px",
    display: "flex",
    borderRadius: "5px",
    border: "1px solid #dedee4",
    height: "48px",
    paddingLeft: "10px",
  }),
};
const CreateStage = ({
  active,
  hideModal,
  titleText,
  type
}) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [isValid, setIsValid] = useState(false);
  const [formValidation, setFormValidation] = useState({});
  const projectId = useSelector((state) => state.navigation.projectId);

  const [data, setData] = useState({
    name: "",
    description: "",
  });
  
  React.useEffect(() => {
    if (isValid && Object.keys(formValidation).length === 0) {
        if(type === true){
            const payload = {
                name: data.name,
                description: data.description 
            };
            dispatch(CreateTypes(payload))
            .then((res) => {
                notifySuccess("Type Created Sucessfuly!");
                hideModal(true)
                dispatch(GetTypes())
                .then((res) => {
    
                })
                .catch((error) => {
    
                });
                setData({});
            })
            .catch((error) => {
    
            });
        } else {
            const payload = {
                name: data.name,
                description: data.description 
            };
            dispatch(CreateStages(payload))
            .then((res) => {
                notifySuccess("Stage Created Sucessfuly!");
                hideModal(true)
                dispatch(GetStages())
                .then((res) => {
    
                })
                .catch((error) => {
    
                });
                setData({});
            })
            .catch((error) => {
    
            });
        }
    }
  }, [formValidation]);

  const submitForm = () => {
    setFormValidation(
        formFieldsValidation(data)
    );
    setIsValid(true);
  };

  const handleChange = (event, name) => {
    setData({ ...data, [name]: event.target.value });
  };


  return (
    <div>
      <Modal
        active={active}
        hideModal={hideModal}
        submitText={titleText}
        submit={submitForm}
        loader={isLoading}
      >
        <h4 className="mb-4" style={{ fontSize: "28px" }}>
          Create {type === true ? "Type" : "Stage"}
        </h4>
        <div>
            <Input
                withInfo
                tooltipText={"Name"}
                inputCapitalize="inputCapitalize"
                id="name"
                label="Name*"
                placeholder="Enter Name"
                onChange={(name) => handleChange(name, "name")}
            />
            {formValidation.name && (
            <p className="error" style={{ color: "#dc3545" }}>
                {formValidation.name}
            </p>
            )}
        </div>
        <div>
            <Input
                rows={5}
                textarea
                id="description"
                label="Description"
                placeholder="Enter Description"
                onChange={(description) => handleChange(description, "description")}
            />
            {formValidation.description && (
            <p className="error" style={{ color: "#dc3545" }}>
                {formValidation.description}
            </p>
            )}
        </div>
            
      </Modal>
    </div>
  );
};

export default CreateStage;
