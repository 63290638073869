import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Checkbox from "../components/Checkbox";
import Input from "../components/Input";
import InputDate from "../components/InputDate";
import TextEditor from "components/textEditor/TextEditor";
import CustomSelector from "../components/CustomSelector";
import { useDispatch, useSelector } from "react-redux";
import { GetAttendees, CreateMeetings, ImageUpload, MeetingDetail } from "app/redux/actions";
import { useHistory, useParams } from "react-router";
import TimezoneSelect from "react-timezone-select";
import { meetingValidation, notifyError, notifySuccess } from "utilities";
import { EditMeeting } from "app/redux/actions";
import moment from "moment";
import Loader from "components/Loader";
import { v4 as uuidv4 } from "uuid";
import useImageUpload from "hooks/useImageUpload";
const CreateMeeting = (props) => {
  const history = useHistory();
  const [LoadingData, SetLoadingData] = useState(false);
  const projectId = useSelector((state) => state.navigation.projectId);
  const dispatch = useDispatch();
  const [meetingLocation, setMeetingLocation] = useState("");
  const [overview, setOverview] = useState("");
  const [isEdit, setisEdit] = useState(false);
  const { id } = useParams();
  const [data, setData] = useState({
    id: uuidv4() || "",
    meetingName: "",
    videConfigLink: "",
    meetingDate: "",
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    startTime: "",
    endTime: "",
    images: [],
    attendees: [],
  });
  const [attendeesList, setAttendees] = useState();
  const [formError, setformError] = useState({});
  const [isValid, setisValid] = useState(false);

  const handleChange = (prop) => (event) => {
    setData({ ...data, [prop]: event.target.value });
  };
  const handleLocation = (value) => {
    setMeetingLocation(value);
  };
  const DateHandlChange = (date) => {
    setData({ ...data, ["meetingDate"]: date });
  };
  const handleTimeZone = (value) => {
    setData({ ...data, ["timeZone"]: value.label });
  };
  const handleOverviewChange = (value) => {
    setOverview(value);
  };
  const handleAttendees = (value) => {
    setData({ ...data, attendees: value });
  };

  const {
    handleImageUpload,
    imageLoader,
    Images,
    removeFile,
    dragEnter,
    dragLeave,
    dragOver,
    Drop,
  } = useImageUpload(data?.images);
  useEffect(() => {
    if (isValid && Object.keys(formError).length === 0) {
      SetLoadingData(true);
      const imagesData = data?.images?.map((image) => image?.url || image?.file);
      let payload = {
        number: data?.id,
        title: data?.meetingName,
        meeting_link: data.videConfigLink,
        meeting_date: moment(data.meetingDate).format("YYYY-MM-DD hh:mm:ss"),
        start_time: data?.startTime,
        end_time: data?.endTime,
        timezone: data?.timeZone,
        location: meetingLocation,
        attachements: Images,
        attendees: data?.attendees?.map((item) => item.value),
        overview: overview,
      };
      if (isEdit && id) {
        dispatch(EditMeeting(payload, { projectId, id }))
          .then((payload) => {
            SetLoadingData(false);
            notifySuccess("Meeting Edited Successfully");
            history.push(`/admin/project/${projectId}/tool/meetings`);
          })
          .catch((err) => {
            SetLoadingData(false);
            notifyError(err);
          });
      } else {
        if (projectId) {
          dispatch(CreateMeetings(payload, projectId))
            .then((payload) => {
              SetLoadingData(false);
              notifySuccess("Meeting Created Successfully");
              history.push(`/admin/project/${projectId}/tool/meetings`);
            })
            .catch((err) => {
              SetLoadingData(false);
              notifyError(err);
            });
        }
      }
    }
  }, [formError]);

  const submitHandler = (e) => {
    e.preventDefault();
    setformError(meetingValidation(data, meetingLocation));
    setisValid(true);
  };

  useEffect(() => {
    props.set_Value({
      title: props.title,
      withSettings: props.withSettings,
      rootClassName: props.rootClassName,
      mainClassName: props.mainClassName,
    });
    if (id && history.location.pathname.includes(`/meetings/${id}/edit`)) {
      dispatch(MeetingDetail(projectId, id))
        .then((res) => {
          const { data } = res;
          setData({
            id: data?.number || "",
            meetingName: data.title || "",
            videConfigLink: data.meeting_link || "",
            meetingDate: new Date(data.meeting_date) || "",
            timeZone: data.timezone || "",
            startTime: data?.start_time?.slice(0, -3) || "",
            endTime: data?.end_time?.slice(0, -3) || "",
            images: data?.attachements || [],
            attendees: data?.attendees.map((e) => ({ label: e.name, value: e.id })) || [],
          });
          setMeetingLocation(data?.location);
          setOverview(data?.overview);
          setisEdit(true);
        })
        .catch((err) => {
          notifyError(err);
        });
    }
    dispatch(GetAttendees(projectId))
      .then((response) => {
        const data = response?.res?.map((item) => {
          const obj = { label: item.name, value: item.id };
          return obj;
        });
        setAttendees(data);
      })
      .catch((err) => {});
  }, []);
  const customStyles = {
    control: () => ({
      border: "1px solid #dedee4",
      borderRadius: "5px",
      background: "#f4f4f8",
      padding: "5px 7px",
      display: "flex",
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = "opacity 300ms";

      return { ...provided, opacity, transition };
    },
  };
  return (
    <>
      <div className="breadcrumb">
        <Link to="/meetings" className="breadcrumb-item">
          Meetings
        </Link>
        <div className="breadcrumb-item">New Meeting</div>
      </div>
      <div className="card mt-20">
        <div className="card-body flex-column">
          <h4 className="fs-20">MEETING INFORMATION</h4>

          <form className="form mt-20" action="">
            <Input
              dull
              label="Meeting #"
              imp
              type="text"
              placeholder="Enter Meeting"
              value={data.id}
              onChange={handleChange("id")}
              errorMsg={formError.id}
            />
            <Input
              dull
              label="Meeting Name"
              imp
              placeholder="Enter Meeting Name"
              inputCapitalize={"inputCapitalize"}
              value={data.meetingName}
              onChange={handleChange("meetingName")}
              errorMsg={formError.meetingName}
            />
            <Input
              dull
              label="Vide Conferencing Link"
              imp
              placeholder="Enter Conference Link"
              value={data.videConfigLink}
              onChange={handleChange("videConfigLink")}
              errorMsg={formError.videConfigLink}
            />
            <div>
              <div className="fw-bold mt-30">Private Meeting</div>
              <Checkbox
                rootClassName="mt-10"
                small
                label="Visible only to meeting admins and scheduled attendees"
              />
            </div>

            <div>
              <div className="fw-bold mt-30">Draft Meeting</div>
              <Checkbox rootClassName="mt-10" small label="Draft Meeting" />
            </div>

            <div className="container-fluid px-0 mt-30">
              <div className="row">
                <div className="col-6">
                  <InputDate
                    noInfo
                    label="Meeting Date"
                    selected={data.meetingDate}
                    onChange={(e) => DateHandlChange(e)}
                    errorMsg={formError.meetingDate}
                  />
                </div>
                <div className="col-6 select-wrapper custom-form-control ">
                  <label>TimeZone</label>
                  <TimezoneSelect
                    styles={customStyles}
                    selected={data.timeZone}
                    value={{ label: data.timeZone, value: data.timeZone }}
                    defaultValue={data.timeZone}
                    onChange={handleTimeZone}
                  />
                  {formError.timeZone && <p className="error">{formError.timeZone}</p>}
                </div>
              </div>
            </div>

            <div className="container-fluid px-0 mt-6 custom-form-control">
              <div className="row">
                <div className="col-6">
                  <label>Start Time</label>
                  <input
                    type="time"
                    controls={["time"]}
                    timeFormat="hh:mm"
                    selected={data.startTime}
                    value={data.startTime}
                    onChange={handleChange("startTime")}
                  />
                  {formError.startTime && <p className="error">{formError.startTime}</p>}
                </div>
                <div className="col-6">
                  <label>End Time</label>
                  <input
                    type="time"
                    selected={data.endTime}
                    value={data.endTime}
                    onChange={handleChange("endTime")}
                  />
                  {formError.endTime && <p className="error">{formError.endTime}</p>}
                </div>
              </div>
            </div>
            <Input
              type="location"
              label="Meeting Location"
              placeholder="Enter location"
              locationChange={handleLocation}
              errorMsg={formError.meetingLocation}
              defaultValue={meetingLocation}
            />
            <div className="px-0 mt-30 select-wrapper custom-form-control">
              <label>OverView</label>
              <TextEditor overview={overview} changeOverview={handleOverviewChange} />
            </div>
            <div className="px-0 mt-30 select-wrapper custom-form-control">
              <Input
                type="file"
                label="Attachments"
                rootClassName="mt-20"
                icon="/assets/vectors/clip-dark.svg"
                labelClassName="fs-16"
                withInfo
                tooltipText={"Attach file"}
                id="meeting-image"
                placeholder="Attach file or Drag &amp; Drop"
                onChange={handleImageUpload("meeting-image")}
                onDragOver={dragOver}
                onDragEnter={dragEnter}
                onDragLeave={dragLeave}
                onDrop={Drop}
                multiple_image={Images}
                imageLoader={imageLoader}
                removeFile={removeFile}
                errorMsg={formError.attachments}
                multiple
              />
            </div>
            <div className="select-wrapper custom-form-control">
              <CustomSelector
                changehandler={handleAttendees}
                data={attendeesList}
                defaultValue={[...data.attendees]}
                value={[...data.attendees]}
                title={"Scheduled Attendees"}
              />
              {formError.attendees && <p className="error">{formError.attendees}</p>}
            </div>
            <div className="px-0 mt-30">
              <div>
                <div className="col-12 p-0 d-flex" style={{ justifyContent: "end" }}>
                  <div>
                    <button
                      className="btn btn-cancel btn-auth"
                      style={{ marginRight: "20px" }}
                      onClick={() => history.push(`/admin/project/${projectId}/tool/meetings`)}
                    >
                      Cancel
                    </button>
                  </div>
                  <div>
                    {LoadingData ? (
                      <button className="btn btn-primary btn-auth">
                        <Loader />
                      </button>
                    ) : (
                      <button className="btn btn-primary btn-auth" onClick={submitHandler}>
                        {isEdit ? "Update" : "Create"}
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default CreateMeeting;
