import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import AppFooter from "components/AppFooter";
import routes from "../Routes/PublicRoutes";
import AppBar from "components/AppBar";
const PublicLayout = () => {
  const GetRoutes = () => {
    return routes.map((route, key) => (
      <Route path={route.path} render={() => <route.component />} key={key} />
    ));
  };
  return (
    <div className="landing-container">
      <AppBar />
      <Switch>
        {GetRoutes()}
        <Route path="*" component={() => <Redirect to="/" />} />
      </Switch>
      <AppFooter />
    </div>
  );
};
export default PublicLayout;
