import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const notify = (text, speed) => {
  toast(text, {
    hideProgressBar: true,
    autoClose: speed ?? 2000,
  });
};
export const notifySuccess = (text, speed) => {
  toast.success(text, {
    hideProgressBar: true,
    autoClose: speed ?? 2000,
  });
};
export const notifyError = (text, speed) => {
  toast.error(text, {
    hideProgressBar: true,
    autoClose: speed ?? 2000,
  });
};

export const validateEmail = (email) => {
  return email.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/);
};

export const meetingValidation = (data, location, Images) => {
  const errors = {};
  if (!data.id) errors.id = "meeting ID is required";
  if (!data.meetingName) errors.meetingName = "Meeting Name is required";
  if (!data.videConfigLink) errors.videConfigLink = "meeting Link is required";
  if (!data.meetingDate) errors.meetingDate = "meeting date is required";
  if (!data.startTime) errors.startTime = " StartTime is required";
  if (!data.endTime) errors.endTime = " endTime is required";
  if (!data.timeZone) errors.timeZone = "Meeting TimeZone is required";
  if (!location) errors.meetingLocation = "Meeting Location is required";
  if (!data?.attendees?.length)
    errors.attendees = "Meeting Attendess is required";
  // if (!Images?.length) errors.attachments = "Attachements is required";
  if (Object.keys(errors).length > 0) {
    notifyError("Please fill all required fields");
  }
  return errors;
};

export const formFieldsValidation = (data) => {
  const errors = {};
  if (!data.name) errors.name = "Name is required";
  if (!data.description) errors.description = "Description is required";
  if (Object.keys(errors).length > 0) {
    notifyError("Please fill all required fields");
  }
  return errors;
};

export const EsignatureValidation = (data, font) => {
  const error = {};
  if (!data.name) error.name = "Name is required";
  if (!data.description) error.description = "Description is required";
  if (!data.attachments) error.attachments = "Image is required";
  // if (!data.fontText) error.fontText = "FontText is required.";
  if (!font) error.font = "Font is required";

  return error;
};
export const checkValidation = (data) => {
  const error = {};
  if (!data.category) error.category = "category is required";
  if (!data.revision) error.revision = "revision is required";
  if (!data.lang) error.lang = "Drawing Language is required";
  if (!data.drawingDate) error.drawingDate = "Drawing Date is required";
  if (!data.receivedDate) error.receivedDate = "Recieved Date is required";
  if (!data.set) error.set = "Set is required";
  if (!data.Images) error.Images = "Image is required";
  if (!data.title) error.title = "Title is required";
  if (Object.keys(error).length > 0) {
    notifyError("Please fill all required fields");
  }
  return error;
};
export const invoiceCreationCheck = (data) => {
  const error = {};
  if (!data.invoiceId) error.invoiceId = "Invoice Id is required";
  if (!data.salesPerson) error.salesPerson = "Sales Person is required";
  // if(!data?.images?.length) error.imagesErrors = "Images Is required";
  if (Object.keys(error).length > 0) {
    notifyError("Please fill all required fields");
  }
  return error;
};

export const invoiceChangeCheck = (data) => {
  const error = {};
  if (!data.invoices) error.invoices = "Invoice Id is required";
  // if (!data.salesPerson) error.salesPerson = "Sales Person is required";
  // if(!data?.images?.length) error.imagesErrors = "Images Is required";
  if (Object.keys(error).length > 0) {
    notifyError("Please fill all required fields");
  }
  return error;
};

export const checkItemValidation = (data) => {
  const error = {};
  if (!data.name) error.name = "Name is required";
  if (!data.unit) error.unit = "Unit Type is required";
  if (!data.selling_Price) error.selling_Price = " selling_price is required";
  if (!data.itemType) error.itemType = " Itme Type is required";
  if (!data.vendorId) error.vendorId = " Vendor is required";
  if (Object.keys(error).length > 0) {
    notifyError("Please fill all required fields");
  }
  return error;
};

export const ExportValidation = (data) => {
  const error = {};
  if (!data.directorSelect)
    error.directorSelect = "Director signature is required";
  if (!data.ownerSelect) error.ownerSelect = "Owner signature is required";
  if (!data.chiefSelect) error.chiefSelect = "Chief signature is required";
  if (Object.keys(error).length > 0) {
    notifyError("Please fill all required fields");
  }
  return error;
};

export const MemberValidation = (data) => {
  const error = {};
  if (!data.role) error.role = "Role is required";
  if (!data.name) error.name = "Name is required";
  if (!data.email) error.email = "Email is required";
  if (!validateEmail(data.email))
    error.email = "Please provide a valid Email Address";
  if (!data.officeNo) error.officeNo = "Office Number is required";
  if (data.officeNo.length < 10) {
    error.officeNo = "Office Number must be atleast 10 characters long";
  }
  if (!data.mobileNo) error.mobileNo = "mobile Number is required";
  if (Object.keys(error).length > 0) {
    notifyError("Please fill all required fields");
  }
  return error;
};

/*
contrators validation
*/

export const ContractorsValidation = (values) => {
  const error = {};
  if (!values.name) error.name = "Name is required";
  if (!values.location) error.location = "Location is required";
  if (!values.contact) error.contact = "mobile Number is required";
  if (!values.type) error.type = "Type is required";
  if (Object.keys(error).length > 0) {
    notifyError("Please fill all required fields");
  }
  return error;
};

export const ContractsValidation = (data, formState, discription) => {
  const error = {};
  if (!data.contractor) error.contractor = "Name is required";
  if (!discription) error.discription = "discription is required";
  if (!formState.startDate) error.startDate = "Start Date is required";
  if (!formState.endDate) error.endDate = "End Date is required";
  if (!data.type) error.type = "Type is required";
  if (!data.amount) error.amount = "Amount is required";
  if (!data.signed_by) error.signed_by = "Signed by is required";
  if (Object.keys(error).length > 0) {
    notifyError("Please fill all required fields");
  }
  return error;
};

// Create To-Do Validation Form
export const todoValidation = (data, attachments) => {
  const error = {};
  if (!attachments || !attachments.length)
    error.attachments = "Attachments is required";
  if (!data.title) error.title = "Title is required";
  if (!data.due_date) error.dueDate = "Due Date is required";
  if (!data.description) error.description = "Description is required";
  if (!data.category) error.category = "Category is required";
  if (!data.assignee) error.assignee = "Assignee is required";
  if (!data.status) error.status = "Status Number is required";
  if (Object.keys(error).length > 0) {
    notifyError("Please fill all required fields");
  }
  return error;
};

export const validateForm = (data, category) => {
  const errors = {};
  if (category == 1) {
    if (!data.company_id) {
      errors.companyId = "Company field required";
    }
    if (!data.workers) {
      errors.workers = "Workers field required";
    }
    if (!data.working_hours) {
      errors.working_hours = "Working hours is required";
    }
    if (!data.total_hours) {
      errors.total_hours = "Total hours is required";
    }
    if (!data.location) {
      errors.location = "Location is required";
    }
  }

  if (category == 2) {
    if (!data.equipment_id) {
      errors.equipment_id = "Equipment is Required";
    }
    if (!data.idle_hours) {
      errors.idle_hours = "Ideal Hours is Required";
    }
    if (!data.operating_hours) {
      errors.operating_hours = "Operating Hours is required";
    }
    if (!data.inspection_time) {
      errors.inspection_time = "Inspection Time is required";
    }
  }

  if (category == 3) {
    if (!data.delivery_time) {
      errors.delivery_time = "Delivery Time is Required";
    }
    if (!data.delivery_company_id) {
      errors.delivery_company_id = "Delivery Company is Required";
    }
    if (!data.tracking_number) {
      errors.tracking_number = "Tracking Number is required";
    }
  }
  if (category == 4) {
    if (!data.location) {
      errors.location = "Location is Required";
    }
  }
  if (Object.keys(errors).length > 0) {
    notifyError("Please fill all required fields");
  }
  return errors;
};

export const validateContact = ({
  name,
  email,
  type,
  mobileNo,
  description,
}) => {
  const error = {};
  if (!name) error.name = "Name is required";
  if (!email) error.email = "Email is required";
  if (!validateEmail(email)) error.email = "Please provide a valid Email";
  if (!type) error.type = "Type is required";
  if (!mobileNo) error.mobileNo = "Mobile Number is required";
  if (!description) error.description = "Message is required";
  if (Object.keys(error).length > 0) {
    notifyError("Please fill all required fields");
  }
  return error;
};

export const resetPasswordValidation = (values) => {
  const errors = {};
  if (!values.currentPassword)
    errors.currentPass = "Current Password is required";
  if (!values.newPassword) errors.newPass = "New Password is required";
  if (!values.confirmNewPassword)
    errors.newConfirmPass = "Confirm Password is required";
  if (values.newPassword !== values.confirmNewPassword)
    errors.newConfirmPass = "Password do not match";
  if (
    values.currentPassword &&
    values.newPassword &&
    values.currentPassword == values.newPassword
  ) {
    errors.newPass =
      "You used that password recently. Choose a different password";
  }
  if (Object.keys(errors).length > 0) {
    notifyError("Please fill all required fields");
  }
  return errors;
};

export const editUserValidation = (editUser) => {
  const errors = {};
  if (!editUser.first_name) errors.first_name = "Name is required";
  if (!editUser.last_name) errors.last_name = "LastName is required";
  // if (!editUser.telephone) errors.telephone = "PhoneNumber is required";
  if (!editUser.gender) errors.gender = "gender is required";
  if (Object.keys(errors).length > 0) {
    notifyError("Please fill all required fields");
  }
  return errors;
};

// const validate = (value) => {
//   const { currentPassword, newPassword, confirmNewPassword } = value;
//   const errors = {};
//   if (!currentPassword) errors.currentPass = "Current Password is required";
//   if (!newPassword) errors.newPass = "New Password is required";
//   if (!confirmNewPassword)
//     errors.newConfirmPass = "Confirm Password is required";
//   if (newPassword !== confirmNewPassword)
//     errors.newConfirmPass = "Password do not match";
//   if (currentPassword && newPassword && currentPassword == newPassword) {
//     errors.newPass =
//       "You used that password recently. Choose a different password";
//   }
//   return errors;
// };
export const paymentRequestVslidate = (values, notes) => {
  const error = {};
  if (!values.amount) error.amount = "Amount is required";
  if (!notes) error.notes = "Note is required";
  if (!values.startDate) error.startDate = "Start Date is required";
  if (!values.invoiceId) error.invoiceId = "Invoice Id is required";
  if (!values.contractId) error.contractId = "Contract Id is required";
  if (Object.keys(error).length > 0) {
    notifyError("Please fill all required fields");
  }
  return error;
};

export const vendorValidation = (vendor, description) => {
  const error = {};
  if (!vendor.venderName) error.venderName = "Vendor Name is required";
  if (!vendor.vendorEmail) error.vendorEmail = "Vendor Email is required";
  if (!vendor.contact) error.contact = "Contact Number is required";
  if (!vendor.location) error.location = "location is required";
  if (!description) error.description = "Description Id is required";
  if (Object.keys(error).length > 0) {
    notifyError("Please fill all required fields");
  }
  return error;
};

export const incidentValidationForm = (
  incident,
  formState,
  incidentLocation
) => {
  const error = {};
  if (!incident.employeeName) error.employeeName = "Employee Name is required";
  if (!incident.jobTitle) error.jobTitle = "Job Title is required";
  if (!incident.leaveDays) error.leaveDays = "Leave Days is required";
  if (!formState.injuryDate) error.injuryDate = "Injury Date is required";
  if (!incidentLocation) error.incidentLocation = "location is required";
  if (!incident.caseType) error.caseType = "Case Type is required";
  if (!incident.illnessType) error.illnessType = "Illness Type is required";

  if (Object.keys(error).length > 0) {
    notifyError("Please fill all required fields");
  }
  return error;
};

export const ContractorAgreementValidation = (
  contAgreement,
  description,
  Location
) => {
  const error = {};
  if (!contAgreement.workDate) error.workDate = "Date is required";
  if (!contAgreement.contractor)
    error.contractor = "Contractor Value is required";
  if (!contAgreement.subcontractor)
    error.subcontractor = "SubContractor Value is required";
  if (!contAgreement.project) error.project = "Project Value is required";
  if (!contAgreement.owner) error.owner = "Owner Name is required";
  if (!contAgreement.archetectEng)
    error.archetectEng = "ArchetectEng Type is required";
  if (!contAgreement.contractAmount)
    error.contractAmount = "ContractAmount Type is required";
  if (!contAgreement.contact) error.contact = "Contact is required";
  if (!contAgreement.payment_date)
    error.payment_date = "payment_date is required";
  if (!Location) error.Location = "Location is required";

  if (!description) error.description = "Description is required";
  if (Object.keys(error).length > 0) {
    notifyError("Please fill all required fields");
  }
  return error;
};

export const RiderValidationForm = (rider) => {
  const error = {};
  if (!rider.riderDate) error.riderDate = "Date is required";
  if (!rider.riderProject) error.riderProject = "Project Value is required";
  if (!rider.riderSubContname)
    error.riderSubContname = "SubContractor Value is required";
  if (Object.keys(error).length > 0) {
    notifyError("Please fill all required fields");
  }
  return error;
};

export const PartialAffidavitValidation = (partials) => {
  const error = {};
  if (!partials.undersigned) error.undersigned = "undersigned is required";
  if (!partials.date) error.date = "date is required";
  if (!partials.person) error.person = "person is required";
  if (!partials.jobId) error.jobId = "jobId is required";
  if (!partials.locatedAt) error.locatedAt = "locatedAt is required";
  if (!partials.payment) error.payment = "payment is required";
  if (!partials.completionDate)
    error.completionDate = "completionDate is required";
  if (!partials.paymentDate) error.paymentDate = "paymentDate is required";
  if (!partials.subsCriptionDate)
    error.subsCriptionDate = "subsCriptionDate is required";
  if (!partials.subContSigned)
    error.subContSigned = "subContSigned is required";
  if (Object.keys(error).length > 0) {
    notifyError("Please fill all required fields");
  }
  return error;
};

export const FinalAffidavitValidation = (final) => {
  const error = {};
  if (!final.date) error.date = "date is required";
  if (!final.undersigned) error.undersigned = "undersigned is required";
  if (!final.subsCriptionDate)
    error.subsCriptionDate = "undersigned is required";
  if (!final.person) error.person = "person is required";
  if (!final.jobId) error.jobId = "jobId is required";
  if (!final.locatedAt) error.locatedAt = "locatedAt is required";
  if (!final.payment) error.payment = "payment is required";
  if (!final.subContSigned) error.subContSigned = "subContSigned is required";
  if (!final.completionDate) error.completionDate = "subsMonth is required";
  if (!final.paymentDate) error.paymentDate = "subsMonth is required";
  if (Object.keys(error).length > 0) {
    notifyError("Please fill all required fields");
  }
  return error;
};

export const subContractValidationForm = (
  data,
  subContDescription,
  location
) => {
  const error = {};
  if (!subContDescription) error.subContDescription = "description is required";
  if (!data.agreementDate) error.agreementDate = "agreementDate is required";
  if (!data.state) error.state = "state is required";
  if (!data.city) error.city = "city is required";
  if (!data.country) error.country = "country is required";
  if (!data.subContractorEmail)
    error.subContractorEmail = "subContractorEmail is required";
  if (!data.contractorName) error.contractorName = "contractorName is required";
  if (!data.subContractorName)
    error.subContractorName = "subContractorName is required";
  if (!data.clientEmail) error.clientEmail = "clientEmail is required";
  if (!data.clientName) error.clientName = "clientName is required";
  if (!data.contractAmount)
    error.contractAmount = "contractAmount  is required";

  if (Object.keys(error).length > 0) {
    notifyError("Please fill all required fields");
  }
  return error;
};
