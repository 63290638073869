import React, { useState, useRef } from "react";
import clsx from "clsx";
import { useEffect } from "react";
import ProjectDetails from "../Partials/ProjectDetails";
import Tools from "../Partials/Tools";
import Directory from "../Partials/Directory";
import Loader from "../components/Loader";
import { useHistory, useParams, Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  GetCountries,
  GetStages,
  GetTemplates,
  GetTypes,
} from "app/redux/actions";
import { RESET_PROJECT } from "app/redux/constants";
import UserInfo from "components/UserInfo";

const CreateProject = (props) => {
  const [stepState, setStepState] = useState(0);
  const [stepsCompleteState, setStepsCompleteState] = useState(false);
  const [loadingData, SetLoadingData] = useState(false);
  const [isEdit, setisEdit] = useState(false);

  const [sideInfoState, setSideInfoState] = useState({
    title: "Project Tip",
    desc: "Selecting a project template will automatically populate the project tools assigned to that template by your Admin. While the step will be skipped, you can click on Tools to make adjustments.",
  });

  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();
  const projectDetails = useRef();
  const ProjectTools = useRef();
  const projectDirectories = useRef();

  const incrementStep = (e) => {
    if (stepState === 0) {
      projectDetails.current.Submit(e);
    } else if (stepState === 1) {
      ProjectTools.current.Submit(e);
    } else if (stepState === 2) {
      projectDirectories.current.Submit(e);
    }
  };

  useEffect(() => {
    dispatch(GetTemplates());
    dispatch(GetStages());
    dispatch(GetTypes());
    dispatch(GetCountries());
    if (
      id &&
      id !== 0 &&
      history.location.pathname.includes(`/create/project/${id}/edit`)
    ) {
      setisEdit(true);
    }
  }, []);

  useEffect(() => {
    setStepState(id ? 0 : 0);
    if (stepState === 0) {
      setStepsCompleteState(false);
    } else if (stepState === 2) {
      setStepsCompleteState(true);
    }

    if (stepState >= 1) {
      setSideInfoState({
        title: "Tool Order",
        desc: "You can change the viewing order of the Project Tools in the menu by dragging them up or down within their group. However, tools cannot be moved across groups.",
      });
    }
    if (stepState >= 2) {
      setSideInfoState({
        title: "Set Project Roles",
        desc: "For security reasons, many of your users do not have access to your Company or Project level Directory. Assigning Project Roles creates a list of key project team members on the Project Home page. You can create custom project roles in the Company level Admin tool.",
      });
    }
  }, [stepState]);

  const onSkip = () => {
    dispatch({ type: RESET_PROJECT });
    history.push("/admin/dashboard");
  };

  return (
    <div
      className="main"
      style={{
        marginTop: "0px",
      }}
    >
      <div className="header">
        <Link to="/admin/dashboad">
          <img src="/assets/vectors/builderLogo.png" alt="logo" height="60px" />
        </Link>
        <UserInfo />
      </div>
      <div className="title-container">
        <h2>{id == 0 ? "Create Project" : "Update Project"}</h2>
        <button onClick={() => onSkip()} className="btn btn-primary-outlined">
          SKIP &amp; SAVE
        </button>
      </div>
      <div className="left">
        <div className="steps">
          {[
            {
              img: "/assets/vectors/create-project-1.svg",
              text: "Project Details",
            },
            {
              img: "/assets/vectors/create-project-2.svg",
              text: "Tools",
            },
            {
              img: "/assets/vectors/create-project-3.svg",
              text: "Directory",
            },
            {
              img: "/assets/vectors/create-project-4.svg",
              text: "Drawings",
              firstStep: true,
            },
            {
              img: "/assets/vectors/create-project-5.svg",
              text: "Specifications",
              firstStep: true,
            },
            {
              img: "/assets/vectors/create-project-6.svg",
              text: "Schedule",
              firstStep: true,
            },
          ].map((el, idx) => {
            const { img, text, firstStep } = el;
            let active;
            let done;
            if (stepState === idx) {
              active = true;
            } else if (stepState > idx) {
              done = true;
            }
            if (firstStep && stepState > 0) {
              return;
            }
            return (
              <div
                key={"step" + idx}
                className={clsx("step", { active, done })}
              >
                <div className="img">
                  <img
                    src={
                      done
                        ? "/assets/vectors/create-project-step-complete.svg"
                        : img
                    }
                    alt="vector"
                  />
                </div>
                <div className="text">{text}</div>
              </div>
            );
          })}
        </div>
        <div className="tip">
          <h4>{sideInfoState.title}</h4>
          <p className="fs-12 text-light-1 mt-3 lh-15">{sideInfoState.desc}</p>
          <a className="fs-12 d-block" href="#0">
            Learn More
          </a>
        </div>
      </div>

      <div className="right">
        {stepState === 0 && (
          <ProjectDetails
            ref={projectDetails}
            setStepState={setStepState}
            SetLoadingData={SetLoadingData}
          />
        )}
        {stepState === 1 && (
          <Tools
            ref={ProjectTools}
            setStepState={setStepState}
            SetLoadingData={SetLoadingData}
          />
        )}
        {stepState === 2 && (
          <Directory
            ref={projectDirectories}
            SetLoadingData={SetLoadingData}
            stepsComplete={stepsCompleteState}
          />
        )}
        <div className="d-flex justify-content-end align-items-center">
          {/* <div>
            <button className="btn btn-primary-text" onClick={decrementStep}>
              {stepState > 0 ? "BACK" : ""}
            </button>
          </div> */}
          <div>
            {/* {stepsCompleteState && (
              <button className="btn btn-primary-outlined no-min-width me-5">SKIP</button>
            )} */}
            {loadingData ? (
              <button className="btn btn-primary buttonHeight">
                <Loader />
              </button>
            ) : (
              <button
                className="btn btn-primary buttonHeight"
                onClick={incrementStep}
              >
                {isEdit ? "UPDATE PROJECT" : "NEXT"}
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateProject;
