import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Input from "../components/Input";
import CustomSelector from "../components/CustomSelector";
import Select from "react-select";
import { invoiceCreationCheck, notifyError, notifySuccess } from "utilities";
import CreateInvoiceItems from "components/CreateInvoiceItems";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import {
  EditInvoice,
  GetAssignee,
  GetInvoiceDetail,
  GetInvoiceItems,
  GetProjectMembers,
  GetCurrencyList,
  invoiceCreation,
  UpdateInvoice,
} from "app/redux/actions";
import useImageUpload from "hooks/useImageUpload";
import Loader from "components/Loader";
import { v4 as uuidv4 } from "uuid";

const CreateInvoice = (props) => {
  const [isModalActive, setIsModalActive] = useState(false);
  const [activeCategory, setActiveCategory] = useState(null);
  const [editData, setEditData] = useState();
  const [page, setPage] = useState(1);
  const projectId = useSelector((state) => state.navigation.projectId);
  const dispatch = useDispatch();
  const [FormErrors, setErrors] = useState({});
  const [isValid, setIsValid] = useState(false);
  const history = useHistory();
  const [LoadingData, SetLoadingData] = useState(false);
  const [options, setOptions] = useState([{ value: "1", label: "+ Create" }]);
  const [isDelete, setIsDeleted] = useState();
  const [isEdit, setIsEdit] = useState();
  const location = useLocation();
  const [attendeesList, setAttendees] = useState();
  const [currencyList, setCurrencyList] = useState();
  const [data, setData] = useState({
    invoiceId: uuidv4() || "",
    discount_value: 0,
    shipping_cost: 0,
    adjustment: 0,
    grossTotal: 0,
    discountType: "fixed",
    invoice_id: null,
    handling_charges: 0,
    currency_id: null,
    type: "PO",
    items: [
      {
        rate: "0",
        qty: "1",
        amount: "",
        tax_type: "fixed",
        tax: "0",
        item_id: null,
      },
    ],
    images: [],
    salesPerson: "",
  });

  const onAddMore = (e) => {
    e.preventDefault();
    const column = {
      rate: "0",
      qty: "1",
      amount: "",
      tax_type: "fixed",
      tax: "0",
      item_id: null,
    };
    setData({ ...data, items: [...data.items, column] });
  };
  const ItemDelete = (e, id) => {
    e.preventDefault();
    const filteredItem = data?.items?.filter((_, index) => index !== id);
    setData({ ...data, items: filteredItem });
  };
  const showModal = () => {
    setIsModalActive(true);
  };
  const hideModal = () => {
    setIsModalActive(false);
  };
  // items select dropdown list
  const getItemsListing = () => {
    dispatch(GetInvoiceItems(page, { project_id: projectId }))
      .then((res) => {
        let items = [];
        res.data.map((data) => {
          items.push({
            value: data?.id,
            label: data?.name,
            price: data?.rate,
          });
        });
        setOptions([...items, options[options.length - 1]]);
      })
      .catch((err) => {
        SetLoadingData(false);
        notifyError(err);
      });
  };
  useEffect(() => {
    getItemsListing();
  }, [projectId]);

  const modelShowHandler = (event, name, index) => {
    if (event.value === "1") {
      showModal();
      return;
    }
    const items = data.items;
    items[index][name] = event.value ? event.value : event.target.value;
    if (event.value) {
      items[index]["rate"] = event?.price;
    }
    setData({ ...data, items });
    handleCalculation(index);
  };

  const handleInputChange = (event, name, idx) => {
    const items = data.items;
    items[idx][name] = event.target.value;
    setData({ ...data, items });
    handleCalculation(idx);
  };

  const handleCalculation = (idx) => {
    const items = data.items;
    const price = parseFloat(items[idx].rate);
    const taxAmount = Math.abs(parseFloat(items[idx].tax));
    const quantity = parseInt(items[idx].qty);
    const taxType = items[idx].tax_type;
    if (price && quantity > 0 && taxAmount >= 0) {
      if (taxType == "fixed") {
        const totalAmount = price * quantity + taxAmount;
        items[idx].amount = totalAmount;
        setData({ ...data, items });
        calculateGrossTotal();
      } else {
        const totalTax = price * (taxAmount / 100);
        const totalAmount = price * quantity + totalTax;
        items[idx].amount = totalAmount;
        setData({ ...data, items });
        calculateGrossTotal();
      }
    } else {
      if (taxAmount && taxAmount < 0)
        notifyError("Tax cannot be negative number");
    }
  };
  const handleChange = async (event, name) => {
    setData({ ...data, [name]: event.target.value });
  };

  const handleSalesPerson = (event, name) => {
      name = event.key;
    setData({ ...data, salesPerson: name });
  };

  const handleCurrency = (event, currency_id) => {
      currency_id = event.key;
    setData({ ...data, currency_id: currency_id });
  };

  const calculateGrossTotal = () => {
    let total = data.items.reduce(
      (acc, total) => acc + parseFloat(total.amount),
      0
    );
    if (data.discountType == "fixed" && data.discount_value) {
      total = total - parseFloat(data.discount_value);
    }
    if (data.discountType == "percentage" && data.discount_value) {
      const discountTotal = total * (parseFloat(data.discount_value) / 100);
      total = total - discountTotal;
    }    
    if (data.handling_charges) {
      total = total + Math.abs(parseFloat(data.handling_charges));
    }
    if (data.shipping_cost) {
      total = total + Math.abs(parseFloat(data.shipping_cost));
    }
    if (data.adjustment) {
      total = total - Math.abs(parseFloat(data.adjustment));
    }
    setData({ ...data, grossTotal: total });

    return total;
  };

  const {
    handleImageUpload,
    imageLoader,
    Images,
    removeFile,
    dragEnter,
    dragLeave,
    dragOver,
    Drop,
  } = useImageUpload(data?.images);
  useEffect(() => {
    if (location?.state?.item) {
      setTimeout(() => {
        const { id, attachments, items, total, number, sales_person_id, type, currency_id } =
          location.state.item;
        setData({
          invoice_id: id,
          invoiceId: number,
          discount_value: total.discount_value,
          shipping_cost: total.shipping_cost,
          adjustment: total.adjustment,
          grossTotal: total.total_amount,
          discountType: total.discount_type,
          handling_charges: total.handling_charges,
          items: items,
          images: attachments,
          type: type,
          salesPerson: sales_person_id.id,
          currency_id: currency_id,
        });
      }, 1);
    }
  }, [location?.state]);
  useEffect(() => {
    props.set_Value({
      title: props.title,
      rootClassName: props.rootClassName,
      mainClassName: props.mainClassName,
    });
    dispatch(GetProjectMembers(projectId)).then((response) => {
      const data = response?.map((item) => ({
        label: item.name,
        key: item.id,
      }));
      setAttendees(data);
    });
  }, []);

  // currency
  useEffect(() => {
    dispatch(GetCurrencyList(projectId)).then((response) => {
      const data = response?.map((item) => ({
        label: item.title + " " + item.symbol,
        key: item.id,
      }));
      setCurrencyList(data);
  });
  }, []);

  useEffect(() => {
    if (isValid && Object.keys(FormErrors).length === 0) {
      SetLoadingData(true);
      const updatedData = data.items.map((item) => {
        return {
          ...item,
          qty: parseInt(item.qty),
          rate: parseInt(item.rate),
          amount: String(item.amount),
        };
      });
      let payload = {
        number: data?.invoiceId,
        discount_type: data?.discountType,
        discount_value: parseInt(data?.discount_value),
        shipping_cost: parseInt(data?.shipping_cost),
        adjustment: parseInt(data?.adjustment),
        handling_charges: parseInt(data?.handling_charges),
        sub_total: String(data?.grossTotal),
        attachments: Images,
        sales_person_id: data?.salesPerson,
        items: updatedData,
        type: data?.type,
        currency_id: data?.currency_id,
      };
      if (location?.state?.item) {
        payload.attachments = Images.map((item) => {
          return { file: item.url };
        });
      }
      if (projectId) {
        if (location?.state?.item) {
          dispatch(UpdateInvoice(payload, projectId, location?.state?.item?.id))
            .then((res) => {
              SetLoadingData(false);
              notifySuccess("update successfully.");
              history.push(`/admin/project/${projectId}/tool/invoices`);
            })
            .catch((err) => {
              SetLoadingData(false);
              notifyError(
                err?.response?.data?.message ?? "Something went wrong."
              );
            });
        } else {
          dispatch(invoiceCreation(payload, projectId))
            .then((res) => {
              if(res.payload){
                localStorage.setItem("recentInvoiceId", res.payload.id)
              }
              SetLoadingData(false);
              notifySuccess("Invoice Created Successfully");
              history.push(`/admin/project/${projectId}/tool/invoices`);
            })
            .catch((err) => {
              SetLoadingData(false);
              notifyError(err?.response?.data?.message ?? "Something wrong");
            });
        }
      }
    }
  }, [FormErrors]);

  const handleCreateInvoice = (e) => {
    e.preventDefault();
    setErrors(invoiceCreationCheck(data));
    setIsValid(true);
  };

  useEffect(() => {
    calculateGrossTotal();
  }, [
    data.adjustment,
    data.discount_value,
    data.shipping_cost,
    data.grossTotal,    
    data.discountType,
    data.handling_charges
  ]);
  return (
    <>
      {isModalActive && (
        <CreateInvoiceItems
          activecategory={activeCategory}
          setactivecategory={setActiveCategory}
          hideModal={hideModal}
          isModalActive={isModalActive}
          editData={editData}
          setPage={setPage}
          getItemsListing={getItemsListing}
        />
      )}
      <div className="breadcrumb">
        <Link
          to={`/admin/project/${projectId}/tool/invoices`}
          className="breadcrumb-item"
        >
          Invoice
        </Link>
        <div className="breadcrumb-item">Create A New Invoice</div>
      </div>
      <div className="card mt-20">
        <div className="card-body">
          <div className="form mt-20 w-100">
            <div className="container-fluid px-0 mt-6 custom-form-control">
              <div className="row">
                <div className="col-6">
                  <Input
                    dull
                    label="Invoice #"
                    // style={{backgroundColor: "#f4f4f8"}}
                    imp
                    type="text"
                    value={data.invoiceId}
                    placeholder="Enter Invoice Id"
                    onChange={(e) => handleChange(e, "invoiceId")}
                    errorMsg={FormErrors.invoiceId}
                  />
                </div>
                <div className="col-6">
                  <Select
                    className="sales-person"
                    options={attendeesList}
                    value={
                      attendeesList?.find((e) => e.key === data.salesPerson) ??
                      null
                    }
                    onChange={(e) => handleSalesPerson(e, "salesPerson")}
                    title={"Sales Person"}
                  />
                  {FormErrors.salesPerson && (
                    <p className="error" style={{ color: "#dc3545" }}>
                      {FormErrors.salesPerson}
                    </p>
                  )}
                </div>
                <div className="col-6">
                  <Select
                    className="currency"
                    options={currencyList}
                    value={
                      currencyList?.find((e) => e.key === data.currency_id) ??
                      currencyList?.find((e) => e.key === 1)
                    }
                    onChange={(e) => handleCurrency(e, "currency")}
                    title={"Currency"}
                  />
                  {FormErrors.currency && (
                    <p className="error" style={{ color: "#dc3545" }}>
                      {FormErrors.currency}
                    </p>
                  )}
                </div>
              </div>
            </div>
            <div className="card">
              <form className="form m-10 project-detail-form">
                <label className="project-label">Invoice Details</label>
                <div className="card mt-2">
                  <div className="card-header project-heading">
                    <div className="row">
                      <div className="col-md-2">
                        <h4
                          className="card-heading text-start"
                          style={{ paddingLeft: "15px" }}
                        >
                          Items Details
                        </h4>
                      </div>
                      <div className="col-md-2">
                        <h4 className="card-heading text-start">Quantity</h4>
                      </div>
                      <div className="col-md-2">
                        <h4 className="card-heading text-start">Rate</h4>
                      </div>
                      <div className="col-md-2">
                        <h4 className="card-heading text-start">Tax</h4>
                      </div>
                      <div className="col-md-2">
                        <h4 className="card-heading text-start">Tax Value</h4>
                      </div>
                      <div className="col-md-2 text-start">
                        <h4 className="text-start card-heading">Amount</h4>
                      </div>
                    </div>
                  </div>
                  <div className="card-body px-0 d-block">
                    {data.items.map((item, idx) => {
                      return (
                        <div className="row p-2  multi_items" key={idx}>
                          <div className="col-md-2">
                            <Select
                              key={idx}
                              className="items-select"
                              options={options}
                              isSearchable
                              value={
                                options?.find(
                                  (e) => e.value === item.item_id
                                ) ?? null
                              }
                              onChange={(e) =>
                                modelShowHandler(e, "item_id", idx)
                              }
                            />
                            {isDelete && <button>delete</button>}
                            {FormErrors.items && (
                              <p className="error">{FormErrors.items}</p>
                            )}
                          </div>
                          <div className="col-md-2">
                            <Input
                              dull
                              imp
                              type="number"
                              placeholder="1.0"
                              value={item.qty}
                              onChange={(e) => handleInputChange(e, "qty", idx)}
                              errorMsg={FormErrors.quantity}
                            />
                          </div>
                          <div className="col-md-2">
                            <Input
                              key={idx}
                              dull
                              imp
                              type="number"
                              placeholder="1.0"
                              disabled
                              value={item.rate}
                              errorMsg={FormErrors.itemRate}
                            />
                          </div>
                          <div className="col-md-2">
                            <select
                              key={idx}
                              className="discount-type-select"
                              value={item.tax_type}
                              onChange={(e) =>
                                modelShowHandler(e, "tax_type", idx)
                              }
                            >
                              <option value="">Select...</option>
                              <option className="discount-option" value="fixed">
                                fixed
                              </option>
                              <option
                                className="discount-option"
                                value="percentage"
                              >
                                percentage
                              </option>
                            </select>
                            {FormErrors.taxType && (
                              <p className="error">{FormErrors.taxType}</p>
                            )}
                          </div>
                          <div className="col-md-2">
                            <Input
                              key={idx}
                              dull
                              imp
                              type="number"
                              placeholder="1.0"
                              value={item.tax}
                              onChange={(e) => handleInputChange(e, "tax", idx)}
                              errorMsg={FormErrors.taxValue}
                            />
                          </div>
                          <div className="col-md-1">
                            <Input
                              key={idx}
                              dull
                              imp
                              type="text"
                              placeholder="0.00"
                              value={item.tax_type === 'percentage' ? (parseInt(item.qty) * parseFloat(item.rate)) + (parseInt(item.qty) * parseFloat(item.rate) * parseFloat(item.tax) / 100) : (parseInt(item.qty) * parseFloat(item.rate)) + parseFloat(item.tax)}
                              onChange={(e) =>
                                handleInputChange(e, "amount", idx)
                              }
                              errorMsg={FormErrors.amount}
                            />
                          </div>
                          <div className="col-md-1">
                            <button
                              className="btn sm btn-primary item_button"
                              onClick={(e) => ItemDelete(e, idx)}
                            >
                              <img
                                className="me-0"
                                src="/assets/vectors/delete-table.svg"
                                alt="delete"
                              />
                            </button>
                          </div>
                        </div>
                      );
                    })}
                    <div className="row">
                      <div className="col">
                        <button
                          className="btn btn-primary btn_addItem"
                          onClick={onAddMore}
                        >
                          ADD NEW ITEM
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="text-center pl-4">
                  <label className="project-label">SubTotal</label>
                </div> */}
                <div
                  className="row"
                  style={{
                    maxHeight: "44px",
                    padding: "13px",
                    marginTop: "25px",
                  }}
                >
                  {/* <div className="col-md-9 text-end">
                    <h4 className="mt-3">SubTotal</h4>
                  </div> */}
                </div>
                <div className="card mt-2">
                  <div className="withBackground">
                    <div
                      className="row"
                      style={{
                        maxHeight: "44px",
                        padding: "10px",
                        marginTop: "25px",
                      }}
                    >
                      <div className="col-md-9 text-end">
                        <h4 className="mt-3">Discount Type</h4>
                      </div>
                      <div className="col-md-3">
                        <select
                          className="discount-type-select"
                          onChange={(e) => handleChange(e, "discountType")}
                          value={data.discountType}
                        >
                          <option value="">Select Discount Type</option>
                          <option className="discount-option" value="fixed">
                            fixed
                          </option>
                          <option
                            className="discount-option"
                            value="percentage"
                          >
                            percentage
                          </option>
                        </select>
                        {FormErrors.discountType && (
                          <p className="error">{FormErrors.discountType}</p>
                        )}
                      </div>
                    </div>
                    <div
                      className="row"
                      style={{
                        maxHeight: "44px",
                        padding: "10px",
                        marginTop: "27px",
                      }}
                    >
                      <div className="col-md-9 text-end">
                        <h4 className="mt-3">Discount Value</h4>
                      </div>
                      <div className="col-md-3">
                        <Input
                          dull
                          imp
                          type="number"
                          placeholder="$  -"
                          value={data.discount_value}
                          onChange={(e) => handleChange(e, "discount_value")}
                          errorMsg={FormErrors.discount_value}
                        />
                      </div>
                    </div>
                    <div
                      className="row"
                      style={{
                        maxHeight: "44px",
                        padding: "10px",
                        marginTop: "28px",
                      }}
                    >
                      <div className="col-md-9 text-end">
                        <h4 className="mt-3">Handling Charges</h4>
                      </div>
                      <div className="col-md-3">
                        <Input
                          dull
                          imp
                          type="number"
                          placeholder="$  -"
                          value={data.handling_charges}
                          onChange={(e) => handleChange(e, "handling_charges")}
                          errorMsg={FormErrors.handling_charges}
                        />
                      </div>
                    </div>
                    <div
                      className="row"
                      style={{
                        maxHeight: "44px",
                        padding: "10px",
                        marginTop: "28px",
                      }}
                    >
                      <div className="col-md-9 text-end">
                        <h4 className="mt-3">Shipping Cost</h4>
                      </div>
                      <div className="col-md-3">
                        <Input
                          dull
                          imp
                          type="number"
                          placeholder="$  -"
                          value={data.shipping_cost}
                          onChange={(e) => handleChange(e, "shipping_cost")}
                          errorMsg={FormErrors.shipping_cost}
                        />
                      </div>
                    </div>
                    <div
                      className="row"
                      style={{
                        maxHeight: "44px",
                        padding: "10px",
                        marginTop: "33px",
                      }}
                    >
                      <div className="col-md-9 text-end">
                        <h4 className="mt-3">Adjustment</h4>
                      </div>
                      <div className="col-md-3">
                        <Input
                          dull
                          imp
                          type="number"
                          placeholder="$  -"
                          value={data.adjustment}
                          onChange={(e) => handleChange(e, "adjustment")}
                          errorMsg={FormErrors.adjustment}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-8">
                    <h4 className="g-total text-end">Gross Total</h4>
                  </div>
                  <div className="col-md-4">
                    <Input
                      dull
                      imp
                      type="number"
                      placeholder="0.00"
                      value={data.grossTotal}
                      disabled
                    />
                  </div>
                </div>
              </form>
            </div>
            <div className="px-0 mt-30 select-wrapper custom-form-control">
              <Input
                type="file"
                label="Attachments"
                rootClassName="mt-20"
                icon="/assets/vectors/clip-dark.svg"
                labelClassName="fs-16"
                withInfo
                tooltipText={"Attach file"}
                id="meeting-image"
                placeholder="Attach file or Drag &amp; Drop"
                onChange={handleImageUpload("meeting-image")}
                onDragOver={dragOver}
                onDragEnter={dragEnter}
                onDragLeave={dragLeave}
                errorMsg={FormErrors.imagesErrors}
                onDrop={Drop}
                multiple_image={Images}
                imageLoader={imageLoader}
                removeFile={removeFile}
                multiple
              />
            </div>
            <div className="select-wrapper custom-form-control"></div>
            <div className="px-0 mt-30">
              <div>
                <div
                  className="col-12 p-0 d-flex"
                  style={{ justifyContent: "end" }}
                >
                  <div>
                    <button
                      className="btn btn-cancel btn-auth"
                      style={{ marginRight: "20px" }}
                      onClick={() =>
                        history.push(
                          `/admin/project/${projectId}/tool/invoices`
                        )
                      }
                    >
                      Cancel
                    </button>
                  </div>
                  <div>
                    {LoadingData ? (
                      <button className="btn btn-primary btn-auth">
                        <Loader />
                      </button>
                    ) : (
                      <button
                        className="btn btn-primary btn-auth"
                        onClick={handleCreateInvoice}
                      >
                        {location?.state?.item ? "Update" : "Create"}
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateInvoice;
