import { Index } from "../services/index";

import {
  LOGIN,
  SIGNUP,
  COMPANYTYPE,
  OTP_VERIFICATION,
  COUNTRY_TYPE,
  GET_DIRECTORIES,
  PROJECT_CREATE,
  ADD_TOOLS,
  ADD_DIRECTORIES,
  GET_PROJECTS,
  GET_COMPANIES,
  GET_TEMPLATES,
  GET_STAGES,
  GET_TYPES,
  MEETING_CREATE,
  LOCATION,
  PROJECT_NOTIFICATION,
  UPDATE_PROJECT,
  STAGE_CREATE,
  UPDATE_USER,
} from "../constants/index";
export const Login = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    Index.Login(payload)
      .then((response) => {
        if (response && response.data && response.data?.user) {
          Index.setCurrentUser(response?.data?.user?.access_token);
          dispatch({ type: LOGIN, payload: response.data?.user });
          resolve({ payload: response });
        }
      })
      .catch((error) => {
        reject({ error });
      });
  });
};

export const SignUp = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    Index.SignUp(payload)
      .then((response) => {
        if (response) {
          resolve({ payload: response.user });
          dispatch({ type: SIGNUP, payload: response.user });
        }
      })
      .catch((error) => {
        reject({ error });
      });
  });
};

export const GetCompanyType = () => (dispatch) => {
  return new Promise((resolve, reject) => {
    Index.CompanyType()
      .then((response) => {
        dispatch({ type: COMPANYTYPE, payload: response });
      })
      .catch((error) => {
        reject({ payload: error });
      });
  });
};

export const GetCountryDetail = () => () => {
  return new Promise((resolve, reject) => {
    Index.getCountryDetail()
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject({ payload: error });
      });
  });
};

export const OtpVerfication = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    Index.otpVerfication(payload)
      .then((res) => {
        Index.setCurrentUser(res?.data?.user?.access_token);
        dispatch({ type: OTP_VERIFICATION, payload: res.data.user });
        resolve({ payload: res });
      })
      .catch((error) => {
        reject({ payload: error });
      });
  });
};

export const forgotPassword = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    Index.ForgotPassword(payload)
      .then((res) => {
        resolve({ payload: res });
      })
      .catch((error) => {
        reject({ payload: error });
      });
  });
};
export const ResendPassword = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    Index.ResendPassword(payload)
      .then((res) => {
        resolve({ payload: res });
      })
      .catch((error) => {
        reject({ payload: error });
      });
  });
};
export const CreateProject = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    Index.CreateProject(payload)
      .then((res) => {
        resolve({ payload: res });
        dispatch({ type: PROJECT_CREATE, payload: res?.data });
      })
      .catch((error) => {
        reject({ payload: error });
      });
  });
};

export const CreateStages = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    Index.CreateStages(payload)
      .then((res) => {
        resolve({ payload: res });
        dispatch({ type: STAGE_CREATE, payload: res });
      })
      .catch((error) => {
        reject({ payload: error });
      });
  });
};

export const CreateTypes = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    Index.CreateTypes(payload)
      .then((res) => {
        resolve({ payload: res });
        dispatch({ type: STAGE_CREATE, payload: res });
      })
      .catch((error) => {
        reject({ payload: error });
      });
  });
};

export const updateProject = (payload, id) => (dispatch) => {
  return new Promise((resolve, reject) => {
    Index.updateProject(payload, id)
      .then((res) => {
        resolve({ payload: res });
        dispatch({ type: UPDATE_PROJECT, payload: res?.data });
      })
      .catch((error) => {
        reject({ payload: error });
      });
  });
};

export const ImageUpload = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    Index.ImageUpload(payload)
      .then((res) => {
        resolve({ payload: res });
      })
      .catch((error) => {
        reject({ payload: error });
      });
  });
};
export const GetToolsData = () => (dispatch) => {
  return new Promise((resolve, reject) => {
    Index.GetTools()
      .then((res) => {
        resolve({ payload: res });
      })
      .catch((error) => {
        reject({ payload: error });
      });
  });
};
export const AddTools = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    Index.AddTools(payload)
      .then((res) => {
        resolve({ payload: res });
        dispatch({ type: ADD_TOOLS, payload: res.data });
      })
      .catch((error) => {
        reject({ payload: error });
      });
  });
};

export const GetCountries = () => (dispatch) => {
  return new Promise((resolve, reject) => {
    Index.GetCountries()
      .then((response) => {
        dispatch({ type: COUNTRY_TYPE, payload: response });
      })
      .catch((error) => {
        reject({ payload: error });
      });
  });
};
export const GetStates = (data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    Index.GetStates(data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject({ error });
      });
  });
};
export const GetCities = (data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    Index.GetCities(data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject({ error });
      });
  });
};
export const GetDirectories = () => (dispatch) => {
  return new Promise((resolve, reject) => {
    Index.GetDirectories()
      .then((response) => {
        resolve(response);
        dispatch({ type: GET_DIRECTORIES, payload: response.data });
      })
      .catch((error) => {
        reject({ payload: error });
      });
  });
};

export const PostDirectories = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    Index.PostDirectories(payload)
      .then((res) => {
        resolve({ payload: res });
        dispatch({ type: ADD_DIRECTORIES, payload: res.data });
      })
      .catch((error) => {
        reject({ payload: error });
      });
  });
};

export const GetProjects = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    Index.GetProjects(payload)
      .then((res) => {
        resolve({ payload: res });
        dispatch({ type: GET_PROJECTS, payload: res.data });
      })
      .catch((error) => {
        reject({ payload: error });
        dispatch({ type: GET_PROJECTS, payload: [] });
      });
  });
};
// get Meeting from listing
export const GetMeetings = (payload, page) => (dispatch) => {
  return new Promise((resolve, reject) => {
    Index.GetMeetings(payload, page)
      .then((res) => {
        resolve({ res });
      })
      .catch((error) => {
        reject({ error });
      });
  });
};

export const GetCompanies = () => (dispatch) => {
  return new Promise((resolve, reject) => {
    Index.GetCompanies()
      .then((res) => {
        resolve({ payload: res });
        dispatch({ type: GET_COMPANIES, payload: res.data });
      })
      .catch((error) => {
        reject({ payload: error });
        dispatch({ type: GET_COMPANIES, payload: [] });
      });
  });
};
export const GetTemplates = () => (dispatch) => {
  return new Promise((resolve, reject) => {
    Index.GetTemplates()
      .then((res) => {
        resolve({ payload: res });
        dispatch({ type: GET_TEMPLATES, payload: res });
      })
      .catch((error) => {
        reject({ payload: error });
      });
  });
};

export const GetStages = () => (dispatch) => {
  return new Promise((resolve, reject) => {
    Index.GetStages()
      .then((res) => {
        resolve({ payload: res });
        dispatch({ type: GET_STAGES, payload: res });
      })
      .catch((error) => {
        reject({ payload: error });
        dispatch({ type: GET_STAGES, payload: [] });
      });
  });
};
export const GetTypes = () => (dispatch) => {
  return new Promise((resolve, reject) => {
    Index.GetTypes()
      .then((res) => {
        resolve({ payload: res });
        dispatch({ type: GET_TYPES, payload: res });
      })
      .catch((error) => {
        reject({ payload: error });
        dispatch({ type: GET_TYPES, payload: [] });
      });
  });
};
export const GetProjectMembers = (data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    Index.GetMembers(data)
      .then((res) => {
        resolve(res?.data);
      })
      .catch((error) => {
        reject({ error });
      });
  });
};

export const GetCurrencyList = (data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    Index.GetCurrency(data)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject({ error });
      });
  });
};

export const GetCountryCode = () => (dispatch) => {
  return new Promise((resolve, reject) => {
    Index.GetCouontryCode()
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const PasswordReset = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    Index.ResetPassword(payload)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const UpdateUserProfile = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    Index.UpdateUserProfile(payload)
      .then((res) => {
        resolve(res);
        dispatch({ type: UPDATE_USER, payload: res });
      })
      .catch((error) => {
        reject(error);
      });
  });
};
// create meeting
export const CreateMeetings = (payload, id) => (dispatch) => {
  return new Promise((resolve, reject) => {
    Index.CreateMeetings(payload, id)
      .then((res) => {
        resolve({ payload: res });
        dispatch({ type: MEETING_CREATE, payload: res });
      })
      .catch((error) => {
        reject({ payload: error });
        dispatch({ type: MEETING_CREATE, payload: [] });
      });
  });
};
// attendees API
export const GetAttendees = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    Index.GetAttendees(payload)
      .then((res) => {
        resolve({ res });
      })
      .catch((error) => {
        reject({ error });
      });
  });
};

// delete meeting list
export const DeleteMeeting = (projectId, meetingId) => (dispatch) => {
  return new Promise((resolve, reject) => {
    Index.DeleteMeeting(projectId, meetingId)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
//meeting detail
export const MeetingDetail = (projectId, meetingId) => (dispatch) => {
  return new Promise((resolve, reject) => {
    Index.MeetingDetail(projectId, meetingId)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

// Edit meeting
export const EditMeeting = (payload, data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    Index.EditMeeting(payload, data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const EditInvoice = (payload, data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    Index.EditInvoice(payload, data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const GetDrawings = (payload, page) => (dispatch) => {
  return new Promise((resolve, reject) => {
    Index.GetDrawings(payload, page)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const GetDrawingsCategories = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    Index.GetDrawingsCategories(payload)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const CreateDrawing = (id, payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    Index.CreateDrawing(id, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const GetDrawingSets = (id) => (dispatch) => {
  return new Promise((resolve, reject) => {
    Index.GetDrawingSets(id)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const EditDrawings = (id, payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    Index.EditDrawings(id, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const DeleteDrawings = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    Index.DeleteDrawings(payload)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const getRoles = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    Index.GetRoles(payload)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const ProjectOverview = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    Index.ProjectOverview(payload)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const CreateMembers = (payload, id) => (dispatch) => {
  return new Promise((resolve, reject) => {
    Index.CreateMembers(payload, id)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const DeleteMember = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    Index.DeleteMember(payload)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const EditMember = (payload, id) => (dispatch) => {
  return new Promise((resolve, reject) => {
    Index.updateMember(payload, id)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// drawing details
export const GetDrawingDetails = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    Index.DrawingDetails(payload)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
/* 
  Create Todo Action
*/
export const createToDo = (payload, id) => (dispatch) => {
  return new Promise((resolve, reject) => {
    Index.createToDo(payload, id)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
/* 
 Get Todo Details Page
*/
export const GetTodoDetails = (projectId, id) => (dispatch) => {
  return new Promise((resolve, reject) => {
    Index.TodoDetails(projectId, id)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
// Get Assignee Action Method
export const GetAssignee = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    Index.GetAssignee(payload)
      .then((res) => {
        resolve({ res });
      })
      .catch((error) => {
        reject({ error });
      });
  });
};
// Get Category Action
export const getCategory = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    Index.getCategory(payload)
      .then((res) => {
        resolve({ res });
      })
      .catch((error) => {
        reject({ error });
      });
  });
};

// Edit Category Action
export const editCategory = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    Index.editCategory(payload)
      .then((res) => {
        resolve({ res });
      })
      .catch((error) => {
        reject({ error });
      });
  });
};

// Edit Stage Action
export const editStage = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    Index.editStage(payload)
      .then((res) => {
        resolve({ res });
      })
      .catch((error) => {
        reject({ error });
      });
  });
};

// Edit Type Action
export const editType = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    Index.editType(payload)
      .then((res) => {
        resolve({ res });
      })
      .catch((error) => {
        reject({ error });
      });
  });
};

// Edit Template Action
export const editTemplate = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    Index.editTemplate(payload)
      .then((res) => {
        resolve({ res });
      })
      .catch((error) => {
        reject({ error });
      });
  });
};

// Update Category Action
export const updateCategory = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    Index.updateCategory(payload)
      .then((res) => {
        resolve({ res });
      })
      .catch((error) => {
        reject({ error });
      });
  });
};

// Update Stage Action
export const updateStage = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    Index.updateStage(payload)
      .then((res) => {
        resolve({ res });
      })
      .catch((error) => {
        reject({ error });
      });
  });
};

// Update Type Action
export const updateType = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    Index.updateType(payload)
      .then((res) => {
        resolve({ res });
      })
      .catch((error) => {
        reject({ error });
      });
  });
};

// Update Template Action
export const updateTemplate = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    Index.updateTemplate(payload)
      .then((res) => {
        resolve({ res });
      })
      .catch((error) => {
        reject({ error });
      });
  });
};

// delete Category Action
export const deleteCategory = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    Index.deleteCategory(payload)
      .then((res) => {
        resolve({ res });
      })
      .catch((error) => {
        reject({ error });
      });
  });
};

// delete Stage Action
export const deleteStage = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    Index.deleteStage(payload)
      .then((res) => {
        resolve({ res });
      })
      .catch((error) => {
        reject({ error });
      });
  });
};

// delete Type Action
export const deleteType = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    Index.deleteType(payload)
      .then((res) => {
        resolve({ res });
      })
      .catch((error) => {
        reject({ error });
      });
  });
};

// delete Template Action
export const deleteTemplate = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    Index.deleteTemplate(payload)
      .then((res) => {
        resolve({ res });
      })
      .catch((error) => {
        reject({ error });
      });
  });
};

// delete Category Action
export const createCategory = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    Index.createCategory(payload)
      .then((res) => {
        resolve({ res });
      })
      .catch((error) => {
        reject({ error });
      });
  });
};

// create Stage Action
export const createStage = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    Index.createStage(payload)
      .then((res) => {
        resolve({ res });
      })
      .catch((error) => {
        reject({ error });
      });
  });
};

// create Type Action
export const createType = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    Index.createType(payload)
      .then((res) => {
        resolve({ res });
      })
      .catch((error) => {
        reject({ error });
      });
  });
};

// create Template Action
export const createTemplate = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    Index.createTemplate(payload)
      .then((res) => {
        resolve({ res });
      })
      .catch((error) => {
        reject({ error });
      });
  });
};

export const ContactForm = (payload) => () => {
  return new Promise((resolve, reject) => {
    Index.ContactForm(payload)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const GetLogsCategories = () => (dispatch) => {
  return new Promise((resolve, reject) => {
    Index.GetLogsCategories()
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// Delete Todo Listing
export const DeleteToDo = (projectId, todoId) => (dispatch) => {
  return new Promise((resolve, reject) => {
    Index.DeleteToDo(projectId, todoId)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

// Edit Todo API Action Action
export const EditTodo = (projectId, id, payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    Index.EditTodo(projectId, id, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const CreateDailyLogs = (id, params) => (dispatch) => {
  return new Promise((resolve, reject) => {
    Index.CreateDailyLogs(id, params)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const FilterDailyLog = (params) => () => {
  return new Promise((resolve, reject) => {
    Index.filterDailyLog(params)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
        reject({ res: error });
      });
  });
};
// Todo Listing API Action
export const ToDoListing = (payload, page) => (dispatch) => {
  return new Promise((resolve, reject) => {
    Index.ToDoListing(payload, page)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject({ res: error });
      });
  });
};

export const GetContractorCompanies = () => (dispatch) => {
  return new Promise((resolve, reject) => {
    Index.GetContractorCompanies()
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject({ res: error });
      });
  });
};
export const GetDailyLogLocation = () => (dispatch) => {
  return new Promise((resolve, reject) => {
    Index.GetDailyLogLocation()
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const GetDailyLogListing = (payload, page) => (dispatch) => {
  return new Promise((resolve, reject) => {
    Index.GetDailyLogListing(payload, page)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const Locations = () => () => {
  return new Promise((resolve, reject) => {
    Index.locations()
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const AlbumListing = () => () => {
  return new Promise((resolve, reject) => {
    Index.albumListing()
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const TradeListing = () => () => {
  return new Promise((resolve, reject) => {
    Index.tradeListing()
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const DeleteDailyLog = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    Index.DeleteDailyLog(payload)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const GetEquipments = () => () => {
  return new Promise((resolve, reject) => {
    Index.GetEquipments()
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const GetDeliveryCompanies = () => () => {
  return new Promise((resolve, reject) => {
    Index.DeliveryComapnies()
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const UpdateDailyLog = (id, params) => (dispatch) => {
  return new Promise((resolve, reject) => {
    Index.UpdateDailyLog(id, params)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const GetLocation = () => (dispatch) => {
  return new Promise((resolve, reject) => {
    fetch("https://api.ipregistry.co/?key=r42pfb793zkv0mdd")
      .then(function (response) {
        return response.json();
      })
      .then(function (payload) {
        dispatch({ type: LOCATION, payload: payload });
      });
  });
};
export const GetCalendarLogs = () => (dispatch) => {
  return new Promise((resolve, reject) => {
    Index.GetCalendarLogs()
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const CreateEquipment = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    Index.CreateEquipment(payload)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const CreateCompanies = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    Index.CreateCompanies(payload)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const InvoiceListing = (payload, projectId) => (dispatch) => {
  return new Promise((resolve, reject) => {
    Index.InvoiceListing(payload, projectId)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
        reject({ res: error });
      });
  });
};
export const InvoiceItemsCreation = (payload, projectId) => (dispatch) => {
  return new Promise((resolve, reject) => {
    Index.InvoiceItemsCreation(payload, projectId)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const VendorsListing = (payload, projectId) => (dispatch) => {
  return new Promise((resolve, reject) => {
    Index.VendorsListing(payload, projectId)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const invoiceCreation = (payload, projectId) => (dispatch) => {
  return new Promise((resolve, reject) => {
    Index.invoiceCreation(payload, projectId)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const GetInvoiceItems = (page, projectId) => (dispatch) => {
  return new Promise((resolve, reject) => {
    Index.GetInvoiceItems(page, projectId)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const DeleteInvoice = (projectId, invoiceId) => (dispatch) => {
  return new Promise((resolve, reject) => {
    Index.DeleteInvoice(projectId, invoiceId)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const UpdateInvoice = (payload, projectId, invoiceId) => () => {
  return new Promise((resolve, reject) => {
    Index.updateInvoice(payload, projectId, invoiceId)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const GetInvoiceDetail = (projectId, invoiceId) => (dispatch) => {
  return new Promise((resolve, reject) => {
    Index.GetInvoiceDetail(projectId.project_id, invoiceId.invoice_id)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const GetNotifications = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    Index.GetNotifications(payload)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const GeneratePDF = (payload) => () => {
  return new Promise((resolve, reject) => {
    Index.GeneratePDF(payload)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const ReadNotificationById = (payload) => () => {
  return new Promise((resolve, reject) => {
    Index.ReadNotificationById(payload)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const ReadAllNotification = (payload) => () => {
  return new Promise((resolve, reject) => {
    Index.ReadAllNotification(payload)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const DelNotification = (payload) => () => {
  return new Promise((resolve, reject) => {
    Index.DelNotification(payload)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const ProjectNotification = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    Index.projectNotification(payload)
      .then((res) => {
        dispatch({ type: PROJECT_NOTIFICATION, payload: res });
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const CreateEsinature = (payload) => () => {
  return new Promise((resolve, reject) => {
    Index.CreateEsinature(payload)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const SignatureListing = () => () => {
  return new Promise((resolve, reject) => {
    Index.signatureListing()
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const DeleteSignature = (payload) => () => {
  return new Promise((resolve, reject) => {
    Index.deleteSignature(payload)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const deleteProjectList = (projectId) => () => {
  return new Promise((resolve, reject) => {
    Index.deleteProjectList(projectId)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getProjectDetails = (projectId) => () => {
  return new Promise((resolve, reject) => {
    Index.getProjectDetails(projectId)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const GetInvoicePaymentList = (payload) => () => {
  return new Promise((resolve, reject) => {
    Index.GetInvoicePaymentList(payload)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const deleteInvoicePaymentRequest = (payload) => () => {
  return new Promise((resolve, reject) => {
    Index.deleteInvoicePaymentRequest(payload)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const GetProjectContracts = (data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    Index.GetProjectContracts(data)
      .then((res) => {
        resolve(res?.data);
      })
      .catch((error) => {
        reject({ error });
      });
  });
};

export const GetSignaturesRollWise = (projectId) => (dispatch) => {
  return new Promise((resolve, reject) => {
    Index.GetSignaturesRollWise(projectId)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject({ error });
      });
  });
};

export const GetContractors = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    Index.GetContractors(payload)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const CreateContrators = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    Index.CreateContrators(payload)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const CreateProjectContracts = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    Index.CreateProjectContracts(payload)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const deleteContracts = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    Index.deleteContracts(payload)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const UpdateProjectContract = (payload, id) => (dispatch) => {
  return new Promise((resolve, reject) => {
    Index.UpdateProjectContract(payload, id)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const CreatePaymentRequest = (payload) => () => {
  return new Promise((resolve, reject) => {
    Index.CreatePaymentRequest(payload)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const ProjectStatementExport = (payload, projectId) => () => {
  return new Promise((resolve, reject) => {
    Index.ProjectStatementExport(payload, projectId)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const invoicepaymentRequest = (payload) => () => {
  return new Promise((resolve, reject) => {
    Index.invoicepaymentRequest(payload)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const exportPaymentRequest = (payload) => () => {
  return new Promise((resolve, reject) => {
    Index.exportPaymentRequest(payload)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const CreateVendor = (payload) => () => {
  return new Promise((resolve, reject) => {
    Index.CreateVendor(payload)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const IncidentsListing = (payload, projectId) => () => {
  return new Promise((resolve, reject) => {
    Index.IncidentsListing(payload, projectId)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const CreateIncidents = (payload, projectId) => () => {
  return new Promise((resolve, reject) => {
    Index.CreateIncidents(payload, projectId)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const DeleteIncident = (projectId, incidentId) => (dispatch) => {
  return new Promise((resolve, reject) => {
    Index.DeleteIncident(projectId, incidentId)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const ExportIncidents = (projectId) => (dispatch) => {
  return new Promise((resolve, reject) => {
    Index.ExportIncidents(projectId)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const UpdateIncident =
  (payload, projectId, incidentId) => (dispatch) => {
    return new Promise((resolve, reject) => {
      Index.UpdateIncident(payload, projectId, incidentId)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

export const GetIncidentDetail = (projectId, incidentId) => (dispatch) => {
  return new Promise((resolve, reject) => {
    Index.GetIncidentDetail(projectId, incidentId)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const GetAgreementData = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    Index.GetAgreementData(payload)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const ExportSubContractorForm = (payload, id) => (dispatch) => {
  return new Promise((resolve, reject) => {
    Index.ExportSubContractorForm(payload, id)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const contractorDetail = (id, contractorId) => (dispatch) => {
  return new Promise((resolve, reject) => {
    Index.contractorDetail(id, contractorId)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const contractsdetail = (id) => (dispatch) => {
  return new Promise((resolve, reject) => {
    Index.contractsdetail(id)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const FetchContractorTypes = (id) => (dispatch) => {
  return new Promise((resolve, reject) => {
    Index.FetchContractorTypes(id)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

// project/contractors/contractor_types/filter
