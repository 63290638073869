import { combineReducers } from "@reduxjs/toolkit";
// import auth from "../auth/store";
// import core from "./core";
import AuthReducer from "../redux/reducers/AuthReducer";
import Project from "../redux/reducers/Projects";
import Navigation from "../redux/reducers/Navigation";

const createReducer = (asyncReducers) =>
  combineReducers({
    AuthReducer,
    Project,
    navigation: Navigation,
    ...asyncReducers,
  });

export default createReducer;
