import React, { useEffect, useState } from "react";
import clsx from "clsx";
import Footer from "../components/Footer";
import UserInfo from "../components/UserInfo";
import DailyLog from "../components/DailyLog";
import { Redirect, useHistory, Switch, Route } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { PROJECT_FILTER, TOOLS_FILTER } from "app/redux/constants";
import routes from "Routes/AdminRoutes";
import NotificationModel from "components/NotificationModel";
import Notification from "components/Notifications";
import { MdDashboard } from "react-icons/md";
import { FaTasks } from "react-icons/fa";
import { FaMountainCity } from "react-icons/fa6";
import { FaHandshake } from "react-icons/fa";
import { MdManageAccounts } from "react-icons/md";
import { MdOutlineMeetingRoom } from "react-icons/md";
import { MdOutlineDraw } from "react-icons/md";
import { IoLogoBuffer } from "react-icons/io";
import { FaFileInvoiceDollar } from "react-icons/fa";
import { FaSignature } from "react-icons/fa";

const ListingLayout = (props) => {
  const ProjectDefault = useSelector((state) => state.navigation.projectId);
  const ToolsDefault = useSelector((state) => state.navigation.toolsId);
  const Companies = useSelector((state) => state.Project.allCompanies);
  const projectNotifications = useSelector(
    (state) => state.Project.projectNotifiations
  );

  const [selectedMenu, setSelectedMenu] = useState("Dashboard");

  const [Project, setProject] = useState();
  const [sideNavOpenState, setSideNavOpenState] = useState(false);
  // const [tools, setTools] = useState();
  const [isModalActive, setIsModalActive] = useState(false);
  const showHideModal = () => {
    setIsModalActive(!isModalActive);
  };
  const handleShowNotificaion = (e) => {
    e.preventDefault();
    showHideModal();
  };
  const [value, setValue] = useState({
    title: "",
    subTitle: "",
    withLogs: false,
    withSettings: true,
    rootClassName: "",
    mainClassName: "",
  });
  const dispatch = useDispatch();
  const history = useHistory();
  const { pathname } = history.location;
  // check user
  const user = useSelector((state) => state.AuthReducer.user);
  const [notificationBadge, setNotificationbadge] = useState("");

  const toggleSideNav = () => setSideNavOpenState((prevState) => !prevState);

  const stringsName = {
    daily_logs: "Daily Log",
    e_signature: "E-Signatures",
    Dashboard: "Dashboard",
    Tasks: "Tasks",
    Incidents: "Incidents",
    Agreements: "Agreements",
    Managment: "Managment",
    Meetings: "Meetings",
    Drawings: "Drawings",
    Invoices: "Invoices",
    Invoices: "Invoices",
  };

  const menuIcons = {
    [stringsName.Dashboard]: MdDashboard,
    [stringsName.Tasks]: FaTasks,
    [stringsName.Incidents]: FaMountainCity,
    [stringsName.Agreements]: FaHandshake,
    [stringsName.Managment]: MdManageAccounts,
    [stringsName.Meetings]: MdOutlineMeetingRoom,
    [stringsName.Drawings]: MdOutlineDraw,
    [stringsName.daily_logs]: IoLogoBuffer,
    [stringsName.Invoices]: FaFileInvoiceDollar,
    [stringsName.e_signature]: FaSignature,
  };

  useEffect(() => {
    if (!user || !user.status == 1) {
      return history.push("/auth/login");
    }
  }, [user]);
  // project dropdown select
  const onProjectChange = (e) => {
    const data = Companies;
    data.map((item) => {
      item.projects.map((project) => {
        if (project.id == e.target.value) {
          setProject(project);
          dispatch({ type: TOOLS_FILTER, payload: "" });
          dispatch({ type: PROJECT_FILTER, payload: project.id });
        }
      });
    });
  };
  // // check if project id is available
  useEffect(() => {
    if (ProjectDefault) {
      return Companies?.map((item) => {
        item?.projects?.map((project) => {
          if (project?.id == ProjectDefault) {
            setProject(project);
          }
        });
      });
    }
  }, [ProjectDefault]);

  // tools dropdown select
  const handleToolChange = (value) => {
    dispatch({ type: TOOLS_FILTER, payload: value });
    setSelectedMenu(value ? value : "Dashboard");
    if (ProjectDefault === null) {
      history.push(`/admin/dashboard`);
    }
    if (!value && ProjectDefault !== null) {
      history.push(`/admin/project/${ProjectDefault}`);
    }
    if (value.includes("Daily Log")) {
      history.push(`/admin/project/${ProjectDefault}/tool/daily-log`);
    }
    if (value.includes("Drawings")) {
      history.push(`/admin/project/${ProjectDefault}/tool/drawings`);
    }
    if (value.includes("Tasks")) {
      history.push(`/admin/project/${ProjectDefault}/tool/todos`);
    }
    if (value.includes("Meetings")) {
      history.push(`/admin/project/${ProjectDefault}/tool/meetings`);
    }
    if (value.includes("Invoices")) {
      history.push(`/admin/project/${ProjectDefault}/tool/invoices`);
    }
    if (value.includes("E-Signatures")) {
      history.push(`/admin/project/${ProjectDefault}/tool/esignature`);
    }
    if (value.includes("Incidents")) {
      history.push(`/admin/project/${ProjectDefault}/tool/incidents`);
    }
    if (value.includes("Agreements")) {
      history.push(`/admin/project/${ProjectDefault}/tool/agreements`);
    }
    if (value.includes("Managment")) {
      history.push(`/admin/project/${ProjectDefault}/tool/management`);
    }
  };

  useEffect(() => {
    dispatch({ type: TOOLS_FILTER, payload: "" });
    // setTimeout(() => {
    if (Project?.tools?.length < 1) {
      if (pathname == "/auth/*" || pathname == "/*") {
        return;
      } else {
        setTimeout(() => {
          if (!pathname.includes(`project/${ProjectDefault}`)) {
            history.push("/admin/dashboard");
          }
        }, 0);
      }
    }
    // }, 1000);
  }, [ProjectDefault]);

  useEffect(() => {
    if (user) {
      if (ProjectDefault && pathname.includes(`project/${ProjectDefault}`)) {
        if (
          ProjectDefault &&
          pathname.includes(`project/${ProjectDefault}/tool/drawings`)
        ) {
          dispatch({ type: TOOLS_FILTER, payload: "Drawings" });
        }
        if (
          ProjectDefault &&
          pathname.includes(`project/${ProjectDefault}/tool/daily-log`)
        ) {
          dispatch({ type: TOOLS_FILTER, payload: "Daily Log" });
        }
        if (
          ProjectDefault &&
          pathname.includes(`project/${ProjectDefault}/tool/meetings`)
        ) {
          dispatch({ type: TOOLS_FILTER, payload: "Meetings" });
        }
        if (
          ProjectDefault &&
          pathname.includes(`project/${ProjectDefault}/tool/invoices`)
        ) {
          dispatch({ type: TOOLS_FILTER, payload: "Invoices" });
        }
        if (
          ProjectDefault &&
          pathname.includes(`project/${ProjectDefault}/tool/esignature`)
        ) {
          dispatch({ type: TOOLS_FILTER, payload: "E-Signatures" });
        }
        if (pathname.includes(`project/${ProjectDefault}/tool/todos`)) {
          // dispatch({ type: TOOLS_FILTER, payload: "Tasks" });
        }
        if (
          ProjectDefault &&
          pathname.includes(`project/${ProjectDefault}/tool/incidents`)
        ) {
          dispatch({ type: TOOLS_FILTER, payload: "Incidents" });
        }
        if (
          ProjectDefault &&
          pathname.includes(`project/${ProjectDefault}/tool/agreements`)
        ) {
          dispatch({ type: TOOLS_FILTER, payload: "Agreements" });
        }
      } else if (
        ProjectDefault &&
        pathname.includes(`project/${ProjectDefault}/edit`)
      ) {
        history.push(`/create/project/${ProjectDefault}`);
      } else if (pathname.includes(`/admin/settings`)) {
        history.push("/admin/settings");
      } else if (ProjectDefault && ProjectDefault !== null) {
        history.push(`/admin/project/${ProjectDefault}`);
        dispatch({ type: TOOLS_FILTER, payload: "" });
      } else {
        history.push("/admin/dashboard");
        dispatch({ type: TOOLS_FILTER, payload: "" });
        dispatch({ type: PROJECT_FILTER, payload: "" });
      }
    } else {
      history.push("/auth/login");
    }
  }, [pathname, ProjectDefault, user]);

  const GetRoutes = () => {
    return routes.map((route, key) => (
      <Route
        path={route.layout + route.path}
        exact
        render={() => (
          <route.component
            set_Value={setValue}
            {...route}
            ProjectDefault={ProjectDefault}
          />
        )}
        key={key}
      />
    ));
  };

  const projectTollsListing = Project?.tools?.length > 0 ? Project?.tools : [];

  const handleLogoClick = () => {
    history.push("/admin/dashboard");
    dispatch({ type: PROJECT_FILTER, payload: "" });
  };
  const handleSettings = () => {
    history.push("/admin/settings");
  };
  const updatedToolsListing = ProjectDefault ? projectTollsListing : [];
  return (
    <>
      {isModalActive && <NotificationModel showHideModal={showHideModal} />}
      <div
        onClick={() => toggleSideNav()}
        className={clsx("dark-overlay", { open: sideNavOpenState })}
      />
      <div className="listing-container">
        <div className={clsx("left", { open: sideNavOpenState })}>
          <div className="header">
            <img
              src="/assets/vectors/builderLogo-transparent.png"
              onClick={() => handleLogoClick()}
              alt="logo"
              height="80px"
              style={{
                background: "none",
                cursor: "pointer",
              }}
            />
          </div>
          <div className="body">
            {Companies?.map((company) => {
              return (
                <div className="input-card rounded-2" key={company.id}>
                  <label htmlFor="projects" className="company-project">
                    {company.name}
                  </label>
                  <select
                    id="projects"
                    onChange={onProjectChange}
                    value={ProjectDefault || ""}
                  >
                    <option value="">Select Project</option>
                    {company.projects?.map((item) => {
                      return (
                        <option value={item.id} key={item.id}>
                          {item.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
              );
            })}

            <div className="input-card rounded-2">
              <label htmlFor="projects">Company Tools</label>
            </div>

            <div className="w-100 menu-base-class">
              {[{ title: "Dashboard" }, ...updatedToolsListing]?.map(
                (tool, index) => {
                  const DynamicIcon = menuIcons[tool?.title];
                  return (
                    <div
                      className={`menu-card ${
                        selectedMenu === tool?.title ? "selected-menu-card" : ""
                      }`}
                      key={index}
                      onClick={(event) =>
                        handleToolChange(
                          tool?.title === "Dashboard" ? "" : tool?.title
                        )
                      }
                    >
                      <DynamicIcon />
                      <p className="">{tool?.title}</p>
                    </div>
                  );
                }
              )}
            </div>
          </div>
          <div className="foot">
            {/* <button className="btn btn-transparent">
              <img src="/assets/vectors/favourite.svg" alt="fav" />
              FAVORITES
            </button> */}
          </div>
        </div>
        <div className={clsx("right", value.rootClassName)}>
          <div className="head">
            <div className="d-flex align-items-center">
              <img
                className="menu me-10 hamburger"
                onClick={toggleSideNav}
                src="/assets/vectors/hamburger.svg"
                alt="hamburger"
              />
              {value.withSettings && (
                <button className="settings-btn" onClick={handleSettings}>
                  <img src="/assets/vectors/settings.svg" alt="settings" />
                </button>
              )}
              <div>
                <h3>{value.title}</h3>
                <p className="fs-14 fs-1550-10 mt-1">{value.subTitle}</p>
              </div>
            </div>
            <div className="nav">
              <div
                className="options d-flex notificationBadge"
                style={{
                  position: "relative",
                }}
              >
                <img
                  src="/assets/vectors/notifications.svg"
                  alt="notifications"
                  onClick={handleShowNotificaion}
                />
                {notificationBadge && (
                  <span onClick={handleShowNotificaion}>
                    {notificationBadge?.unReadCount}
                  </span>
                )}
              </div>
              <UserInfo />
            </div>
          </div>
          <div className="body">
            <div className="container-fluid px-0">
              <div className="row gx-0">
                <div
                  className={clsx(
                    value.withLogs && "col-md-8",
                    !value.withLogs && "col-12"
                  )}
                >
                  <div className={clsx("body-main", value.mainClassName)}>
                    <Switch>
                      {GetRoutes()}
                      <Route
                        path="/admin/*"
                        component={() => <Redirect to="/admin/dashboard" />}
                      />
                    </Switch>
                  </div>
                </div>

                {value.withLogs && (
                  <div className="col-md-4">
                    {projectNotifications !== undefined &&
                    projectNotifications?.data?.length ? (
                      <Notification
                        setNotificationbadge={setNotificationbadge}
                        projectNotification={projectNotifications}
                      />
                    ) : (
                      <DailyLog />
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
          <Footer className="mt-0" />
        </div>
      </div>
    </>
  );
};

export default ListingLayout;
