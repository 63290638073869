import React, { useEffect, useState, useCallback, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import clsx from "clsx";
import moment from "moment";
import Input from "../components/Input";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteProjectList,
  GetCompanies,
  GetProjects,
  ProjectNotification,
} from "app/redux/actions";
import _debounce from "lodash/debounce";
import Loader from "components/Loader";
import ConsentModel from "components/ConsentModel";
import { notifyError, notifySuccess } from "utilities";
import { FaRegTrashAlt } from "react-icons/fa";
import { FaRegEdit } from "react-icons/fa";
import { PROJECT_FILTER, TOOLS_FILTER } from "app/redux/constants";

const Dashboard = (props) => {
  const companies = useSelector((state) => state.Project.allCompanies);
  const [listingTypeState, setListingTypeState] = useState("grid");
  const [loader, setLoader] = useState(false);
  const dispatch = useDispatch();
  const AllProjects = useSelector((state) => state.Project.allProjects);
  const user = useSelector((state) => state.AuthReducer.user);
  const history = useHistory();
  const [deleteActive, setdeleteActive] = useState(false);
  const [DeleteData, setDeleteData] = useState();
  const [deleteloader, setDeleteLoader] = useState(false);

  console.log(AllProjects, "AllProjects");

  const handleEditProject = (event, data) => {
    event.preventDefault();
    event.stopPropagation();
    localStorage.setItem("recentProjectId", data.id);
    history.push(`/create/project/${data?.id}/edit`);
  };

  const handleProjectDelete = (event, data) => {
    event.preventDefault();
    event.stopPropagation();
    setDeleteData(data);
    setdeleteActive(true);
  };
  const deleteProject = () => {
    setDeleteLoader(true);
    dispatch(deleteProjectList(DeleteData?.id))
      .then((res) => {
        handleDebounceFn();
        setDeleteLoader(false);
        setdeleteActive(false);
        notifySuccess("Project deleted successfully");
      })
      .catch((err) => {
        setDeleteLoader(false);
        notifyError(
          err?.response?.data?.message ?? "Error Deleting Project Id",
          5000
        );
      });
  };

  const handleDebounceFn = (value) => {
    setLoader(true);
    if (user) {
      const data = {
        company_id: user?.companies[0]?.id ?? companies[0]?.id,
        name: value,
      };
      dispatch(GetProjects(data))
        .then((res) => {
          setLoader(false);
        })
        .catch((err) => {
          setLoader(false);
        });
    }
  };
  const debounceFn = useCallback(_debounce(handleDebounceFn, 1000), []);

  const handleSearch = (e) => {
    debounceFn(e.target.value);
  };
  const changeListingType = (type) => {
    setListingTypeState(type);
  };
  useEffect(() => {
    props.set_Value({
      title: props.title,
      subTitle: props.subTitle,
      withLogs: props.withLog,
    });

    if (user) {
      const data = {
        company_id: user?.companies[0]?.id ?? companies[0]?.id,
      };
      dispatch(GetCompanies());
      dispatch(GetProjects(data));
    }
  }, []);

  const handleClickProject = (el) => {
    dispatch({ type: TOOLS_FILTER, payload: "" });
    dispatch({ type: PROJECT_FILTER, payload: el?.id });
    setTimeout(() => {
      history.push(`/admin/project/${el?.id}`);
    }, 100);
  };

  return (
    <>
      {deleteActive && (
        <div className="px-0 mt-30">
          <ConsentModel
            active={deleteActive}
            id={DeleteData?.id}
            onsubmit={deleteProject}
            oncancel={() => setdeleteActive(false)}
            loader={deleteloader}
            title={DeleteData?.name}
          />
        </div>
      )}
      <div className="top">
        <div className="tabs">
          <div to="#0" className="tab active">
            Projects
          </div>
          {/* <div to="#0" className="tab">
            Executive Dashboard
          </div>
          <div to="#0" className="tab">
            Health Dashboard
          </div>
          <div to="#0" className="tab">
            My Open Items
          </div> */}
        </div>
        <div className="options d-flex">
          {user?.role_id !== 3 ? (
            <Link
              to={"/create/project/0"}
              className="ms-10 btn btn-primary-outlined"
            >
              CREATE PROJECT
            </Link>
          ) : (
            ""
          )}
        </div>
      </div>
      <div className="main">
        <div className="filter">
          <div className="search">
            <Input
              rootClassName="mb-0 "
              type="search"
              placeholder="Search"
              onChange={handleSearch}
            />
            {/* <Dropdown btn dropdownItems={["filter 1"]}>
              ADD FILTERS
            </Dropdown>
            <div className="btn btn-primary-text ">CLEAR ALL</div> */}
          </div>
          <div className="more-filters">
            {[
              {
                type: "list",
                icon: "/assets/vectors/list-check.svg",
              },
              {
                type: "grid",
                icon: "/assets/vectors/grid.svg",
              },
              {
                type: "laptop",
                icon: "/assets/vectors/laptop.svg",
              },
            ].map((el, idx) => {
              const { type, icon } = el;

              return (
                <img
                  key={"opt" + idx}
                  className={clsx({
                    active: type === listingTypeState,
                  })}
                  onClick={() => {
                    changeListingType(type);
                  }}
                  src={icon}
                  alt={type}
                />
              );
            })}
          </div>
        </div>
        <div className="listing mt-20">
          {listingTypeState === "list" && (
            <div className="table-container">
              <table>
                <thead>
                  <tr>
                    <th>Project Name</th>
                    <th>Project No.</th>
                    <th>Address</th>
                    <th>Phone</th>
                    <th>Status</th>
                    {/* <th>Stage</th>
                    <th>Type</th>
                    <th>Note</th> */}
                    {user?.role_id !== 3 ? (
                      <th className="text-center sticky-column-header">
                        Actions
                      </th>
                    ) : (
                      ""
                    )}
                  </tr>
                </thead>
                <tbody>
                  {loader ? (
                    <tr>
                      <td>
                        <Loader />
                      </td>
                    </tr>
                  ) : AllProjects?.length ? (
                    AllProjects?.map((el, idx) => {
                      return (
                        <tr
                          className="backgroundRowColor"
                          key={"table-item" + idx}
                        >
                          <td
                            onClick={() =>
                              history.push(`/admin/project/${el.id}`)
                            }
                            style={{ cursor: "pointer" }}
                          >
                            {el.name}
                          </td>
                          <td>{el.project_number}</td>
                          <td>{el.address}</td>
                          <td>{el.phone || "-"}</td>
                          <td>
                            {el?.status && (
                              <div className="badge success">{el.status}</div>
                            )}
                          </td>

                          {user?.role_id !== 3 && (
                            <td className="text-center sticky-column-body">
                              <img
                                className="me-10"
                                src="/assets/vectors/edit-table.svg"
                                alt="edit-table"
                                style={{ cursor: "pointer" }}
                                onClick={(event) =>
                                  handleEditProject(event, el)
                                }
                              />
                              <img
                                className="m"
                                src="/assets/vectors/delete-table.svg"
                                alt="delete-table"
                                style={{ cursor: "pointer" }}
                                onClick={(event) =>
                                  handleProjectDelete(event, el)
                                }
                              />
                            </td>
                          )}
                        </tr>
                      );
                    })
                  ) : (
                    <tr
                      style={{ width: "100%", textAlign: "center", height: 50 }}
                    >
                      <td
                        colSpan={"2"}
                        style={{ fontWeight: "600", textAlign: "left" }}
                      >
                        No Project Created Yet!
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          )}

          {listingTypeState === "grid" && (
            <div className="grid">
              <div className="container-fluid px-0">
                <div className="row g-3">
                  {loader ? (
                    <tr>
                      <td>
                        <Loader />
                      </td>
                    </tr>
                  ) : AllProjects?.length ? (
                    AllProjects?.map((el, idx) => {
                      return (
                        <div key={"grid" + idx} className="col-xxl-4 col-lg-6">
                          <div
                            className="grid-card backgroundRowColor"
                            style={{
                              cursor: "pointer",
                            }}
                            onClick={() => handleClickProject(el)}
                          >
                            <div className="position-absolute w-100 top-0">
                              <div className="d-flex align-items-center justify-content-between">
                                {el?.status && (
                                  <div className="badge success">
                                    {el?.status}
                                  </div>
                                )}
                                {user?.role_id !== 3 ? (
                                  <div className="text-center project-action-button position-absolute">
                                    <FaRegEdit
                                      size={18}
                                      style={{
                                        fill: "#E79436",
                                        color: "#E79436",
                                        cursor: "pointer",
                                      }}
                                      onClick={(event) =>
                                        handleEditProject(event, el)
                                      }
                                    />

                                    <FaRegTrashAlt
                                      size={18}
                                      style={{
                                        fill: "#F84949",
                                        color: "#F84949",
                                        cursor: "pointer",
                                      }}
                                      onClick={(event) =>
                                        handleProjectDelete(event, el)
                                      }
                                    />
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>

                            <img
                              className="laptopImage mt-3"
                              src={
                                el?.photo
                                  ? el.photo
                                  : "/assets/img/default_project_img.png"
                              }
                              alt="img"
                              height="66px"
                              width="66px"
                            />
                            <div className="text mt-3">
                              <h4>
                                <span
                                  className="h4 hoverPointer"
                                  title={el.name}
                                  onClick={() =>
                                    history.push(`/admin/project/${el.id}`)
                                  }
                                >
                                  {" "}
                                  {el.name}
                                </span>
                                <div
                                  className="tag c-pointer"
                                  onClick={() => {
                                    dispatch(
                                      ProjectNotification({
                                        projectId: el.id,
                                      })
                                    );
                                  }}
                                >
                                  {el?.unread_notification_count}
                                </div>
                              </h4>
                              <div className="text-light-1 my-10">
                                {el.address}
                              </div>
                              <div className="text-light-1">
                                {el.stage.name}
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <p className="empty">No Project Created Yet!</p>
                  )}
                </div>
              </div>
            </div>
          )}
          {listingTypeState === "laptop" && (
            <div className="laptop">
              {loader ? (
                <tr>
                  <td>
                    <Loader />
                  </td>
                </tr>
              ) : AllProjects?.length ? (
                AllProjects?.map((el, idx) => {
                  const { start_date, completion_date } = el;
                  const diff = moment
                    .duration(
                      moment(completion_date, "YYYY-MM-DD").diff(
                        moment(start_date, "YYYY-MM-DD")
                      )
                    )
                    .asDays();
                  return (
                    <div
                      key={"laptop" + idx}
                      className="laptop-card backgroundRowColor"
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={() => handleClickProject(el)}
                    >
                      <div className="d-flex align-content-center justify-content-between project-action-base">
                        <h4
                          className="hoverPointer"
                          title={el.name}
                          onClick={() =>
                            history.push(`/admin/project/${el.id}`)
                          }
                        >
                          {" "}
                          {el.name}
                        </h4>
                        {user?.role_id !== 3 ? (
                          <div className="text-center px-3 py-2">
                            <img
                              className="me-10"
                              src="/assets/vectors/edit-table.svg"
                              alt="edit-table"
                              style={{ cursor: "pointer" }}
                              onClick={(event) => handleEditProject(event, el)}
                            />
                            <img
                              className="m"
                              src="/assets/vectors/delete-table.svg"
                              alt="delete-table"
                              style={{ cursor: "pointer" }}
                              onClick={(event) =>
                                handleProjectDelete(event, el)
                              }
                            />
                          </div>
                        ) : (
                          ""
                        )}
                      </div>

                      <div className="body">
                        <div className="details">
                          <img
                            className="me-3 laptopImage"
                            height="100px"
                            width="100px"
                            src={
                              el?.logo
                                ? el?.photo
                                : "/assets/img/default_project_img.png"
                            }
                            alt="portfolio-img"
                          />
                          <div className="text">
                            <div>
                              <div className="left">Duration:</div>
                              <div className="right fw-bold">
                                {diff * 1} days
                              </div>
                            </div>
                            <div>
                              <div className="left">Stage:</div>
                              <div className="fw-bold">{el.stage.name}</div>
                            </div>
                            <div>
                              <div className="left">Type:</div>
                              <div className="fw-bold">{el.type.name}</div>
                            </div>
                          </div>
                        </div>
                        <div className="stats pt-3">
                          <div className="progress">
                            <div className="progress-bar"></div>
                          </div>

                          <div className="legend">
                            <div className="item">
                              <div
                                className="clr"
                                style={{
                                  backgroundColor: "#F84949",
                                }}
                              ></div>
                              <div className="label">Overdue(3)</div>
                            </div>
                            <div className="item">
                              <div
                                className="clr"
                                style={{
                                  backgroundColor: "#F6BB21",
                                }}
                              ></div>
                              <div className="label">Next 7 Days(0)</div>
                            </div>
                            <div className="item">
                              <div
                                className="clr"
                                style={{
                                  backgroundColor: "#346979",
                                }}
                              ></div>
                              <div className="label">&gt; 7 Days(0)</div>
                            </div>
                            <button className="btn btn-primary-outlined">
                              SCHEDULE 03
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <p className="empty">No Project Created Yet!</p>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Dashboard;
