import { OtpVerfication, ResendPassword } from "app/redux/actions";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import { notifyError, notifySuccess } from "utilities";
import Loader from "../components/Loader";

const Verification = () => {
  const history = useHistory();
  const data = useLocation();
  const [state, setState] = useState({
    numberVerfiy: "",
    otp1: "",
    otp2: "",
    otp3: "",
    otp4: "",
  });
  const [loader, setLoader] = useState(false);
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.AuthReducer.user);

  const handleSubmit = () => {
    if (state && data) {
      setLoader(true);
      dispatch(
        OtpVerfication({
          verification_code: `${state.otp1}${state.otp2}${state.otp3}${state.otp4}`,
          email: userData && userData.email,
        })
      )
        .then((res) => {
          if (res) {
            setLoader(false);
            notifySuccess("You are verified now");
            history.push("/create/project/0");
          }
        })
        .catch((err) => {
          setLoader(false);
          notifyError(err?.payload?.response?.data?.message);
        });
    }
  };

  const resendPassword = () => {
    if (userData?.email) {
      dispatch(ResendPassword({ email: userData?.email }))
        .then((res) => {
          notifySuccess("OTP sent successfully");
        })
        .catch((err) => {
          notifyError(err?.payload?.response?.data?.message);
        });
    }
  };

  const handleChange = (value1, event) => {
    setState((prevState) => ({ ...prevState, [value1]: event.target.value }));
  };
  const inputfocus = (elmnt) => {
    if (elmnt.key === "Delete" || elmnt.key === "Backspace") {
      const next = elmnt.target.tabIndex - 2;
      if (next > -1) {
        elmnt.target.form.elements[next].focus();
      }
    } else {
      const next = elmnt.target.tabIndex;
      if (next < 4) {
        elmnt.target.form.elements[next].focus();
      }
    }
  };

  const handlePaste = (event) => {
    event.clipboardData.items[0].getAsString((text) => {
      const code = text.split("").map((char, index) => {
        if (Number.isInteger(Number(char))) {
          return Number(char);
        }
        return "";
      });
      setState({
        otp1: code[0],
        otp2: code[1],
        otp3: code[2],
        otp4: code[3],
      });
    });
  };

  const code = state.otp1 + state.otp2 + state.otp3 + state.otp4;
  return (
    <div className="form">
      <div className="custom-form-control">
        <label htmlFor="email">Verification Code</label>
        <form>
          <div
            onPaste={handlePaste}
            style={{ justifyContent: "start", marginTop: "5%", marginBottom: "5%", display: "flex" }}
            className="verification-inputs"
          >
            <input
              name="otp1"
              type="text"
              autoComplete="off"
              value={state.otp1}
              onPaste={handlePaste}
              onChange={(e) => handleChange("otp1", e)}
              tabIndex="1"
              maxLength="1"
              onKeyUp={(e) => inputfocus(e)}
            />
            <input
              name="otp2"
              type="text"
              autoComplete="off"
              value={state.otp2}
              onPaste={handlePaste}
              onChange={(e) => handleChange("otp2", e)}
              tabIndex="2"
              maxLength="1"
              onKeyUp={(e) => inputfocus(e)}
            />
            <input
              name="otp3"
              type="text"
              autoComplete="off"
              value={state.otp3}
              onPaste={handlePaste}
              onChange={(e) => handleChange("otp3", e)}
              tabIndex="3"
              maxLength="1"
              onKeyUp={(e) => inputfocus(e)}
            />
            <input
              name="otp4"
              type="text"
              autoComplete="off"
              value={state.otp4}
              onPaste={handlePaste}
              onChange={(e) => handleChange("otp4", e)}
              tabIndex="4"
              maxLength="1"
              onKeyUp={(e) => inputfocus(e)}
            />
          </div>
        </form>
      </div>
      <div>
        Don’t get OTP{" "}
        <button
          onClick={resendPassword}
          style={{
            background: "none",
            border: "none",
            color: "#E79436",
            fontWeight: "600",
            fontSize: "16px",
            cursor: "pointer",
          }}
        >
          Resend
        </button>
      </div>
      <div className="mt-3">
        {!loader ? (
          <button
            disabled={code ? false : true}
            className="mt-4 btn btn-primary btn-auth"
            onClick={handleSubmit}
          >
            SUBMIT
          </button>
        ) : (
          <div className="btn btn-primary btn-auth">
            <Loader />
          </div>
        )}
      </div>
    </div>
  );
};

export default Verification;
