import React, { forwardRef, useEffect, useState, useImperativeHandle } from "react";
import { Draggable } from "react-drag-reorder";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { notifyError } from "utilities";
import { AddTools, GetToolsData } from "../app/redux/actions";
import Checkbox from "../components/Checkbox";
import { RESET_PROJECT } from "../app/redux/constants";
const Tools = forwardRef(({ setStepState, SetLoadingData }, ref) => {
  const projectData = useSelector((state) => state.Project.project);
  const dispatch = useDispatch();
  const [tools, setTools] = useState([]);
  const [selectedtools, setSelectedTools] = useState([]);
  const [IsCheckAll, setIsCheckAll] = useState(false);
  const history = useHistory();
  const { id } = useParams();

  useEffect(async () => {
    let data = [];
    await projectData?.tools?.map((item) => {
      data.push(item.id);
    });
    setSelectedTools(data);
    dispatch(GetToolsData()).then((res) => {
      setTools(res.payload.data);
    });
    FindLength();
  }, []);
  const Submit = () => {
    SetLoadingData(true);
    if (projectData.id) {
      const payload = {
        project_id: projectData.id,
        tools: {
          tool_id: selectedtools,
        },
      };
      if (selectedtools) {
        dispatch(AddTools(payload))
          .then((res) => {
            if (res.payload) {
              SetLoadingData(false);
              history.push(`/create/project/${parseInt(id) + 1}`);
              setStepState((prevState) => {
                if (prevState === 2) {
                  return prevState;
                } else {
                  return prevState + 1;
                }
              });
            }
          })
          .catch((err) => {
            SetLoadingData(false);
            notifyError(err?.payload?.response?.data?.message ?? "Error", 5000);
          });
      }
    }
  };
  useImperativeHandle(ref, () => ({
    Submit,
  }));
  const FindLength = () => {
    const totalLength = tools.reduce((count, current) => count + current.tools.length, 0);
    if (selectedtools.length) {
      if (selectedtools.length === totalLength) {
        setIsCheckAll(true);
      }
    }
  };

  useEffect(() => {
    FindLength();
  }, [selectedtools]);
  const handleChange = (ele) => {
    if (selectedtools.includes(ele)) {
      setIsCheckAll(false);
      setSelectedTools(selectedtools.filter((value) => value !== ele));
    } else {
      FindLength();
      setSelectedTools((prev) => [...prev, ele]);
    }
  };
  const handleCheckAll = () => {
    setIsCheckAll(!IsCheckAll);
    const _data = [];
    tools.map((el) => {
      el.tools?.map((li) => _data.push(li.id));
    });
    setSelectedTools(_data);
    if (IsCheckAll) {
      setSelectedTools([]);
    }
  };
  const handleCategoryCheck = async (tools) => {
    const items = [];
    tools.map((item) => {
      items.push(item.id);
      if (selectedtools.filter((value) => items.includes(value)).length === items.length) {
        setIsCheckAll(false);
        setSelectedTools(selectedtools.filter((value) => !items.includes(value)));
      } else {
        setSelectedTools((prev) => [...prev, item.id]);
      }
    });
  };
  return (
    <div className="tools">
      <h3>Tools</h3>

      <div className="tools-main">
        <div className="tools-checkbox-container tools-main-title">
          {tools?.length > 0 ? (
            <Checkbox
              rootClassName="fs-12 fw-bold lh-15"
              label="Select all tools"
              checked={IsCheckAll}
              onChange={handleCheckAll}
            />
          ) : (
            <p>No Tools Found</p>
          )}
        </div>
        <div className="tools-section">
          {tools?.length &&
            tools?.map((item, index) => {
              return (
                <div key={index}>
                  <div key={index} className="tools-checkbox-container tools-section-title">
                    <Checkbox
                      small
                      bold
                      label={item.category}
                      checked={IsCheckAll || item?.tools?.every((e) => selectedtools.includes(e.id))}
                      onChange={() => handleCategoryCheck(item.tools)}
                    />
                  </div>
                  {item.tools.map((el) => {
                    const { title, description, id } = el;
                    return (
                      <div key={id} className="tools-checkbox-container tools-section-item">
                        <Checkbox
                          checked={IsCheckAll || selectedtools.includes(id)}
                          key={id}
                          withReorder
                          bold
                          label={title}
                          subLabel={description}
                          onChange={() => handleChange(id)}
                        />
                      </div>
                    );
                  })}
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
});

export default Tools;
