export const base_Url = process.env.REACT_APP_BASE_API_V1;
export const apiUrls = {
  endpoints: {
    login: `/account/login`,
    signUp: `/account/signup`,
    companyType: `/types`,
    otpVerfication: `/account/verify/email`,
    forgotPassword: `/account/forgot-password`,
    createProject: `/projects`,
    
    updateProject: (id) => {
      return `/projects/${id}`;
    },
    ImageUpload: `/images`,
    getTools: `/tools`,
    addTools: `/prtools`,
    getCountries: `/countries`,
    getDirectories: `/directories`,
    postDirectories: `/project-directories`,
    getProjects: `/project/search`,
    getCompanies: `/companies`,
    getTemplates: `/templates`,
    getStages: `/project/stages`,
    getTypes: `/project/types`,
    getMembers: `/project`,
    createStages: `/stages/create`,
    createTypes: `/types/create`,
    /*
    project Contracts And Contractors:
    */
    GetProjectContracts: `/project/contracts/search`,
    GetSignaturesRollWise: (projectId) => {
      return `/project/${projectId}/e-signatures/rolewise`;
    },

    GetContractors: `/project/contractors/search`,
    CreateContrators: `/project/contractors`,
    CreateProjectContracts: (id) => {
      return `/project/${id}/contracts`;
    },

    UpdateProjectContract: (projectId, id) => {
      return `/project/${projectId}/contracts/${id}`;
    },

    /*
    project Contracts And Contractors:
    */
    getCurrency: `/currencies`,
    getCountryCodes: `/account/country-codes`,
    resetPassword: `/reset-password`,
    UpdateUserProfile: `/account/update-profile`,
    resendPassword: `/account/resend-code`,
    getMeetings: `/project/`,
    getAttendees: `/project/`,
    // deleting meeting
    createMeetings: (projectId) => {
      return `/project/${projectId}/meetings`;
    },
    // delete meeting
    deleteMeeting: (projectId, meetingId) => {
      return `/project/${projectId}/meetings/${meetingId}`;
    },
    // meeting detail View
    meetingDetail: (projectId, meetingId) => {
      return `/project/${projectId}/meetings/${meetingId}`;
    },
    // Edit current meeting
    EditMeeting: (projectId, meetingId) => {
      return `/project/${projectId}/meetings/${meetingId}`;
    },

    deleteProjectList: (projectId) => {
      return `/projects/${projectId}`;
    },

    getProjectDetails: (projectId) => {
      return `/projects/${projectId}`;
    },

    EditInvoice: (projectId, invoiceId) => {
      return `/project/${projectId}/invoices/${invoiceId}`;
    },
    getDrawings: `/drawings/search`,
    drawingsCategory: `/drawings/categories`,
    createDrawing: `/project/`,
    getDrawingSets: `/project/`,
    editDrawings: `/drawings/`,
    deleteDrawings: `/drawings/`,
    getRoles: `/project/roles`,
    projectOverview: `/project/`,
    createMember: `/project/`,
    deleteMember: `/members/`,

    deleteContracts: (params) => {
      const project_id = params.project_id;
      const contract_id = params.contract_id;
      return `/project/${project_id}/contracts/${contract_id}`;
    },
    updateMember: `/`,
    GetDrawingDetails: (id) => {
      return `/drawings/${id}`;
    },
    // Todo Listing API URL
    getToDo: `/project/`,
    // Create Todo API URL
    createToDo: (projectId) => {
      return `/project/${projectId}/todos/`;
    },
    // Todo Details
    TodoDetails: (projectId, id) => {
      return `/project/${projectId}/todos/${id}`;
    },
    // Get Assignee Api
    getAssignee: `/project/`,
    // Get Todo Category API
    getCategory: `/project/`,

    // edit management
    editManagement: `/managements/`,

    // delete management
    deleteManagement: `/managements/`,

    // Update category management
    updateCategoryManagement: (id) => {
      return `/managements/update-category/${id}`;
    },

    // Update stage management
    updateStageManagement: (id) => {
      return `/managements/update-stage/${id}`;
    },

    // Update type management
    updateTypeManagement: (id) => {
      return `/managements/update-type/${id}`;
    },

    // Update template management
    updateTemplateManagement: (id) => {
      return `/managements/update-template/${id}`;
    },

    // Update category management
    createCategoryManagement: () => {
      return `/managements/create-category`;
    },

    // create stage management
    createStageManagement: () => {
      return `/managements/create-stage`;
    },

    // create type management
    createTypeManagement: () => {
      return `/managements/create-type`;
    },

    // create template management
    createTemplateManagement: () => {
      return `/managements/create-template`;
    },

    // Delete Todo API
    deleteTodo: (projectId, todoId) => {
      return `/project/${projectId}/todos/${todoId}`;
    },
    EditTodo: (projectId, todoId) => {
      return `/project/${projectId}/todos/${todoId}`;
    },
    contactForm: `/home/contact-form`,
    getLogsCategory: `/project/dailyLogs/list`,
    createDailyLog: (id) => {
      return `/project/${id}/dailyLogs`;
    },
    ContractorCompanies: `/project/dailyLogs/contractor-companies`,
    dailyLogLocation: `/project/dailyLogs/locations`,
    dailyLogListing: `/project/dailyLogs/filter`,
    deleteDailyLog: (id) => {
      return `/project/dailyLogs/${id}`;
    },
    albumListing: `/project/dailyLogs/albums`,
    tradeListing: `/project/dailyLogs/trades`,
    locations: `/project/dailyLogs/locations`,
    filterDailyLog: `/project/dailyLogs/filter`,
    equipments: `/project/dailyLogs/equipments`,
    deliveryCompanies: `/project/dailyLogs/delivery-companies`,
    UpdateDailyLog: (id) => {
      return `/project/dailyLogs/${id}`;
    },
    getCalendarsLogs: `/project/dailyLogs/calendar`,
    createEquipment: `/equipments`,
    createCompanies: `/deliveryCompanies`,
    // invoice listing//
    InvoiceListing: `/project/invoices/search`,
    invoiceCreation: (projectId) => {
      return `/project/${projectId}/invoices`;
    },
    // invoice pro Items creation//
    InvoiceItemsCreation: (projectId) => {
      return `/project/${projectId}/items`;
    },

    VendorsListing: (projectId) => {
      return `/project/vendors/search`;
    },

    getInvoiceItems: `/project/items/search`,
    DeleteInvoice: (projectId, invoiceId) => {
      return `/project/${projectId}/invoices/${invoiceId}`;
    },
    updateInvoice: (project_id, invoice_id) => {
      return `/project/${project_id}/invoices/${invoice_id}`;
    },
    GetInvoiceDetail: (projectId, invoiceId) => {
      return `/project/${projectId}/invoices/${invoiceId}`;
    },
    getNotification: (page) => {
      return `/notifications?page=${page}`;
    },
    generatePdf: (project_id, invoice_id) => {
      return `/project/${project_id}/invoices/${invoice_id}/export`;
    },
    readNotificationById: (id) => {
      return `/notifications/${id}/read`;
    },
    readAllNotification: `/notifications/read`,

    delNotification: (id) => {
      return `/notifications/${id}/delete`;
    },

    projectNotification: (project_id) => {
      return `/notifications/project/${project_id}`;
    },

    //esignature api's
    createEsinature: (projectId) => {
      return `/project/esignatures`;
    },
    signatureListing: `/project/esignatures`,

    deleteSignature: (project_id, signature_id) => {
      return `/project/esignatures/${signature_id}`;
    },

    GetInvoicePaymentList: `/project/invoices/paymentRequests/search`,

    CreatePaymentRequest: (projectId, invoiceId) => {
      return `/project/${projectId}/invoices/${invoiceId}/paymentRequests`;
    },
    deleteInvoicePaymentRequest: (params) => {
      const projectId = params?.projectId;
      const invoiceId = params?.invoiceId;
      const id = params?.id;
      return `/project/${projectId}/invoices/${invoiceId}/paymentRequests/${id}`;
    },

    ProjectStatementExport: (projectId) => {
      return `/project/${projectId}/statement`;
    },

    invoicepaymentRequest: (params) => {
      const projectId = params.projectId;
      const invoiceId = params.invoiceId;
      const id = params.requestId;
      return `/project/${projectId}/invoices/${invoiceId}/paymentRequests/${id}`;
    },

    exportPaymentRequest: (params) => {
      const projectId = params.projectId;
      const invoiceId = params.invoiceId;
      const id = params.requestId;
      return `/project/${projectId}/invoices/${invoiceId}/paymentRequests/${id}/export`;
    },
    CreateVendor: `/project/vendors`,
    IncidentsListing: `/project/incidents/search`,

    CreateIncidents: (projectId) => {
      return `/project/${projectId}/incidents`;
    },

    DeleteIncident: (projectId, id) => {
      return `/project/${projectId}/incidents/${id}`;
    },

    ExportIncidents: (projectId) => {
      return `/project/${projectId}/incidents/export`;
    },

    UpdateIncident: (projectId, id) => {
      return `/project/${projectId}/incidents/${id}`;
    },

    GetIncidentDetail: (projectId, id) => {
      return `/project/${projectId}/incidents/${id}`;
    },

    GetAgreementData: (id) => {
      return `/project/${id}/agreements`;
    },

    ExportSubContractorForm: (id) => {
      return `/project/${id}/agreements/export`;
    },

    contractorDetail: (id, contractorId) => {
      return `/project/contractors/${contractorId}`;
    },
    contractsdetail: (id) => {
      return `/project/${id}/contractors/contracts`;
    },
    FetchContractorTypes: `/project/contractors/contractor_types/filter`,
  },
};
