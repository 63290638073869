import React from "react";
const ChangeHistory = ({ data }) => {
  return (
    <div className="card todochangehistory-card">
      <div className="card-header">
        <h3 className="card-heading">Change History</h3>
      </div>
      <div className="card-body">
        <div className="row">
          {!data?.length && <p>No Record Found</p>}
          {data?.map((data) => (
            <>
              <div className="col-md-12">
                <h3>{data?.date}</h3>
              </div>
              <div className="row table-heading">
                <div className="col-md-6 headings">Action By:</div>
                <div className="col-md-6 small-text headings">Changed:</div>
              </div>
              <div className="row table-description">
                <div className="col-md-6">{data?.action_by}</div>
                <div className="col-md-6 text-date">{data?.changed}</div>
              </div>
              <div className="row  table-heading">
                <div className="col-md-6 headings">From:</div>
                <div className="col-md-6 small-text headings">To:</div>
              </div>
              <div className="row table-description">
                <div className="col-md-6">{data?.from}</div>
                <div className="col-md-6 text-date">{data?.to}</div>
              </div>
              <hr className="horizntal-row" />
            </>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ChangeHistory;
