import CreateProject from "../screens/CreateProject";
import Dashboard from "../screens/Dashboard";
import ProjectDetails from "../screens/ProjectDetails";
import Specifications from "../screens/Specifications";
import Meetings from "../screens/Meetings";
import ToDo from "../screens/ToDo";
import CreateMeeting from "../screens/CreateMeeting";
import DailyLog from "../screens/DailyLog";
import DailyLogCalender from "../screens/DailyLogCalender";
import Drawings from "screens/Drawings";
import DrawingDetails from "screens/DrawingDetail";
import MeetingsDetails from "screens/MeetingDetails";
import TodoDetails from "screens/TodoDetails";
import Invoice from "screens/Invoices";
import CreateInvoice from "screens/CreateInvoice";
import EsigNature from "screens/EsigNature";
import InvoiceDetails from "screens/InvoiceDetails";
import InvoiceEdit from "screens/CreateInvoice";
import Settings from "screens/Settings";
import ChangeInvoice from "screens/ChangeInvoice";
import InvoicePaymentRequest from "screens/InvoicePaymentRequest";
import PaymentRequestDetail from "screens/PaymentRequestDetails";
import Inccidents from "screens/Inccidents";
import IncidentDetail from "screens/IncidentDetais";
import Agreements from "screens/Agreements";
import ExportStatment from "screens/ExportStatment";
import Owner from "screens/Owner";
import SubContractor from "screens/SubContractor";
import Labor from "screens/Labor";
import Vendor from "screens/Vendor";
import Material from "screens/Material";
import CoverLetter from "screens/CoverLetter";
import Management from "screens/Management";

var routes = [
  {
    path: "/dashboard",
    layout: "/admin",
    name: "Dashboard Page",
    title: "Portfolio",
    mini: "DP",
    withLog: true,
    rootClassName: "portfolio",
    title: "Portfolio",
    component: Dashboard,
  },

  {
    path: "/project/:id",
    layout: "/admin",
    name: "Project Details Page",
    mini: "PDP",
    withSettings: true,
    rootClassName: "project",
    title: "Refined Homes",
    subTitle: "3517 W. Gray St. Utica, Pennsylvania 57867",
    component: ProjectDetails,
  },
  {
    path: "/project/:id/exportStatment",
    layout: "/admin",
    name: "Project Statement Page",
    mini: "PDP",
    withSettings: true,
    rootClassName: "exportStatment",
    title: "Export Statments",
    component: ExportStatment,
  },
  {
    path: "/project/:id/exportStatment/labor",
    layout: "/admin",
    name: "Labor Page",
    mini: "LP",
    withSettings: true,
    rootClassName: "labor",
    title: "Export Statments",
    component: Labor,
  },
  {
    path: "/project/:id/exportStatment/vendor",
    layout: "/admin",
    name: "Vendor Page",
    mini: "VP",
    withSettings: true,
    rootClassName: "Vendor",
    title: "Export Statments",
    component: Vendor,
  },
  {
    path: "/project/:id/exportStatment/material",
    layout: "/admin",
    name: "Material Page",
    mini: "VP",
    withSettings: true,
    rootClassName: "Material",
    title: "Export Statments",
    component: Material,
  },
  {
    path: "/project/:id/specification",
    layout: "/admin",
    name: "Specification Page",
    mini: "SP",
    component: Specifications,
  },
  {
    path: "/project/:id/tool/meetings",
    layout: "/admin",
    name: "Meeting Page",
    mini: "MP",
    rootClassName: "meetings",
    mainClassName: "py-20",
    withSettings: true,
    title: "Meetings",
    component: Meetings,
  },
  {
    path: "/project/:id/tool/management",
    layout: "/admin",
    name: "Management Page",
    mini: "MP",
    rootClassName: "management",
    mainClassName: "py-20",
    withSettings: true,
    title: "Management",
    component: Management,
  },
  {
    path: "/project/:id/tool/meetings/create",
    layout: "/admin",
    name: "Create Meeting Page",
    mini: "CMP",
    rootClassName: "create-meeting",
    mainClassName: "py-20",
    withSettings: true,
    title: "Create A New Meeting",
    component: CreateMeeting,
  },
  {
    path: "/project/:id/tool/meetings/:id/edit",
    layout: "/admin",
    name: "Edit Meeting Page",
    mini: "EMP",
    rootClassName: "edit-meeting",
    mainClassName: "py-20",
    withSettings: true,
    title: "Edit A  Meeting",
    component: CreateMeeting,
  },
  {
    path: "/project/:id/tool/meetings/:id",
    layout: "/admin",
    name: "Meetings Page",
    mini: "MD",
    withSettings: true,
    rootClassName: "meetings",
    mainClassName: "py-20",
    title: "Meetings Details",
    component: MeetingsDetails,
  },
  {
    path: "/project/:id/tool/daily-log",
    layout: "/admin",
    name: "Daily Logs Page",
    mini: "DLP",
    withSettings: true,
    rootClassName: "daily-log",
    mainClassName: "py-20",
    title: "Daily Log",
    component: DailyLog,
  },
  {
    path: "/project/:id/tool/daily-log/calendars",
    layout: "/admin",
    name: "Daily Logs Calendar Page",
    mini: "DLCP",
    withSettings: true,
    rootClassName: "daily-log-calender",
    mainClassName: "py-20",
    title: "Daily Log",
    component: DailyLogCalender,
  },
  {
    path: "/project/:id/tool/drawings",
    layout: "/admin",
    name: "Drawings Page",
    mini: "DP",
    withSettings: true,
    rootClassName: "drawings",
    mainClassName: "py-20",
    title: "Drawings",
    component: Drawings,
  },
  {
    path: "/project/:id/tool/drawings/:id",
    layout: "/admin",
    name: "Drawings Page",
    mini: "DD",
    withSettings: true,
    rootClassName: "drawings",
    mainClassName: "py-20",
    title: "Architecture Details",
    component: DrawingDetails,
  },
  {
    path: "/project/:id/tool/todos/:id",
    layout: "/admin",
    name: "Todo Page",
    mini: "TD",
    withSettings: true,
    rootClassName: "",
    mainClassName: "py-20",
    title: "Interior Modeling Phase 2",
    component: TodoDetails,
  },
  {
    path: "/project/:id/tool/todos",
    layout: "/admin",
    name: "Todo",
    mini: "TD",
    withSettings: true,
    title: "To Do",
    component: ToDo,
  },
  {
    path: "/project/:id/tool/invoices",
    layout: "/admin",
    name: "Invoices Page",
    mini: "IP",
    rootClassName: "Invoices",
    mainClassName: "py-20",
    withSettings: true,
    title: "Invoices",
    component: Invoice,
  },
  {
    path: "/project/:id/tool/invoices/create",
    layout: "/admin",
    name: "Create Invoice Page",
    mini: "CIP",
    rootClassName: "create-Invoice",
    mainClassName: "py-20",
    withSettings: true,
    title: "Create A New Invoice",
    component: CreateInvoice,
  },

  {
    path: "/project/:id/tool/invoices/paymentRequest",
    layout: "/admin",
    name: "Payment Request",
    mini: "IPR",
    rootClassName: "InvoicePaymentRequest",
    mainClassName: "py-20",
    withSettings: true,
    title: "Payment Request",
    component: InvoicePaymentRequest,
  },

  {
    path: "/project/:projectId/tool/invoices/:InvoiceId/paymentRequest/:id",
    layout: "/admin",
    name: "Invoice Page",
    mini: "IP",
    withSettings: true,
    rootClassName: "invoices",
    mainClassName: "py-20",
    title: "InvoicePayment Request Details",
    component: PaymentRequestDetail,
  },

  {
    path: "/project/:id/tool/invoices/change-invoice",
    layout: "/admin",
    name: "change Invoice",
    mini: "CIP",
    rootClassName: "change-Invoice",
    mainClassName: "py-20",
    withSettings: true,
    title: "Change Invoice",
    component: ChangeInvoice,
  },
  {
    path: "/project/:id/tool/invoices/:id/edit",
    layout: "/admin",
    name: "Edit Invoice Page",
    mini: "EIP",
    rootClassName: "edit-Invoice",
    mainClassName: "py-20",
    withSettings: true,
    title: "Edit Invoice",
    component: InvoiceEdit,
  },

  {
    path: "/project/:id/tool/esignature",
    layout: "/admin",
    name: "Esignature Page",
    mini: "EP",
    rootClassName: "E-signature",
    mainClassName: "py-20",
    withSettings: true,
    title: "E-signature",
    component: EsigNature,
  },

  {
    path: "/project/:id/cover-letter",
    layout: "/admin",
    name: "Cover Letter",
    mini: "CL",
    rootClassName: "Cover-letter",
    mainClassName: "py-20",
    withSettings: true,
    title: "Cover-letter",
    component: CoverLetter,
  },

  {
    path: "/project/:id/tool/specifications",
    layout: "/admin",
    name: "Specifications Page",
    mini: "SP",
    rootClassName: "Specifications",
    mainClassName: "py-20",
    withSettings: true,
    title: "Specifications",
    component: Specifications,
  },
  {
    path: "/project/:id/exportstatment/sub-contractor/:contractorId",
    layout: "/admin",
    name: "Sub Contractor Page",
    mini: "OP",
    rootClassName: "Sub Contrator",
    mainClassName: "py-20",
    withSettings: true,
    title: "Project Statment",
    component: SubContractor,
  },
  {
    path: "/project/:id/exportstatment/sub-contractor",
    layout: "/admin",
    name: "Sub Contractor Page",
    mini: "OP",
    rootClassName: "Sub Contrator",
    mainClassName: "py-20",
    withSettings: true,
    title: "Project Statment",
    component: SubContractor,
  },
  {
    path: "/project/:id/exportstatment/owner",
    layout: "/admin",
    name: "Owner Page",
    mini: "OP",
    rootClassName: "Owner",
    mainClassName: "py-20",
    withSettings: true,
    title: "Project Statment",
    component: Owner,
  },
  {
    path: "/project/:id/tool/invoices/:id",
    layout: "/admin",
    name: "Invoice Page",
    mini: "IP",
    withSettings: true,
    rootClassName: "invoices",
    mainClassName: "py-20",
    title: "Invoice Details",
    component: InvoiceDetails,
  },
  {
    path: "/settings",
    layout: "/admin",
    name: "settings Page",
    title: "settings",
    mini: "SP",
    withLog: true,
    rootClassName: "settings",
    title: "settings",
    component: Settings,
  },

  {
    path: "/project/:id/edit",
    layout: "/create",
    name: "Project Page",
    mini: "PP",
    component: ProjectDetails,
  },

  {
    path: "/project/:id/tool/incidents",
    layout: "/admin",
    name: "Inccidents Page",
    title: "Inccidents",
    mini: "IP",
    withLog: true,
    rootClassName: "Incidents",
    title: "Incidents",
    component: Inccidents,
  },
  {
    path: "/project/:id/tool/incidents/:incidentId",
    layout: "/admin",
    name: "Incidents Detail Page",
    title: "IncidentDetail",
    mini: "IP",
    withLog: true,
    rootClassName: "Incident-Details",
    title: "Incident-Details",
    component: IncidentDetail,
  },
  {
    path: "/project/:id/tool/agreements",
    layout: "/admin",
    name: "Project Agreements",
    title: "Project Agreements",
    mini: "PA",
    withLog: true,
    rootClassName: "WORK AGREEMENT",
    title: "WORK AGREEMENT",
    component: Agreements,
  },
];

export const CreateProjectRoutes = [
  {
    path: "/project/:id",
    layout: "/create",
    name: "Project Page",
    mini: "PP",
    component: CreateProject,
  },
];

export default routes;
