import React, { Fragment } from "react";
import moment from "moment";
import { useHistory } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import {
  DelNotification,
  GetNotifications,
  ReadAllNotification,
  ReadNotificationById,
} from "app/redux/actions";
import Loader from "./Loader";
import { notifyError } from "utilities";

const NotificationModel = (data) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const projectId = useSelector((state) => state.navigation.projectId);
  const [notifyTab, setNotifyTab] = React.useState("all");
  const [notificationListing, setNotifications] = React.useState();
  const [loader, setLoader] = React.useState(false);

  React.useEffect(() => {
    setLoader(true);
    dispatch(GetNotifications({ page: 1 }))
      .then((res) => {
        setLoader(false);
        setNotifications(res);
      })
      .catch((err) => {
        setLoader(false);
      });
  }, []);

  const handleHideShowModel = (item) => {
    dispatch(
      ReadNotificationById({
        id: item.id,
      })
    )
      .then((res) => {
        dispatch(GetNotifications({ page: 1 })).then((res) => {
          setNotifications(res);
        });
      })
      .catch((err) => {
        console.log(err);
      });
    if (item?.is_deleted === 0) {
      const typeId = item?.data?.id;
      const type = item?.type;
      if (type && item.id) {
        history.push(`/admin/project/${projectId}/tool/${type}/${typeId}`);
      } else {
        history.push(`/admin/project/${projectId}/tool/${type}`);
      }
      data.showHideModal();
    } else {
      notifyError(`Deleted by ${item?.deleted_by}`);
    }
  };

  return (
    <div className="card card-Notification">
      <div className="card-header border_bottom">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div className="mt-2">
            <h4 className="p-3" style={{ fontWeight: 700 }}>
              Notification
            </h4>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div className="tabs mt-2">
              <div
                className={`tab p-3 mb-2
                ${notifyTab === "all" && "active notificationBack"}`}
                onClick={() => {
                  setNotifyTab("all");
                }}
              >
                All
              </div>
              <div
                className={`tab p-3 mb-2 notificationBadge
                ${notifyTab === "unread" && "active notificationBack"}
                `}
                onClick={() => {
                  setNotifyTab("unread");
                }}
              >
                Unread
              </div>
            </div>
          </div>
        </div>
      </div>
      {notifyTab === "unread" ? (
        <div>
          <div
            className="card-body d-block notification-box"
            style={{ padding: "5px" }}
          >
            {loader && <Loader />}
            {notificationListing?.data?.length ? (
              notificationListing?.data?.map((item, idx) => (
                <Fragment key={idx}>
                  {item?.read_at === null && (
                    <div
                      className="row"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      key={idx}
                    >
                      <div className="col-md-1 px-3">
                        <span className="dot"></span>
                      </div>
                      <div className="col-md-9  mt-3">
                        <h6
                          className="text-limit-2-lines notification-container"
                          style={{
                            paddingBottom: "8px",
                            lineSpacing: "1.5px",
                            display: "inline-block",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            width: "280px",
                          }}
                          onClick={() => handleHideShowModel(item)}
                          title={item?.data?.message}
                        >
                          {item?.data?.message}
                        </h6>
                      </div>
                      <div className="col-2 p-0">
                        <span
                          style={{
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setLoader(true);
                            dispatch(DelNotification({ id: item.id }))
                              .then((res) => {
                                dispatch(GetNotifications({ page: 1 })).then(
                                  (res) => {
                                    setLoader(false);
                                    setNotifications(res);
                                  }
                                );
                              })
                              .catch((err) => {
                                setLoader(false);
                                console.log(err, "err");
                              });
                          }}
                        >
                          <img
                            className="ms-15 notificationStatus"
                            src="/assets/vectors/delete-table.svg"
                            alt="delete"
                            title="delete"
                          />
                        </span>
                      </div>
                      <div className="col-10">
                        <p
                          className={`${
                            item?.read_at === null && "notificationStatus"
                          }`}
                        >
                          {item?.date}
                        </p>
                      </div>
                      <hr className="mt-3 underline-notifications" />
                    </div>
                  )}
                </Fragment>
              ))
            ) : (
              <p className="mt-4 mb-4 px-3 text-center">No Notification.</p>
            )}
          </div>
        </div>
      ) : (
        <div
          className="card-body d-block notification-box"
          style={{ padding: "5px" }}
        >
          {loader && <Loader />}
          {notificationListing?.data?.length > 0 ? (
            notificationListing?.data?.map((item, idx) => (
              <Fragment key={idx}>
                {
                  <div
                    className="row"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    key={idx}
                  >
                    <div className="col-md-1 px-3">
                      <span className="dot"></span>
                    </div>
                    <div className="col-md-9  mt-3">
                      <h6
                        className="text-limit-2-lines notification-container"
                        style={{
                          paddingBottom: "8px",
                          lineSpacing: "1.5px",
                          display: "inline-block",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          width: "280px",
                        }}
                        onClick={() => handleHideShowModel(item)}
                        title={item?.data?.message}
                      >
                        {item?.data?.message}
                      </h6>
                    </div>
                    <div className="col-2 p-0">
                      <span
                        style={{
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setLoader(true);
                          dispatch(DelNotification({ id: item.id }))
                            .then((res) => {
                              dispatch(GetNotifications({ page: 1 })).then(
                                (res) => {
                                  setLoader(false);
                                  setNotifications(res);
                                }
                              );
                            })
                            .catch((err) => {
                              setLoader(false);
                              console.log(err, "err");
                            });
                        }}
                      >
                        <img
                          className="ms-15 notificationStatus"
                          src="/assets/vectors/delete-table.svg"
                          alt="delete"
                          title="delete"
                        />
                      </span>
                    </div>
                    <div className="col-10">
                      <p
                        className={`${
                          item?.read_at === null && "notificationStatus"
                        }`}
                      >
                        {item?.date}
                      </p>
                    </div>
                    <hr className="mt-3 underline-notifications" />
                  </div>
                }
              </Fragment>
            ))
          ) : (
            <p className="mt-4 mb-4 px-3 text-center">No Notification.</p>
          )}
        </div>
      )}

      <div className="card-footer marknotification-topline">
        <div className="row">
          <div className="col-md-8">
            <h5 className="p-3" style={{ fontWeight: 700 }}>
              <a
                className="mark-notification"
                style={{
                  cursor: "pointer",
                }}
                onClick={() => {
                  dispatch(ReadAllNotification({}))
                    .then((res) => {
                      dispatch(GetNotifications({ page: 1 })).then((res) => {
                        setNotifications(res);
                      });
                    })
                    .catch((err) => {
                      console.log(err);
                    });
                }}
              >
                Mark all as read
              </a>
            </h5>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotificationModel;
