import { apiUrls, base_Url } from "./urls.service";
import HttpHandler from "./http.service";

class Services {
  http = "";

  constructor() {
    this.http = HttpHandler(base_Url);
  }

  setCurrentUser = (tokenDetail) => {
    let normalizeValue = JSON.stringify(this.normalizeTokenDetail(tokenDetail));
    localStorage.setItem("userDetails", normalizeValue);
  };

  normalizeTokenDetail = (tokenDetail) => {
    return {
      token: `Bearer ${tokenDetail}`,
    };
  };

  Login(params) {
    return this.http.post(apiUrls.endpoints.login, params);
  }

  SignUp(params) {
    return this.http.post(apiUrls.endpoints.signUp, params);
  }

  otpVerfication(params) {
    return this.http.post(apiUrls.endpoints.otpVerfication, params);
  }

  ForgotPassword(params) {
    return this.http.post(apiUrls.endpoints.forgotPassword, params);
  }

  CompanyType() {
    return this.http.get(apiUrls.endpoints.companyType);
  }

  CreateProject(params) {
    return this.http.post(apiUrls.endpoints.createProject, params);
  }

  CreateStages(params) {
    return this.http.post(apiUrls.endpoints.createStages, params);
  }

  CreateTypes(params) {
    return this.http.post(apiUrls.endpoints.createTypes, params);
  }

  updateProject(params, id) {
    return this.http.put(apiUrls.endpoints.updateProject(id), params);
  }

  getCountryDetail() {
    return this.http.get(apiUrls.endpoints.getCountryDetail);
  }

  ImageUpload(params) {
    return this.http.post(apiUrls.endpoints.ImageUpload, params);
  }
  GetTools() {
    return this.http.get(apiUrls.endpoints.getTools);
  }
  AddTools(params) {
    return this.http.post(apiUrls.endpoints.addTools, params);
  }

  GetCountries() {
    return this.http.get(apiUrls.endpoints.getCountries);
  }
  GetDirectories() {
    return this.http.get(apiUrls.endpoints.getDirectories);
  }
  PostDirectories(params) {
    return this.http.post(apiUrls.endpoints.postDirectories, params);
  }
  GetProjects(params) {
    return this.http.post(apiUrls.endpoints.getProjects, params);
  }
  GetCompanies() {
    return this.http.get(apiUrls.endpoints.getCompanies);
  }
  GetTemplates() {
    return this.http.get(apiUrls.endpoints.getTemplates);
  }
  GetStages() {
    return this.http.get(apiUrls.endpoints.getStages);
  }
  GetTypes() {
    return this.http.get(apiUrls.endpoints.getTypes);
  }
  GetMembers(params) {
    return this.http.get(apiUrls.endpoints.getMembers + `/${params}/members`);
  }

  GetCurrency(params) {
    return this.http.get(apiUrls.endpoints.getCurrency);
  }
  GetStates(params) {
    return this.http.get(apiUrls.endpoints.getCountries + `/${params}/states`);
  }
  GetCities(params) {
    return this.http.get(
      apiUrls.endpoints.getCountries +
        `/${params.countryId}/states/${params.stateId}/cities`
    );
  }
  GetCouontryCode() {
    return this.http.get(apiUrls.endpoints.getCountryCodes);
  }
  ResetPassword(params) {
    return this.http.post(apiUrls.endpoints.resetPassword, params);
  }

  ResetPassword(params) {
    return this.http.post(apiUrls.endpoints.resetPassword, params);
  }

  UpdateUserProfile(params) {
    return this.http.put(apiUrls.endpoints.UpdateUserProfile, params);
  }
  GetMeetings(params, page) {
    return this.http.post(
      apiUrls.endpoints.getMeetings +
        `${params.project_id}/meetings/search?page=${page}`,
      params
    );
  }
  CreateMeetings(params, projectId) {
    return this.http.post(apiUrls.endpoints.createMeetings(projectId), params);
  }

  GetAttendees(params) {
    return this.http.get(
      apiUrls.endpoints.getAttendees + `${params}/meetings/attendees`
    );
  }
  DeleteMeeting(projectId, meetingId) {
    return this.http.delete(
      apiUrls.endpoints.deleteMeeting(projectId, meetingId)
    );
  }
  MeetingDetail(projectId, meetingId) {
    return this.http.get(apiUrls.endpoints.meetingDetail(projectId, meetingId));
  }
  EditMeeting(params, data) {
    return this.http.put(
      apiUrls.endpoints.meetingDetail(data.projectId, data.id),
      params
    );
  }
  EditInvoice(params, data) {
    return this.http.put(
      apiUrls.endpoints.EditInvoice(data.projectId, data.id),
      params
    );
  }
  //
  GetMeetingSerch(params, page) {
    return this.http.post(
      apiUrls.endpoints.getMeetingSerch + `?page=${page}`,
      params
    );
  }
  GetDrawings(params, page) {
    return this.http.post(
      apiUrls.endpoints.getDrawings + `?page=${page}`,
      params
    );
  }
  GetDrawingsCategories() {
    return this.http.get(apiUrls.endpoints.drawingsCategory);
  }
  CreateDrawing(projectid, params) {
    return this.http.post(
      apiUrls.endpoints.createDrawing + `${projectid}/drawings`,
      params
    );
  }
  GetDrawingSets(projectid) {
    return this.http.get(
      apiUrls.endpoints.getDrawingSets + `${projectid}/sets `
    );
  }
  EditDrawings(id, params) {
    return this.http.put(apiUrls.endpoints.editDrawings + `${id}`, params);
  }
  DeleteDrawings(params) {
    return this.http.delete(apiUrls.endpoints.deleteDrawings + `${params}`);
  }
  GetRoles() {
    return this.http.get(apiUrls.endpoints.getRoles);
  }
  ProjectOverview(params) {
    return this.http.get(
      apiUrls.endpoints.projectOverview + `${params}/overview-details`
    );
  }
  CreateMembers(params, id) {
    return this.http.post(
      apiUrls.endpoints.createMember + `${id}/members`,
      params
    );
  }
  DeleteMember(params) {
    return this.http.delete(apiUrls.endpoints.deleteMember + params);
  }

  updateMember(payload, id) {
    return this.http.put(
      apiUrls.endpoints.updateMember + `members/${id}`,
      payload
    );
  }

  //////////////////////////////// Start Todo API //////////////////////////////////////////////////////////////////
  ToDoListing(params, page) {
    return this.http.post(
      apiUrls.endpoints.getToDo + `todos/search?page=${page}`,
      params
    );
  }
  createToDo(params, projectId) {
    return this.http.post(apiUrls.endpoints.createToDo(projectId), params);
  }
  GetAssignee(project_id) {
    return this.http.get(
      apiUrls.endpoints.getAssignee + `${project_id}/todos/assignees`
    );
  }
  DeleteToDo(projectId, todoId) {
    return this.http.delete(apiUrls.endpoints.deleteTodo(projectId, todoId));
  }
  EditTodo(projectId, id, data) {
    return this.http.put(apiUrls.endpoints.EditTodo(projectId, id), data);
  }
  getCategory(project_id) {
    return this.http.get(
      apiUrls.endpoints.getCategory + `${project_id}/todos/categories`
    );
  }

  editCategory(category_id) {
    return this.http.get(
      apiUrls.endpoints.editManagement + `edit-category/${category_id}`
    );
  }

  editStage(stage_id) {
    return this.http.get(
      apiUrls.endpoints.editManagement + `edit-stage/${stage_id}`
    );
  }

  editType(type_id) {
    return this.http.get(
      apiUrls.endpoints.editManagement + `edit-type/${type_id}`
    );
  }

  editTemplate(template_id) {
    return this.http.get(
      apiUrls.endpoints.editManagement + `edit-template/${template_id}`
    );
  }

  updateCategory(payload) {
    return this.http.put(
      apiUrls.endpoints.updateCategoryManagement(payload.id),
      payload
    );
  }

  updateStage(payload) {
    return this.http.put(
      apiUrls.endpoints.updateStageManagement(payload.id),
      payload
    );
  }

  updateType(payload) {
    return this.http.put(
      apiUrls.endpoints.updateTypeManagement(payload.id),
      payload
    );
  }

  updateTemplate(payload) {
    return this.http.put(
      apiUrls.endpoints.updateTemplateManagement(payload.id),
      payload
    );
  }

  deleteCategory(category_id) {
    return this.http.delete(
      apiUrls.endpoints.deleteManagement + `delete-category/${category_id}`
    );
  }

  deleteStage(stage_id) {
    return this.http.delete(
      apiUrls.endpoints.deleteManagement + `delete-stage/${stage_id}`
    );
  }

  deleteType(type_id) {
    return this.http.delete(
      apiUrls.endpoints.deleteManagement + `delete-type/${type_id}`
    );
  }

  deleteTemplate(template_id) {
    return this.http.delete(
      apiUrls.endpoints.deleteManagement + `delete-template/${template_id}`
    );
  }

  createCategory(payload) {
    return this.http.post(
      apiUrls.endpoints.createCategoryManagement(),
      payload
    );
  }

  createStage(payload) {
    return this.http.post(apiUrls.endpoints.createStageManagement(), payload);
  }

  createType(payload) {
    return this.http.post(apiUrls.endpoints.createTypeManagement(), payload);
  }

  createTemplate(payload) {
    return this.http.post(
      apiUrls.endpoints.createTemplateManagement(),
      payload
    );
  }

  ContactForm(params) {
    return this.http.post(apiUrls.endpoints.contactForm, params);
  }
  GetLogsCategories() {
    return this.http.get(apiUrls.endpoints.getLogsCategory);
  }
  CreateDailyLogs(id, params) {
    return this.http.post(apiUrls.endpoints.createDailyLog(id), params);
  }
  GetContractorCompanies() {
    return this.http.get(apiUrls.endpoints.ContractorCompanies);
  }
  GetDailyLogLocation() {
    return this.http.get(apiUrls.endpoints.dailyLogLocation);
  }
  GetDailyLogListing(params, page) {
    return this.http.post(
      apiUrls.endpoints.dailyLogListing + `?page=${page}`,
      params
    );
  }
  DeleteDailyLog(id) {
    return this.http.delete(apiUrls.endpoints.deleteDailyLog(id));
  }

  deleteProjectList(projectId) {
    return this.http.delete(apiUrls.endpoints.deleteProjectList(projectId));
  }
  getProjectDetails(projectId) {
    return this.http.get(apiUrls.endpoints.getProjectDetails(projectId));
  }
  albumListing() {
    return this.http.get(apiUrls.endpoints.albumListing);
  }
  tradeListing() {
    return this.http.get(apiUrls.endpoints.tradeListing);
  }
  locations() {
    return this.http.get(apiUrls.endpoints.locations);
  }
  filterDailyLog(params) {
    return this.http.post(apiUrls.endpoints.filterDailyLog, params);
  }
  GetEquipments() {
    return this.http.get(apiUrls.endpoints.equipments);
  }
  DeliveryComapnies() {
    return this.http.get(apiUrls.endpoints.deliveryCompanies);
  }
  UpdateDailyLog(id, params) {
    return this.http.put(apiUrls.endpoints.UpdateDailyLog(id), params);
  }
  DrawingDetails(id) {
    return this.http.get(apiUrls.endpoints.GetDrawingDetails(id));
  }
  TodoDetails(projectId, id) {
    return this.http.get(apiUrls.endpoints.TodoDetails(projectId, id));
  }
  GetCalendarLogs() {
    return this.http.get(apiUrls.endpoints.getCalendarsLogs);
  }
  CreateEquipment(payload) {
    return this.http.post(apiUrls.endpoints.createEquipment, payload);
  }
  CreateCompanies(payload) {
    return this.http.post(apiUrls.endpoints.createCompanies, payload);
  }
  InvoiceListing(projectId) {
    return this.http.post(apiUrls.endpoints.InvoiceListing, projectId);
  }
  InvoiceItemsCreation(payload, projectId) {
    return this.http.post(
      apiUrls.endpoints.InvoiceItemsCreation(projectId),
      payload
    );
  }

  VendorsListing(payload, projectId) {
    return this.http.post(apiUrls.endpoints.VendorsListing(projectId), payload);
  }

  invoiceCreation(payload, projectId) {
    return this.http.post(
      apiUrls.endpoints.invoiceCreation(projectId),
      payload
    );
  }
  GetInvoiceItems(page, projectId) {
    return this.http.post(
      apiUrls.endpoints.getInvoiceItems + `?page=${page}`,
      projectId
    );
  }
  DeleteInvoice(projectId, invoiceId) {
    return this.http.delete(
      apiUrls.endpoints.DeleteInvoice(projectId, invoiceId)
    );
  }
  updateInvoice(payload, projectId, invoiceId) {
    return this.http.put(
      apiUrls.endpoints.updateInvoice(projectId, invoiceId),
      payload
    );
  }
  GetInvoiceDetail(projectId, invoiceId) {
    return this.http.get(
      apiUrls.endpoints.GetInvoiceDetail(projectId, invoiceId)
    );
  }
  GetNotifications(params) {
    return this.http.get(apiUrls.endpoints.getNotification(params.page));
  }

  GeneratePDF(params) {
    return this.http.post(
      apiUrls.endpoints.generatePdf(params.projectId, params.invoiceId),
      params
    );
  }

  ReadNotificationById(params) {
    return this.http.put(apiUrls.endpoints.readNotificationById(params.id));
  }
  ReadAllNotification() {
    return this.http.put(apiUrls.endpoints.readAllNotification);
  }
  DelNotification(params) {
    return this.http.delete(apiUrls.endpoints.delNotification(params.id));
  }

  projectNotification(params) {
    return this.http.get(
      apiUrls.endpoints.projectNotification(params.projectId)
    );
  }

  CreateEsinature(params) {
    return this.http.post(
      apiUrls.endpoints.createEsinature(params.projectId),
      params.payload
    );
  }

  signatureListing() {
    return this.http.get(apiUrls.endpoints.signatureListing);
  }

  deleteSignature(params) {
    return this.http.delete(
      apiUrls.endpoints.deleteSignature(params.project_id, params.signature_id)
    );
  }

  GetProjectContracts(params) {
    return this.http.post(apiUrls.endpoints.GetProjectContracts, params);
  }

  GetSignaturesRollWise(projectId) {
    return this.http.get(apiUrls.endpoints.GetSignaturesRollWise(projectId));
  }

  GetContractors() {
    return this.http.post(apiUrls.endpoints.GetContractors);
  }
  CreateContrators(params) {
    return this.http.post(apiUrls.endpoints.CreateContrators, params);
  }
  CreateProjectContracts(params) {
    const project_id = params.project_id;
    return this.http.post(
      apiUrls.endpoints.CreateProjectContracts(project_id),
      params
    );
  }

  UpdateProjectContract(params, id) {
    const projectId = params?.project_id;
    return this.http.put(
      apiUrls.endpoints.UpdateProjectContract(projectId, id),
      params
    );
  }

  deleteContracts(params) {
    return this.http.delete(apiUrls.endpoints.deleteContracts(params));
  }

  GetInvoicePaymentList(params) {
    return this.http.post(apiUrls.endpoints.GetInvoicePaymentList, params);
  }

  deleteInvoicePaymentRequest(params) {
    return this.http.delete(
      apiUrls.endpoints.deleteInvoicePaymentRequest(params)
    );
  }

  CreatePaymentRequest(params) {
    const pId = params.projectId;
    const InId = params.invoiceId;
    return this.http.post(
      apiUrls.endpoints.CreatePaymentRequest(pId, InId),
      params
    );
  }

  ProjectStatementExport(params, projectId) {
    return this.http.post(
      apiUrls.endpoints.ProjectStatementExport(projectId),
      params
    );
  }
  invoicepaymentRequest(params) {
    return this.http.get(apiUrls.endpoints.invoicepaymentRequest(params));
  }
  exportPaymentRequest(params) {
    return this.http.post(
      apiUrls.endpoints.exportPaymentRequest(params),
      params
    );
  }
  CreateVendor(params) {
    return this.http.post(apiUrls.endpoints.CreateVendor, params);
  }
  IncidentsListing(params) {
    return this.http.post(apiUrls.endpoints.IncidentsListing, params);
  }

  CreateIncidents(params, projectId) {
    return this.http.post(apiUrls.endpoints.CreateIncidents(projectId), params);
  }

  DeleteIncident(projectId, id) {
    return this.http.delete(apiUrls.endpoints.DeleteIncident(projectId, id));
  }

  ExportIncidents(projectId) {
    return this.http.get(apiUrls.endpoints.ExportIncidents(projectId));
  }

  UpdateIncident(params, projectId, id) {
    return this.http.put(
      apiUrls.endpoints.UpdateIncident(projectId, id),
      params
    );
  }

  GetIncidentDetail(projectId, id) {
    return this.http.get(apiUrls.endpoints.GetIncidentDetail(projectId, id));
  }

  GetAgreementData(params) {
    return this.http.post(
      apiUrls.endpoints.GetAgreementData(params.projectId),
      params
    );
  }

  ExportSubContractorForm(params, id) {
    return this.http.post(
      apiUrls.endpoints.ExportSubContractorForm(id),
      params
    );
  }

  contractorDetail(id, contractorId) {
    return this.http.get(apiUrls.endpoints.contractorDetail(id, contractorId));
  }
  contractsdetail(id) {
    return this.http.get(apiUrls.endpoints.contractsdetail(id));
  }

  FetchContractorTypes() {
    return this.http.post(apiUrls.endpoints.FetchContractorTypes);
  }
}

export const Index = new Services();
