import React, { useEffect } from "react";
import clsx from "clsx";
import Loader from "./Loader";

const ConsentModel = ({ title, active, oncancel, onsubmit, loader }) => {
  return (
    <>
      <div className={clsx("custom-modal-overlay", { active })}></div>
      <div className={clsx("custom-modal consentModal", { active })}>
        <p style={{ marginLeft: "15px" }}>
          Are you sure you want to delete? {title ? <strong>"{title}"</strong> : ""}
        </p>
        <div className="mt-4 d-flex justify-content-end" style={{ marginRight: "25px" }}>
          <button className="btn btn-cancel m-2" onClick={() => oncancel(false)}>
            No
          </button>
          {loader ? (
            <button className="btn btn-primary m-2">
              <Loader />
            </button>
          ) : (
            <button className="btn btn-primary m-2" onClick={() => onsubmit()}>
              Yes
            </button>
          )}
        </div>
      </div>
    </>
  );
};

export default ConsentModel;
