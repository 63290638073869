import React, { useState, useEffect } from "react";
import Modal from "../components/Modal";
import InputDate from "../components/InputDate";
import Input from "../components/Input";
import moment from "moment";
import { todoValidation } from "utilities";
import { useDispatch, useSelector } from "react-redux";
import SelectDropdown from "./SelectDropdown";
import {
  createToDo,
  GetAssignee,
  getCategory,
  EditTodo,
  ToDoListing,
  SetStatus,
} from "app/redux/actions";
import { checkValidation, notifyError, notifySuccess } from "utilities";
import useImageUpload from "hooks/useImageUpload";
import SelectInput from "./SelectInput";
const customStyles = {
  control: () => ({
    width: "100%",
    background: "#f9f9f9",
    display: "flex",
    borderRadius: "5px",
    border: "1px solid #dedee4",
    height: "48px",
    paddingLeft: "10px",
  }),
};
const array = [
  { key: "pending", value: "pending" },
  { key: "completed", value: "completed" },
];
const ToDoModal = (props) => {
  const [isValid, setIsValid] = useState(false);
  const [dataLoader, SetLoadingData] = useState(false);
  const [FormErrors, setErrors] = useState({});
  const dispatch = useDispatch();
  const projectId = useSelector((state) => state.navigation.projectId);
  const {
    Drop,
    dragEnter,
    dragLeave,
    dragOver,
    handleImageUpload,
    Images,
    imageLoader,
    removeFile,
  } = useImageUpload(props?.editData?.attachments);
  const [values, setValues] = useState({
    title: "",
    due_date: "",
    description: "",
    category: "",
    assignee: "",
    status: "",
    categoryId: "",
    assigneeId: "",
  });

  useEffect(() => {
    if (props?.editData !== null) {
      setValues({
        title: props?.editData?.title || "",
        due_date: new Date(props?.editData?.due_date) || "",
        description: props?.editData?.description || "",
        category: props?.editData?.category.title || "",
        categoryId: props?.editData?.category?.id || "",
        assignee: props?.editData?.assignee?.name || "",
        assigneeId: props?.editData?.assignee?.id || "",
        status: props?.editData?.status || "",
      });
    }
  }, []);

  const [assigneList, setAssigne] = useState();
  const [categoryList, setCategory] = useState();

  useEffect(() => {
    dispatch(getCategory(projectId)).then((response) => {
      
      const data = response?.res?.map((item) => {
        const obj = { value: item.title, key: item.id };
        return obj;
      });
      setCategory(data);
    });
    dispatch(GetAssignee(projectId)).then((response) => {
      const data = response?.res?.map((item) => ({
        value: item.name,
        key: item.id,
      }));
      setAssigne(data);
    });
  }, []);

  useEffect(() => {
    if (Object.keys(FormErrors).length === 0 && isValid) {
      SetLoadingData(true);
      const imagesData = Images?.map((image) => image?.url || image?.file);
      const data = {
        title: values.title,
        status: values.status,
        assignee_id: values.assigneeId,
        due_date: moment(values.due_date).format("YYYY-MM-DD hh:mm:ss"),
        category_id: values.categoryId,
        description: values.description,
        attachments: imagesData,
      };

      if (props.editData) {
        dispatch(EditTodo(projectId, props.editData.id, data))
          .then((res) => {
            SetLoadingData(false);
            props.getToDoListing();
            notifySuccess("Todo Updated Successfully!");
            props.hideModal();
          })
          .catch((err) => {
            SetLoadingData(false);
            notifyError(err?.response?.data?.message ?? "Error", 5000);
          });
      } else {
        dispatch(createToDo(data, projectId))
          .then((res) => {
            notifySuccess("Todo Created Successfully!");
            SetLoadingData(false);
            props.getToDoListing();
            props.hideModal();
          })
          .catch((err) => {
            SetLoadingData(false);
            notifyError(err);
          });
      }
    }
  }, [FormErrors]);

  const createTodo = () => {
    setErrors(todoValidation(values, Images));
    setIsValid(true);
  };
  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };
  const dateChangeHandler = (date) => {
    setValues({ ...values, due_date: date });
  };
  const handleSelectChange = (value) => {
    setValues({ ...values, status: value });
  };
  const handleSelectAssignee = (value) => {
    setValues({ ...values, assignee: value.value, assigneeId: value.key });
  };
  const handleSelectCategory = (value) => {
    setValues({ ...values, category: value.value, categoryId: value.key });
  };

  return (
    <Modal
      active={props.isModalActive}
      hideModal={props.hideModal}
      submit={createTodo}
      loader={dataLoader}
      submitText={props?.editData ? "Update" : "Create"}
    >
      <h4 className="mb-4" style={{ fontSize: "28px", fontWeight: "700" }}>
        {props?.editData ? "Edit Todo" : "Create Todo"}
      </h4>

      <Input
        label="Title"
        rootClassName="mt-20"
        icon="/assets/vectors/clip-dark.svg"
        labelClassName="fs-16"
        defaultValue={values?.title}
        withInfo
        tooltipText={"Enter title"}
        type="text"
        placeholder="Enter Title"
        inputCapitalize={"inputCapitalize"}
        errorMsg={FormErrors.title}
        onChange={handleChange("title")}
      />
      <div>
        <SelectInput
          options={array}
          label={"Status"}
          placeholder={"Enter Status"}
          value={values?.status}
          setdrawingset={handleSelectChange}
        />
      </div>

      <div>
        <SelectInput
          options={assigneList}
          label={"Assignee"}
          placeholder={"Enter Assignee"}
          value={values?.assignee}
          setdrawingset={handleSelectAssignee}
          module={"todo"}
        />
      </div>

      <div className="container-fluid px-0">
        <div className="row">
          <div className="col-lg-6">
            <InputDate
              label="Due Date"
              selected={values.due_date}
              errorMsg={FormErrors.dueDate}
              defaultValue={values?.due_date}
              onChange={(date) => dateChangeHandler(date)}
            />
          </div>
          <div className="col-lg-6">
            <SelectInput
              options={categoryList}
              label={"Category"}
              placeholder={"Select Category"}
              value={values?.category}
              setdrawingset={handleSelectCategory}
              module={"todo"}
            />
          </div>
        </div>
      </div>
      <Input
        label="Description"
        rootClassName="mt-20"
        icon="/assets/vectors/clip-dark.svg"
        labelClassName="fs-16"
        withInfo
        tooltipText={"Enter description"}
        defaultValue={values?.description}
        textarea
        errorMsg={FormErrors.description}
        placeholder="Description"
        onChange={handleChange("description")}
      />
      <Input
        label="Attachments"
        rootClassName="mt-20"
        icon="/assets/vectors/clip-dark.svg"
        labelClassName="fs-16"
        onChange={handleImageUpload("todo")}
        onDragOver={dragOver}
        onDragEnter={dragEnter}
        onDragLeave={dragLeave}
        onDrop={Drop}
        withInfo
        tooltipText={"Attach file"}
        id="drawing-images"
        errorMsg={FormErrors.attachments}
        defaultValue={values?.attachments}
        multiple_image={Images}
        type="file"
        imageLoader={imageLoader}
        placeholder="Attach file or Drag &amp; Drop"
        removeFile={removeFile}
      />
    </Modal>
  );
};

export default ToDoModal;
