import React from "react";
import DatePicker from "react-datepicker";
import clsx from "clsx";

import "react-datepicker/dist/react-datepicker.css";

const InputDate = ({
  label,
  withInfo,
  rootClassName,
  tooltipText,
  errorMsg,
  minDate,
  ...rest
}) => {
  return (
    <div className={clsx("custom-form-control", { rootClassName })}>
      <label htmlFor="startDate">
        {label}
        {withInfo && (
          <div className="tooltip">
            <img
              className="ms-2 info"
              src="/assets/vectors/info.svg"
              alt="info"
            />
            <span className="tooltiptext">&nbsp;&nbsp;{tooltipText}</span>
          </div>
        )}
      </label>
      <DatePicker
        placeholderText="MM-DD-YYYY"
        dateFormat="MM-dd-yyyy"
        {...rest}
      />
      <img
        className="calender"
        src="/assets/vectors/calender.svg"
        alt="calender"
      />
      {errorMsg && <p className="error-msg">{errorMsg}</p>}
    </div>
  );
};

export default InputDate;
