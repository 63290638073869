import React from "react";
import {
  Redirect,
  useHistory,
  Switch,
  Route,
  Link,
  useParams,
} from "react-router-dom";
import Footer from "../components/Footer";
import { CreateProjectRoutes } from "Routes/AdminRoutes";
import { useDispatch, useSelector } from "react-redux";
import { RESET_PROJECT } from "app/redux/constants";

const CreationLayout = () => {
  const user = useSelector((state) => state.AuthReducer.user);
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams();
  React.useEffect(() => {
    if (!user || !user.status == 1) {
      history.push("/auth/login");
    }
  }, [user]);

  const GetRoutes = () => {
    return CreateProjectRoutes.map((route, index) => (
      <Route
        path={route.layout + route.path}
        render={() => <route.component />}
        key={index}
      />
    ));
  };
  return (
    <>
      <div className="project-creation-container">
        <Switch>
          {GetRoutes()}
          <Route
            path="*"
            component={() => <Redirect to="/admin/dashboard" />}
          />
        </Switch>
      </div>
      <Footer />
    </>
  );
};

export default CreationLayout;
