import React, { useState } from "react";
import clsx from "clsx";
import { Redirect, Switch, Route, useLocation, useHistory } from "react-router-dom";
import routes from "../Routes/AuthRoutes";
import { useSelector } from "react-redux";
const AuthLayout = (props) => {
  const { pathname } = useLocation();
  const history = useHistory();
  const user = useSelector((state) => state.AuthReducer.user);
  const [data, setData] = useState({
    img: null,
    title: "",
    subTitle: "",
  });
  const GetRoutes = () => {
    return routes.map((route, key) => (
      <Route path={route.layout + route.path} render={() => <route.component />} key={key} />
    ));
  };

  React.useEffect(() => {
    if (user && user.status == 1) {
      if (pathname.includes("reset-password")) {
        return;
      } else {
        return history.push("/admin/dashboard");
      }
    }
  }, [user]);

  const templateData = () => {
    if (pathname === "/auth/login") {
      setData({
        ...data,
        img: "/assets/img/signin.png",
        title: "Sign In",
        subTitle: "Sign in to manage your account",
      });
    }
    if (pathname === "/auth/signup") {
      setData({
        ...data,
        img: "/assets/img/signin.png",
        title: "Sign Up",
        subTitle: "Sign Up to manage your account",
      });
    }
    if (pathname === "/auth/forgot-password") {
      setData({
        ...data,
        img: "/assets/vectors/forgot-pwd.svg",
        title: "Forgot Password?",
        subTitle: "Enter your registered email will send you link for reset password",
      });
    }
    if (pathname === "/auth/reset-password") {
      setData({
        ...data,
        img: "/assets/vectors/reset-pwd.svg",
        title: "Reset Password?",
        subTitle: "&nbsp;",
      });
    }
    if (pathname === "/auth/verification") {
      setData({
        ...data,
        img: "/assets/vectors/verification-code.svg",
        title: "Verification Code",
        subTitle: "We have to sent the code for verification",
      });
    }
  };

  React.useEffect(() => {
    templateData();
  }, [pathname]);
  return (
    <div className={clsx("auth-container", pathname === "/auth/signup")}>
      <div className="left">
        <img
          className="circles circle-left"
          src="/assets/vectors/aut-left-circles.svg"
          alt="aut-left-circles"
        />
        <div className="main">
          <h1>
            Connected <br />
            <span className="text-secondary">Construction:</span> Top Technology Trends
          </h1>
          <img src={data.img} alt="vector" />
        </div>
      </div>
      <div className={clsx(pathname === "/auth/signup", "right")}>
        <img
          className="circles circle-right"
          src="/assets/vectors/auth-right-circles.svg"
          alt="auth-right-circles"
        />
        <div className="main">
          <button className="btn btn-arrow" onClick={history.goBack}>
            <img src="/assets/vectors/back-arrow.svg" alt="back-arrow" />
          </button>
          <h2>{data.title}</h2>
          <p className="mt-2">{data.subTitle}</p>
          <Switch>
            {GetRoutes()}
            <Route path="*" component={() => <Redirect to="/auth/login" />} />
          </Switch>
        </div>
      </div>
    </div>
  );
};

export default AuthLayout;
