import { createLogger } from "redux-logger";
import { LOGIN, SIGNUP, COMPANYTYPE, OTP_VERIFICATION, LOGOUT, LOCATION, UPDATE_USER } from "../constants/index";

const initial_state = {
  user: null,
  companyType: [],
  location: null,
  updateProfile: null,
};

const AuthReducer = (state = initial_state, action) => {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        user: action.payload,
      };
    case SIGNUP:
      return {
        ...state,
        user: action.payload,
      };
    case LOGOUT:
      localStorage.removeItem("userDetails");
      return {
        ...state,
        user: null,
      };
    case OTP_VERIFICATION:
      return {
        ...state,
        user: action.payload,
      };
    case COMPANYTYPE:
      return {
        ...state,
        companyType: action.payload,
      };
    case LOCATION:
      return {
        ...state,
        location: action.payload,
      };
    case UPDATE_USER:
      return{
        ...state,
        updateProfile: action.payload.data,
      };
    default:
      return state;
  }
};

export default AuthReducer;
