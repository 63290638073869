import React, { useState, useEffect } from "react";
import Modal from "../components/Modal";
import Input from "../components/Input";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import {
  CreateVendor,
  InvoiceItemsCreation,
  VendorsListing,
} from "app/redux/actions";
import {
  checkItemValidation,
  notifyError,
  notifySuccess,
  vendorValidation,
} from "utilities";
import PhoneInput from "react-phone-number-input";
import TextEditor from "./textEditor/TextEditor";

const units = [
  { value: 0, label: "Kilogram" },
  { value: 1, label: "Meters" },
  { value: 2, label: "Miles" },
  { value: 3, label: "Yards" },
  { value: 4, label: "Feet" },
  { value: 5, label: "UsSurveyFeet" },
  { value: 6, label: "Inches" },
  { value: 7, label: "Mils" },
  { value: 8, label: "NauticalMiles" },

  { value: 9, label: "Fathoms" },
  { value: 10, label: "Shackles" },
  { value: 11, label: "Microinches" },
  { value: 12, label: "PrinterPoints" },
  { value: 13, label: "DtpPoints" },
  { value: 14, label: "PrinterPicas" },
  { value: 15, label: "DtpPicas" },
  { value: 16, label: "Twips" },
  { value: 17, label: "Hands" },
  { value: 18, label: "AstronomicalUnits" },
  { value: 19, label: "Parsecs" },
  { value: 20, label: "LightYears" },
  { value: 21, label: "SolarRadiuses" },
  { value: 22, label: "Chains" },
  { value: 23, label: "Angstroms" },
  { value: 24, label: "DataMiles" },
  { value: 25, label: "Nanometers" },
  { value: 26, label: "Micrometers" },
  { value: 27, label: "Millimeters" },
  { value: 28, label: "Centimeters" },
  { value: 29, label: "Decimeters" },
  { value: 30, label: "Hectometers" },
  { value: 31, label: "Kilometers" },
  { value: 32, label: "Kiloparsecs" },
  { value: 33, label: "Megaparsecs" },
  { value: 34, label: "KilolightYears" },
  { value: 35, label: "MegalightYears" },
];

const CreateInvoiceItems = (props) => {
  const dispatch = useDispatch();
  const [dataLoader, setDataLoader] = useState(false);
  const [FormErrors, setErrors] = useState({});
  const [isValid, setIsValid] = useState(false);
  const projectId = useSelector((state) => state.navigation.projectId);
  const [vendorListing, setVendorListing] = useState([
    { value: "1", label: "+ Create" },
  ]);

  const [data, setData] = useState({
    name: "",
    unit: "",
    selling_Price: "",
    itemType: "",
    vendorId: "",
  });
  const handleCHange = (prop) => (event) => {
    setData({ ...data, [prop]: event.target.value });
  };

  const handleUnitChange =(event) => {
    setData({ ...data, ["unit"]: event.label });
  };
  const handleTypeChange = (event) => {
    setData({ ...data, itemType: event.target.value });
  };

  useEffect(() => {
    if (Object.keys(FormErrors).length === 0 && isValid) {
      setDataLoader(true);
      const payload = {
        name: data?.name,
        type: data?.itemType,
        unit: data?.unit,
        selling_price: data?.selling_Price,
        vendor_id: data?.vendorId,
      };
      dispatch(InvoiceItemsCreation(payload, projectId))
        .then((res) => {
          props.hideModal();
          props.getItemsListing();
          notifySuccess("Invoice Item Created Successfully");
          setDataLoader(false);
        })
        .catch((err) => {
          setDataLoader(false);
          notifyError(err?.response?.data?.message ?? "Error", 5000);
        });
    }
  }, [FormErrors]);
  const createItems = () => {
    setErrors(checkItemValidation({ ...data }));
    setIsValid(true);
  };

  const [vendorModel, setVendorModel] = useState(false);

  const handleVendors = (event, vendorId) => {
    if (event.value === "1") {
      setVendorModel(true);
    } else {
      setVendorModel(false);
    }
    vendorId = event.key;
    setData({ ...data, vendorId: vendorId });
  };

  const vendorListingData = () => {
    const payload = {
      keywords: "",
    };
    dispatch(VendorsListing(payload, projectId))
      .then((res) => {
        let items = [];
        const data = res.data?.map((vendor) => {
          items.push({
            label: vendor.name,
            key: vendor.id,
          });
        });
        setVendorListing([...items, vendorListing[vendorListing.length - 1]]);
      })
      .catch((err) => {
        notifyError(err?.response?.data?.message ?? "Error", 5000);
      });
  }
  useEffect(() => {
    vendorListingData();
  }, []);


  /*
  vendor end to end functionality
  */

  const [isValidVendor, setIsvalidVendor] = useState(false);

  const userLocation = useSelector((state) => state?.AuthReducer?.location);

  const [vendorFormValidation, setVendorFormValidation] = useState({});

  const [vendor, setVendor] = useState({
    venderName: "",
    vendorEmail: "",
    contact: "",
    location: userLocation?.location?.city ?? "",
  });

  const handleVendor = (name) => (event) => {
    setVendor({ ...vendor, [name]: event?.target?.value });
  };

  const handleVendorPhonNmbr = (value) => {
    setVendor({ ...vendor, ["contact"]: value });
  };
  const [description, setDescription] = useState("");

  const handleDiscriptionChange = (value) => {
    setDescription(value);
  };
  
  const vendorSubmit = () => {
    setVendorFormValidation(vendorValidation(vendor, description));
    setIsvalidVendor(true);
  };

  const [isNew, setIsNew] = useState(false);
  const [selectedVendor, setSelectedVendor] = useState('');
  useEffect(() => {
    if(Object.keys(vendorFormValidation).length === 0 && isValidVendor) {
      setDataLoader(true);
      const payload = {
        name: vendor.venderName,
        location: vendor.location,
        description: description,
        contact_no: vendor.contact,
        email: vendor.vendorEmail,
      };
      dispatch(CreateVendor(payload))
        .then((res) => {
          vendorListingData();
          setIsNew(true);
          setSelectedVendor({key: res?.data?.id, label: res?.data?.name});
          notifySuccess("Vendor Created Successfully!");
          setDataLoader(false);
          setVendorModel(false);
          setVendor({
            venderName: "",
            vendorEmail: "",
            contact: "",
            location: userLocation?.location?.city ?? "",
          });
          setDescription("");
        })
        .catch((error) => {
          notifyError("something went wrong");
          setDataLoader(false);
        });
    }
  }, [vendorFormValidation]);

  const venderDefaultValue = isNew 
  ? {key: selectedVendor?.key, label: selectedVendor?.label} 
  : {key: null, label: "Select Vendor"}

  return (
    <>
      <Modal
        active={props.isModalActive}
        hideModal={props.hideModal}
        submit={vendorModel ? vendorSubmit : createItems}
        loader={dataLoader}
      >
        {props?.editData ? (
          <h4>Edit Itmes</h4>
        ) : (
          <h4 style={{ paddingBottom: "15px" }}>Invoice Items Creation</h4>
        )}
        <Input
          label="Name"
          placeholder="Enter Item Name"
          errorMsg={FormErrors.name}
          onChange={handleCHange("name")}
        />
        <div className="col-lg-12 custom-form-control">
          <label>Unit Type*</label>
          <Select
            options={units}
            className="vendors"
            // value={
            //   units?.find((e) => e.key === data.unit) ?? null
            // }
            onChange={handleUnitChange}
            title={"Units*"}
          />
          {FormErrors.unit && (
            <p className="error" style={{ color: "#dc3545" }}>
              {FormErrors.unit}
            </p>
          )}
        </div>
        <Input
          label="Selling Price"
          placeholder="$ Enter Selling Price"
          inputCapitalize={"inputCapitalize"}
          errorMsg={FormErrors.selling_Price}
          onChange={handleCHange("selling_Price")}
        />
        <div className="container-fluid mt-20 px-0">
          <div className="row">
            <div className="col-lg-12 custom-form-control">
              <label>Item Type</label>
              <select onChange={(e) => handleTypeChange(e)}>
                <option>Select Items Type</option>
                <option value="goods">Goods</option>
                <option value="services">Services</option>
              </select>
              {FormErrors.itemType && (
                <p
                  className="error-msg"
                  style={{ color: "#dc3545", fontSize: "11px" }}
                >
                  {FormErrors.itemType}
                </p>
              )}
            </div>

            <div className="col-lg-12 custom-form-control">
              <label>Vendor</label>
              <Select
                options={vendorListing}
                className="vendors"
                defaultValue={venderDefaultValue}
                value={
                  vendorListing?.find((e) => e.key === data.vendorId) ?? null
                }
                onChange={(e) => handleVendors(e, "vendors")}
                title={"vendors"}
              />
              {FormErrors.invoices && (
                <p className="error" style={{ color: "#dc3545" }}>
                  {FormErrors.invoices}
                </p>
              )}
            </div>
          </div>
        </div>
        {vendorModel ? (
          <div className="mt-4">
            <h4 style={{ paddingBottom: "15px" }}>Create Vendor</h4>
            <Input
              label="Name"
              placeholder="Enter Item Name"
              value={vendor.venderName}
              onChange={handleVendor("venderName")}
              errorMsg={vendorFormValidation.venderName}
            />
            <Input
              label="Email*"
              placeholder="Enter Vendor Email"
              value={vendor?.vendorEmail}
              onChange={handleVendor("vendorEmail")}
              errorMsg={vendorFormValidation.vendorEmail}
            />
            <div className="custom-form-control mb-3">
              <label htmlFor="number">Mobile Number</label>
              <div
                className="phone_number_input"
                style={{ borderRadius: "5px" }}
              >
                <PhoneInput
                  placeholder="Enter phone number"
                  international
                  countryCallingCodeEditable={false}
                  defaultCountry={userLocation?.location?.country?.code}
                  value={vendor.contact}
                  onChange={(value) => handleVendorPhonNmbr(value)}
                />
              </div>
              {vendorFormValidation.contact && (
                <p className="error mb-3">{vendorFormValidation.contact}</p>
              )}
            </div>
            <Input
              label="Location"
              type="text"
              placeholder="Enter Location"
              onChange={handleVendor("location")}
              defaultValue={vendor.location}
              errorMsg={vendorFormValidation.location}
            />
            <div className="px-0 mt-30 select-wrapper custom-form-control">
              <label>Discription</label>
              <TextEditor
                overview={description}
                changeOverview={handleDiscriptionChange}
              />
              {vendorFormValidation?.description && (
                <p
                  className="error"
                  style={{ color: "#dc3545", marginBottom: "10px" }}
                >
                  {vendorFormValidation?.description}
                </p>
              )}
            </div>
          </div>
        ) : null}
      </Modal>
    </>
  );
};

export default CreateInvoiceItems;
