import React from "react";
import CreatableSelect from "react-select/creatable";
const customStyles = {
  control: () => ({
    width: "100%",
    background: "#f9f9f9",
    display: "flex",
    borderRadius: "5px",
    border: "1px solid #dedee4",
    height: "48px",
    paddingLeft: "10px",
    marginBottom: 30,
  }),
};
const CreateableSelect = ({ ...rest }) => {
  return (
    <>
      <label>
        Set <span style={{ color: "red" }}>*</span>
      </label>
      <CreatableSelect {...rest} styles={customStyles} />
    </>
  );
};

export default CreateableSelect;
