import React, { useEffect, useState } from "react";
import EsignatureModal from "components/EsignatureModal";
import { useDispatch, useSelector } from "react-redux";
import { DeleteSignature, SignatureListing } from "app/redux/actions";
import Loader from "components/Loader";

const CoverLetter = (props) => {
    return 
};
export default CoverLetter;
