import Home from "../screens/Landing";

var routes = [
  {
    path: "/",
    name: "Landing Page",
    mini: "LP",
    component: Home,
  },
];

export default routes;
