import { DeleteIncident, ExportIncidents, IncidentsListing } from "app/redux/actions";
import ConsentModel from "components/ConsentModel";
import IncidentModel from "components/IncidentModel";
import Loader from "components/Loader";
import { isEmpty } from "lodash";
import React, { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
import { notifyError, notifySuccess } from "utilities";

const Inccidents = (props) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const [incidentModel, setIncidentModel] = useState(false);
  const [loader, setLoader] = useState(false);
  const [incidentList, setIncidentList] = useState([]);
  const projectId = useSelector((state) => state.navigation.projectId);

  useEffect(() => {
    props.set_Value({
      title: props.title,
      rootClassName: props.rootClassName,
      mainClassName: props.mainClassName,
    });
    getIncidentsListing();
  }, []);

  const getIncidentsListing = () => {
    setLoader(true);
    dispatch(IncidentsListing({ project_id: id }))
      .then((res) => {
        setIncidentList(res.data);
        setLoader(false);
      })
      .catch((error) => {
        setLoader(false);
      });
  };

  const handleIncidentModel = () => {
    setIncidentModel(true);
  };
  const hideIncidentModel = () => {
    setIncidentModel(false);
    setUpdateData(null);
  };
  const [deleteActive, setDeleteActive] = useState(false);
  const [deletedData, setDeletedData] = useState();
  const [deleteloader, setDeleteLoader] = useState(false);
  const handleDelete = (data) => {
    setDeleteActive(true);
    setDeletedData(data);
  };

  const deleteIncident = () => {
    setDeleteLoader(true);
    dispatch(DeleteIncident(id, deletedData.id))
      .then((res) => {
        notifySuccess("Incident deleted successfully");
        getIncidentsListing();
        setDeleteLoader(false);
        setDeleteActive(false);
      })
      .catch((err) => {
        setDeleteLoader(false);
        notifyError(
          err?.response?.data?.message ?? "Error Deleting Incident Id",
          5000
        );
      });
  };

  const handleExport = () => {
    dispatch(ExportIncidents(projectId))
      .then((res) => {
        window.open(res?.link, "_blank");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  /*
  update incident functionality:
  */
  const [updateData, setUpdateData] = useState(null);
  const handleUpdateIncident = (data) => {
    setUpdateData(data);
    setIncidentModel(true);
  };
  return (
    <>
      {incidentModel && (
        <IncidentModel
          active={incidentModel}
          hideModal={hideIncidentModel}
          projectId={id}
          incidentListing={getIncidentsListing}
          editData={updateData}
          setEditData={setUpdateData}
          titleText={updateData ? "Update Incident" : "Create Incident"}
        />
      )}
      {deleteActive && (
        <div className="px-0 mt-30">
          <ConsentModel
            active={deleteActive}
            id={deletedData?.id}
            onsubmit={deleteIncident}
            oncancel={() => setDeleteActive(false)}
            loader={deleteloader}
            title={deletedData?.case_number}
          />
        </div>
      )}
      <div className="top" style={{ borderBottom: "none" }}>
        <div className="tabs"></div>
        <div className="options d-flex">
          <div>
          <button
            onClick={handleExport}
            // onClick={showExportModal}
            className="ms-10 btn btn-primary"
            style={{ background: "#FFF2D9", color: "#E79436" }}
          >
            EXPORT
          </button>
          </div>
          <button
            className="ms-10 btn btn-primary-outlined"
            onClick={handleIncidentModel}
          >
            <img src="/assets/vectors/add.svg" alt="add" />
            NEW INCIDENT
          </button>
        </div>
      </div>
      <div className="main">
        <div className="listing mt-20">
          <div className="table-container">
            <table>
              <thead>
                <tr>
                  <th style={{ maxWidth: "7%" }}>Case#</th>
                  <th style={{ maxWidth: "10%" }}>Employee Name</th>
                  <th style={{ maxWidth: "10%" }}>Job Title</th>
                  <th style={{ maxWidth: "10%" }}>Location</th>
                  <th style={{ maxWidth: "10%" }}>Leave Days</th>
                  <th style={{ maxWidth: "10%" }}>Case Type</th>
                  <th style={{ maxWidth: "10%" }}>Injury Date</th>
                  <th style={{ maxWidth: "10%" }}>Type Of Illness</th>
                  <th style={{ maxWidth: "10%" }} className="text-center">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {loader ? (
                  <tr>
                    <td colSpan={4}>
                      <Loader />
                    </td>
                  </tr>
                ) : isEmpty(incidentList) ? (
                  <tr>
                    <td>No Records Found</td>
                  </tr>
                ) : (
                  <>
                    {incidentList.map((incident, index) => {
                      return (
                        <tr key={index}>
                          <td style={{ width: "25%", maxWidth: "25%" }}>
                            <a
                              style={{ cursor: "pointer" }}
                                onClick={() =>
                                  history.push(
                                    `/admin/project/${id}/tool/incidents/${incident?.id}`
                                  )
                                }
                            >
                               {incident?.case_number}
                            </a>
                          </td>
                          <td style={{ width: "10%", maxWidth: "15%" }}>
                            {incident?.employee_name}
                          </td>
                          <td style={{ width: "10%", maxWidth: "15%" }}>
                            {incident?.job_title}
                          </td>
                          <td style={{ width: "10%", maxWidth: "15%" }}>
                            {incident?.location}
                          </td>
                          <td style={{ width: "10%", maxWidth: "15%" }}>
                            {incident?.leave_days}
                          </td>
                          <td style={{ width: "10%", maxWidth: "15%" }}>
                            {incident?.case_type}
                          </td>
                          <td style={{ width: "10%", maxWidth: "15%" }}>
                            {new Date(
                              incident?.date_of_injury
                            ).toLocaleDateString()}
                          </td>
                          <td style={{ width: "10%", maxWidth: "15%" }}>
                            {incident?.type_of_illness}
                          </td>
                          <td
                            className="text-center"
                            style={{ width: "20%", maxWidth: "20%" }}
                          >
                            <img
                              className="me-10"
                              src="/assets/vectors/edit-table.svg"
                              alt="edit-table"
                              style={{ cursor: "pointer" }}
                              onClick={() => handleUpdateIncident(incident)}
                            />
                            <img
                              className="me-10"
                              src="/assets/vectors/delete-table.svg"
                              alt="delete-table"
                              style={{ cursor: "pointer" }}
                              onClick={() => handleDelete(incident)}
                            />
                          </td>
                        </tr>
                      );
                    })}
                  </>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};
export default Inccidents;
