import React from "react";
import { useEffect } from "react";
import { Link, useParams } from "react-router-dom";

const Material = (props) => {
  const { id } = useParams();
  const meterial = JSON.parse(localStorage.getItem("vendorData"));
  
  useEffect(() => {
    props.set_Value({
      title: props.title,
      rootClassName: props.rootClassName,
      mainClassName: props.mainClassName,
    });
  }, []);

  return (
    <>
      {/* Bread Crumbs */}
      <div className="top pb-20" style={{ borderBottom: "none" }}>
        <div className="breadcrumb">
          <Link to={`/admin/project/${id}`} className="breadcrumb-item">
            Dashboard
          </Link>
          <Link
            to={`/admin/project/${id}/exportStatment`}
            className="breadcrumb-item"
          >
            Export Statment
          </Link>
          <div className="breadcrumb-item">Material</div>
        </div>
      </div>
      {/* End Bread Crumbs */}
      {/* Export Section */}
      <div className="top pb-20 mb-20 mt-20" style={{ borderBottom: "none" }}>
        <div className="section-title">
          <h4>Project Statment</h4>
        </div>
        <div className="options d-flex align-items-right">
          {/* <button
            className="ms-10 btn btn-primary"
            style={{ background: "#FFF2D9", color: "#E79436" }}
          >
            EXPORT
          </button> */}
        </div>
      </div>
      {/* End Export Section */}
      {/* Sub Contractor Section */}
      <div className="section">
        <div className="section-title mb-10">
          <h4>Materials</h4>
        </div>
        <div className="table-container">
          <table className="team">
            <thead>
              <tr>
                <th colSpan={2}>Date</th>
                <th className="text-center" colSpan={4}>
                  Description
                </th>
                <th className="text-center">Amount</th>
              </tr>
            </thead>
            <tbody>
              {meterial.vendorsDetail.length > 0 ? (
                <>
                  {meterial.vendorsDetail.map((data, index) => {
                    const date = new Date(data?.created_at);
                    return (
                      <tr key={index}>
                        <td colSpan={2}>{date?.toLocaleDateString()}</td>
                        <td className="text-center" colSpan={4}>
                          {data?.name}
                        </td>
                        <td className="text-center">$ {data?.selling_price}</td>
                      </tr>
                    );
                  })}
                </>
              ) : (
                <tr>
                  <td>Record Not Found</td>
                </tr>
              )}
              <tr>
                <td
                  style={{
                    textAlign: "center",
                    color: "#2F5597",
                    fontWeight: "bold",
                  }}
                  colSpan={6}
                >
                  Total
                </td>
                <td style={{ textAlign: "center", fontWeight: "bold" }}>
                  $ {meterial?.vendors ?? "0"}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      {/* End Sub Contractor Section */}
    </>
  );
};

export default Material;
