import React, { useEffect, useState } from "react";
import TodoChangeHistory from "components/TodoChangeHistory";
import { useDispatch } from "react-redux";
import { notifySuccess, notifyError } from "utilities";
import { GetTodoDetails, DeleteToDo, ToDoListing } from "app/redux/actions";
import { useParams, useHistory } from "react-router";
import Loader from "components/Loader";
import ConsentModel from "components/ConsentModel";
import { Link } from "react-router-dom";
import moment from "moment";
const TodoDetails = (props) => {
  const [loader, setLoader] = useState(false);
  const history = useHistory();
  const projectId = props?.ProjectDefault;
  const [deleteActive, setdeleteActive] = useState(false);
  const [DeleteData, setDeleteData] = useState();
  const { id } = useParams();
  const dispatch = useDispatch();
  const [todoDetail, setTodoDetail] = useState();
  const [deleteloader, setDeleteLoader] = useState(false);
  const getTodoDetails = () => {
    setLoader(true);
    dispatch(GetTodoDetails(projectId, id))
      .then((res) => {
        setTodoDetail(res.data);
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
        notifyError(err?.error?.response?.data?.message ?? "Error", 5000);
      });
  };
  const handleDelete = (data) => {
    setdeleteActive(true);
    setDeleteData(data);
  };

  const deleteToDo = () => {
    setDeleteLoader(true);
    dispatch(DeleteToDo(projectId, id))
      .then((res) => {
        ToDoListing();
        setDeleteLoader(false);
        setdeleteActive(false);
        notifySuccess("Todo deleted successfully");
        history.push(`/admin/project/${projectId}/tool/todos`);
      })
      .catch((err) => {
        setDeleteLoader(false);
        notifyError(err?.response?.data?.message ?? "Error Deleting Meeting Id", 5000);
      });
  };
  useEffect(() => {
    props.set_Value({
      title: todoDetail?.title,
      rootClassName: props.rootClassName,
      mainClassName: props.mainClassName,
    });
  }, [todoDetail?.title]);
  useEffect(() => {
    getTodoDetails();
  }, []);
  return (
    <div>
      <div className="top pb-20" style={{ borderBottom: "none", marginLeft: "10px" }}>
        <div
          className="breadcrumb tododetail-breadcrumb"
        >
          <Link
            to={`/admin/project/${projectId}/tool/todos`}
            className="breadcrumb-item"
            style={{ fontSize: "15px", textDecoration: "none", fontWeight: "bold" }}
          >
            ToDo
          </Link>
          <div className="breadcrumb-item" style={{ fontSize: "15px" }}>
            {todoDetail?.title}
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-8">
          <div className="card-left m-10">
            <div className="table-container">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  backgroundColor: "#F9F9F9",
                 
                }}
              >
                <div className="mt-3 mb-3">
                  <h3 className="px-3">General Information</h3>
                </div>
                <div className="mt-3 mb-3">
                  <img
                    className="me-10 tododetail-delete px-3"
                    src="/assets/vectors/delete-table.svg"
                    alt="delete-table"
                    onClick={() => handleDelete(id)}
                  />
                  {deleteActive && (
                    <div className="px-0 mt-30">
                      <ConsentModel
                        active={deleteActive}
                        id={DeleteData?.id}
                        onsubmit={deleteToDo}
                        oncancel={() => setdeleteActive(false)}
                        loader={deleteloader}
                        title={DeleteData.title}
                      />
                    </div>
                  )}
                </div>
              </div>
              {loader ? (
                <Loader />
              ) : (
                <>
                  <div className="Todo_heading">
                    <h3>{todoDetail?.title}</h3>
                    <p>{todoDetail?.description}</p>
                  </div>
                  <div className="row m-2 table-heading">
                    <div className="col-md-4 headings">Assignee:</div>
                    <div className="col-md-4 headings">Created By:</div>
                    <div className="col-md-4 headings">Created At:</div>
                  </div>
                  <div className="row m-2 table-description">
                    <div className="col-md-4">{todoDetail?.assignee.name}</div>
                    <div className="col-md-4">{todoDetail?.created_by}</div>
                    <div className="col-md-4">{todoDetail?.created_at}</div>
                  </div>
                  <div className="row m-2 table-heading">
                    <div className="col-md-4 headings">Due Date:</div>
                    <div className="col-md-4 headings">Status:</div>
                  </div>
                  <div className="row m-2 table-description">
                    <div className="col-md-4">{moment(todoDetail?.due_date).format("DD/MM/YYYY")}</div>
                    <div className="col-md-4">{todoDetail?.status}</div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="card-left m-10">
            <div className="table-container">
              <div>
                <TodoChangeHistory data={todoDetail?.changeHistory} loader={loader} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default TodoDetails;
