import React from "react";
const Loader = ({ size }) => {
  return (
    // <div class="lds-ripple">
    //   <div></div>
    //   <div></div>
    // </div>
    <div className="loader" style={{ width: size, height: size }}></div>
  );
};

export default Loader;
