import React, {
  forwardRef,
  useState,
  useImperativeHandle,
  useEffect,
} from "react";
import Input from "../components/Input";
import InputDate from "../components/InputDate";
import { useSelector } from "react-redux";
import {
  CreateProject,
  GetCities,
  getProjectDetails,
  GetStates,
  updateProject,
} from "../app/redux/actions";
import { useDispatch } from "react-redux";
import { notifyError, notifySuccess } from "../utilities";
import moment from "moment";
import { useHistory, useParams } from "react-router-dom";
import useImageUpload from "hooks/useImageUpload";
import TimezoneSelect from "react-timezone-select";
import CreateStage from "../components/CreateStage";

import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { deformatNumber } from "components/common/Utilities";
import { NumberInput } from "components/common/NumberInput";

const customStyles = {
  control: () => ({
    border: "1px solid #dedee4",
    borderRadius: "5px",
    background: "transparent",
    padding: "5px 7px",
    display: "flex",
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = "opacity 300ms";
    return { ...provided, opacity, transition };
  },
};

const ProjectDetails = forwardRef(({ setStepState, SetLoadingData }, ref) => {
  const history = useHistory();
  const [isEdit, setIsEdit] = useState(false);

  const [isStageModalActive, setIsSatgeModalActive] = useState(false);
  const recentProjectId = localStorage.getItem("recentProjectId");
  const location = useSelector((state) => state.AuthReducer.location);
  const [FormErrors, setFormErrors] = useState({});
  const [activeStage, setActiveStage] = useState(null);
  const dispatch = useDispatch();
  const state = useSelector((state) => state.AuthReducer.user);
  const { id } = useParams();
  const user = useSelector((state) => state.AuthReducer.user);
  const companyId = useSelector((state) => state.Project.allCompanies[0].id);
  const templates = useSelector((state) => state.Project.Templates);
  const stages = useSelector((state) => state.Project.stages);
  const types = useSelector((state) => state.Project.types);
  const countries = useSelector((state) => state.Project.countries);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [IsValid, setIsValid] = useState(false);
  const [type, setType] = useState(false);

  useEffect(() => {
    if (history.location.pathname.includes(`/create/project/${id}/edit`)) {
      setIsEdit(true);
    }
  }, [id]);

  const [defaultCountry, setDefaultCountry] = useState(
    countries.find((c) => c.id === 233)
  );

  const [formState, setFormState] = useState({
    startDate: "",
    endDate: "",
  });

  const [projectValues, setProjectValues] = useState({
    template: "",
    Name: "",
    totalValue: "",
    type: "",
    projectNo: "",
    squareFeet: "",
    description: "",
    country: defaultCountry?.id,
    timezone: Intl.DateTimeFormat(undefined, {
      timeZone: "America/Chicago",
    }).resolvedOptions().timeZone,
    address: "",
    city: "",
    state: "",
    zipcode: "",
    phone: "",
    region: "",
    language: "",
    stage: "",
    testProject: "0",
    logo: null,
    photo: null,
    updateState: false,
  });

  // HANDLER FOR IMAGES RENDERING STARTING
  const {
    logo,
    logoLoader,
    Drop,
    dragEnter,
    dragLeave,
    dragOver,
    photo,
    photoLoader,
    handleImageUpload,
  } = useImageUpload(projectValues);

  // HANDLER FOR IMAGES RENDERING ENDING

  const handleRemoveError = (name) => {
    setFormErrors({
      ...FormErrors,
      [name]: "",
    });
  };

  const dateChangeHandler = (name, date) => {
    setFormState((prevState) => {
      return { ...prevState, [name]: date };
    });
    handleRemoveError(name);
  };

  const handlePhoneInput = (value) => {
    setProjectValues({ ...projectValues, phone: value });
    handleRemoveError("phone");
  };
  const handleNumberInput = (event) => {
    const { name, value } = event.target;
    setProjectValues({ ...projectValues, [name]: deformatNumber(value) });
    handleRemoveError(name);
  };

  const handleChange = (prop) => (event) => {
    if (prop === "stage" && event.target.value === "create") {
      showModal();
      return;
    }
    if (prop === "type" && event.target.value === "create") {
      showTypeModal();
      return;
    }
    setProjectValues({ ...projectValues, [prop]: event.target.value });
    if (prop === "country") {
      dispatch(GetStates(event.target.value)).then((res) => {
        setStates(res);
      });
    }
    if (prop === "state") {
      const payload = {
        countryId: projectValues.country,
        stateId: event.target.value,
      };
      dispatch(GetCities(payload))
        .then((res) => {
          setCities(res);
        })
        .catch((err) => console.log(err));
    }
    handleRemoveError(prop);
  };

  const handleTimeZone = (e) => {
    setProjectValues({ ...projectValues, timezone: e.value });
    handleRemoveError("timezone");
  };

  const validate = (value) => {
    let error = {};
    if (!value.template) {
      error.template = "Template is required";
    }
    if (!value.Name) {
      error.Name = "Name is required";
    }
    if (!value.totalValue) {
      error.totalValue = "Total Value is required";
    }
    if (!formState.startDate) {
      error.startDate = "Start Date is required";
    }
    if (!formState.endDate) {
      error.endDate = "End Date is required";
    } else if (formState.endDate < formState.startDate) {
      error.endDate = "End Date should be greater than Start Date.";
    }
    if (!value.stage) {
      error.stage = "End Date is required";
    }
    if (!value.type) {
      error.type = "Type is required";
    }
    if (!value.projectNo) {
      error.projectNo = "Project No is required";
    }
    if (!value.squareFeet) {
      error.squareFeet = "Square Feet is required";
    }
    if (!value.description) {
      error.description = "Description is required";
    }
    // if (!logo) {
    //   error.logo = "Logo is required";
    // }
    // if (!photo) {
    //   error.photo = "Photo is required";
    // }
    if (!value.address) {
      error.address = "Address is required";
    }
    if (!value.city) {
      error.city = "City is required";
    }
    if (!value.state) {
      error.state = "State is required";
    }
    if (!value.zipcode) {
      error.zipcode = "Zip Code is required";
    }
    if (!value.phone) {
      error.phone = "Phone Number is required";
    }
    // if (!value.region) {
    //   error.region = "Region is required";
    // }
    // if (!value.language) {
    //   error.language = "Language is required";
    // }
    if (!value.country) {
      error.country = "Country is required";
    }
    if (!value.timezone) {
      error.timezone = "Time zone is required";
    }
    if (Object.keys(error).length > 0) {
      notifyError("Please fill all required fields");
    }
    return error;
  };

  useEffect(() => {
    if (history.location.pathname.includes(`/create/project/${id}/edit`)) {
      dispatch(getProjectDetails(recentProjectId))
        .then((res) => {
          const { data } = res;

          setProjectValues({
            template: data?.template?.id || data?.template?.title || "",
            Name: data?.name || "",
            totalValue: data?.cost || "",
            type: data?.type?.id || "",
            projectNo: data?.project_number || "",
            squareFeet: data?.square_feet || "",
            description: data?.description || "",
            country: data?.country?.id || "",
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone || "",
            address: data?.address || "",
            city: data?.city?.id || "",
            state: data?.state?.id || "",
            zipcode: data?.zip_code || "",
            phone: state.telephone ?? "",
            region: data?.region || "",
            language: data?.lang || "",
            stage: data?.stage?.id || data?.stage?.name || "",
            testProject: "0",
            photo: data?.photo || "",
            logo: data?.logo || "",
            updateState: true,
          });

          setFormState({
            startDate: new Date(data?.start_date) || "",
            endDate: new Date(data?.completion_date) || "",
          });
        })
        .catch((error) => {
          console.log(error, "error");
        });
    }
  }, []);

  useEffect(() => {
    if (projectValues?.country) {
      dispatch(GetStates(projectValues?.country)).then((res) => {
        setStates(res);
      });
    }
    const payload = {
      countryId: projectValues?.country,
      stateId: projectValues?.state,
    };
    dispatch(GetCities(payload)).then((res) => {
      setCities(res);
    });
  }, [projectValues]);

  useEffect(() => {
    if (projectValues?.country && projectValues?.state) {
      const payload = {
        countryId: projectValues?.country,
        stateId: projectValues?.state,
      };
      dispatch(GetCities(payload)).then((res) => {
        setCities(res);
      });
    }
  }, [projectValues]);

  useEffect(() => {
    if (IsValid && Object.keys(FormErrors).length === 0) {
      const payload = {
        template_id: parseInt(projectValues.template),
        name: projectValues.Name,
        value: projectValues.totalValue,
        start_date: moment(formState.startDate).format("YYYY-MM-DD"),
        completion_date: moment(formState.endDate).format("YYYY-MM-DD"),
        stage_id: parseInt(projectValues.stage),
        type_id: parseInt(projectValues.type),
        project_number: projectValues.projectNo,
        square_feet: projectValues.squareFeet,
        description: projectValues.description,
        country_id: parseInt(projectValues.country),
        city_id: parseInt(projectValues.city),
        state_id: parseInt(projectValues.state),
        timezone: projectValues.timezone,
        address: projectValues.address,
        zip_code: projectValues.zipcode,
        phone: projectValues.phone,
        region: projectValues.region,
        lang: projectValues.language,
        test_project: projectValues.testProject.toString(),
        company_id: user?.companies[0]?.id ?? companyId,
        logo: logo,
        photo: photo,
      };
      if (
        isEdit &&
        recentProjectId &&
        history.location.pathname.includes(`/create/project/${id}/edit`)
      ) {
        SetLoadingData(true);
        dispatch(updateProject(payload, recentProjectId))
          .then((res) => {
            notifySuccess("Project Updated Successfully!");
            setProjectValues({});
            history.push(`/admin/dashboard`);
            SetLoadingData(false);
          })
          .catch((error) => {
            SetLoadingData(false);
          });
      } else {
        SetLoadingData(true);
        dispatch(CreateProject(payload))
          .then((res) => {
            SetLoadingData(false);
            notifySuccess("Project created successfully");
            if (res?.payload) {
              history.push(`/create/project/ ${parseInt(id) + 1}`);
              setStepState((prevState) => {
                if (prevState === 2) {
                  return prevState;
                } else {
                  return prevState + 1;
                }
              });
            }
          })
          .catch((err) => {
            notifyError(err?.payload?.response?.data?.message ?? "Error", 5000);
            SetLoadingData(false);
          });
      }
    }
  }, [FormErrors]);

  const Submit = (e) => {
    setFormErrors(validate(projectValues));
    setIsValid(true);
  };

  useImperativeHandle(ref, () => ({
    Submit,
  }));

  const showModal = () => {
    setIsSatgeModalActive(true);
    setType(false);
  };

  const showTypeModal = () => {
    setIsSatgeModalActive(true);
    setType(true);
  };

  const hideModal = () => {
    setIsSatgeModalActive(false);
  };

  return (
    <>
      {isStageModalActive && (
        <CreateStage
          activestage={activeStage}
          setactivestage={setActiveStage}
          hideModal={hideModal}
          active={isStageModalActive}
          type={type}
        />
      )}

      <div className="project">
        <div className="sub-section">
          <h3>{isEdit ? "Update Project" : "Project Details"}</h3>
          <div className="custom-form-control margin-bottom-30">
            <label htmlFor="lastName">Template</label>
            <select
              value={projectValues.template}
              onChange={handleChange("template")}
            >
              <option value="">Select Template</option>
              {templates?.length &&
                templates?.map((item) => {
                  return (
                    <option value={item.id} key={item.id}>
                      {item.title}
                    </option>
                  );
                })}
            </select>
            {FormErrors.template && (
              <p className="error">{FormErrors.template}</p>
            )}
          </div>

          <div className="container-fluid px-0">
            <div className="row">
              <div className="col-lg-6">
                <Input
                  withInfo
                  tooltipText={"Project Name"}
                  inputCapitalize="inputCapitalize"
                  id="name"
                  label="Name*"
                  placeholder="Enter Name"
                  defaultValue={projectValues.Name}
                  onChange={handleChange("Name")}
                />
                {FormErrors.Name && <p className="error">{FormErrors.Name}</p>}
              </div>
              <div className="col-lg-6">
                <NumberInput
                  lable="Total Value"
                  placeholder="Enter Cost In $"
                  name="totalValue"
                  value={projectValues.totalValue}
                  required={true}
                  errorMsg={FormErrors?.totalValue}
                  rootClassName=""
                  onChange={handleNumberInput}
                />
              </div>
            </div>
          </div>

          <div className="container-fluid px-0">
            <div className="row">
              <div className="col-lg-6">
                <InputDate
                  withInfo
                  tooltipText={"Project Start Date"}
                  selected={formState.startDate}
                  label="Start Date*"
                  onChange={(date) => dateChangeHandler("startDate", date)}
                />
                {FormErrors.startDate && (
                  <p className="error">{FormErrors.startDate}</p>
                )}
              </div>
              <div className="col-lg-6">
                <InputDate
                  withInfo
                  tooltipText={"Project Completion Date"}
                  selected={formState.endDate}
                  label="Completion Date*"
                  onChange={(date) => dateChangeHandler("endDate", date)}
                />
                {FormErrors.endDate && (
                  <p className="error">{FormErrors.endDate}</p>
                )}
              </div>
            </div>
          </div>

          <div className="container-fluid px-0">
            <div className="row">
              <div className="col-lg-6">
                <div className="custom-form-control">
                  <label htmlFor="stage">Stage</label>
                  <select
                    value={projectValues?.stage}
                    onChange={handleChange("stage")}
                  >
                    <option value="0">Select Stage</option>
                    {stages &&
                      stages?.map((stage) => {
                        return (
                          <option value={stage.id} key={stage.id}>
                            {stage.name}
                          </option>
                        );
                      })}
                    <option value="create">create</option>
                  </select>
                  {FormErrors.stage && (
                    <p className="error">{FormErrors.stage}</p>
                  )}
                </div>
              </div>
              <div className="col-lg-6">
                <div className="custom-form-control">
                  <label htmlFor="type">Type</label>
                  <select
                    value={projectValues.type}
                    onChange={handleChange("type")}
                  >
                    <option value="">Select Type</option>
                    {types &&
                      types?.map((type) => {
                        return (
                          <option value={type.id} key={type.id}>
                            {type.name}
                          </option>
                        );
                      })}
                    <option value="create">create</option>
                  </select>
                  {FormErrors.type && (
                    <p className="error">{FormErrors.type}</p>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="container-fluid px-0">
            <div className="row">
              <div className="col-lg-6">
                <Input
                  withInfo
                  tooltipText={"Project Number"}
                  id="projectNumber"
                  label="Project Number"
                  placeholder="Enter Project Number"
                  defaultValue={projectValues.projectNo}
                  onChange={handleChange("projectNo")}
                />
                {FormErrors.projectNo && (
                  <p className="error">{FormErrors.projectNo}</p>
                )}
              </div>
              <div className="col-lg-6">
                <Input
                  id="squareFeet"
                  type="number"
                  label="Square Feet"
                  placeholder="Enter Square Feet"
                  defaultValue={projectValues.squareFeet}
                  onChange={handleChange("squareFeet")}
                />
                {FormErrors.squareFeet && (
                  <p className="error">{FormErrors.squareFeet}</p>
                )}
              </div>
            </div>
          </div>
          <div className="margin-bottom-30">
            <Input
              rows={5}
              textarea
              id="description"
              label="Description"
              placeholder="Enter Description"
              defaultValue={projectValues.description}
              onChange={handleChange("description")}
            />
            {FormErrors.description && (
              <p className="error">{FormErrors.description}</p>
            )}
          </div>

          <div className="container-fluid px-0">
            <div className="row">
              <div className="col-lg-6 image_upload">
                <Input
                  withInfo
                  id="logo"
                  type="file"
                  label="Logo"
                  placeholder="Click to Upload Drag and drop image here"
                  onChange={handleImageUpload("logo")}
                  onDragOver={dragOver}
                  onDragEnter={dragEnter}
                  onDragLeave={dragLeave}
                  onDrop={Drop("logo")}
                  Uploaded_image={logo}
                  className="text-center"
                  loader={logoLoader}
                />
                {FormErrors.logo && <p className="error">{FormErrors.logo}</p>}
              </div>
              <div className="col-lg-6 image_upload">
                <Input
                  withInfo
                  tooltipText={"Logo"}
                  id="photo"
                  type="file"
                  label="Photo"
                  placeholder="Click to Upload Drag and drop image here"
                  onChange={handleImageUpload("photo")}
                  onDragOver={dragOver}
                  onDragEnter={dragEnter}
                  onDragLeave={dragLeave}
                  onDrop={Drop("photo")}
                  Uploaded_image={photo}
                  loader={photoLoader}
                />
                {FormErrors.photo && (
                  <p className="error">{FormErrors.photo}</p>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="sub-section">
          <h3>Project Location</h3>
          <div className="container-fluid px-0">
            <div className="row">
              <div className="col-lg-6 custom-form-control">
                <label htmlFor="">Country</label>
                <select
                  value={projectValues.country}
                  onChange={handleChange("country")}
                >
                  <option value={defaultCountry?.id}>
                    {defaultCountry?.name}
                  </option>
                  {countries &&
                    countries?.map((country) => {
                      return (
                        <option value={country.id} key={country.id}>
                          {country.name}
                        </option>
                      );
                    })}
                </select>
                {FormErrors.country && (
                  <p className="error">{FormErrors.country}</p>
                )}
              </div>
              <div className="col-lg-6 custom-form-control">
                <label htmlFor="">Time Zone</label>
                <TimezoneSelect
                  styles={customStyles}
                  selected={projectValues.timezone}
                  value={{
                    label: projectValues.timezone,
                    value: projectValues.timezone,
                  }}
                  onChange={handleTimeZone}
                />
                {FormErrors.timezone && (
                  <p className="error">{FormErrors.timezone}</p>
                )}
              </div>
            </div>
          </div>
          <div className="container-fluid px-0">
            <div className="row">
              <div className="col-lg-4">
                <div className="custom-form-control">
                  <label htmlFor="state">State</label>
                  <select
                    value={projectValues.state}
                    onChange={handleChange("state")}
                  >
                    <option value={states ? states[54]?.id : ""}>
                      {states ? states[54]?.name : "Select State"}
                    </option>
                    {states &&
                      states?.map((state) => {
                        return (
                          <option value={state.id} key={state.id}>
                            {state.name}
                          </option>
                        );
                      })}
                  </select>
                  {FormErrors.state && (
                    <p className="error">{FormErrors.state}</p>
                  )}
                </div>
              </div>
              <div className="col-lg-4 custom-form-control">
                <label htmlFor="">City</label>
                <select
                  value={projectValues.city}
                  onChange={handleChange("city")}
                >
                  <option value="">Select City</option>
                  {cities?.length > 0 &&
                    cities?.map((city) => {
                      return (
                        <option value={city.id} key={city.id}>
                          {city.name}
                        </option>
                      );
                    })}
                </select>
                {FormErrors.city && <p className="error">{FormErrors.city}</p>}
              </div>
              <div className="col-lg-4">
                <Input
                  id="zip"
                  label="Zip*"
                  placeholder="Enter Zip"
                  defaultValue={projectValues.zipcode}
                  onChange={handleChange("zipcode")}
                />
                {FormErrors.zipcode && (
                  <p className="error">{FormErrors.zipcode}</p>
                )}
              </div>
            </div>
          </div>

          <div className="container-fluid px-0 mt-4">
            <div className="row">
              <div className="col-lg-12">
                <Input
                  id="address"
                  label="Address*"
                  textarea={true}
                  placeholder="Enter Address"
                  defaultValue={projectValues.address}
                  onChange={handleChange("address")}
                />
                {FormErrors.address && (
                  <p className="error">{FormErrors.address}</p>
                )}
              </div>
            </div>
          </div>

          <div className="container-fluid px-0 mt-4">
            <div className="row">
              <div className="col-lg-6">
                <div className="custom-form-control">
                  <label htmlFor="number">Mobile Number</label>
                  <div className="phone_number_input">
                    <PhoneInput
                      placeholder="Enter phone number"
                      international
                      countryCallingCodeEditable={false}
                      defaultCountry={location?.location?.country?.code}
                      value={projectValues?.phone}
                      onChange={handlePhoneInput}
                    />
                  </div>
                  {FormErrors.phone && (
                    <p className="error">{FormErrors.phone}</p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="sub-section">
          {/* <h3>Advanced</h3> */}
          {/* <div className="container-fluid px-0">
            <div className="row">
              <div className="col-lg-6">
                <div className="custom-form-control">
                  <label htmlFor="state">Region</label>
                  <select value={data.region} onChange={handleChange("region")}>
                    <option value="">Select Region</option>
                    <option value="asia">Asia</option>
                    <option value="africa">Africa</option>
                  </select>
                  {FormErrors.region && (
                    <p className="error">{FormErrors.region}</p>
                  )}
                </div>
              </div>
              <div className="col-lg-6">
                <div className="custom-form-control">
                  <label htmlFor="language">Language - Country</label>
                  <select
                    value={data.language}
                    onChange={handleChange("language")}
                  >
                    <option value="">Select Language</option>
                    <option value="english">English</option>
                    <option value="french">French</option>
                  </select>
                  {FormErrors.language && (
                    <p className="error">{FormErrors.language}</p>
                  )}
                </div>
              </div>
            </div>
          </div> */}

          {/* <Checkbox label="Test Project" rootClassName="fs-12 lh-15" onChange={handleChange("testProject")} /> */}
        </div>
      </div>
    </>
  );
});

export default ProjectDetails;
