import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { notifyError, notifySuccess } from "../utilities";
import { ImageUpload } from "../app/redux/actions";

const useImageUpload = (props) => {
  const dispatch = useDispatch();
  const [logo, setLogo] = useState();
  const [photo, setPhoto] = useState();
  const [logoLoader, setlogoLoader] = useState(false);
  const [photoLoader, setphotoLoader] = useState(false);
  const [imageLoader, setImageLoader] = useState(false);
  const [Images, setImages] = useState([]);

  useEffect(() => {
    if (props) {
      setImages(props);
      if(props?.updateState === true){
        setLogo(props?.logo);
        setPhoto(props?.photo);
      }
    }
  }, [props]);
  const upload = (formData, type) => {
    return new Promise((resolve, reject) => {
      dispatch(ImageUpload(formData))
        .then((res) => {
          setlogoLoader(false);
          setphotoLoader(false);
          setImageLoader(false);
          if (type == "logo") {
            setLogo(res.payload.data.url ?? res.payload.data.file);
          } else if (type == "photo") {
            setPhoto(res.payload.data.url ?? res.payload.data.file);
          } else {
            resolve(res.payload.data);
          }
          notifySuccess("Image uploaded successfully");
        })
        .catch((err) => {
          setlogoLoader(false);
          setphotoLoader(false);
          setImageLoader(false);
          notifyError("Error uploading!");
        });
    });
  };
  const handleImageUpload = (type) => (e) => {
    e.preventDefault();
    let file = null;
    if (e?.dataTransfer?.files) {
      file = e.dataTransfer?.files;
    } else {
      file = Array.from(e.target.files);
    }
    let formData = new FormData();
    if (type == "logo" || type == "photo") {
      formData.append("file", file[0]);
      formData.append("type", `antiquelogic-construction/project/${type}`);
      upload(formData, type);
    } else {
      if (file && file?.length) {
        file.map((el) => {
          let formData = new FormData();
          formData.append(`file`, el);
          formData.append("type", `antiquelogic-construction/${type}`);
          upload(formData, type).then((res) => {
            setImages((prev) => [...prev, res]);
          });
        });
      }
    }
    if (file && formData) {
      if (type == "logo") {
        setlogoLoader(true);
      } else if (type == "photo") {
        setphotoLoader(true);
      } else {
        setImageLoader(true);
      }
    }
  };

  const dragOver = (e) => {
    e.preventDefault();
  };
  const dragEnter = (e) => {
    e.preventDefault();
  };
  const dragLeave = (e) => {
    e.preventDefault();
  };

  const Drop = (prop) => (e) => {
    e.preventDefault();
    e.stopPropagation();
    handleImageUpload(prop)(e);
  };
  const removeFile = (id) => {
    setImages((prev) => prev.filter((item) => item.id !== id));
  };

  return {
    handleImageUpload,
    dragEnter,
    dragLeave,
    dragOver,
    Drop,
    logo,
    setLogo,
    setPhoto,
    logoLoader,
    photo,
    photoLoader,
    Images,
    imageLoader,
    removeFile,
    setImages,
  };
};

export default useImageUpload;
