import React, { useState, useEffect } from "react";
import Modal from "./Modal";
import { useDispatch, useSelector } from "react-redux";
import {
  exportPaymentRequest,
  GeneratePDF,
  GetSignaturesRollWise, ProjectStatementExport
} from "app/redux/actions";
import Select from "react-select";
import { ExportValidation, notifyError, notifySuccess } from "utilities";
import { useParams } from "react-router";

const customStyles = {
  control: () => ({
    width: "100%",
    background: "#f9f9f9",
    marginBottom: "10px",
    display: "flex",
    borderRadius: "5px",
    border: "1px solid #dedee4",
    height: "48px",
    paddingLeft: "10px",
  }),
};
const ExportType = ({
  active,
  hideModal,
  titleText,
  type,
  setExport,
  isTrue,
}) => {
  const dispatch = useDispatch();
  const [isLoadingExport, setIsLoadingExport] = useState(false);
  const [options, setOptions] = useState([]);
  const [isExportValid, setIsExportValid] = useState(false);
  const [ExportformValidation, setExportFormValidation] = useState({});
  const [setIsActive, setIsModalActive] = useState({});
  const [isLoading, setIsModelExport] = useState(false);
  const [exportDirectorList, setDirectorList] = useState();
  const [exportOwnerList, setOwnerList] = useState();
  const [exportChiefList, setChiefList] = useState();
  const projectId = useSelector((state) => state.navigation.projectId);
  const {InvoiceId, id} = useParams();

  const [data, setData] = useState({
    directorSelect: "",
    ownerSelect: "",
    chiefSelect: "",
  });
  
  React.useEffect(() => {
    if (isExportValid && Object.keys(ExportformValidation).length === 0) {
      if(type && type === true){
        const payload = {
          invoiceId: InvoiceId,
          projectId: projectId,
          directorSignatureText: data.directorSelect,
          ownerSignatureText: data.ownerSelect,
          chiefSignatureText: data.chiefSelect,
          requestId: id,
        };
        dispatch(exportPaymentRequest(payload))
          .then((res) => {
            if (res) {
              window.open(res?.link, "_blank");
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        const payload = {
          projectId: projectId,
          invoiceId: id,
          directorSignatureText: data.directorSelect,
          ownerSignatureText: data.ownerSelect,
          chiefSignatureText: data.chiefSelect
        };
        dispatch(GeneratePDF(payload))
          .then((res) => {
            window.open(res?.link, "_blank");
          })
          .catch((err) => {
            console.log(err);
          });
      }
      
    }
  }, [ExportformValidation]);

  const handleChiefSignatures = (event, name) => {
    setData({ ...data, [name]: event.label, chiefSelect: event.label });
  };

  const handleOwnerSignatures = (event,name) => {
    setData({ ...data, [name]: event.label, ownerSelect: event.label });
  };

  const handleDirectorSignatures = (event, name) => {
    setData({ ...data, [name]: event.label, directorSelect: event.label });
  };

  const submitExportForm = () => {
    setExportFormValidation(
      ExportValidation(data)
    );
    setIsExportValid(true);
  };

  
  useEffect(() => {
    dispatch(GetSignaturesRollWise(projectId))
    .then((res) => {
        if (res) {
          let director = [];
          res?.director?.map((data) => {
              director.push({
              value: data?.id,
              label: data?.name + " (" + (data?.signature_text ?? + "<img src={data?.signature[0]} />") + ")",
              });
          });

          let owner = [];
          res?.owner?.map((data) => {
              owner.push({
              value: data?.id,
              label: data?.name + " (" + (data?.signature_text ?? + "<img src={data?.signature[0]} />") + ")",
              });
          });

          let chief = [];
          res?.chief?.map((data) => {
              chief.push({
              value: data?.id,
              label: data?.name + " (" + (data?.signature_text ?? + "<img src={data?.signature[0]} />") + ")",
              });
          });

          setDirectorList(director);
          setOwnerList(owner);
          setChiefList(chief);
        }
    })
    .catch((err) => {});
}, []);


  return (
    <div>
      <Modal
        active={active}
        hideModal={hideModal}
        submitText={titleText}
        submit={submitExportForm}
        loader={isLoadingExport}
      >
        <h4 className="mb-4" style={{ fontSize: "28px" }}>
          {titleText}
        </h4>
        <label
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: "5px",
            marginTop: "12px",
            fontSize: "12px",
          }}
        >
          Select Director Signature
        </label>
        <Select
          className="basic-single"
          classNamePrefix="select"
          options={exportDirectorList}
          value={exportDirectorList?.find((e) => e.key === data.directorSelect)}
          onChange={(e) => handleDirectorSignatures(e, "directorSignature")}
          styles={customStyles}
        />
        {ExportformValidation.directorSelect && (
          <p className="error" style={{ color: "#dc3545" }}>
            {ExportformValidation.directorSelect}
          </p>
        )}
        <label
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: "5px",
            marginTop: "15px",
            fontSize: "12px",
          }}
        >
          Select Owner Signature
        </label>
        <Select
          className="basic-single"
          classNamePrefix="select"
          options={exportOwnerList}
          value={exportDirectorList?.find((e) => e.key === data.ownerSelect)}
          onChange={(e) => handleOwnerSignatures(e, "ownerSignature")}
          styles={customStyles}
        />
        {ExportformValidation.ownerSelect && (
          <p className="error" style={{ color: "#dc3545" }}>
            {ExportformValidation.ownerSelect}
          </p>
        )}
        <label
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: "5px",
            marginTop: "15px",
            fontSize: "12px",
          }}
        >
          Select Chief Signature
        </label>
        <Select
          className="basic-single"
          classNamePrefix="select"
          options={exportChiefList}
          value={exportDirectorList?.find((e) => e.key === data.chiefSelect)}
          onChange={(e) => handleChiefSignatures(e, "chiefSignature")}
          styles={customStyles}
        />
        {ExportformValidation.chiefSelect && (
          <p className="error" style={{ color: "#dc3545" }}>
            {ExportformValidation.chiefSelect}
          </p>
        )}
      </Modal>
    </div>
  );
};

export default ExportType;
