import React from "react";
import clsx from "clsx";
import { useState } from "react";
import Loader from "components/Loader";
import Autocomplete from "react-google-autocomplete";

const Input = ({
  id,
  imp,
  dull,
  rootClassName,
  textarea,
  label,
  type,
  errorMsg,
  withInfo,
  Uploaded_image,
  imageLoader,
  isLocation,
  defaultValue,
  locationChange,
  multiple_image,
  removeFile,
  loader,
  tooltipText,
  inputCapitalize,
  ...rest
}) => {
  const [pwdState, setPwdState] = useState("password");

  const togglePwdState = () => {
    setPwdState((prevState) => {
      if (prevState === "password") {
        return "text";
      } else {
        return "password";
      }
    });
  };
  return (
    <div
      className={clsx(
        "custom-form-control",
        { error: errorMsg },
        { dull },
        rootClassName
      )}
    >
      {label && (
        <label htmlFor={id}>
          {label}
          {imp && <span className="text-danger">*</span>}
          {withInfo && (
            <div className="tooltip">
              <img
                className="ms-2 info"
                src="/assets/vectors/info.svg"
                alt="info"
              />
              <span className="tooltiptext">&nbsp;{tooltipText}</span>
            </div>
          )}
        </label>
      )}
      {type === "file" && (
        <div className="position-relative" style={{ zIndex: 1 }}>
          <label
            className={`uploader position-relative ${
              multiple_image && "flex-row uploader_multiple"
            }`}
            htmlFor={id}
          >
            {Uploaded_image ? (
              <img
                src={Uploaded_image}
                style={{ width: "100%", height: "100px", objectFit: "contain" }}
              />
            ) : loader ? (
              <Loader />
            ) : (
              <>
                <img src="/assets/vectors/upload.svg" alt="upload" />
                <div className="text">{rest.placeholder}</div>
              </>
            )}
          </label>
          <div
            className="d-flex flex-wrap"
            style={{ position: "relative", top: 0, left: 0 }}
          >
            {imageLoader ? (
              <Loader />
            ) : multiple_image?.length ? (
              multiple_image?.map((item, index) => (
                <div
                  key={index}
                  className="position-relative"
                  style={{ maxWidth: "95px" }}
                >
                  <img
                    src="/assets/vectors/close-icon.svg"
                    onClick={() => removeFile(item?.id ?? item)}
                    style={{
                      position: "absolute",
                      top: 5,
                      right: 5,
                      cursor: "pointer",
                    }}
                  />
                  <img
                    src={item?.file ?? item?.url ?? item}
                    style={{
                      width: "100px",
                      height: "100px",
                      objectFit: "cover",
                    }}
                  />
                </div>
              ))
            ) : null}
          </div>
          <div className="helper">
            Image (JPG, PNG, GIF or PDF) must be 200 x 70 pixels. File Max: 10MB
          </div>
        </div>
      )}
      {textarea ? (
        <textarea
          style={{ backgroundColor: "#f9f9f9" }}
          defaultValue={defaultValue}
          id={id}
          {...rest}
        ></textarea>
      ) : type === "location" ? (
        <Autocomplete
          apiKey="AIzaSyD-X91pEZVFOiE2kRRM8yaqvdcZulVXTZE"
          onPlaceSelected={(e) => locationChange(e.formatted_address)}
          defaultValue={defaultValue}
          onKeyDown={(event) => {
            if (event.keyCode === 13) {
              event.preventDefault();
            }
          }}
        />
      ) : (
        <input
          className={inputCapitalize && `inputCapitalize`}
          id={id}
          style={{ backgroundColor: "#f4f4f8" }}
          type={type === "password" ? pwdState : type}
          {...rest}
          defaultValue={defaultValue}
        />
      )}
      {errorMsg && <p className="error-msg">{errorMsg}</p>}
      {type === "password" && (
        <img
          className="pwd-icon"
          src={
            pwdState === "password"
              ? "/assets/vectors/pwd-show.svg"
              : "/assets/vectors/pwd-hide.svg"
          }
          alt="show"
          onClick={togglePwdState}
        />
      )}
      {type === "search" && (
        <img
          className="search-icon"
          src="/assets/vectors/search.svg"
          alt="search"
        />
      )}
    </div>
  );
};

export default Input;
