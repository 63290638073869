import React, { useEffect, useState } from "react";
import ChangeHistory from "components/ChangeHistory";
import { useDispatch, useSelector } from "react-redux";
import { notifyError } from "utilities";
import { GetDrawingDetails } from "app/redux/actions";
import { useParams } from "react-router";
import Loader from "components/Loader";
import { Link } from "react-router-dom";
import moment from "moment";
const DrawingDetails = (props) => {
  const [loader, setLoader] = useState(false);
  const { id } = useParams();
  const dispatch = useDispatch();
  const projectId = useSelector((state) => state.navigation.projectId);
  const [drawingDetail, setDrawingDetail] = useState();
  const getDrawingDetails = () => {
    dispatch(GetDrawingDetails(id))
      .then((res) => {
        setDrawingDetail(res.data);
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
        notifyError(err?.error?.response?.data?.message ?? "Error", 5000);
      });
  };
  useEffect(() => {
    props.set_Value({
      title: props.title,
      rootClassName: props.rootClassName,
      mainClassName: props.mainClassName,
    });
    setLoader(true);
    getDrawingDetails();
  }, []);
  return (
    <>
      <div className="top pb-20" style={{ borderBottom: "none" }}>
        <div className="breadcrumb">
          <Link
            to={`/admin/project/${projectId}/tool/drawings`}
            className="breadcrumb-item"
          >
            Current Drawings
          </Link>
          <div className="breadcrumb-item">Architecture Details</div>
        </div>
        <div className="options d-flex align-items-center">
          {/* <button className="ms-10 btn btn-primary" style={{ background: "#FFF2D9", color: "#E79436" }}>
            DOWNLOAD
          </button>
          <button className="ms-10 btn btn-primary" style={{ background: "#FFF2D9", color: "#E79436" }}>
            EMAIL
          </button> */}
          {/* <button
            className="ms-10 btn btn-primary"
            style={{ background: "#FBE4E8", color: "#F84949" }}
          >
            DELETE REVISION
          </button> */}
        </div>
      </div>

      <div className="row">
        <div className="col-md-8">
          <div className="card-left m-10">
            <div className="table-container">
              <div className="row m-2 table-heading">
                <div className="col-md-3 headings">Number:</div>
                <div className="col-md-3 headings">Disiplene:</div>
                <div className="col-md-3 headings">Title:</div>
                <div className="col-md-3 headings">Obsolete:</div>
              </div>
              <div className="row m-2 table-description">
                <div className="col-md-3">{drawingDetail?.number}</div>
                <div className="col-md-3">{drawingDetail?.created_by}</div>
                <div className="col-md-3">{drawingDetail?.title}</div>
                <div className="col-md-3">No</div>
              </div>
              <div className="row m-2 table-heading">
                <div className="col-md-3 headings">Revision:</div>
                <div className="col-md-3 headings">Drawing Set:</div>
                <div className="col-md-3 headings">Drawing Date:</div>
                <div className="col-md-3 headings">Recieve Date:</div>
              </div>
              <div className="row m-2 table-description">
                <div className="col-md-3">{drawingDetail?.revision}</div>
                <div className="col-md-3">{drawingDetail?.set}</div>
                <div className="col-md-3">
                  {moment(drawingDetail?.drawing_date).format("YYYY-MM-DD")}
                </div>
                <div className="col-md-3">
                  {moment(drawingDetail?.recieved_date).format("YYYY-MM-DD")}
                </div>
              </div>
              <div className="row m-2 table-heading">
                <div className="col-md-3 headings">Status:</div>
                <div className="row table-description">
                  <div className="col-md-3">{drawingDetail?.status}</div>
                </div>
              </div>
              <div className="row m-2 table-heading">
                <div className="col-md-12 headings">
                  <label className="headings">Drawing:</label>
                  <div className="imageLoader">
                    {loader ? (
                      <tr>
                        <td>
                          <Loader size={36} />
                        </td>
                      </tr>
                    ) : (
                      drawingDetail?.files?.map((img) => (
                        <img
                          src={img?.url ?? img?.file}
                          style={{
                            maxWidth: "350px",
                            margin: "5px",
                            height: "430px",
                            objectFit: "cover",
                          }}
                        />
                      ))
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="card-left m-10">
            <div className="table-container" style={{ marginLeft: "12px" }}>
              <div className="imageLoader">
                {loader ? (
                  <Loader size={36} />
                ) : (
                  <ChangeHistory data={drawingDetail?.changeHistory} />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default DrawingDetails;
