import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { PasswordReset } from "app/redux/actions";
import { notifyError, notifySuccess } from "utilities";

const ResetPassword = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [values, setValues] = useState({
    currentPassword: "",
    newPassword: "",
    confirmNewPassword: "",
  });
  const [validation, setValidation] = useState({});
  const [isValid, setIsValid] = useState(false);
  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };
  const validate = (value) => {
    const { currentPassword, newPassword, confirmNewPassword } = value;
    const errors = {};
    if (!currentPassword) errors.currentPass = "Current Password is required";
    if (!newPassword) errors.newPass = "New Password is required";
    if (!confirmNewPassword) errors.newConfirmPass = "Confirm Password is required";
    if (newPassword !== confirmNewPassword) errors.newConfirmPass = "Password do not match";
    if (currentPassword && newPassword && currentPassword == newPassword) {
      errors.newPass = "You used that password recently. Choose a different password";
    }
    return errors;
  };
  useEffect(() => {
    if (isValid && Object.keys(validation).length === 0) {
      const data = {
        current_password: values.currentPassword,
        new_password: values.newPassword,
        confirmed: values.confirmNewPassword,
      };
      dispatch(PasswordReset(data))
        .then((res) => {
          notifySuccess("Password changed successfully");
          history.push("/auth/login");
        })
        .catch((err) => {
          notifyError(err.response?.data?.message ?? "Error resetting password");
        });
    }
  }, [validation]);
  const handleSubmit = (e) => {
    e.preventDefault();
    setValidation(validate(values));
    setIsValid(true);
  };
  return (
    <div className="form">
      <div className="custom-form-control">
        <label htmlFor="password">Current Password</label>
        <input type="password" onChange={handleChange("currentPassword")} placeholder="*****************" />
        {validation.currentPass && <p className="error">{validation.currentPass}</p>}
      </div>
      <div className="custom-form-control">
        <label htmlFor="password">New Password</label>
        <input type="password" onChange={handleChange("newPassword")} placeholder="*****************" />
        {validation.newPass && <p className="error">{validation.newPass}</p>}
      </div>
      <div className="custom-form-control">
        <label htmlFor="password">Confirm New Password</label>
        <input
          type="password"
          onChange={handleChange("confirmNewPassword")}
          placeholder="*****************"
        />
        {validation.newConfirmPass && <p className="error">{validation.newConfirmPass}</p>}
      </div>
      <div className="bottom">
        <button
          className="btn btn-primary btn-auth"
          style={{ width: "207px", maxWidth: "none" }}
          onClick={handleSubmit}
        >
          CHANGE PASSWORD
        </button>
      </div>
    </div>
  );
};

export default ResetPassword;
