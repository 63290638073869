import qs from "qs";
const axios = require("axios");
axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";

const parseBody = (response) => {
  if (response.status === 200 || response.status === 201) {
    return response.data;
  } else {
    return response.data.message;
  }
};

export default function HttpHandler(baseUrl) {
  let headers = {};
  let instance = axios.create({
    baseURL: baseUrl,
    paramsSerializer: function (params) {
      return qs.stringify(params, { indices: false });
    },
    timeout: 10000,
    headers: headers,
  });

  /*****************************Start Request Handler of Axios (interceptors)***************************************/
  instance.interceptors.request.use(
    (request) => {
      // request.headers['APP-ID'] = "514f36ac-224a-45d7-ac07-7b5b773ccc3a";
      //request.headers['APP-ID'] = "cca0d78c-3c7b-4521-9215-4bf2f16e4d24";
      return request;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  /*****************************End Request Handler of Axios (interceptors)***************************************/
  /*****************************Start Request Handler of Axios (interceptors)***************************************/
  instance.interceptors.request.use(
    (request) => {
      // Logged in User Block
      if (localStorage.getItem("userDetails")) {
        const loggedInUser = JSON.parse(localStorage.getItem("userDetails"));
        if (loggedInUser && loggedInUser.token) {
          request.headers["Authorization"] = loggedInUser.token;
        } else {
          // Make it failed and redirect it to login.
        }
      }

      return request;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  /*****************************End Request Handler of Axios (interceptors)***************************************/

  /*****************************Start Response Handler of Axios (interceptors)***********************************/
  instance.interceptors.response.use(
    (response) => {
      return parseBody(response);
    },
    async (error) => {
      return Promise.reject(error);
    }
  );

  /*****************************End Response Handler of Axios (interceptors)***********************************/

  return instance;
}
