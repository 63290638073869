import React from "react";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import clsx from "clsx";
import { useSelector } from "react-redux";
const PhoneNumberInput = ({
  lable,
  name,
  value,
  placeholder,
  rootClassName,
  onChange,
  errorMsg,
  required,
}) => {
  const location = useSelector((state) => state.AuthReducer.location);
  return (
    <div className={clsx("custom-form-control", rootClassName)}>
      <label>
        {lable}
        {required && <span className="text-danger">*</span>}
      </label>
      <PhoneInput
        placeholder={placeholder}
        international
        name={name}
        countryCallingCodeEditable={false}
        defaultCountry={location?.location?.country?.code}
        value={value}
        onChange={onChange}
      />
      {errorMsg && <p className="error">{errorMsg}</p>}
    </div>
  );
};
export default PhoneNumberInput;
