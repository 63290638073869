import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router";
import { Link, NavLink } from "react-router-dom";
import Input from "../components/Input";
import { useDispatch, useSelector } from "react-redux";
import { createCategory, createStage, createTemplate, createType, deleteCategory, deleteStage, deleteTemplate, deleteType, editCategory, editStage, editTemplate, editType, getCategory, GetStages, GetTemplates, GetTypes, updateCategory, updateStage, updateTemplate, updateType } from "app/redux/actions";
import { notifyError, formFieldsValidation, notifySuccess } from "utilities";
import moment from "moment";
import Loader from "components/Loader";
import Modal from "components/Modal";
import ConsentModel from "components/ConsentModel";
const Management = (props) => {

    useEffect(() => {
      props.set_Value({
        title: props.title,
        withSettings: props.withSettings,
        rootClassName: props.rootClassName,
        mainClassName: props.mainClassName,
      });
    }, []);

    const dispatch = useDispatch();
    const projectId = useSelector((state) => state.navigation.projectId);
    const [values, setValues] = useState();
    const [editValues, setEditValues] = useState();
    const [dataLoader, SetLoadingData] = useState(false);
    const [formValidation, setFormValidation] = useState({});
    const [activeModal, setActiveModal] = useState(false);
    const [isValid, setIsValid] = useState(false);
    const [deleteActive, setdeleteActive] = useState(false);
    const [DeleteData, setDeleteData] = useState();
    const [activeTab, setActiveTab] = useState(1);
    const [text, setText] = useState("Todo Category");
    const [createText, setCreateText] = useState("Create");

    const [data, setData] = useState({
      name: "",
      description: "",
    });

    if(values == null){
        dispatch(getCategory(projectId)).then((response) => {
            const data = response?.res?.map((item) => {
              const obj = { title: item.title, key: item.id, description: item.description, ref: "todo_category" };
              return obj;
            });
            setValues(data);
          });
    }
    const management = (e) => {
        if(e === "todo_category"){
            setText("Todo Category");
            setActiveTab(1);
            dispatch(getCategory(projectId)).then((response) => {
                const data = response?.res?.map((item) => {
                  const obj = { title: item.title, key: item.id, description: item.description, ref: e };
                  return obj;
                });
                setValues(data);
              });
        }
        else if(e === "project_stages"){
            setText("Project Stage");
            setActiveTab(2);
            dispatch(GetStages()).then((response) => {
                const data = response?.payload?.map((item) => {
                    const obj = { title: item.name, key: item.id, description: item.description, ref: e };
                    return obj;
                  });
                  setValues(data);
            });
        }
        else if(e === "project_types"){
            setText("Project Type");
            setActiveTab(3);
            dispatch(GetTypes()).then((response) => {
                const data = response?.payload?.map((item) => {
                    const obj = { title: item.name, key: item.id, description: item.description, ref: e };
                    return obj;
                  });
                  setValues(data);
            });
        }
        else if(e === "project_templates"){
            setText("Project Template");
            setActiveTab(4);
            dispatch(GetTemplates()).then((response) => {
                const data = response?.payload?.map((item) => {
                    const obj = { title: item.title, key: item.id, description: item.description, ref: e };
                    return obj;
                  });
                  setValues(data);
            });
        }
        else {
            dispatch(getCategory(projectId)).then((response) => {
                setText("Todo Category");
                setActiveTab(1);
                const data = response?.res?.map((item) => {
                  const obj = { title: item.title, key: item.id, description: item.description, ref: "todo_category" };
                  return obj;
                });
                setValues(data);
              });
        }
    };

    const handleEdit = (ref, id) => {
      setCreateText("Update");
      if(ref === "todo_category"){
        setEditValues({});
        dispatch(editCategory(id)).then((response) => {
          setEditValues({name: response?.res.title, description: response?.res.description, id: response?.res.id, ref: ref});
          setData({name: response?.res.title, description: response?.res.description});
        });
        setIsModalActive(true);
        setActiveModal(true);
      }
      else if(ref === "project_stages"){
        setEditValues({});
        dispatch(editStage(id)).then((response) => {
          setEditValues({name: response?.res.name, description: response?.res.description, id: response?.res.id, ref: ref});
          setData({name: response?.res.name, description: response?.res.description});
        });
        setIsModalActive(true);
        setActiveModal(true);
      }
      else if(ref === "project_types"){
        setEditValues({});
        dispatch(editType(id)).then((response) => {
          setEditValues({name: response?.res.name, description: response?.res.description, id: response?.res.id, ref: ref});
          setData({name: response?.res.name, description: response?.res.description});
        });
        setIsModalActive(true);
        setActiveModal(true);
      }
      else if(ref === "project_templates"){
        setEditValues({});
        dispatch(editTemplate(id)).then((response) => {
          setEditValues({name: response?.res.title, description: response?.res.description, id: response?.res.id, ref: ref});
          setData({name: response?.res.title, description: response?.res.description});
        });
        setIsModalActive(true);
        setActiveModal(true);
      }
    }

    const submitForm = () => {
      setFormValidation(
        formFieldsValidation(data)
      );
      setIsValid(true);
      if(data.name != '' && data.description != ''){
        if(createText === "Update"){
          handleUpdate();
        }
        if(createText === "Create"){
          createManagement();
        }
      } else {
        SetLoadingData(false);
      }
      
    };
    const [isModalActive, setIsModalActive] = useState(false);

    const hideModal = () => {
      setIsModalActive(false);
      setFormValidation({});
    };

    const handleChange = (e, type) => {
      setData({...data, [type]: e?.target?.value});
      setEditValues({...editValues, [type]: e?.target?.value});
    };

    const handleUpdate = () => {
      SetLoadingData(true);
      const payload = {
        title: editValues?.name,
        name: editValues?.name,
        description: editValues?.description,
        id: editValues?.id
      };
      if(editValues?.ref === "todo_category"){
        dispatch(updateCategory(payload)).then((response) => {
          setEditValues({title: response?.res.title, description: response?.res.description, id: response?.res.id, ref: "todo_category"});
          management("todo_category");
          setIsModalActive(false);
          notifySuccess("Data updated successfuly");
          SetLoadingData(false);
        })
        .catch((err) => {
          notifyError(err?.error?.response?.data?.message ?? "Error", 5000);
          SetLoadingData(false);
        });
      } 
      else if(editValues?.ref === "project_stages"){
        dispatch(updateStage(payload)).then((response) => {
          setEditValues({title: response?.res.name, description: response?.res.description, id: response?.res.id, ref: "todo_category"});
          management("project_stages");
          setIsModalActive(false);
          notifySuccess("Data updated successfuly");
          SetLoadingData(false);
        })
        .catch((err) => {
          notifyError(err?.error?.response?.data?.message ?? "Error", 5000);
          SetLoadingData(false);
        });
      }
      else if(editValues?.ref === "project_types"){
        dispatch(updateType(payload)).then((response) => {
          setEditValues({title: response?.res.name, description: response?.res.description, id: response?.res.id, ref: "todo_category"});
          management("project_types");
          setIsModalActive(false);
          notifySuccess("Data updated successfuly");
          SetLoadingData(false);
        })
        .catch((err) => {
          notifyError(err?.error?.response?.data?.message ?? "Error", 5000);
          SetLoadingData(false);
        });
      }
      else if(editValues?.ref === "project_templates"){
        dispatch(updateTemplate(payload)).then((response) => {
          setEditValues({title: response?.res.title, description: response?.res.description, id: response?.res.id, ref: "todo_category"});
          management("project_templates");
          setIsModalActive(false);
          notifySuccess("Data updated successfuly");
          SetLoadingData(false);
        })
        .catch((err) => {
          notifyError(err?.error?.response?.data?.message ?? "Error", 5000);
          SetLoadingData(false);
        });
      }
    };

    const handleDelete = (ref, id) => {
        setEditValues({...editValues, ["ref"]: ref, ["id"]: id});
        setdeleteActive(true);
    };

    const deleteManagment = (ref, id) => {
      SetLoadingData(true);
      if(ref === "todo_category"){
        dispatch(deleteCategory(id)).then((response) => {
            notifySuccess("Data deleted successfuly");
            setdeleteActive(false);
            SetLoadingData(false);
            management(ref);
          })
          .catch((err) => {
            notifyError(err?.error?.response?.data?.message ?? "Error", 5000);
        });
      }
      else if(ref === "project_stages"){
        dispatch(deleteStage(id)).then((response) => {
            notifySuccess("Data deleted successfuly");
            setdeleteActive(false);
            SetLoadingData(false);
            management(ref);
          })
          .catch((err) => {
            notifyError(err?.error?.response?.data?.message ?? "Error", 5000);
        });
      }
      else if(ref === "project_types"){
        dispatch(deleteType(id)).then((response) => {
            notifySuccess("Data deleted successfuly");
            setdeleteActive(false);
            SetLoadingData(false);
            management(ref);
          })
          .catch((err) => {
            notifyError(err?.error?.response?.data?.message ?? "Error", 5000);
        });
      }
      else if(ref === "project_templates"){
        dispatch(deleteTemplate(id)).then((response) => {
            notifySuccess("Data deleted successfuly");
            setdeleteActive(false);
            SetLoadingData(false);
            management(ref);
          })
          .catch((err) => {
            notifyError(err?.error?.response?.data?.message ?? "Error", 5000);
        });
      }
    };



    // create 

    const handleCreate = () => {
      SetLoadingData(false);
      setEditValues({});
      setIsModalActive(true);
      setActiveModal(true);
      setCreateText("Create");
      setData({
        name: "",
        description: "",
      });
    };

    // create 

    const createManagement = () => {
      SetLoadingData(true);
      const payload = {
        title: data?.name,
        name: data?.name,
        description: data?.description
      };
      if(text === 'Todo Category'){
        dispatch(createCategory(payload)).then((response) => {
          notifySuccess("Data created successfuly");
          setIsModalActive(false);
          SetLoadingData(false);
          management("todo_category");
        })
        .catch((err) => {
          notifyError(err?.error?.response?.data?.message ?? "Error", 5000);
        });
      }
      else if(text === 'Project Stage'){
        dispatch(createStage(payload)).then((response) => {
          notifySuccess("Data created successfuly");
          setIsModalActive(false);
          SetLoadingData(false);
          management("project_stages");
        })
        .catch((err) => {
          notifyError(err?.error?.response?.data?.message ?? "Error", 5000);
        });
      }
      else if(text === 'Project Type'){
        dispatch(createType(payload)).then((response) => {
          notifySuccess("Data created successfuly");
          setIsModalActive(false);
          SetLoadingData(false);
          management("project_types");
        })
        .catch((err) => {
          notifyError(err?.error?.response?.data?.message ?? "Error", 5000);
        });
      }
      else if(text === 'Project Template'){
        dispatch(createTemplate(payload)).then((response) => {
          notifySuccess("Data created successfuly");
          setIsModalActive(false);
          SetLoadingData(false);
          management("project_templates");
        })
        .catch((err) => {
          notifyError(err?.error?.response?.data?.message ?? "Error", 5000);
        });
      }
    };

  return (
    <>

      {deleteActive && (
        <div className="px-0 mt-30">
          <ConsentModel
            active={deleteActive}
            oncancel={() => setdeleteActive(false)}
            onsubmit={() => deleteManagment(editValues?.ref, editValues?.id)}
            loader={dataLoader}
          />
        </div>
      )}

    {isModalActive && 
      <Modal
        active={activeModal}
        hideModal={hideModal}
        submit={submitForm}
        loader={dataLoader}
        submitText={createText}
      >

      <h4 className="mb-4" style={{ fontSize: "28px" }}>
            {createText}
          </h4>
          <div>
              <Input
                  withInfo
                  tooltipText={"Name"}
                  inputCapitalize="inputCapitalize"
                  id="name"
                  label="Name*"
                  placeholder="Enter Name"
                  value={editValues.name}
                  onChange={(name) => handleChange(name, "name")}
              />
              {formValidation.name && (
              <p className="error" style={{ color: "#dc3545" }}>
                  {formValidation.name}
              </p>
              )}
          </div>
          <div>
              <Input
                  rows={5}
                  textarea
                  id="description"
                  label="Description"
                  placeholder="Enter Description"
                  value={editValues.description}
                  onChange={(description) => handleChange(description, "description")}
              />
              {formValidation.description && (
              <p className="error" style={{ color: "#dc3545" }}>
                  {formValidation.description}
              </p>
              )}
          </div>
        
      </Modal>
    }

      <div className="top pb-20">
        <div className="tabs">
          <NavLink to="" className="tab active">
            Management
          </NavLink>
        </div>
      </div>

      <div className="card">
        {/* <div className="card-head">
          <div className="card-right"> */}
            {/* <Input
              rootClassName="m-0"
              type="search"
              placeholder="Search..."
              onChange={(e) => setSearch(e.target.value)}
              value={searchTerm}
            /> */}
            {/* <Dropdown rootClassName="mx-20" btn dropdownItems={["Export document"]}>
              ADD FILTERS
            </Dropdown> */}
            {/* <button className="btn btn-primary-outlined card-right" onClick={() => handleCreate()}>Create {text}</button> */}
          {/* </div>
        </div> */}
        <div className="card-body p-0 d-flex">
          <div className="card-left">
            <div className="card-nav">
                <div
                key={1}
                className={`nav-item ${activeTab == 1 ? "active" : ""}`}
                onClick={() => management("todo_category")}
                >
                Todo Categories
                </div>
                <div
                key={2}
                className={`nav-item ${activeTab == 2 ? "active" : ""}`}
                onClick={() => management("project_stages")}
                >
                Project Stages
                </div>
                <div
                key={3}
                className={`nav-item ${activeTab == 3 ? "active" : ""}`}
                onClick={() => management("project_types")}
                >
                Project Types
                </div>
                <div
                key={4}
                className={`nav-item ${activeTab == 4 ? "active" : ""}`}
                onClick={() => management("project_templates")}
                >
                Project Templates
                </div>
            </div>
          </div>
          <div className="card-right">
            <h4 className="fs-20 mb-20"><button className="btn btn-primary-outlined" onClick={() => handleCreate()} style={{float: 'right', marginBottom: '20px'}}>Create {text}</button></h4>

                <div className="table-container">
                  <table>
                    <thead>
                      <tr>
                        <th>No.</th>
                        <th>Title</th>
                        <th>Description</th>
                        <th className="text-left">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                        {values!= null && values.length > 0 ? 
                        
                        values?.map((value, index) => {
                            return (
                                <tr>
                                    <td>{index + 1}</td>
                                    <td>{value?.title}</td>
                                    <td>{value?.description}</td>
                                    <td>
                                        <div className="d-flex text-center">
                                            {/* <button
                                            className="btn btn-contained-2 me-10"
                                            style={{ height: 40 }}
                                            // onClick={() =>
                                            //     history.push(`/admin/project/${projectId}/tool/drawings/${id}`)
                                            // }
                                            >
                                            <img className="me-10" src="/assets/vectors/eye.svg" alt="edit" />
                                            </button> */}
                                            <button
                                            className="btn btn-contained-2 me-10"
                                            style={{ height: 40 }}
                                            onClick={() => handleEdit(value?.ref, value?.key)}
                                            >
                                            <img className="me-10" src="/assets/vectors/edit-table.svg" alt="edit" />
                                            </button>
                                            <button
                                            className="btn btn-contained-2 ms-10"
                                            style={{ height: 40 }}
                                            onClick={() => handleDelete(value?.ref, value?.key)}
                                            >
                                            <img
                                                className="ms-10"
                                                src="/assets/vectors/delete-table.svg"
                                                alt="delete"
                                            />
                                            </button>
                                            {/* <button className="btn btn-contained-2 me-10">INFO</button>
                                            <button className="btn btn-contained-2 ms-10">OPEN</button> */}
                                        </div>
                                    </td>
                                </tr>
                            );
                        })
                        : "No Record Found"}
                    </tbody>
                  </table>
                </div>
          </div>
        </div>
      </div>
    </>
  );

};

export default Management;
