import { GetLocation } from "app/redux/actions";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import MainRoutes from "Routes";

function App() {
  const dispatch = useDispatch();
  const location = useSelector((state) => state.AuthReducer.location);
  useEffect(() => {
    if (!location?.type) {
      dispatch(GetLocation());
    }
  }, []);
  return (
    <>
      <ToastContainer />
      <MainRoutes />
    </>
  );
}

export default App;
