import React, { useState, useEffect } from "react";
import Modal from "../components/Modal";
import Input from "../components/Input";
import SelectDropdown from "./SelectDropdown";
import InputDate from "./InputDate";
import { notifyError, notifySuccess, paymentRequestVslidate } from "utilities";
import { useDispatch } from "react-redux";
import {
  CreatePaymentRequest,
  GetProjectContracts,
  InvoiceListing,
} from "app/redux/actions";
import moment from "moment";
import TextEditor from "./textEditor/TextEditor";
const customStyles = {
  control: () => ({
    width: "100%",
    background: "#f9f9f9",
    display: "flex",
    borderRadius: "5px",
    border: "1px solid #dedee4",
    height: "48px",
    paddingLeft: "10px",
  }),
};
const PaymentRequestModel = ({
  active,
  hideModal,
  projectId,
  titleText,
  paymentLisitng,
}) => {
  const [isValid, setIsValid] = useState(false);
  const [formValidation, setFormValidation] = useState({});

  const dispatch = useDispatch();

  const [values, setValues] = useState({
    amount: 0,
    startDate: null,
    invoiceId: null,
    contractId: null,
  });

  const [contractAmount, setContractAmount] = useState();
  const [notes, setNotes] = useState("");

  const handleChange = (name) => (event) => {
    const currentValue = event.target.value;
    if (parseFloat(currentValue) > contractAmount) {
      notifyError(`Amount should Not be Greater than ${contractAmount}`);
    }
      setValues({ ...values, [name]: event.target.value });
  };

  const handleNotesChange = (name) => (event) => {
    setValues({ ...values, [name]: event.target.value });
  };

  const handleNotes = (value) => {
    setNotes(value);
  }
 
  const dateChangeHandler = (name, date) => {
    setValues({ ...values, [name]: date });
  };

  useEffect(() => {
    if (isValid && Object.keys(formValidation).length === 0) {
      const payload = {
        projectId: projectId,
        invoiceId: values.invoiceId,
        amount: parseFloat(values?.amount),
        notes: notes,
        contract_id: values.contractId,
        issued_at: moment(values.startDate).format("YYYY-MM-DD"),
      };
      setLoading(true);
      dispatch(CreatePaymentRequest(payload))
        .then((res) => {
          notifySuccess("PayMentRequest Created Successfully!");
          paymentLisitng();
          setLoading(false);
          hideModal();
        })
        .catch((error) => {
          setLoading(false);
          notifyError(error?.response?.data?.message);
        });
    }
  }, [formValidation]);
  const [loading, setLoading] = useState(false);

  const handleSubmit = () => {
    if (parseFloat(values.amount) > contractAmount) {
      notifyError(`Amount should Not be Greater than ${contractAmount}`);
    } else {
      setFormValidation(paymentRequestVslidate(values, notes));
      setIsValid(true);
    }
  };

  const handleinvoiceChange = (event, name) => {
    setValues({ ...values, [name]: event.value });
  };

  const [options, setOptios] = useState();

  useEffect(() => {
    dispatch(InvoiceListing({ project_id: projectId }))
      .then((res) => {
        if (res) {
          const data = res?.data?.map((item) => {
            return { value: item.id, label: item.number };
          });
          setOptios(data);
        }
      })
      .catch((error) => {});
  }, []);

  const handleContractChange = (event, name) => {
    setContractAmount(event?.amount);
    setValues({ ...values, [name]: event.value });
  };

  const [contractOptions, setContractOptions] = useState();

  const getProjectContracts = () => {
    dispatch(GetProjectContracts({ project_id: projectId }))
      .then((res) => {
        if (res) {
          const data = res?.map((item) => {
            return {
              value: item.id,
              amount: item?.amount,
              label: `${item.contractor.name} (${item.amount})`,
            };
          });
          setContractOptions(data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getProjectContracts();
  }, []);


  return (
    <Modal
      active={active}
      hideModal={hideModal}
      submit={handleSubmit}
      loader={loading}
    >
      <h4 className="mb-4" style={{ fontSize: "28px" }}>
        {titleText}
      </h4>
      <div>
        <SelectDropdown
          className="basic-single"
          classNamePrefix="select"
          // isLoading={isLoading}
          isSearchable={true}
          options={options}
          // styles={customStyles}
          defaultValue={values.invoiceId}
          placeholder={"Select Invoice"}
          onChange={(e) => handleinvoiceChange(e, "invoiceId")}
          validation={formValidation?.invoiceId}
          loadingMessage={() => "Fetching Roles"}
          label={"Invoice Listing"}
        />
      </div>
      <div>
        <SelectDropdown
          className="basic-single"
          classNamePrefix="select"
          label={"Contract Listing"}
          // isLoading={isLoading}
          isSearchable={true}
          options={contractOptions}
          placeholder={"Select Contract"}
          onChange={(e) => handleContractChange(e, "contractId")}
          validation={formValidation?.contractId}
          loadingMessage={() => "Fetching Roles"}
        />
      </div>
      <Input
        rootClassName="mb-20"
        label="Amount"
        type='number'
        placeholder="Enter Amount"
        onChange={handleChange("amount")}
        errorMsg={formValidation.amount}
        defaultValue={values.amount}
        inputCapitalize={"inputCapitalize"}
      />
      <div className="px-0 mt-30 select-wrapper custom-form-control">
          <label>Notes</label>
          <TextEditor
            overview={notes}
            changeOverview={handleNotes}
          />
          {formValidation?.notes && (
            <p
              className="error"
              style={{ color: "#dc3545", marginBottom: "10px" }}
            >
              {formValidation?.notes}
            </p>
          )}
        </div>
      <div>
        <InputDate
          withInfo
          tooltipText={"Issue Date"}
          selected={values.startDate}
          label="Start Date*"
          onChange={(date) => dateChangeHandler("startDate", date)}
        />
        {formValidation.startDate && (
          <p className="error">{formValidation.startDate}</p>
        )}
      </div>
    </Modal>
  );
};

export default PaymentRequestModel;
