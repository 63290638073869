import React, { useState } from "react";
import Modal from "../components/Modal";
import Input from "../components/Input";
import SelectInput from "./SelectInput";
import { useDispatch } from "react-redux";
import { ContactForm } from "app/redux/actions";
import { notifyError, notifySuccess, validateContact } from "utilities";

const options = [
  { key: "products", value: "Contact For Products" },
  { key: "scoringwidgets", value: "Website Scoring widget" },
  { key: "scoringapp", value: "Tournament & Livescoring Module" },
  { key: "whitelabelapp", value: "Whitelable Website & Apps" },
  { key: "overlays", value: "Contact for livestraming overlays" },
  { key: "Requestfordemo", value: "Request For Demo" },
  { key: "Technicalquestion", value: "Technical Question" },
  { key: "Technicalsupport", value: "Technical Support" },
  { key: "Generalinquiry", value: "General Inquiry" },
  { key: "Other", value: "Other" },
];

const ContactModal = ({ active, hideModal, titleText }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [Error, setError] = useState({});
  const [values, setValues] = useState({
    name: "",
    email: "",
    mobileNo: "",
    description: "",
  });
  const [type, setType] = React.useState("");
  const handleType = (value) => {
    setType(value);
  };
  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value ? event.target.value : event.value });
  };
  const Formsubmit = (e) => {
    e.preventDefault();
    Submit();
  };
  const Submit = () => {
    const { name, email, mobileNo, description } = values;
    if (name && email && type && mobileNo && description) {
      setLoading(true);
      dispatch(
        ContactForm({
          name,
          email,
          phone: mobileNo,
          type,
          message: description,
        })
      )
        .then((res) => {
          notifySuccess(res?.message ?? "Request recieved successfuly");
          setLoading(false);
          hideModal();
        })
        .catch((err) => {
          notifyError(err?.response?.data?.message ?? "Error!");
          setLoading(false);
        });
    } else {
      setError(validateContact(values));
    }
  };

  return (
    <Modal active={active} hideModal={hideModal} submitText={"Submit"} submit={Formsubmit} loader={loading}>
      <h4 className="mb-4" style={{ fontSize: "28px" }}>
        {titleText}
      </h4>
      <Input
        rootClassName="mb-20"
        label="Name"
        placeholder="Enter Name"
        onChange={handleChange("name")}
        defaultValue={values.name}
        autoCapitalize="on"
        errorMsg={Error.name}
      />
      <Input
        rootClassName="mb-20"
        label="Email"
        placeholder="Enter Email"
        onChange={handleChange("email")}
        defaultValue={values.email}
        errorMsg={Error.email}
      />
      <Input
        label="Mobile Number"
        placeholder="Enter Mobile Number"
        onChange={handleChange("mobileNo")}
        defaultValue={values.mobileNo}
        errorMsg={Error.mobileNo}
      />
      <SelectInput
        options={options}
        label={"Types"}
        placeholder={"Select Type"}
        value={type}
        setdrawingset={handleType}
        errorMsg={Error.type}
      />
      <Input
        rows={5}
        textarea
        id="description"
        label="Description"
        placeholder="Write Message"
        onChange={handleChange("description")}
        errorMsg={Error.description}
      />
    </Modal>
  );
};

export default ContactModal;
