import {
  PROJECT_CREATE,
  GET_PROJECTS,
  GET_DIRECTORIES,
  ADD_DIRECTORIES,
  GET_COMPANIES,
  GET_TEMPLATES,
  GET_STAGES,
  GET_TYPES,
  COUNTRY_TYPE,
  LOGOUT,
  RESET_PROJECT,
  PROJECT_NOTIFICATION,
  UPDATE_PROJECT,
} from "../constants/index";

const initial_state = {
  project: null,
  allProjects: [],
  GetDirectories: [],
  Directories: null,
  allCompanies: [],
  Templates: [],
  stages: [],
  types: [],
  countries: [],
  allMeetings: [],
  projectNotifiations: [],
};

const Projects = (state = initial_state, action) => {
  switch (action.type) {
    case PROJECT_CREATE:
      return {
        ...state,
        project: action.payload,
      };
    case GET_PROJECTS:
      return {
        ...state,
        allProjects: action.payload,
      };
    case UPDATE_PROJECT:
      const projects = [...state.getChapterListing];
      const chapter = projects.filter((cp) => cp.id === action.payload.id)[0];
      projects[projects.indexOf(chapter)] = action.payload;
      return {
        ...state,
        allProjects: projects,
      };
    case GET_DIRECTORIES:
      return {
        ...state,
        GetDirectories: action.payload,
      };
    case ADD_DIRECTORIES:
      return {
        ...state,
        Directories: action.payload,
      };
    case GET_COMPANIES:
      return {
        ...state,
        allCompanies: action.payload,
      };
    case GET_TEMPLATES:
      return {
        ...state,
        Templates: action.payload,
      };
    case GET_STAGES:
      return {
        ...state,
        stages: action.payload,
      };
    case GET_TYPES:
      return {
        ...state,
        types: action.payload,
      };
    case COUNTRY_TYPE:
      return {
        ...state,
        countries: action.payload,
      };
    case LOGOUT:
      return {
        ...state,
        project: null,
        allProjects: [],
        GetDirectories: [],
        Directories: null,
        allCompanies: [],
        Templates: [],
        stages: [],
        types: [],
        countries: [],
        allMeetings: [],
      };
    case RESET_PROJECT:
      return {
        ...state,
        project: null,
      };
    case PROJECT_NOTIFICATION:
      return {
        ...state,
        projectNotifiations: action.payload,
      };
    default:
      return state;
  }
};

export default Projects;
