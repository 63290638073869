import Input from "../../components/Input";
import React from "react";
import Loader from "components/Loader";
import InfiniteScroll from "react-infinite-scroll-component";
const ManPower = ({
  state,
  Listing,
  fetchData,
  location,
  companies,
  dataLoader,
  loader,
  meta,
  errors,
  handleDelete,
  handleChange,
  Images,
  handleImageUpload,
  imageLoader,
  removeFile,
  handleCreate,
  handleEdit,
  isEditing,
  cancelEdit,
  setIsEditing,
  setImages,
  handlelocation
}) => {
  return (
    <>
      <h4 className="fs-20 mb-20">Manpower</h4>
      <InfiniteScroll
        dataLength={Listing?.length ?? 0}
        next={fetchData}
        hasMore={meta && meta?.total > Listing?.length}
        loader={loader ? <Loader /> : null}
        height={"100%"}
        style={{ overflow: "scroll", overflowX: "hidden", maxHeight: "550px" }}
        className="infinite-scroll w-100"
      >
        <div className="table-container dailylog_table">
          <table>
            <thead>
              <tr>
                <th>Company</th>
                <th>Workers</th>
                <th>Hours</th>
                <th>Total Hours</th>
                <th>Location</th>
                <th>Comments</th>
                <th>Attachments</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {dataLoader ? (
                <tr>
                  <td colSpan={7}>
                    <Loader />
                  </td>
                </tr>
              ) : (
                Listing?.map((item, idx) =>
                  isEditing.id !== item.id ? (
                    <tr key={idx}>
                      <td>{item?.company?.name}</td>
                      <td className="ps-4">{item?.workers}</td>
                      <td className="ps-4">{item?.working_hours}</td>
                      <td className="ps-4">{item?.total_hours}</td>
                      <td>{item?.location ?? "No Location"}</td>
                      <td>{item?.comments ?? "No Comments"}</td>
                      <td>
                        {item?.files?.length
                          ? item?.files?.map((image) => (
                              <img
                                src={image?.file ?? image?.url}
                                alt={image?.title}
                                style={{ width: 50, height: 50 }}
                                key={image?.id}
                              />
                            ))
                          : "No Attachement"}
                      </td>
                      <td>
                        <button
                          className="btn btn-contained-2 me-10 create__btn"
                          onClick={() => handleEdit(item)}
                        >
                          <img
                            className="me-0"
                            src="/assets/vectors/edit-table.svg"
                            alt="edit"
                          />
                        </button>
                        <button
                          className="btn btn-contained-2 ms-10 create__btn"
                          onClick={() => handleDelete(item)}
                        >
                          <img
                            className="me-0"
                            src="/assets/vectors/delete-table.svg"
                            alt="delete"
                          />
                        </button>
                      </td>
                    </tr>
                  ) : (
                    <tr key={idx}>
                      <td>
                        <select
                          name="company_id"
                          onChange={handleChange}
                          className={`${errors.companyId && "error"}`}
                          value={state.company_id}
                        >
                          <option value="">Select Company</option>
                          {companies &&
                            companies?.map((el) => (
                              <option value={el.id} key={el.id}>
                                {el.name}
                              </option>
                            ))}
                        </select>
                      </td>
                      <td>
                        <Input
                          type={"number"}
                          placeholder="No Of Workers"
                          onChange={handleChange}
                          name="workers"
                          errorMsg={errors.workers}
                          value={state.workers}
                        />
                      </td>
                      <td>
                        <Input
                          type={"number"}
                          placeholder="No Of Hours"
                          onChange={handleChange}
                          name="working_hours"
                          errorMsg={errors.working_hours}
                          value={state.working_hours}
                        />
                      </td>
                      <td>
                        <Input
                          type={"number"}
                          placeholder="Total Hours"
                          onChange={handleChange}
                          name="total_hours"
                          errorMsg={errors.total_hours}
                          value={state.total_hours}
                        />
                      </td>
                      <td>
                        <Input
                          type="location"
                          name="location"
                          placeholder="Enter location"
                          locationChange={handlelocation}
                          errorMsg={errors.location}
                          value={state.location}
                        />
                        {/* <select
                          onChange={handleChange}
                          name="location"
                          value={state.location}
                        >
                          <option value="location-1">Select Location</option>
                          {location &&
                            location.map((el, i) => (
                              <option value={el} key={i}>
                                {el}
                              </option>
                            ))}
                        </select> */}
                      </td>
                      <td>
                        <Input
                          placeholder="Add Comments"
                          onChange={handleChange}
                          name="comments"
                          value={state.comments}
                        />
                      </td>
                      <td>
                        <div className="d-flex">
                          {Images?.length ? (
                            Images?.map((item, idx) => (
                              <div className="position-relative" key={idx}>
                                <button
                                  onClick={() => removeFile(item.id || item)}
                                  style={{
                                    background: "transparent",
                                    border: 0,
                                    position: "absolute",
                                    top: 2,
                                    right: 0,
                                  }}
                                >
                                  <img
                                    src="/assets/vectors/close-icon.svg"
                                    alt=""
                                  />
                                </button>
                                <img
                                  src={item.file || item.url}
                                  style={{
                                    width: 50,
                                    height: 50,
                                    objectFit: "cover",
                                  }}
                                />
                              </div>
                            ))
                          ) : imageLoader ? (
                            <Loader />
                          ) : (
                            <>
                              <label htmlFor="file" className="d-flex me-20">
                                <img
                                  className="clip"
                                  src="/assets/vectors/clip.svg"
                                  alt="clip"
                                />
                                <div className="text">Attach File(s)</div>
                              </label>
                              <input
                                id="file"
                                className="d-none"
                                type="file"
                                onChange={handleImageUpload("manPower")}
                              />
                            </>
                          )}
                        </div>
                      </td>
                      <td>
                        <div className="d-flex align-items-center">
                          {dataLoader ? (
                            <button className="btn sm btn-primary create__btn">
                              <Loader />
                            </button>
                          ) : (
                            <button
                              className="btn sm btn-primary create__btn"
                              onClick={() => handleCreate()}
                            >
                              <img
                                src="/assets/vectors/check-mark.png"
                                alt="submit"
                                style={{ maxWidth: 24 }}
                              />
                            </button>
                          )}
                          <button
                            className="btn sm create__btn ms-2 cancelEdit"
                            onClick={() => cancelEdit()}
                          >
                            <img
                              src="/assets/vectors/close-icon.png"
                              alt="cancel"
                              style={{ maxWidth: 24 }}
                            />
                          </button>
                        </div>
                      </td>
                    </tr>
                  )
                )
              )}

              <tr>
                <td>
                  <select
                    name="company_id"
                    onChange={handleChange}
                    className={`${errors.company_id && "error"}`}
                    value={!isEditing.editing ? state.company_id : ""}
                  >
                    <option value="">Select Company</option>
                    {companies &&
                      companies?.map((el) => (
                        <option value={el.id} key={el.id}>
                          {el.name}
                        </option>
                      ))}
                  </select>
                </td>
                <td>
                  <Input
                    type={"number"}
                    placeholder="No Of Workers"
                    onChange={handleChange}
                    name="workers"
                    errorMsg={errors.workers}
                    value={!isEditing.editing ? state.workers : ""}
                  />
                </td>
                <td>
                  <Input
                    type={"number"}
                    placeholder="No Of Hours"
                    onChange={handleChange}
                    name="working_hours"
                    errorMsg={errors.working_hours}
                    value={!isEditing.editing ? state.working_hours : ""}
                  />
                </td>
                <td>
                  <Input
                    type={"number"}
                    placeholder="Total Hours"
                    onChange={handleChange}
                    name="total_hours"
                    errorMsg={errors.total_hours}
                    value={!isEditing.editing ? state.total_hours : ""}
                  />
                </td>
                <td>
                  <Input
                    type="location"
                    name="location"
                    placeholder="Enter location"
                    locationChange={handlelocation}
                    errorMsg={errors.location}
                    defaultValue={state?.location}
                  />
                  {/* <select
                    onChange={handleChange}
                    name="location"
                    value={!isEditing.editing ? state.location : ""}
                  >
                    <option value="location-1">Select Location</option>
                    {location &&
                      location.map((el, i) => (
                        <option value={el} key={i}>
                          {el}
                        </option>
                      ))}
                  </select> */}
                </td>
                <td>
                  <Input
                    placeholder="Add Comments"
                    onChange={handleChange}
                    name="comments"
                    value={!isEditing.editing ? state.comments : ""}
                  />
                </td>
                <td>
                  <div className="d-flex">
                    {Images?.length && !isEditing.editing ? (
                      Images?.map((item, idx) => (
                        <div className="position-relative" key={idx}>
                          <button
                            onClick={() => removeFile(item.id || item)}
                            style={{
                              background: "transparent",
                              border: 0,
                              position: "absolute",
                              top: 2,
                              right: 0,
                            }}
                          >
                            <img src="/assets/vectors/close-icon.svg" alt="" />
                          </button>
                          <img
                            src={item.file || item.url}
                            style={{
                              width: 50,
                              height: 50,
                              objectFit: "cover",
                            }}
                          />
                        </div>
                      ))
                    ) : !isEditing.editing && imageLoader ? (
                      <Loader />
                    ) : (
                      <>
                        <label
                          htmlFor="file"
                          className="d-flex me-20"
                          onClick={() => {
                            setIsEditing({ editing: false, id: null });
                            setImages([]);
                          }}
                        >
                          <img
                            className="clip"
                            src="/assets/vectors/clip.svg"
                            alt="clip"
                          />
                          <div className="text">Attach File(s)</div>
                        </label>
                        <input
                          id="file"
                          className="d-none"
                          type="file"
                          onChange={handleImageUpload("manPower")}
                        />
                      </>
                    )}
                  </div>
                </td>
                <td>
                  {dataLoader ? (
                    <button className="btn sm btn-primary create__btn">
                      <Loader />
                    </button>
                  ) : (
                    <button
                      className="btn sm btn-primary create__btn"
                      onClick={() => handleCreate()}
                    >
                      +
                    </button>
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </InfiniteScroll>
    </>
  );
};

export default ManPower;
