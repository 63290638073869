export const deformatNumber = (value) => {
  if (typeof value !== "string") {
    return value;
  }
  return value.replace(/,/g, "");
};

export const NumberFormatter = (value) => {
  if (value === "" || value === undefined) {
    return "";
  } else if (typeof value !== "string") {
    return new Intl.NumberFormat("en-US").format(value);
  } else {
    const sanitizedValue = value.replace(/,/g, "");
    if (/^\d+$/.test(sanitizedValue)) {
      const bigIntValue = parseInt(sanitizedValue);
      return bigIntValue.toLocaleString("en-US");
    } else {
      const sanitizedInput = sanitizedValue.replace(/[^0-9]/g, "");
      if (sanitizedInput !== "") {
        const bigIntValue = parseInt(sanitizedInput);
        return bigIntValue.toLocaleString("en-US");
      } else {
        return "";
      }
    }
  }
};

export const handleNegativeInput = (event) => {
  if (/^[A-Za-z]$/.test(event.key) || event.key === "-" || event.key === "e") {
    event.preventDefault();
  }
};
