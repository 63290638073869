import {
  PasswordReset,
  SignatureListing,
  UpdateUserProfile,
} from "app/redux/actions";
import Loader from "components/Loader";
import { use } from "i18next";
import React, { useEffect, useState } from "react";
import PhoneInput, { parsePhoneNumber } from "react-phone-number-input";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import {
  editUserValidation,
  notifyError,
  notifySuccess,
  resetPasswordValidation,
} from "utilities";
import EsigNature from "./EsigNature";

const Settings = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.AuthReducer.user);
  const profile = useSelector((state) => state.AuthReducer.updateProfile);
  const location = useSelector((state) => state.AuthReducer.location);
  const [LoadingData, SetLoadingData] = useState(false);

  const [values, setValues] = useState({
    currentPassword: "",
    newPassword: "",
    confirmNewPassword: "",
  });

  const countrycode = parsePhoneNumber(toString((profile == null ) ? user?.telephone : profile?.telephone));
  
  const [editUser, setEditUser] = useState({
    first_name: (profile == null ) ? user?.first_name : profile?.first_name,
    last_name: (profile == null ) ? user?.last_name : profile?.last_name,
    gender: (profile == null ) ? user?.user_detail?.gender : user?.user_detail?.gender,
    telephone: (profile == null ) ? user?.telephone : profile?.telephone,
    country_code: countrycode == null ? location?.location?.country?.code : countrycode?.country,
  });

  console.log(editUser)

  const handleEditUserChange = (prop) => (event) => {
    setEditUser({ ...editUser, [prop]: event.target.value });
  };

  const [validation, setValidation] = useState({});
  const [isValid, setIsValid] = useState(false);

  const [formError, setformError] = useState({});

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handlePhoneNumber = (value) => {
    if(value != null){
      if(value.length > 8){
        const countrycode = parsePhoneNumber(value);
        setEditUser({
          ...editUser,
          ["telephone"]: value,
          ["country_code"]: countrycode.country,
        });
        // setEditUser({ ...editUser, ["country_code"]: countrycode.country });
      } else {
          setEditUser({
            ...editUser,
            ["telephone"]: null,
          });
      }
    }
  };

  useEffect(() => {
    props.set_Value({
      title: props.title,
      subTitle: props.subTitle,
      withLogs: props.withLog,
    });
  }, []);

  useEffect(() => {
    if (isValid && Object.keys(validation).length === 0) {
      SetLoadingData(true);
      const data = {
        current_password: values.currentPassword,
        new_password: values.newPassword,
        confirmed: values.confirmNewPassword,
      };
      dispatch(PasswordReset(data))
        .then((res) => {
          SetLoadingData(false);
          notifySuccess("Password changed successfully");
        })
        .catch((err) => {
          SetLoadingData(false);
          notifyError(
            err.response?.data?.message ?? "Error resetting password"
          );
        });
    }
  }, [validation]);

  useEffect(() => {
    if (isValid && Object.keys(validation).length === 0) {
      SetLoadingData(true);
      const payload = {
        first_name: editUser?.first_name,
        last_name: editUser?.last_name,
        telephone: editUser?.telephone,
        country_code: editUser.country_code,
        gender: editUser?.gender,
      };

      dispatch(UpdateUserProfile(payload))
        .then((res) => {
          SetLoadingData(false);
          notifySuccess("User Updated Successfully");
          history.push("/admin/dashboard");
        })
        .catch((error) => {
          SetLoadingData(false);
          notifyError(error.response?.data?.message);
        });
    }
  }, [formError]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setValidation(resetPasswordValidation(values));
    setIsValid(true);
  };

  const handleEditUserSubmit = (e) => {
    e.preventDefault();
    setformError(editUserValidation(editUser));
    setIsValid(true);
  };

  const [signatureListing, setSignaturelisting] = useState();
  const [loader, setloader] = useState(false);
  const [tab, setTab] = useState("edituser");

  useEffect(() => {
    if (tab == "signature") {
      setloader(true);
      dispatch(SignatureListing({}))
        .then((res) => {
          setloader(false);
          setSignaturelisting(res);
        })
        .catch((err) => {
          setloader(false);
        });
    }
  }, [tab == "signature"]);

  const [tabIndex, setTabIndex] = useState(0);

  return (
    <div>
      <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
        <TabList>
          <Tab
            onClick={() => {
              setTab("edituser");
            }}first_name
          >
            EDIT USER PROFILE
          </Tab>
          <Tab
            onClick={() => {
              setTab("password");
            }}
          >
            CHANGE PASSWORD
          </Tab>
          <Tab
            onClick={() => {
              setTab("signature");
            }}
          >
            USER SIGNATURE
          </Tab>
        </TabList>
        <TabPanel>
          <div className="form changePaswordForm">
            <div className="container-fluid px-0">
              <div className="row">
                <div className="col-6">
                  <div className="custom-form-control">
                    <label htmlFor="firstName">First Name</label>
                    <input
                      type="text"
                      defaultValue={editUser.first_name}
                      placeholder="Enter Your first name"
                      onChange={handleEditUserChange("first_name")}
                      style={{ textTransform: "capitalize" }}
                    />
                    {formError.first_name && (
                      <p className="error">{formError.first_name}</p>
                    )}
                  </div>
                </div>
                <div className="col-6">
                  <div className="custom-form-control">
                    <label htmlFor="lastName">Last Name</label>
                    <input
                      type="text"
                      defaultValue={editUser.last_name}
                      placeholder="Enter Your last name"
                      onChange={handleEditUserChange("last_name")}
                      style={{ textTransform: "capitalize" }}
                    />
                    {formError.last_name && (
                      <p className="error">{formError.last_name}</p>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <div className="custom-form-control">
                  <label htmlFor="number">Mobile Number</label>
                  <div className="phone_number_input">
                    <PhoneInput
                      className="number_input"
                      placeholder="Enter phone number"
                      international
                      countryCallingCodeEditable={false}
                      defaultCountry={location?.location?.country?.code}
                      value={editUser.telephone}
                      onChange={(phone) => handlePhoneNumber(phone)}
                    />
                  </div>
                  {formError.telephone && (
                    <p className="error">{formError.telephone}</p>
                  )}
                </div>
              </div>
              <div className="col-6">
                <div className="custom-form-control">
                  <label htmlFor="email">Gender</label>
                  <select
                    id="projects"
                    value={editUser?.gender}
                    onChange={handleEditUserChange("gender")}
                  >
                    <option value="">Gender</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                  </select>
                  {formError.gender && (
                    <p className="error">{formError.gender}</p>
                  )}
                </div>
              </div>
            </div>
            <div className="bottom">
              {LoadingData ? (
                <div className="btn btn-primary btn-auth">
                  <Loader />
                </div>
              ) : (
                <button
                  className="btn btn-primary btn-auth"
                  style={{ width: "207px", maxWidth: "none" }}
                  onClick={handleEditUserSubmit}
                >
                  UPDATE USER
                </button>
              )}
            </div>
          </div>
        </TabPanel>
        {tab == "password" && (
          <TabPanel>
            <div className="form changePaswordForm">
              <div className="custom-form-control">
                <label htmlFor="password">Current Password</label>
                <input
                  type="password"
                  onChange={handleChange("currentPassword")}
                  placeholder="*****************"
                />
                {validation.currentPass && (
                  <p className="error">{validation.currentPass}</p>
                )}
              </div>
              <div className="custom-form-control">
                <label htmlFor="password">New Password</label>
                <input
                  type="password"
                  onChange={handleChange("newPassword")}
                  placeholder="*****************"
                />
                {validation.newPass && (
                  <p className="error">{validation.newPass}</p>
                )}
              </div>
              <div className="custom-form-control">
                <label htmlFor="password">Confirm New Password</label>
                <input
                  type="password"
                  onChange={handleChange("confirmNewPassword")}
                  placeholder="*****************"
                />
                {validation.newConfirmPass && (
                  <p className="error">{validation.newConfirmPass}</p>
                )}
              </div>
              <div className="bottom">
                {!LoadingData ? (
                  <button
                    className="btn btn-primary btn-auth"
                    style={{ width: "207px", maxWidth: "none" }}
                    onClick={handleSubmit}
                  >
                    CHANGE PASSWORD
                  </button>
                ) : (
                  <div className="btn btn-primary btn-auth">
                    <Loader />
                  </div>
                )}
              </div>
            </div>
          </TabPanel>
        )}
      </Tabs>
      <div className="container-fluid">
        {loader && <Loader />}
        <div className="row">
          {tab == "signature" &&
            signatureListing?.length > 0 &&
            signatureListing?.map((signature, idx) => (
              <div
                key={idx}
                className="col-lg-5 col-md-5 m-3"
                style={{
                  border: "1px solid rgba(0,0,0, 0.1)",
                  borderRadius: "0.4rem",
                  position: "relative",
                }}
              >
                {signature?.signature.length > 0 ? (
                  <img
                    height={"250px"}
                    style={{
                      borderTopRightRadius: "0.4rem",
                      borderTopLeftRadius: "0.4rem",
                      objectFit: "contain",
                    }}
                    src={signature?.signature[0]}
                  />
                ) : (
                  <div
                    style={{
                      borderTopRightRadius: "0.4rem",
                      borderTopLeftRadius: "0.4rem",
                      height: "250px",
                      position: "relative",
                    }}
                  >
                    <div
                      style={{
                        textAlign: "center",
                        fontFamily: `${signature?.style}`,
                        fontSize: "70px",
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%,-50%)",
                      }}
                    >
                      {signature?.signature_text}
                    </div>
                  </div>
                )}

                <div className="p-3">
                  <h4
                    className="signatureTitle"
                    style={{ textTransform: "uppercase" }}
                    title={signature.name}
                  >
                    {signature?.name}
                  </h4>
                  <p
                    className="signatureDesciption"
                    title={signature?.description}
                  >
                    {signature?.description}
                  </p>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};
export default Settings;
