import React from "react";
import Input from "../../components/Input";
import TimeSelect from "../../components/TimeSelect";
import Loader from "components/Loader";
import InfiniteScroll from "react-infinite-scroll-component";
import moment from "moment";
const Equipment = ({
  state,
  Listing,
  fetchData,
  location,
  equipments,
  dataLoader,
  meta,
  errors,
  handleDelete,
  handleChange,
  handleCreate,
  setstate,
  handleEdit,
  isEditing,
  cancelEdit,
  setIsEditing,
  setImages,
  handlelocation
}) => {
  return (
    <>
      <h4 className="fs-20 mb-20">Equipment</h4>
      <InfiniteScroll
        dataLength={Listing?.length ?? 0}
        next={fetchData}
        hasMore={meta && meta?.total > Listing?.length}
        loader={dataLoader ? <Loader /> : null}
        height={"100%"}
        style={{ overflow: "scroll", overflowX: "hidden", maxHeight: "550px" }}
        className="infinite-scroll w-100"
      >
        <div className="table-container dailylog_table">
          <table>
            <thead>
              <tr>
                <th>Equipment Name</th>
                <th>Hours Operating</th>
                <th>Hours Idle</th>
                <th>Cost Code</th>
                <th>Location</th>
                <th>Inspection Time</th>
                <th>Comments</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {dataLoader ? (
                <tr>
                  <td colSpan={7}>
                    <Loader />
                  </td>
                </tr>
              ) : (
                Listing?.map((item) =>
                  isEditing.id !== item.id ? (
                    <tr key={item.id}>
                      <td>{item?.equipment?.name}</td>
                      <td>{item?.operating_hours}</td>
                      <td>{item?.idle_hours}</td>
                      <td>{item?.cost_code ?? "No Cost Code"}</td>
                      <td>{item?.location ?? "No Location"}</td>
                      <td>{item?.inspection_time}</td>
                      <td>{item?.comments ?? "No Comments"}</td>
                      <td>
                        <button
                          className="btn btn-contained-2 me-10 create__btn"
                          onClick={() => handleEdit(item)}
                        >
                          <img
                            className="me-0"
                            src="/assets/vectors/edit-table.svg"
                            alt="edit"
                          />
                        </button>
                        <button
                          className="btn btn-contained-2 ms-10 create__btn"
                          onClick={() => handleDelete(item)}
                        >
                          <img
                            className="me-0"
                            src="/assets/vectors/delete-table.svg"
                            alt="delete"
                          />
                        </button>
                      </td>
                    </tr>
                  ) : (
                    <tr key={item.id}>
                      <td>
                        <select
                          onChange={handleChange}
                          name="equipment_id"
                          value={state.equipment_id}
                        >
                          <option value="">Select Equipments</option>
                          {equipments?.map((eq) => (
                            <option value={eq.id} key={eq.id}>
                              {eq.name}
                            </option>
                          ))}
                        </select>
                        {errors.equipment_id && (
                          <p className="error">{errors.equipment_id}</p>
                        )}
                      </td>
                      <td>
                        <Input
                          type={"number"}
                          placeholder="Hours Operating"
                          name="operating_hours"
                          onChange={handleChange}
                          errorMsg={errors.operating_hours}
                          value={state.operating_hours}
                        />
                      </td>
                      <td>
                        <Input
                          type={"number"}
                          placeholder="Hours Idle"
                          onChange={handleChange}
                          name="idle_hours"
                          errorMsg={errors.idle_hours}
                          value={state.idle_hours}
                        />
                      </td>
                      <td>
                        <Input
                          type={"number"}
                          placeholder="Cost Code"
                          onChange={handleChange}
                          name="cost_code"
                          value={state.cost_code}
                        />
                      </td>
                      <td>
                        <Input
                          type="location"
                          name="location"
                          placeholder="Enter location"
                          locationChange={handlelocation}
                          errorMsg={errors.location}
                          value={state?.location}
                        />
                        {/* <select
                          onChange={handleChange}
                          name="location"
                          value={state.location}
                        >
                          <option value="">Select Location</option>

                          {location?.map((item, idx) => (
                            <option value={item} key={idx}>
                              {item}
                            </option>
                          ))}
                        </select> */}
                      </td>
                      <td>
                        <TimeSelect
                          style={{ width: "100%" }}
                          showSecond={false}
                          use12Hours={true}
                          placeholder={"00:00"}
                          onChange={(e) =>
                            setstate({
                              ...state,
                              inspection_time: e.format("HH:mm:ss"),
                            })
                          }
                          value={
                            state.inspection_time &&
                            moment(state?.inspection_time, "HH:mm:ss")
                          }
                        />
                        {errors?.inspection_time && (
                          <p className="error">{errors?.inspection_time}</p>
                        )}
                      </td>
                      <td>
                        <Input
                          placeholder="Add Comments"
                          onChange={handleChange}
                          name="comments"
                          value={state.comments}
                        />
                      </td>
                      <td>
                        <div className="d-flex align-items-center">
                          {dataLoader ? (
                            <button className="btn sm btn-primary create__btn">
                              <Loader />
                            </button>
                          ) : (
                            <button
                              className="btn sm btn-primary create__btn"
                              onClick={() => handleCreate()}
                            >
                              <img
                                src="/assets/vectors/check-mark.png"
                                alt="submit"
                                style={{ maxWidth: 24 }}
                              />
                            </button>
                          )}
                          <button
                            className="btn sm create__btn ms-2 cancelEdit"
                            onClick={() => cancelEdit()}
                          >
                            <img
                              src="/assets/vectors/close-icon.png"
                              alt="cancel"
                              style={{ maxWidth: 24 }}
                            />
                          </button>
                        </div>
                      </td>
                    </tr>
                  )
                )
              )}
              <tr>
                <td>
                  <select
                    onChange={handleChange}
                    name="equipment_id"
                    value={!isEditing.editing ? state.equipment_id : undefined}
                  >
                    <option value="">Select Equipments</option>
                    {equipments &&
                      equipments?.map((eq) => (
                        <option value={eq.id} key={eq.id}>
                          {eq.name}
                        </option>
                      ))}
                  </select>
                  {errors.equipment_id && (
                    <p className="error">{errors.equipment_id}</p>
                  )}
                </td>
                <td>
                  <Input
                    type={"number"}
                    placeholder="Hours Operating"
                    name="operating_hours"
                    onChange={handleChange}
                    errorMsg={errors.operating_hours}
                    value={
                      !isEditing.editing ? state.operating_hours : undefined
                    }
                  />
                </td>
                <td>
                  <Input
                    type={"number"}
                    placeholder="Hours Idle"
                    onChange={handleChange}
                    name="idle_hours"
                    errorMsg={errors.idle_hours}
                    value={!isEditing.editing ? state.idle_hours : undefined}
                  />
                </td>
                <td>
                  <Input
                    type={"number"}
                    placeholder="Cost Code"
                    onChange={handleChange}
                    name="cost_code"
                    value={!isEditing.editing ? state.cost_code : undefined}
                  />
                </td>
                <td>
                  {/* <select
                    onChange={handleChange}
                    name="location"
                    value={!isEditing.editing ? state.location : undefined}
                  >
                    <option value="">Select Location</option>
                    {location?.map((item, idx) => (
                      <option value={item} key={idx}>
                        {item}
                      </option>
                    ))}
                  </select> */}
                  <Input
                    type="location"
                    name="location"
                    placeholder="Enter location"
                    locationChange={handlelocation}
                    errorMsg={errors.location}
                    value={!isEditing.editing ? state.location : undefined}
                  />
                </td>
                <td>
                  <TimeSelect
                    style={{ width: "100%" }}
                    showSecond={false}
                    use12Hours={true}
                    placeholder={"00:00"}
                    onChange={(e) =>
                      setstate({
                        ...state,
                        inspection_time: e.format("HH:mm:ss"),
                      })
                    }
                  />
                  {errors?.inspection_time && (
                    <p className="error">{errors?.inspection_time}</p>
                  )}
                </td>
                <td>
                  <Input
                    placeholder="Add Comments"
                    onChange={handleChange}
                    name="comments"
                    value={!isEditing.editing ? state.comments : undefined}
                  />
                </td>
                <td>
                  {dataLoader ? (
                    <button className="btn sm btn-primary create__btn">
                      <Loader />
                    </button>
                  ) : (
                    <button
                      className="btn sm btn-primary create__btn"
                      onClick={() => handleCreate()}
                    >
                      +
                    </button>
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </InfiniteScroll>
    </>
  );
};

export default Equipment;
