import React, { useEffect, useRef } from "react";
import { NavLink, useParams } from "react-router-dom";
import moment from "moment";
import { Calendar } from "@fullcalendar/core";
import dayGridPlugin from "@fullcalendar/daygrid";
import Collapse from "../components/Collapse";
import { useDispatch } from "react-redux";
import { GetCalendarLogs } from "app/redux/actions";
const myEventsList = [
  {
    id: 0,
    title: "All Day Event very long title",
    allDay: true,
    start: new Date(2015, 3, 0),
    end: new Date(2015, 3, 1),
  },
  {
    id: 1,
    title: "Long Event",
    start: new Date(2015, 3, 7),
    end: new Date(2015, 3, 10),
  },

  {
    id: 2,
    title: "DTS STARTS",
    start: new Date(2016, 2, 13, 0, 0, 0),
    end: new Date(2016, 2, 20, 0, 0, 0),
  },

  {
    id: 3,
    title: "DTS ENDS",
    start: new Date(2016, 10, 6, 0, 0, 0),
    end: new Date(2016, 10, 13, 0, 0, 0),
  },

  {
    id: 4,
    title: "Some Event",
    start: new Date(2015, 3, 9, 0, 0, 0),
    end: new Date(2015, 3, 10, 0, 0, 0),
  },
];

const DailyLogCalender = (props) => {
  const params = useParams();
  const dispatch = useDispatch();
  const ref = useRef();
  useEffect(() => {
    props.set_Value({
      title: props.title,
      withSettings: props.withSettings,
      rootClassName: props.rootClassName,
      mainClassName: props.mainClassName,
    });
    dispatch(GetCalendarLogs()).then((res) => {
      var calendar = new Calendar(ref.current, {
        plugins: [dayGridPlugin],
        timeZone: "UTC",
        initialView: "dayGridMonth",
        events: res,
        editable: true,
        selectable: true,
      });
      calendar.render();
    });
  }, []);
  return (
    <>
      <div className="top b-0 pb-20 d-flex align-items-center flex-row">
        <div className="tabs">
          <NavLink exact to={`/admin/project/${params?.id}/tool/daily-logs`} className="tab">
            List
          </NavLink>
          <NavLink to={`/admin/project/${params?.id}/tool/daily-log/calendars`} className="tab">
            Calender
          </NavLink>
        </div>
      </div>

      <div className="calender-container">
        <div className="calender-left" ref={ref}></div>
        <div className="calender-right">
          <div className="d-flex justify-content-between">
            <div>
              <h4 className="fs-20">2 Approved Entries</h4>
              <div className="mt-1">Thursday, 10th 2022</div>
            </div>
            <div>
              <button className="btn btn-primary-outlined">COMPLETE DAY</button>
            </div>
          </div>
          <div className="entries mt-20">
            {[
              {
                name: "MANPOWER",
              },
              {
                name: "NOTES",
              },
            ].map((el, idx) => {
              return (
                <div key={"entry" + idx} className="entry">
                  <Collapse closed title={el.name}>
                    Lorem
                  </Collapse>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default DailyLogCalender;
