import { exportPaymentRequest, invoicepaymentRequest } from "app/redux/actions";
import ExportSignatureModal from "components/ExportSignatureModal";
import Loader from "components/Loader";
import { use } from "i18next";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";

const PaymentRequestDetail = (props) => {
  const dispatch = useDispatch();
  const { projectId, InvoiceId, id } = useParams();

  const [loader, setLoader] = useState(true);

  const [requestData, setRequestData] = useState(null);

  const paymentRequestDetails = () => {
    const payload = {
      invoiceId: InvoiceId,
      projectId: projectId,
      requestId: id,
    };
    dispatch(invoicepaymentRequest(payload))
      .then((res) => {
        setRequestData(res.data);
        setLoader(false);
      })
      .catch((error) => {
        console.log(error);
        setLoader(false);
      });
  };
  useEffect(() => {
    props.set_Value({
      title: props.title,
      rootClassName: props.rootClassName,
      mainClassName: props.mainClassName,
    });
    paymentRequestDetails();
  }, []);

  const handleExport = () => {
    const payload = {
      invoiceId: InvoiceId,
      projectId: projectId,
      requestId: id,
    };
    dispatch(exportPaymentRequest(payload))
      .then((res) => {
        if (res) {
          window.open(res?.link, "_blank");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // e-signatures
  const [isExportModalActive, setSignatures] = useState(false);
  const showExportModal = () => {
    setSignatures(true);
    setType(true);
  };

  const hideExportModal = () => {
    setSignatures(false);
    setType(false);
  };
  

  const createdAt = new Date(requestData?.created_at).toLocaleDateString();
  const updatedAt = new Date(requestData?.updated_at).toLocaleDateString();
  const issued_at = new Date(requestData?.issued_at).toLocaleDateString();
  const [type, setType] = useState(false);

  return (
    <>
    { isExportModalActive && (
      <ExportSignatureModal 
        active={isExportModalActive}
        hideModal={hideExportModal}
        setSignatures={setSignatures}
        titleText={"Export"}
        type={type}
      />
    )}
    <div>
      <div className="top pb-20" style={{ borderBottom: "none" }}>
        <div className="breadcrumb">
          <Link
            to={`/admin/project/${projectId}/tool/invoices/paymentRequest`}
            className="breadcrumb-item"
          >
            Invoices Payment Request
          </Link>
          <div className="breadcrumb-item">Payment Request Details</div>
        </div>
        <div className="options d-flex align-items-right">
          <button
            // onClick={handleExport}
            onClick={showExportModal}
            className="ms-10 btn btn-primary"
            style={{ background: "#FFF2D9", color: "#E79436" }}
          >
            EXPORT
          </button>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <div className="card-left m-10">
            <div className="table-container">
              <div className="row m-2 table-heading invoice__details">
                <div className="col-md-4">
                  <label className="headings">Request ID</label>
                  <div>{requestData?.id}</div>
                </div>
                <div className="offset-md-3 col-md-4">
                  <label className="headings">Invoice ID</label>
                  <div>{requestData?.invoice_id}</div>
                </div>
              </div>
              <form className="form m-10 project-detail-form mt-4">
                <div className="table-container invoice_details_table">
                  <table className="">
                    <thead>
                      <tr>
                        <td>
                          <h4 className="card-heading text-start">Notes</h4>
                        </td>
                        <td>
                          <h4 className="card-heading text-start">Amount</h4>
                        </td>
                        <td>
                          <h4 className="card-heading text-start">
                            Created At
                          </h4>
                        </td>
                        <td>
                          <h4 className="card-heading text-start">
                            Updated At
                          </h4>
                        </td>
                        <td>
                          <h4 className="text-start card-heading">Issued At</h4>
                        </td>
                      </tr>
                    </thead>
                    <tbody>
                      {loader ? (
                        <tr>
                          <td>
                            <Loader size={36} />
                          </td>
                        </tr>
                      ) : (
                        <tr>
                          <td
                            dangerouslySetInnerHTML={{
                              __html: requestData?.notes,
                            }}
                          ></td>
                          <td>{requestData?.amount}</td>
                          <td>{createdAt}</td>
                          <td>{updatedAt}</td>
                          <td>{issued_at}</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
};

export default PaymentRequestDetail;
