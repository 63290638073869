import React, { useState, useEffect } from "react";
import Modal from "../components/Modal";
import Input from "../components/Input";
import { useDispatch } from "react-redux";
import { MemberValidation, notifyError, notifySuccess } from "utilities";
import {
  CreateMembers,
  EditMember,
  GetProjectMembers,
  getRoles,
} from "app/redux/actions";
import { useParams } from "react-router";
import SelectDropdown from "./SelectDropdown";
import PhoneNumberInput from "./common/PhoneNumberInput";
const customStyles = {
  control: () => ({
    width: "100%",
    background: "#f9f9f9",
    display: "flex",
    borderRadius: "5px",
    border: "1px solid #dedee4",
    height: "48px",
    paddingLeft: "10px",
  }),
};
const MemberModal = ({ active, hideModal, setTeam, editData, titleText }) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [roles, setRoles] = useState();
  const [FormValidation, setFormValidation] = useState({});
  const [isValid, setisValid] = useState(false);
  const [values, setValues] = useState({
    role: editData?.role?.title ?? "",
    name: editData?.name ?? "",
    email: editData?.email ?? "",
    officeNo: editData?.office ?? "",
    mobileNo: editData?.mobile ?? "",
  });
  const handleChange = (prop) => (event) => {
    setValues({
      ...values,
      [prop]: event.target.value ? event.target.value : event.value,
    });
  };

  const handlePhoneInput = (value, name) => {
    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleRoleChange = (e) => {
    setValues({ ...values, ["role"]: e.value });
  };
  //   get roles
  useEffect(() => {
    setIsLoading(true);
    dispatch(getRoles())
      .then((res) => {
        setIsLoading(false);
        if (res) {
          const data = res?.map((item) => {
            return { value: item.id, label: item.title };
          });
          setRoles(data);
        }
      })
      .catch((err) => {
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    if (isValid && Object.keys(FormValidation).length === 0) {
      setLoading(true);
      const data = {
        role_id: editData ? editData?.role?.id : values.role,
        name: values.name,
        email: values.email,
        office: values.officeNo,
        mobile: values.mobileNo,
      };
      if (editData) {
        dispatch(EditMember(data, editData?.id))
          .then((res) => {
            setLoading(false);
            dispatch(GetProjectMembers(id)).then((res) => {
              setTeam(res);
            });
            notifySuccess("Member Updated Successfully");
            hideModal();
          })
          .catch((err) => {
            setLoading(false);
            notifyError(err?.response?.data?.message ?? "Error", 5000);
          });
      } else {
        dispatch(CreateMembers(data, id))
          .then((res) => {
            setLoading(false);
            dispatch(GetProjectMembers(id)).then((res) => {
              setTeam(res);
            });
            notifySuccess("Member created Successfully");
            hideModal();
          })
          .catch((err) => {
            setLoading(false);
            notifyError(err?.response?.data?.message ?? "Error", 5000);
          });
      }
    }
  }, [FormValidation]);

  const Formsubmit = (e) => {
    e.preventDefault();
    setFormValidation(MemberValidation(values));
    setisValid(true);
  };
  const defaultvalue = editData
    ? { label: values.role, value: editData?.role?.id }
    : { label: "Select Role", value: null };

  return (
    <Modal
      active={active}
      hideModal={hideModal}
      submitText={titleText}
      submit={Formsubmit}
      loader={loading}
    >
      <h4 className="mb-4" style={{ fontSize: "28px" }}>
        {titleText}
      </h4>
      <SelectDropdown
        className="basic-single"
        classNamePrefix="select"
        isLoading={isLoading}
        isSearchable={true}
        options={roles}
        styles={customStyles}
        defaultValue={defaultvalue}
        placeholder={"Select Role"}
        onChange={(e) => handleRoleChange(e)}
        validation={FormValidation?.role}
        loadingMessage={() => "Fetching Roles"}
        label={"Roles"}
      />
      <Input
        rootClassName="mb-20"
        label="Name"
        placeholder="Enter Name"
        onChange={handleChange("name")}
        errorMsg={FormValidation.name}
        defaultValue={values.name}
        inputCapitalize={"inputCapitalize"}
      />
      <Input
        rootClassName="mb-20"
        label="Email ID"
        placeholder="Enter Email ID"
        onChange={handleChange("email")}
        errorMsg={FormValidation.email}
        defaultValue={values.email}
      />
      <PhoneNumberInput
        lable="Ofiice Phone Number"
        name="officeNo"
        placeholder="Enter Ofiice Phone Number"
        errorMsg={FormValidation.officeNo}
        value={values.officeNo}
        required={true}
        onChange={(value) => handlePhoneInput(value, "officeNo")}
        rootClassName="mb-20"
      />

      <PhoneNumberInput
        lable="Mobile Number"
        name="mobileNo"
        placeholder="Enter Mobile Number"
        errorMsg={FormValidation.mobileNo}
        value={values.mobileNo}
        required={true}
        onChange={(value) => handlePhoneInput(value, "mobileNo")}
        rootClassName="mb-20"
      />
    </Modal>
  );
};

export default MemberModal;
