import React, { useState, useRef } from "react";
const SelectInput = ({
  setdrawingset,
  options,
  errorMsg,
  onchange,
  value,
  label,
  placeholder,
  module,
}) => {
  const ref = useRef();
  const [drop, setShowDrop] = useState();

  const handleOptionSelect = (val) => {
    setShowDrop(false);
    setdrawingset(val);
  };

  React.useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setShowDrop(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
  return (
    <div className="custom-form-control position-relative" ref={ref}>
      <label htmlFor="">
        {label} <span style={{ color: "tomato" }}>*</span>
      </label>
      <input
        type={"text"}
        placeholder={placeholder}
        defaultValue={value}
        onFocus={() => setShowDrop(true)}
        onChange={(e) => onchange(e)}
        className="dropInput"
      />
      {errorMsg && <p className="error-msg">{errorMsg}</p>}
      {drop && (
        <div className="drop__down">
          <ul className="unstyled">
            {options.map((item, index) => {
              return (
                <li
                  value={
                    module === "photo"
                      ? item.name
                      : item?.key
                  }
                  key={index}
                  onClick={() =>
                    handleOptionSelect(
                      module === "photo" || module === "todo"
                        ? item
                        : item.value
                    )
                  }
                >
                  {module === "photo"
                    ? item?.name
                    : item?.value}
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </div>
  );
};

export default SelectInput;
