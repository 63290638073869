import AuthLayout from "layouts/AuthLayout";
import CreationLayout from "layouts/CreationLayout";
import ListingLayout from "layouts/ListingLayout";
import PublicLayout from "layouts/PublicLayout";
import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
const MainRoutes = () => {
  return (
    <Router>
      <Switch>
        <Route path="/auth" render={() => <AuthLayout />} />
        <Route path="/create" render={() => <CreationLayout />} />
        <Route path="/admin" render={() => <ListingLayout />} />
        <Route path="/" render={() => <PublicLayout />} />
        <Redirect from="*" to="/" />
      </Switch>
    </Router>
  );
};
export default MainRoutes;
