import React, { useEffect, useState } from "react";
import EsignatureModal from "components/EsignatureModal";
import { useDispatch, useSelector } from "react-redux";
import { DeleteSignature, SignatureListing } from "app/redux/actions";
import Loader from "components/Loader";
import ConsentModel from "components/ConsentModel";
import { notifySuccess } from "utilities";

const EsigNature = (props) => {
  const dispatch = useDispatch();

  const [activeCategory, setActiveCategory] = useState(null);
  const [deleteSign, setDeleteModal] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [signatureListing, setSignaturelisting] = useState();
  const [deleteData, setDeleteData] = useState();
  const [loader, setloader] = useState(false);
  const [deleteloader, setDeleteLoader] = useState(false);

  const projectId = useSelector((state) => state.navigation.projectId);

  useEffect(() => {
    props.set_Value({
      title: props.title,
      rootClassName: props.rootClassName,
      mainClassName: props.mainClassName,
    });
  }, []);

  useEffect(() => {
    dispatch(
      SignatureListing({
      })
    )
      .then((res) => {
        setloader(false);
        setSignaturelisting(res);
      })
      .catch((err) => {
        setloader(false);
      });
  }, []);
  const showModal = () => {
    setModalVisible(true);
  };

  const hideModal = () => {
    setModalVisible(false);
  };

  const handleDelete = (signature) => {
    setDeleteLoader(false);
    setDeleteModal(true);
    setDeleteData(signature);
  }

  const signatureDelete = () => {
      setDeleteLoader(true);
      dispatch(
        DeleteSignature({
          project_id: projectId,
          signature_id: deleteData.id,
        })
      )
        .then((res) => {
          setloader(true);
          dispatch(
            SignatureListing({
              project_id: projectId,
            })
          ).then((res) => {
            setDeleteModal(false);
            setDeleteLoader(false);
            notifySuccess("Signature delete successfuly");
            setloader(false);
            setSignaturelisting(res);
          });
        })
        .catch((err) => {
          setloader(false);
        });
  }

  return (
    <>
      {modalVisible && (
        <EsignatureModal
          activecategory={activeCategory}
          setactivecategory={setActiveCategory}
          hideModal={hideModal}
          isModalActive={modalVisible}
          setSignaturelisting={setSignaturelisting}
          setloader={setloader}
        />
      )}
      {deleteSign && (
        <ConsentModel
          active={deleteSign}
          id={deleteData.id}
          onsubmit={signatureDelete}
          oncancel={() => setDeleteModal(false)}
          loader={deleteloader}
          title={deleteData.signature.name}
        />
      )}
      <div className="top" style={{ borderBottom: "none" }}>
        <div className="tabs"></div>
        <div className="options d-flex">
          <button
            className="ms-10 btn btn-primary-outlined"
            onClick={showModal}
          >
            <img src="/assets/vectors/add.svg" alt="add" />
            NEW SIGNATURE
          </button>
        </div>
      </div>
      <div className="container-fluid">
        {loader && <Loader />}
        <div className="row">
          {signatureListing?.length > 0 ? (
            signatureListing?.map((signature, idx) => (
              <div
                key={idx}
                className="col-lg-3 mx-3 p-0"
                style={{
                  border: "1px solid rgba(0,0,0, 0.1)",
                  borderRadius: "0.4rem",
                  position: "relative",
                }}
              >
                {signature?.signature.length > 0 ? (
                  <img
                    width={"100%"}
                    style={{
                      borderTopRightRadius: "0.4rem",
                      borderTopLeftRadius: "0.4rem",
                      objectFit: "scale-down",
                      borderBottom: "1px solid rgba(0,0,0, 0.1)",
                      height: "236px",
                    }}
                    src={signature?.signature[0]}
                  />
                ) : (
                  <div
                    style={{
                      borderTopRightRadius: "0.4rem",
                      borderTopLeftRadius: "0.4rem",
                      height: "250px",
                      position: "relative",
                    }}
                  >
                    <div
                      style={{
                        textAlign: "center",
                        fontFamily: `${signature?.style}`,
                        fontSize: "70px",
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%,-50%)",
                      }}
                    >
                      {signature?.signature_text}
                    </div>
                  </div>
                )}
                <img
                  className="me-10"
                  src="/assets/vectors/delete-table.svg"
                  alt="delete-table"
                  style={{
                    cursor: "pointer",
                    position: "absolute",
                    top: "15px",
                    right: "5px",
                    background: "aliceblue",
                    borderRadius: "0.2rem",
                  }}
                  title={"delete"}
                  onClick={() => handleDelete(signature)}
                />
                <div className="p-3">
                  <div className="d-flex" style={{ justifyContent: "space-between"}}>
                  <h6>Name:</h6>
                  <h4
                    className="signatureTitle"
                    style={{ textTransform: "uppercase" }}
                    title={signature.name}
                  >
                    {signature?.name}
                  </h4>
                  </div>
                  <p
                    className="signatureDesciption"
                    title={signature?.description}
                  >
                    {signature?.description}
                  </p>
                </div>
              </div>
            ))
          ) : (
          <div className="container eSignature-root">
            <h3>Don't Have E-Signature</h3>
            <p>Please Click Below Button and Upload Or Create E-Signature</p>
            <button className="ms-10 btn btn-primary" onClick={showModal}>
              CREATE-E-SIGNATURE
            </button>
          </div>
          )}
        </div>
      </div>
    </>
  );
};
export default EsigNature;
