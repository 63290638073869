import Loader from "components/Loader";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";

import { Link, useParams } from "react-router-dom";

const Owner = (props) => {
  const { id } = useParams();

  const [loader, setLoader] = useState(true);

  const contactsData = JSON.parse(localStorage.getItem("contracts"));

  useEffect(() => {
    props.set_Value({
      title: props.title,
      rootClassName: props.rootClassName,
      mainClassName: props.mainClassName,
    });
    if (contactsData) {
      setLoader(false);
    }
  }, []);

  const emptyData = [];
  return (
    <>
      {/* Bread Crumbs */}
      <div className="top pb-20" style={{ borderBottom: "none" }}>
        <div className="breadcrumb">
          <Link to={`/admin/project/${id}`} className="breadcrumb-item">
            Dashboard
          </Link>
          <Link
            to={`/admin/project/${id}/exportStatment`}
            className="breadcrumb-item"
          >
            Export Statment
          </Link>
          <div className="breadcrumb-item">Sub Contractor</div>
        </div>
      </div>
      {/* End Bread Crumbs */}
      {/* Export Section */}
      <div className="top pb-20 mb-20 mt-20" style={{ borderBottom: "none" }}>
        <div className="section-title">
          <h4>Owner</h4>
        </div>
        <div className="options d-flex align-items-right">
          {/* <button
            className="ms-10 btn btn-primary"
            style={{ background: "#FFF2D9", color: "#E79436" }}
          >
            EXPORT
          </button> */}
        </div>
      </div>
      {/* End Export Section */}
      {/* Contrator Section */}
      <h4 className="mb-3">Contract ( incl. CO )</h4>
      <div className="table-container">
        <div className="section m-20">
          <div className="section-title mb-10">
            <h4>Contracts</h4>
          </div>
          <div className="table-container">
            <table className="team">
              <thead>
                <tr>
                  <th colSpan={2}>Date</th>
                  <th className="text-center" colSpan={4}>
                    Description
                  </th>
                  <th className="text-center">Amount</th>
                </tr>
              </thead>
              <tbody>
                {loader ? (
                  <tr>
                    <td colSpan={5}>
                      <Loader />
                    </td>
                  </tr>
                ) : contactsData?.subContractors?.contracts.length <= 0 ? (
                  <tr>
                    <td>No Record Found</td>
                  </tr>
                ) : (
                  <>
                    {contactsData?.subContractors?.contracts.length > 0 ? (
                      contactsData?.subContractors?.contracts?.map(
                        (data, index) => {
                          const date = new Date(data?.created_at);
                          return (
                            <tr>
                              <td colSpan={2}>{date?.toLocaleDateString()}</td>
                              <td
                                className="text-center"
                                colSpan={4}
                                dangerouslySetInnerHTML={{
                                  __html: data?.description,
                                }}
                              ></td>
                              <td className="text-center">${data?.amount}</td>
                            </tr>
                          );
                        }
                      )
                    ) : (
                      <tr>
                        <td>there is no data</td>
                      </tr>
                    )}
                  </>
                )}
                <tr>
                  <td
                    style={{
                      textAlign: "center",
                      color: "#2F5597",
                      fontWeight: "bold",
                    }}
                    colSpan={6}
                  >
                    Total Contract
                  </td>
                  <td style={{ textAlign: "center", fontWeight: "bold" }}>
                    $ {contactsData?.project?.balanceRecievable ?? "0"}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        {/* Payment Reciveing */}
        <div className="section m-20">
          <div className="section-title mb-10">
            <h4>Payment Reciveing</h4>
          </div>

          <div className="table-container">
            <table className="team">
              <thead>
                <tr>
                  <th colSpan={2}>Date</th>
                  <th className="text-center" colSpan={4}>
                    Description
                  </th>
                  <th className="text-center">Recived Amount</th>
                </tr>
              </thead>
              <tbody>
                {contactsData?.subContractors?.contracts.length <= 0 ? (
                  <tr>
                    <td>No Record Found</td>
                  </tr>
                ) : (
                  <>
                    {contactsData?.subContractors?.contracts.length > 0 ? (
                      contactsData?.subContractors?.contracts?.map(
                        (data, index) => {
                          const date = new Date(data?.created_at);
                          return (
                            <tr>
                              <td colSpan={2}>{date?.toLocaleDateString()}</td>
                              <td
                                className="text-center"
                                colSpan={4}
                                dangerouslySetInnerHTML={{
                                  __html: data?.description,
                                }}
                              />
                              <td className="text-center">${data?.amount}</td>
                            </tr>
                          );
                        }
                      )
                    ) : (
                      <tr>
                        <td>there is no data</td>
                      </tr>
                    )}
                  </>
                )}
                <tr>
                  <td
                    style={{ textAlign: "center", fontWeight: "bold" }}
                    colSpan={6}
                  >
                    Total Received
                  </td>
                  <td style={{ textAlign: "center", fontWeight: "bold" }}>
                    $ {contactsData?.project?.totalRecievedAmounts ?? "0"}
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      textAlign: "center",
                      color: "red",
                      fontWeight: "bold",
                    }}
                    colSpan={6}
                  >
                    Balance Receivable
                  </td>
                  <td
                    style={{
                      textAlign: "center",
                      color: "red",
                      fontWeight: "bold",
                    }}
                  >
                    $ {contactsData?.project?.balanceRecievable ?? "0"}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {/* End Payment Reciveing */}
      {/* End Contractor Section */}
      {/* Permit and Other Fee */}
      <h4 className="mt-30 mb-3">Permit Fee and Other Reimbursement</h4>
      <div className="table-container">
        <div className="section m-20">
          {emptyData ? (
            <tr>
              <td>No Record Found</td>
            </tr>
          ) : (
            <>
              <div className="section-title mb-10">
                <h4>Permit Fee</h4>
              </div>
              <div className="table-container">
                <table className="team">
                  <thead>
                    <tr>
                      <th colSpan={2}>Date</th>
                      <th className="text-center" colSpan={4}>
                        Description
                      </th>
                      <th className="text-center">Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td colSpan={2}>10/10/2022</td>
                      <td className="text-center" colSpan={4}>
                        Its Test Description
                      </td>
                      <td className="text-center">$ 100</td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          textAlign: "center",
                          color: "#2F5597",
                          fontWeight: "bold",
                        }}
                        colSpan={6}
                      >
                        Total Contract
                      </td>
                      <td style={{ textAlign: "center", fontWeight: "bold" }}>
                        $ 1000 -
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </>
          )}
        </div>

        <div className="section m-20">
          {emptyData ? (
            <tr></tr>
          ) : (
            <>
              <div className="section-title mb-10">
                <h4>Payment Reciveing</h4>
              </div>
              <div className="table-container">
                <table className="team">
                  <thead>
                    <tr>
                      <th colSpan={2}>Date</th>
                      <th className="text-center" colSpan={4}>
                        Description
                      </th>
                      <th className="text-center">Recived Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td colSpan={2}>10/10/2022</td>
                      <td className="text-center" colSpan={4}>
                        Its Test Description 12
                      </td>
                      <td className="text-center">$ 100</td>
                    </tr>
                    <tr>
                      <td
                        style={{ textAlign: "center", fontWeight: "bold" }}
                        colSpan={6}
                      >
                        Total Received
                      </td>
                      <td style={{ textAlign: "center", fontWeight: "bold" }}>
                        $ 1000 -
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          textAlign: "center",
                          color: "red",
                          fontWeight: "bold",
                        }}
                        colSpan={6}
                      >
                        Balance Receivable
                      </td>
                      <td
                        style={{
                          textAlign: "center",
                          color: "red",
                          fontWeight: "bold",
                        }}
                      >
                        $ 1000 -
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </>
          )}
        </div>
        {/* End Permit and Other Fee */}
        {/* Payment Reciveing */}
      </div>
      {/* End Payment Reciveing */}
    </>
  );
};

export default Owner;
