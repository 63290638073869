import React from "react";
import { useState } from "react";
import Input from "./Input";
import InputDate from "./InputDate";
import Modal from "./Modal";
import Select from "react-select";
import { incidentValidationForm, notifyError, notifySuccess } from "utilities";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { CreateIncidents, UpdateIncident } from "app/redux/actions";
import moment from "moment";

const caseTypes = [
  { value: 1, label: "Death", title: "death" },
  { value: 2, label: "Away From Work", title: "away_from_work" },
  { value: 3, label: "Job Transfer", title: "job_transfer" },
  { value: 4, label: "Other", title: "other" },
];
const illnessTypes = [
  { value: 1, label: "Injury", title: "injury" },
  { value: 2, label: "Skin Disaster", title: "skin_disoster" },
  { value: 3, label: "Respiratory Condition", title: "respiratory_condition" },
  { value: 4, label: "Poisoning", title: "poisoning" },
  { value: 5, label: "Hearing Loss", title: "hearing_loss" },
  { value: 6, label: "Other", title: "other" },
];

const IncidentModel = ({
  active,
  hideModal,
  projectId,
  incidentListing,
  titleText,
  editData,
  setEditData,
}) => {
  const dispatch = useDispatch();
  const [loadingIncident, setLoadingIncident] = useState(false);
  const [isValidIncident, setIsValidIncident] = useState(false);
  const [incidentValidation, setIncidentValidation] = useState({});
  const [incidentLocation, setIncidentLocation] = useState("");

  const [incident, setIncident] = useState({
    employeeName: "",
    jobTitle: "",
    leaveDays: "",
    caseType: "",
    illnessType: "",
  });
  const [formState, setFormState] = useState({
    injuryDate: "",
  });

  const handleChange = (name) => (event) => {
    setIncident({ ...incident, [name]: event?.target?.value });
  };
  const handleLocation = (value) => {
    setIncidentLocation(value);
  };

  const handleCaseType = (event) => {
    setIncident({ ...incident, ["caseType"]: event?.title });
  };

  const handleIllnessType = (event) => {
    setIncident({ ...incident, ["illnessType"]: event?.title });
  };

  const dateChangeHandler = (name, date) => {
    setFormState((prevState) => {
      return { ...prevState, [name]: date };
    });
  };

  const incidentSubmit = (e) => {
    e.preventDefault();
    setIncidentValidation(
      incidentValidationForm(incident, formState, incidentLocation)
    );
    setIsValidIncident(true);
  };
  useEffect(() => {
    if (isValidIncident && Object.keys(incidentValidation).length === 0) {
      const payload = {
        employee_name: incident?.employeeName,
        job_title: incident.jobTitle,
        date_of_injury: moment(formState.injuryDate).format(
          "YYYY-MM-DD hh:mm:ss"
        ),
        location: incidentLocation,
        case_type: incident.caseType,
        leave_days: incident.leaveDays,
        type_of_illness: incident.illnessType,
      };
      if (editData) {
        setLoadingIncident(true);
        dispatch(UpdateIncident(payload, projectId, editData.id))
          .then((res) => {
            notifySuccess("Incident Updated Successfully!");
            setLoadingIncident(false);
            incidentListing();
            setEditData(null);
            hideModal();
          })
          .catch((error) => {
            notifyError(error?.payload?.message ?? "something went wrong!");
            setLoadingIncident(false);
          });
      } else {
        setLoadingIncident(true);
        dispatch(CreateIncidents(payload, projectId))
          .then((res) => {
            notifySuccess("Incident Created Successfully!");
            setLoadingIncident(false);
            incidentListing();
            hideModal();
          })
          .catch((error) => {
            notifyError("something went wrong");
            setLoadingIncident(false);
          });
      }
    }
  }, [incidentValidation]);

  useEffect(() => {
    if (editData) {
      setIncident({
        employeeName: editData?.employee_name ?? "",
        jobTitle: editData?.job_title ?? "",
        leaveDays: editData?.leave_days ?? "",
        caseType: editData?.case_type ?? "",
        illnessType: editData?.type_of_illness ?? "",
      });

      const date = new Date(editData?.date_of_injury);
      setFormState({
        injuryDate: date ?? "",
      });
      setIncidentLocation(editData?.location ?? "");
    }
  }, []);

  const caseTypeValue = editData
    ? { label: editData?.case_type }
    : { label: "Select Type", value: null };

  const illnessTypeValue = editData
    ? { label: editData?.type_of_illness }
    : { label: "Select Type", value: null };

  return (
    <Modal
      active={active}
      hideModal={hideModal}
      submit={incidentSubmit}
      loader={loadingIncident}
    >
      <h4 className="mb-4" style={{ fontSize: "28px" }}>
        {titleText}
      </h4>
      <Input
        rootClassName="mb-20"
        label="Employee Name"
        placeholder="Enter Employee Name"
        onChange={handleChange("employeeName")}
        defaultValue={incident?.employeeName}
        inputCapitalize={"inputCapitalize"}
        errorMsg={incidentValidation.employeeName}
      />
      <Input
        rootClassName="mb-20"
        label="Employee Job Title"
        placeholder="Enter Job Title"
        onChange={handleChange("jobTitle")}
        defaultValue={incident?.jobTitle}
        errorMsg={incidentValidation.jobTitle}
      />
      <Input
        type="location"
        label="Incident Location"
        placeholder="Enter location"
        locationChange={handleLocation}
        defaultValue={incidentLocation}
        errorMsg={incidentValidation.incidentLocation}
      />
      <Input
        label="Leave Days"
        type="number"
        placeholder="Enter Leave Days"
        onChange={handleChange("leaveDays")}
        defaultValue={incident?.leaveDays}
        errorMsg={incidentValidation.leaveDays}
      />
      <InputDate
        withInfo
        tooltipText={"Date of Injury"}
        selected={formState.injuryDate}
        label="Date of Injury*"
        onChange={(date) => dateChangeHandler("injuryDate", date)}
      />
      {incidentValidation.injuryDate && (
        <p className="error mt-3">{incidentValidation.injuryDate}</p>
      )}
      <label
        style={{
          display: "flex",
          alignItems: "center",
          marginTop: "10px",
          marginBottom: "12px",
          fontSize: "12px",
        }}
      >
        Case Type*
      </label>
      <Select
        className="basic-single"
        classNamePrefix="select"
        options={caseTypes}
        defaultValue={caseTypeValue}
        value={caseTypes?.find((e) => e?.title === incident?.caseType ?? null)}
        onChange={(e) => handleCaseType(e, "caseType")}
      />
      {incidentValidation?.caseType && (
        <p className="error" style={{ color: "#dc3545", marginBottom: "10px" }}>
          {incidentValidation?.caseType}
        </p>
      )}

      <label
        style={{
          display: "flex",
          alignItems: "center",
          marginBottom: "12px",
          fontSize: "12px",
          marginTop: "30px",
        }}
      >
        Type of Illness*
      </label>
      <Select
        className="basic-single"
        classNamePrefix="select"
        options={illnessTypes}
        defaultValue={illnessTypeValue}
        value={illnessTypes?.find(
          (e) => e?.title === incident.illnessType ?? null
        )}
        onChange={(e) => handleIllnessType(e, "illnessType")}
      />
      {incidentValidation?.illnessType && (
        <p className="error" style={{ color: "#dc3545", marginBottom: "10px" }}>
          {incidentValidation?.illnessType}
        </p>
      )}
    </Modal>
  );
};
export default IncidentModel;
