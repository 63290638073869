export const LOGIN = "LOGIN";
export const SIGNUP = "SIGNUP";
export const LOGOUT = "LOGOUT";
export const COMPANYTYPE = "COMPANYTYPE";
export const OTP_VERIFICATION = "OTP_VERIFICATION";
export const COUNTRY_TYPE = "COUNTRY_TYPE";
export const GET_DIRECTORIES = "GET_DIRECTORIES";
export const PROJECT_CREATE = "PROJECT_CREATE";
export const UPDATE_PROJECT = "UPDATE_PROJECT";
export const ADD_TOOLS = "ADD_TOOLS";
export const ADD_DIRECTORIES = "ADD_DIRECTORIES";
export const GET_PROJECTS = "GET_PROJECTS";
export const GET_COMPANIES = "GET_COMPANIES";
export const GET_TEMPLATES = "GET_TEMPLATES";
export const GET_STAGES = "GET_STAGES";
export const GET_TYPES = "GET_TYPES";
export const PROJECT_FILTER = "PROJECT_FILTER";
export const TOOLS_FILTER = "TOOLS_FILTER";
export const MEETING_CREATE = "MEETING_CREATE";
export const GET_MEETINGS = "GET_MEETINGS";
export const RESET_PROJECT = "RESET_PROJECT";
export const LOCATION = "LOCATION";
export const PROJECT_NOTIFICATION = 'PROJECT_NOTIFICATION';
export const STAGE_CREATE = 'STAGE_CREATE';
export const UPDATE_USER = "UPDATE_USER";

