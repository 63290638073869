import PhotoUpload from "../../components/PhotoUpload";
import React, { useState } from "react";
import ModalPhotoUpload from "../../components/ModalPhotoUpload";
import PhotoViewModal from "../../components/PhotoViewModal";
import Loader from "components/Loader";
import { FaRegEye } from "react-icons/fa";
const Photos = ({
  state,
  Listing,
  location,
  dataLoader,
  errors,
  handleDelete,
  handleChange,
  Images,
  handleImageUpload,
  imageLoader,
  removeFile,
  handleCreate,
  trade,
  album,
  handleSelectChange,
  ModalHide,
  ModalShow,
  handleViewImage,
  ModalActive,
  ModalView,
  handlelocation,
}) => {
  const [ActiveItem, setActiveItem] = useState();
  const [isHovering, setIsHovering] = useState(false);

  return (
    <>
      <h4 className="fs-20 mb-20 border-bottom">Photos</h4>
      <ModalPhotoUpload
        isModalActive={ModalActive}
        hideModal={ModalHide}
        trade={trade}
        album={album}
        handleSelectChange={handleSelectChange}
        location={location}
        handlelocation={handlelocation}
        handleImageUpload={handleImageUpload}
        imageLoader={imageLoader}
        removeFile={removeFile}
        Images={Images}
        handleCreate={handleCreate}
        handleChange={handleChange}
        dataLoader={dataLoader}
        errors={errors}
        state={state}
      />
      <div className="d-flex flex-wrap">
        {dataLoader ? (
          <div className="text-center w-100 mt-4">
            <Loader size={60} />
          </div>
        ) : (
          <>
            <PhotoUpload showModal={ModalShow} />
            {Listing?.map((el, idx) => (
              <div
                key={idx}
                onMouseEnter={() => setIsHovering({ value: true, data: el.id })}
                onMouseLeave={() => setIsHovering({ value: false, data: "" })}
                className="d-flex flex-wrap align-items-start ms-3 mb-3"
              >
                <button
                  key={el.id}
                  className="log_image me-3"
                  style={{
                    position: "relative",
                  }}
                  onClick={() => {
                    handleViewImage();
                    setActiveItem(el);
                  }}
                >
                  <img
                    src={el?.files[0]?.url ?? el?.files[0]?.file}
                    alt="image"
                  />
                  <span
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%,-50%)",
                    }}
                  >
                    {isHovering.value && isHovering.data == el.id && (
                      <FaRegEye
                        style={{
                          width: "30px",
                          height: "30px",
                        }}
                      />
                    )}
                  </span>
                </button>
              </div>
            ))}
          </>
        )}
        {ModalView && (
          <PhotoViewModal
            isModalActive={ModalView}
            hideModal={handleViewImage}
            data={ActiveItem}
            handleDelete={handleDelete}
          />
        )}
      </div>
    </>
  );
};

export default Photos;
