export const dropDownCustomStyles = {
    control: () => ({
      width: "100%",
      background: "#f9f9f9",
      display: "flex",
      borderRadius: "5px",
      border: "1px solid #dedee4",
      height: "48px",
      paddingLeft: "10px",
    }),
  };