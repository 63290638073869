import { ExportSubContractorForm, GetAgreementData } from "app/redux/actions";
import Input from "components/Input";
import Loader from "components/Loader";
import TextEditor from "components/textEditor/TextEditor";
import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import Select from "react-select";
import PhoneInput from "react-phone-number-input";

import {
  ContractorAgreementValidation,
  FinalAffidavitValidation,
  notifyError,
  notifySuccess,
  PartialAffidavitValidation,
  RiderValidationForm,
  subContractValidationForm,
} from "utilities";

const scopOfWork = [
  {
    id: 16,
    discription:
      "Without restricting the generality of the work which shall be performed within the Contract Price, it is clearly understood and agreed that the Subcontractor shall provide all labor, material, equipment, scaffolding, tools, supervision, supplies, applicable taxes, freight, insurance, engineering, layout, surveying work in accordance with the Contract Documents and shall include, but is not limited to, the following",
  },
  {
    id: 14,
    discription:
      "In addition to the duties and responsibilities set forth in the Subcontract Agreement between INCODM and Subcontractor, Subcontractor agrees to the following:",
  },
  {
    id: 13,
    discription:
      "1. Subcontractor agrees to coordinate its work with INCODM and other trades such that performance and completion of its work, including but not limited to, procurement, submittals, shop drawings, fabrications, delivery of equipment, testing, inspections and installation, comply with all aspects of INCODM's schedule, as revised from time to time. Subcontractor further understands that its work (a) will be performed concurrently with the work of other subcontractors on the Project, (b) is dependent upon the progress of the work of other subcontractors and (c) may not be performed in a continuous manner due tothe activities of other subcontractors and job conditions. The Contract Price fully accounts for and considers the fact that Subcontractor will be working under the above-referenced conditions.",
  },
  {
    id: 1,
    discription:
      "2. If, in the opinion of INCODM, Subcontractor is behind schedule and is so notified by INCODM, Subcontractor shall employ appropriate means to overcome such schedule delays, including, but not limited to working overtime, adding multiple work shifts, manpower and equipment. All of these means shall be without additional compensation and shall continue until the progress of the work is, in the opinion of INCODM, in conformance with the construction schedule. If Subcontractor fails to take appropriate means to overcome delays, INCODM may take any action it deems prudent to attempt to put Project or Subcontractor's work back on schedule and deduct costs of such actions from moneys due or to become due to Subcontractor, without prejudice to INCODM’s rights under Article XIV of this Subcontract.",
  },
  {
    id: 2,
    discription:
      "3. Subcontractor shall be responsible for any and all costs of stand-by trades and other subcontractors if Subcontractor elects to, or is required due to its failure to maintain scheduled performance, work other than normal working hours or on Saturdays, Sundays or Holidays. ",
  },
  {
    id: 3,
    discription:
      "4. If the progress of the work is delayed due to no fault attributable to Subcontractor or if INCODM determines it is in its best interest to accelerate the work, INCODM may direct Subcontractor in writing to work overtime and/or weekends, and/or add multiple work shifts, manpower and equipment. If so directed, INCODM shall pay Subcontractor for such overtime, weekend work, and/or additional shifts, manpower and equipment in an amount equal to the actual additional wages paid and additional rental costs incurred for equipment, plus taxes and other charges imposed by law on such additional wages required to be paid by Subcontractor.",
  },
  {
    id: 4,
    discription:
      "5. Subcontractor will be fully responsible for any and all layout of its work. Axis lines and bench marks will be established by INCODM at each floor level, unless such work is specifically required under the Subcontract Agreement as an obligation of Subcontractor. ",
  },
  {
    id: 5,
    discription:
      "5. Subcontractor will be fully responsible for any and all layout of its work. Axis lines and bench marks will be established by INCODM at each floor level, unless such work is specifically required under the Subcontract Agreement as an obligation of Subcontractor. ",
  },
  {
    id: 6,
    discription:
      "6. Subcontractor is responsible for any and all scaffolding, hoisting and power connections required for its work. ",
  },
  {
    id: 7,
    discription:
      "7. Subcontractor shall designate one or more persons who shall be Subcontractor’s authorized on-site supervisor and off-site representative. Such authorized representative(s) shall be the only person(s) to whom INCODM shall issue instructions, orders or directions, except in an emergency. The designated on-site supervisor shall be on the job site at all times when Subcontractor’s Work is ongoing, shall have full understanding of the Subcontractor’s contractual responsibilities on the project as well as a complete understanding of the overall project, Subcontractor or its duly authorized representative with decision-making authority shall attend periodic progress meetings as may be called by INCODM, the Owner or the Architect. ",
  },
  {
    id: 8,
    discription:
      "8. Subcontractor agrees that INCODM shall have the right to assign or transfer INCODM's responsibilitiesand obligations to any other entity, including but not limited to the Owner or any entity affiliated with or related to INCODM. In the event of such assignment or transference, Subcontractor shall be fully obligated to the assignee or transferee to perform each and every obligation, whether express or implied, arising out of or relating to the Subcontract Agreement. ",
  },
  {
    id: 9,
    discription:
      "9. Subcontractor is responsible for any changes to site conditions necessary for the performance of its work at the full cost and expense of Subcontractor. Subcontractor is responsible for its method of access to the site. Any damage to streets, sidewalks, curbs, trees, utilities, adjacent properties and existing work, unless directly called for in the scope of work, that is a result of the performance of any work under this Subcontract Agreement shall be fully repaired to the satisfaction of INCODM and the Owner and/or authorities having jurisdiction thereof, at Subcontractor's sole cost and expense. ",
  },
  {
    id: 10,
    discription:
      "10. Contractor Registrations must be kept current by Subcontractor. If Subcontractor lets its registration expire or lapse, Subcontractor will be liable for all hard and soft costs to INCODM associated with this lapse including, but not limited to, attorneys’ fees. ",
  },
  {
    id: 11,
    discription:
      "11. Subcontractor shall perform any and all punchlist work within fourteen (14) days of receipt of punchlistfrom INCODM. If Subcontractor fails to complete all punchlist items or notify INCODM in writing of any items which will not be complete (and the reason why they will not be completed) then INCODM shall have the right to perform all incomplete work and hold Subcontractor responsible for all costs incurred, including INCODM's administrative costs and profit. ",
  },
  {
    id: 12,
    discription:
      "12. Subcontractor shall furnish and install any and all necessary materials and labor to complete its scopeof work in accordance with the Subcontract Documents, including but not limited to, Exhibit A through Exhibit B attached hereto. ",
  },
];

const category = [
  { id: 1, title: "WORK AGREEMENT" },
  // { id: 2, title: "SUBCONTRACTOR AGREEMENT" },
  { id: 3, title: "Rider 'A'" },
  { id: 4, title: "FINAL RELAEASE TO DATES" },
  { id: 5, title: "PARTIAL RELAEASE TO DATES" },
];

const Agreements = (props) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const userLocation = useSelector((state) => state?.AuthReducer?.location);

  const [activeCategory, setActiveCategory] = useState(null);

  const [isContValid, setIsContValid] = useState(false);

  const [contFormValidation, setContFormValidation] = useState({});
  const [riderValidation, setRiderValidation] = useState({});

  useEffect(() => {
    props.set_Value({
      title: props.title,
      subTitle: props.subTitle,
      withSettings: props.withSettings,
      rootClassName: props.rootClassName,
      mainClassName: props.mainClassName,
    });
    setActiveCategory(category[0].id);
  }, []);

  const [subContOptions, setSubContOptions] = useState([]);

  const [riderValue, setRiderValue] = useState(null);

  const [LoadingData, SetLoadingData] = useState(false);

  const [contAgreement, setContAgreement] = useState({
    workDate: "",
    contractor: "",
    subcontractor: "",
    project: "",
    owner: "",
    archetectEng: "",
    contractAmount: "",
    contact: "",
    payment_date: "",
  });

  const agreementData = () => {
    const payload = {
      projectId: id,
    };
    dispatch(GetAgreementData(payload))
      .then((res) => {
        if (res) {
          let item = [];
          res?.work?.subContractor?.map((data, index) => {
            item.push({
              value: index + 1,
              label: data,
            });
          });

          setSubContOptions([...item]);
          setContAgreement({
            contractor: res?.work?.contractor ?? "",
            project: res?.work?.project ?? "",
            owner: res?.work?.owner ?? "",
          });
          setRiderValue(res.rider);
          setPartials({
            undersigned: res?.final?.project ?? "",
            person: res.final?.company ?? "",
            jobId: res.final?.job_number ?? null,
            locatedAt: res.final?.address ?? "",
          });
          setFinal({
            ...final,
            undersigned: res?.final?.project ?? "",
            person: res.final?.company ?? "",
            jobId: res.final?.job_number ?? null,
            locatedAt: res.final?.address ?? "",
          });
        }
      })
      .catch((error) => {});
  };

  useEffect(() => {
    agreementData();
  }, []);

  const [description, setDescription] = useState("");
  const [finalValidation, setFinalValidation] = useState({});

  const [subContDescription, setSubContDescription] = useState("");
  const [subContractValidation, setSubContractValidation] = useState({});
  const [Location, setLocation] = useState("");
  const [isSubContValid, setIsSubContValid] = useState(false);

  const [subContAgreement, setSubContAgreement] = useState({
    agreementDate: "",
    contractorName: "",
    subContractorEmail: "",
    subContractorName: "",
    clientEmail: "",
    clientName: "",
    contractAmount: "",
    city: "",
    state: "",
    country: "",
  });

  const handleLocation = (value) => {
    setLocation(value);
  };

  const [rider, setRider] = useState({
    riderDate: "",
    riderProject: riderValue?.project,
    riderSubContname: riderValue?.subContractor[0],
  });

  const [partials, setPartials] = useState({
    undersigned: "",
    date: "",
    person: "",
    jobId: null,
    locatedAt: "",
    payment: "",
    completionDate: "",
    paymentDate: "",
    subContSigned: "",
    subsCriptionDate: "",
  });

  const [final, setFinal] = useState({
    date: "",
    undersigned: "",
    person: "",
    jobId: null,
    locatedAt: "",
    payment: "",
    completionDate: "",
    paymentDate: "",
    assignedDay: "",
    assignedMonth: "",
    subContSigned: "",
    subsCriptionDate: "",
  });

  const handlePhonNmbr = (value) => {
    setContAgreement({ ...contAgreement, ["contact"]: value });
  };

  const handleSubContractor = (name) => (event) => {
    setSubContAgreement({ ...subContAgreement, [name]: event.target.value });
  };

  const hanfldeFinalRelease = (name) => (event) => {
    setFinal({ ...final, [name]: event.target.value });
  };

  const handleDateChange = (event) => {
    setContAgreement({ ...contAgreement, ["workDate"]: event.target.value });
  };
  const handlepaymentDate = (event) => {
    setContAgreement({
      ...contAgreement,
      ["payment_date"]: event?.target?.value,
    });
  };

  const handleDropDownChange = (name) => (event) => {
    setContAgreement({ ...contAgreement, [name]: event?.label });
  };

  const handleInputChange = (name) => (event) => {
    setContAgreement({ ...contAgreement, [name]: event?.target?.value });
  };

  const [affidavitValidation, setAffidavitValidation] = useState({});

  const [isRiderValid, setIsRiderValid] = useState(false);
  const [isFinalValid, setIsFinalValid] = useState(false);

  const [isPartialValid, setIsPartialValid] = useState(false);

  const contractorSubmit = () => {
    if (activeCategory === 1) {
      setContFormValidation(
        ContractorAgreementValidation(contAgreement, description, Location)
      );
      setIsContValid(true);
    }
    if (activeCategory === 3) {
      setRiderValidation(RiderValidationForm(rider));
      setIsRiderValid(true);
    }
    if (activeCategory === 4) {
      setFinalValidation(FinalAffidavitValidation(final));
      setIsFinalValid(true);
    }
    if (activeCategory === 5) {
      setAffidavitValidation(PartialAffidavitValidation(partials));
      setIsPartialValid(true);
    }
  };

  const handleRiderInput = (name) => (event) => {
    setRider({ ...rider, [name]: event?.target?.value });
  };
  const handleRider = (name) => (event) => {
    setRider({ ...rider, [name]: event?.label });
  };

  const handleAffidavitChange = (name) => (event) => {
    setPartials({ ...partials, [name]: event?.target?.value });
  };

  const handleActiveCategory = (id) => {
    setActiveCategory(id);
  };

  useEffect(() => {
    if (isContValid && Object.keys(contFormValidation).length === 0) {
      const payload = {
        type: "work",
        work: {
          agreement_date: contAgreement.workDate,
          contractor: contAgreement.contractor,
          subContractor: contAgreement.subcontractor,
          project: contAgreement.project,
          owner: contAgreement.owner,
          architect: contAgreement.archetectEng,
          description: description,
          amount: contAgreement.contractAmount,
          location: Location,
          phone: contAgreement.contact,
          payment_date: contAgreement.payment_date,
        },
      };

      SetLoadingData(true);
      dispatch(ExportSubContractorForm(payload, id))
        .then((res) => {
          SetLoadingData(false);
          notifySuccess("be patient work pdf generatd!");
          window.open(res?.link, "_blank");
          window.location.reload();
        })
        .catch((error) => {
          console.log(error);
          SetLoadingData(false);
        });
    }
  }, [contFormValidation]);

  useEffect(() => {
    if (isSubContValid && Object.keys(subContractValidation).length === 0) {
      const payload = {
        type: "subContractor",
        subContractor: {
          agreement_date: subContAgreement.agreementDate,
          contractor: subContAgreement.contractorName,
          subContractor: subContAgreement.subContractorName,
          subContractor_email: subContAgreement.subContractorEmail,
          description: subContDescription,
          amount: subContAgreement.contractAmount,
          city: subContAgreement.city,
          state: subContAgreement.state,
          country: subContAgreement.country,
        },
      };
      SetLoadingData(true);
      dispatch(ExportSubContractorForm(payload, id))
        .then((res) => {
          SetLoadingData(false);
          notifySuccess("Be Patient! Partial Affidavit Report Created");
          window.open(res?.link, "_blank");
          window.location.reload();
        })
        .catch((error) => {
          console.log(error);
          SetLoadingData(false);
        });
    }
  }, [subContractValidation]);

  useEffect(() => {
    if (isRiderValid && Object.keys(riderValidation).length === 0) {
      const payload = {
        type: "rider",
        rider: {
          date: rider?.riderDate,
          project: rider.riderProject,
          subContractor: rider.riderSubContname,
        },
      };

      SetLoadingData(true);
      dispatch(ExportSubContractorForm(payload, id))
        .then((res) => {
          SetLoadingData(false);
          notifySuccess("Be Patient! Partial Affidavit Report Created");
          window.open(res?.link, "_blank");
          window.location.reload();
        })
        .catch((error) => {
          console.log(error);
          SetLoadingData(false);
        });
    }
  }, [riderValidation]);

  useEffect(() => {
    if (isFinalValid && Object.keys(finalValidation).length === 0) {
      const payload = {
        type: "final",
        final: {
          date: final?.date,
          project: final?.undersigned,
          location: final?.locatedAt,
          job: final?.jobId,
          amount: final?.payment,
          work_date: final?.completionDate,
          amount_date: final?.paymentDate,
          subContractor: final?.subContSigned,
          subscription_date: final?.subsCriptionDate,
        },
      };

      SetLoadingData(true);
      dispatch(ExportSubContractorForm(payload, id))
        .then((res) => {
          notifySuccess("Be Patient! Partial Affidavit Report Created");
          SetLoadingData(false);
          window.open(res?.link, "_blank");
          window.location.reload();
        })
        .catch((error) => {
          console.log(error);
          SetLoadingData(false);
        });
    }
  }, [finalValidation]);

  useEffect(() => {
    if (isPartialValid && Object.keys(affidavitValidation).length === 0) {
      const payload = {
        type: "partial",
        partial: {
          date: partials?.date,
          project: partials?.undersigned,
          location: partials?.locatedAt,
          job: partials?.jobId,
          amount: partials?.payment,
          work_date: partials?.completionDate,
          amount_date: partials?.paymentDate,
          subContractor: partials?.subContSigned,
          subscription_date: partials?.subsCriptionDate,
        },
      };

      SetLoadingData(true);
      dispatch(ExportSubContractorForm(payload, id))
        .then((res) => {
          notifySuccess("Be Patient! Partial Affidavit Report Created");
          SetLoadingData(false);
          window.open(res?.link, "_blank");
          window.location.reload();
        })
        .catch((error) => {
          console.log(error);
          SetLoadingData(false);
          notifyError("something went wrong");
        });
    }
  }, [affidavitValidation]);

  const riderProject = [{ value: 1, label: riderValue?.project }];
  const riderSubCont = [{ value: 1, label: riderValue?.subContractor[0] }];
  return (
    <>
      <div className="card">
        <div className="card-head d-flex justify-content-between">
          <h4>AGREEMENT FORMS</h4>
          {LoadingData ? (
            <button className="btn btn-primary btn-auth">
              <Loader />
            </button>
          ) : (
            <button
              className="btn btn-primary-outlined"
              onClick={() => contractorSubmit()}
            >
              EXPORT
            </button>
          )}
        </div>
        <div className="card-body p-0 d-flex">
          <div className="card-left">
            <div className="card-nav" style={{ position: "sticky", top: 0 }}>
              {category?.map((el, idx) => {
                const { title, id } = el;
                return (
                  <div
                    key={"body-nav-" + idx}
                    className={`nav-item ${id == activeCategory && "active"}`}
                    onClick={() => handleActiveCategory(id)}
                  >
                    {title}
                  </div>
                );
              })}
            </div>
          </div>
          <div className="card-right" style={{ margin: "0px 70px" }}>
            <h4 className="fs-20 mb-20 text-center">
              {category && category[activeCategory - 1]?.title}
            </h4>
            {activeCategory === 1 ? (
              <div className="comon_class mt-2">
                <div className="mt-2">
                  <div className="row">
                    <div className="col">
                      <span>THIS AGREEMENT IS MADE THIS:</span>
                      <Input
                        className="textInternalInput"
                        type={"date"}
                        onChange={(e) => handleDateChange(e, "workDate")}
                        errorMsg={contFormValidation.workDate}
                      />
                      <span>BY AND BETWEEN THE</span>
                    </div>
                    <div className="col">
                      <span>Payment Date:</span>
                      <Input
                        className="textInternalInput"
                        type={"date"}
                        onChange={(e) => handlepaymentDate(e, "payment_date")}
                        errorMsg={contFormValidation.payment_date}
                      />
                    </div>
                  </div>
                </div>

                <div className="mt-2">
                  <div className="row">
                    <div className="col">
                      <span>
                        <h4 style={{ paddingTop: "28px" }}>CONTRACTOR:</h4>
                      </span>
                      <Input
                        className="textInternalInput"
                        defaultValue={contAgreement?.contractor}
                        onChange={handleInputChange("contractor")}
                        errorMsg={contFormValidation?.contractor}
                      />
                      <span>
                        {" "}
                        <h4>and</h4>
                      </span>
                    </div>
                    <div className="col">
                      <span>
                        <h4 style={{ paddingTop: "28px" }}>PROJECT:</h4>
                      </span>
                      <Input
                        className="textInternalInput"
                        defaultValue={contAgreement?.project}
                        onChange={handleInputChange("project")}
                        errorMsg={contFormValidation?.project}
                      />
                    </div>
                  </div>
                </div>

                <div className="mt-2">
                  <div className="row">
                    <div className="col">
                      <span>
                        <h4 style={{ paddingTop: "28px" }}>SUBCONTRACTOR:</h4>
                      </span>
                      <Select
                        // className="contractorInput"
                        // styles={customStyles}
                        defaultValue={subContOptions}
                        classNamePrefix="select"
                        options={subContOptions}
                        value={subContOptions?.find(
                          (e) => e?.label === subContOptions[0]?.label ?? null
                        )}
                        onChange={handleDropDownChange("subcontractor")}
                      />
                      {contFormValidation?.subcontractor && (
                        <p
                          className="error"
                          style={{ color: "#dc3545", marginBottom: "10px" }}
                        >
                          {contFormValidation?.subcontractor}
                        </p>
                      )}
                    </div>
                  </div>
                </div>

                <div className="mt-2">
                  <div className="row">
                    <div className="col">
                      <span>
                        <h4 style={{ paddingTop: "28px" }}>OWNER:</h4>
                      </span>
                      <Input
                        placeholder="Owner Name"
                        className="textInternalInput"
                        defaultValue={contAgreement?.owner}
                        onChange={handleInputChange("owner")}
                        errorMsg={contFormValidation?.owner}
                      />
                    </div>
                    <div className="col">
                      <span>
                        <h4 style={{ paddingTop: "28px" }}>Phone Number</h4>
                      </span>
                      <div className="custom-form-control mb-3">
                        <div
                          className="phone_number_input"
                          style={{ borderRadius: "5px" }}
                        >
                          <PhoneInput
                            placeholder="Enter phone number"
                            international
                            countryCallingCodeEditable={false}
                            defaultCountry={
                              userLocation?.location?.country?.code
                            }
                            value={contAgreement.contact}
                            onChange={(value) => handlePhonNmbr(value)}
                          />
                        </div>
                        {contFormValidation?.contact && (
                          <p
                            className="error"
                            style={{ color: "#dc3545", marginBottom: "10px" }}
                          >
                            {contFormValidation?.contact}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mt-2">
                  <div className="row">
                    <div className="col">
                      <span>
                        {" "}
                        <h4 style={{ paddingTop: "28px" }}>
                          ARCHETECT/ENGINEER:
                        </h4>
                      </span>
                      <Input
                        placeholder="Eng Name"
                        className="textInternalInput"
                        onChange={handleInputChange("archetectEng")}
                        errorMsg={contFormValidation?.archetectEng}
                      />
                    </div>
                    <div className="col">
                      <span>
                        {" "}
                        <h4 style={{ paddingTop: "28px" }}>Location:</h4>
                      </span>
                      <Input
                        type={"location"}
                        className="contractorInput"
                        locationChange={handleLocation}
                      />
                      {contFormValidation?.Location && (
                        <p
                          className="error"
                          style={{ color: "#dc3545", marginBottom: "10px" }}
                        >
                          {contFormValidation?.Location}
                        </p>
                      )}
                    </div>
                  </div>
                </div>

                <div className="mt-2">
                  <div className="row">
                    <h4 style={{ paddingTop: "28px" }}>
                      1. SUBCOTRACT WORK(as follow job site direction)
                    </h4>
                  </div>
                  <div className="col mt-3">
                    <TextEditor
                      className="contractorInput"
                      overview={description}
                      changeOverview={(value) => setDescription(value)}
                    />
                    {contFormValidation?.description && (
                      <p
                        className="error"
                        style={{ color: "#dc3545", marginBottom: "10px" }}
                      >
                        {contFormValidation?.description}
                      </p>
                    )}
                  </div>
                </div>

                <div className="mt-2">
                  <div className="row">
                    <h4 style={{ paddingTop: "28px" }}>
                      2. SUBCONTRACT AMOUNT:
                    </h4>
                  </div>
                  <div style={{ width: "100%" }}>
                    <p className="pt-3">
                      Contractor agrees to pay Subcontractor for satisfactory
                      and timely performance and completion of Subcontract Work
                      <br /> ($). Retainage shall be ten percent (10%), which
                      may be equal to the percentage retained from Contractor's
                      payment by Owner for Subcontract Work.
                    </p>
                    <div className="row">
                      <div className="col">
                        <Input
                          type={"number"}
                          placeholder="Enter amount"
                          className={`textInternalInput ${
                            contFormValidation?.contractAmount &&
                            "borderBottopm"
                          }`}
                          onChange={handleInputChange("contractAmount")}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mt-2">
                  <div>
                    <h4 style={{ paddingTop: "28px" }}>
                      3. Certificate Insurance:
                    </h4>
                  </div>
                  <div style={{ width: "100%" }}>
                    <p className="pt-3">
                      Subcontractor shall purchase and maintain insurance that
                      will protect Subcontractor form claims arising out of
                      Subcontractor operations under this Agreement, whether the
                      operations are by Subcontractor, or any of the
                      Subcontractor's consultants or subcontractor or anyone
                      directly or indirectly employed by any of them, or by
                      anyone for whose acts any of them may be liable. The
                      information below must be indicated on the Certificate of
                      Insurance.
                    </p>
                  </div>
                </div>

                <div className="mt-2">
                  <div>
                    <h4 style={{ paddingTop: "28px" }}>
                      Certificate holder: INCODM INC at 2030 Hudson St Suite 914{" "}
                      <br />
                      Fort Lee, NJ 07024 Job Location: Additional Insured: the{" "}
                      <br />
                      same as Certificate holder
                    </h4>
                  </div>
                </div>

                <div className="mt-2">
                  <div>
                    <h4 style={{ paddingTop: "28px" }}>4. PAYMENT SCHEDULE:</h4>
                  </div>
                  <div style={{ width: "100%" }}>
                    <p className="pt-3">
                      20% - Initial Payment, 70% - work in progress, 10% - when
                      work is complete.
                    </p>
                    <h4 className="pt-3">
                      ***The initial payment will be issued after we have
                      received the Certificate of Insurance from subcontractor.
                    </h4>
                  </div>
                </div>
              </div>
            ) : activeCategory === 3 ? (
              <div className="mt-4">
                <div className="mt-2">
                  <div className="row">
                    <div className="col">
                      <span>
                        <h4 style={{ paddingTop: "28px" }}>DATE:</h4>
                      </span>
                      <Input
                        // className="contractorInput"
                        type={"date"}
                        onChange={handleRiderInput("riderDate")}
                        errorMsg={riderValidation.riderDate}
                      />
                    </div>
                  </div>
                </div>

                <div className="mt-2">
                  <div className="row">
                    <div className="col">
                      <span>
                        <h4 style={{ paddingTop: "28px" }}>PROJECT:</h4>
                      </span>
                      <Select
                        // className="contractorInput"
                        // styles={customStyles}
                        classNamePrefix="select"
                        options={riderProject}
                        defaultValue={riderProject}
                        value={riderProject.find(
                          (e) => e.label === riderValue?.project ?? null
                        )}
                        onChange={handleRider("riderProject")}
                      />
                      {riderValidation?.riderProject && (
                        <p
                          className="error"
                          style={{ color: "#dc3545", marginBottom: "10px" }}
                        >
                          {riderValidation?.riderProject}
                        </p>
                      )}
                    </div>
                  </div>
                </div>

                <div className="mt-2">
                  <div className="row">
                    <div className="col">
                      <span>
                        <h4 style={{ paddingTop: "28px" }}>
                          SUBCONTRACTOR’S NAME:
                        </h4>
                      </span>
                      <Select
                        // className="contractorInput"
                        // styles={customStyles}
                        classNamePrefix="select"
                        options={riderSubCont}
                        defaultValue={riderSubCont}
                        value={riderSubCont.find(
                          (e) =>
                            e.label === riderValue?.subContractor[0] ?? null
                        )}
                        onChange={handleRider("riderSubContname")}
                      />
                      {riderValidation?.riderSubContname && (
                        <p
                          className="error"
                          style={{ color: "#dc3545", marginBottom: "10px" }}
                        >
                          {riderValidation?.riderSubContname}
                        </p>
                      )}
                    </div>
                  </div>
                </div>

                <div className="mt-2">
                  <h4 style={{ paddingTop: "28px" }}>A. SCOPE OF WORK:</h4>
                  {scopOfWork.map((data, index) => {
                    return (
                      <div
                        className="comon_class discription_class mt-4"
                        key={index}
                      >
                        <p>{data.discription}</p>
                      </div>
                    );
                  })}
                </div>
                <div className="mt-4">
                  <h4>EXHIBIT A: Architect Drawing</h4>
                  <h4>EXHIBIT B: Bid Proposal</h4>
                </div>
              </div>
            ) : activeCategory === 4 ? (
              <div className="mt-4">
                <h5 className="text-center">
                  SUBCONTRACTOR AFFIDAVIT OF PAYMENT AND FINAL RELAEASE TO DATE
                </h5>
                <div className="mt-2">
                  <div className="" style={{ width: "100%" }}>
                    <div className="row">
                      <div className="col">
                        <span className="mt-2">WHEREAS, the undersigned,</span>
                        <Input
                          className={`textInternalInput ${
                            finalValidation?.undersigned && "borderBottopm"
                          }`}
                          defaultValue={final.undersigned}
                          onChange={hanfldeFinalRelease("undersigned")}
                        />
                      </div>
                      <div className="col">
                        <span className="mt-2">Date:</span>
                        <Input
                          type={"date"}
                          className={`textInternalInput ${
                            finalValidation?.date && "borderBottopm"
                          }`}
                          defaultValue={final.date}
                          onChange={hanfldeFinalRelease("date")}
                        />
                      </div>
                      <p className="pt-4">
                        . has been retained by INCODM Inc, its successors and/or
                        assigns on the building or premises commonly
                      </p>
                    </div>
                    <div className="row">
                      <div className="col">
                        <span>known as</span>
                        <Input
                          className={`textInternalInput ${
                            finalValidation?.person && "borderBottopm"
                          }`}
                          defaultValue={final?.person}
                          onChange={hanfldeFinalRelease("person")}
                        />
                      </div>
                    </div>
                    <div className="row mt-4">
                      <div className="col">
                        <span>INCODM job</span>
                        <Input
                          className={`textInternalInput ${
                            finalValidation?.jobId && "borderBottopm"
                          }`}
                          defaultValue={final.jobId}
                          onChange={hanfldeFinalRelease("jobId")}
                        />
                      </div>
                      <div className="col">
                        <span>(“Project”) located at:</span>
                        <Input
                          className={`textInternalInput ${
                            finalValidation?.locatedAt && "borderBottopm"
                          }`}
                          defaultValue={final.locatedAt}
                          onChange={hanfldeFinalRelease("locatedAt")}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="first_heading mt-2">
                  <div className="" style={{ width: "100%", display: "flex" }}>
                    <p style={{ paddingTop: "28px", lineHeight: "3.0" }}>
                      Upon receipt of this payment of ${" "}
                      <span>
                        <Input
                          className={`textInternalInput ${
                            finalValidation?.payment && "borderBottopm"
                          }`}
                          defaultValue={final.payment}
                          onChange={hanfldeFinalRelease("payment")}
                        ></Input>
                      </span>
                      the undersigned hereby releases, waives and discharges any
                      rights, including but not limited to, lien rights, the
                      undersigned now has or may have against JD Phoenix Inc,
                      the General Contractor of the project and any surety
                      involved herein, arising out of or relating to the Project
                      on account of labor performed, work done and/or material
                      furnished up to and including :{" "}
                      <div className="row">
                        <div className="col">
                          <span className="">Work Date</span>
                          <Input
                            className={`textInternalInput ${
                              finalValidation?.completionDate && "borderBottopm"
                            }`}
                            type={"date"}
                            defaultValue={final.completionDate}
                            onChange={hanfldeFinalRelease("completionDate")}
                          />
                        </div>
                      </div>
                    </p>
                  </div>
                </div>
                <div className="first_heading mt-2">
                  <div className="" style={{ width: "100%", display: "flex" }}>
                    <p style={{ paddingTop: "28px", lineHeight: "3.0" }}>
                      The undersigned certifies that, upon receipt of this
                      payment all of its subcontractors, suppliers, laborers,
                      material or other providers of services for the Project
                      have been paid in full for up to and including{" "}
                      <div className="row">
                        <div className="col">
                          <span className="ml-2 mr-2">Amount Date</span>
                          <Input
                            className={`textInternalInput ${
                              finalValidation?.paymentDate && "borderBottopm"
                            }`}
                            type={"date"}
                            defaultValue={final.paymentDate}
                            onChange={hanfldeFinalRelease("paymentDate")}
                          />
                        </div>
                      </div>
                      . The undersigned further certifies that all Social
                      Security taxes, withholding taxes, sales and use taxes,
                      Workmen’s Compensation taxes and insurance premiums which
                      have accrued in connection with the Project have been
                      fully paid and discharged.
                    </p>
                  </div>
                </div>
                <div className="first_heading mt-2">
                  <div className="" style={{ width: "100%" }}>
                    <p style={{ paddingTop: "28px", lineHeight: "3.0" }}>
                      IN WITNESS WHEREOF, the undersigned has executed these
                      presents this{" "}
                    </p>
                  </div>
                </div>
                <div className="first_heading mt-2">
                  <div className="" style={{ width: "100%", marginTop: 20 }}>
                    <div className="row">
                      <div className="col">
                        <span>Sub-Contractor: </span>
                        <Input
                          className={`textInternalInput ${
                            finalValidation?.subContSigned && "borderBottopm"
                          }`}
                          defaultValue={final.subContSigned}
                          onChange={hanfldeFinalRelease("subContSigned")}
                        />
                      </div>
                      <div className="col">
                        <span>SubsCription Date: </span>
                        <Input
                          type={"date"}
                          className={`textInternalInput ${
                            finalValidation?.subsCriptionDate && "borderBottopm"
                          }`}
                          defaultValue={final.subsCriptionDate}
                          onChange={hanfldeFinalRelease("subsCriptionDate")}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : activeCategory === 5 ? (
              <div className="comon_class mt-4">
                <h5 className="text-center">
                  SUBCONTRACTOR AFFIDAVIT OF PAYMENT AND PARTIALS RELAEASE TO
                  DATE
                </h5>
                <div className="first_heading mt-2">
                  <div className="" style={{ width: "100%" }}>
                    <div className="row">
                      <div className="col">
                        <span> WHEREAS, the undersigned,</span>
                        <Input
                          className={`textInternalInput ${
                            affidavitValidation?.undersigned && "borderBottopm"
                          }`}
                          defaultValue={partials.undersigned}
                          onChange={handleAffidavitChange("undersigned")}
                          errorMsg={affidavitValidation?.undersigned}
                        />
                      </div>
                      <div className="col">
                        <span>Date:</span>
                        <Input
                          type={"date"}
                          className={`textInternalInput ${
                            affidavitValidation?.date && "borderBottopm"
                          }`}
                          defaultValue={partials.date}
                          onChange={handleAffidavitChange("date")}
                          errorMsg={affidavitValidation?.date}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <p>
                        . has been retained by INCODM Inc, its successors and/or
                        assigns on the building or premises commonly
                        <br /> known as
                      </p>
                      <div className="col">
                        <Input
                          className={`textInternalInput ${
                            affidavitValidation?.person && "borderBottopm"
                          }`}
                          defaultValue={partials?.person}
                          onChange={handleAffidavitChange("person")}
                          errorMsg={affidavitValidation?.person}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                        <span>. INCODM job</span>
                        <Input
                          className={`textInternalInput ${
                            affidavitValidation?.jobId && "borderBottopm"
                          }`}
                          defaultValue={partials.jobId}
                          onChange={handleAffidavitChange("jobId")}
                          errorMsg={affidavitValidation?.jobId}
                        />
                      </div>
                      <div className="col">
                        <span>. (“Project”) located at ,</span>
                        <Input
                          className={`textInternalInput ${
                            affidavitValidation?.locatedAt && "borderBottopm"
                          }`}
                          defaultValue={partials.locatedAt}
                          onChange={handleAffidavitChange("locatedAt")}
                          errorMsg={affidavitValidation?.locatedAt}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="first_heading mt-2">
                  <div className="" style={{ width: "100%", display: "flex" }}>
                    <p style={{ paddingTop: "28px", lineHeight: "3.0" }}>
                      Upon receipt of this payment of ${" "}
                      <span>
                        <Input
                          type={"number"}
                          placeholder="Enter Amount Here"
                          className={`textInternalInput ${
                            affidavitValidation?.payment && "borderBottopm"
                          }`}
                          defaultValue={partials.payment}
                          onChange={handleAffidavitChange("payment")}
                          errorMsg={affidavitValidation?.payment}
                        />
                      </span>
                      the undersigned hereby releases, waives and discharges any
                      rights, including but not limited to, lien rights, the
                      undersigned now has or may have against JD Phoenix Inc,
                      the General Contractor of the project and any surety
                      involved herein, arising out of or relating to the Project
                      on account of labor performed, work done and/or material
                      furnished up to and including :{" "}
                      <span className="pl-2">
                        <Input
                          label={"Work Date"}
                          className={`textInternalInput ${
                            affidavitValidation?.completionDate &&
                            "borderBottopm"
                          }`}
                          type={"date"}
                          defaultValue={partials.completionDate}
                          onChange={handleAffidavitChange("completionDate")}
                          errorMsg={affidavitValidation?.completionDate}
                        />
                      </span>
                      .
                    </p>
                  </div>
                </div>
                <div className="first_heading mt-2">
                  <div className="" style={{ width: "100%", display: "flex" }}>
                    <p style={{ paddingTop: "28px", lineHeight: "3.0" }}>
                      The undersigned certifies that, upon receipt of this
                      payment all of its subcontractors, suppliers, laborers,
                      material or other providers of services for the Project
                      have been paid in full for up to and including{" "}
                      <span className="ml-2 mr-2">
                        <Input
                          label={"Payment Date"}
                          className={`textInternalInput ${
                            affidavitValidation?.paymentDate && "borderBottopm"
                          }`}
                          type={"date"}
                          defaultValue={partials.paymentDate}
                          onChange={handleAffidavitChange("paymentDate")}
                          errorMsg={affidavitValidation?.paymentDate}
                        />
                      </span>
                      . The undersigned further certifies that all Social
                      Security taxes, withholding taxes, sales and use taxes,
                      Workmen’s Compensation taxes and insurance premiums which
                      have accrued in connection with the Project have been
                      fully paid and discharged.
                    </p>
                  </div>
                </div>
                <div className="first_heading mt-2">
                  <div className="" style={{ width: "100%" }}>
                    <p style={{ paddingTop: "28px", lineHeight: "3.0" }}>
                      IN WITNESS WHEREOF, the undersigned has executed these
                      presents this{" "}
                    </p>
                  </div>
                </div>
                <div className="first_heading mt-2">
                  <div className="" style={{ width: "100%", marginTop: 20 }}>
                    <div className="row">
                      <div className="col">
                        <span>Sub-Contractor: </span>{" "}
                        <Input
                          className={`textInternalInput ${
                            affidavitValidation?.subContSigned &&
                            "borderBottopm"
                          }`}
                          defaultValue={partials.subContSigned}
                          onChange={handleAffidavitChange("subContSigned")}
                          errorMsg={affidavitValidation?.subContSigned}
                        />
                      </div>
                      <div className="col">
                        <span>Subscription Date:</span>
                        <Input
                          type={"date"}
                          className={`textInternalInput ${
                            affidavitValidation?.subsCriptionDate &&
                            "borderBottopm"
                          }`}
                          defaultValue={partials.subsCriptionDate}
                          onChange={handleAffidavitChange("subsCriptionDate")}
                          errorMsg={affidavitValidation?.subsCriptionDate}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              "there is no Form available"
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Agreements;
