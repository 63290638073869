import React from "react";

const PhotoUpload = ({ showModal }) => {
  return (
    <div className="ps-4">
      <button className="daily-log-upload" onClick={showModal}>
        <img src="/assets/vectors/plus.png" alt="plus icon" />
        Upload
      </button>
    </div>
  );
};

export default PhotoUpload;
