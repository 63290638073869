import React, { useEffect, useState, useImperativeHandle, forwardRef } from "react";

import Select from "../components/select/select";
import Option from "../components/select/option";
import { useDispatch } from "react-redux";
import { GetDirectories, PostDirectories } from "../app/redux/actions";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { notifyError } from "utilities";
const Directory = forwardRef(({ stepsComplete, SetLoadingData }, ref) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const state = useSelector((state) => state.Project.GetDirectories);
  const projectData = useSelector((state) => state.Project.project);
  const [selectedDirectory, setselectedDirectory] = useState([]);
  useEffect(() => {
    dispatch(GetDirectories());
  }, []);
  const Submit = () => {
    let data = [];
    selectedDirectory &&
      selectedDirectory.map((item) => {
        return data.push(item.id);
      });

    if (projectData && data) {
      SetLoadingData(true);
      const payload = {
        project_id: projectData.id,
        directories: {
          directory_id: data,
        },
      };
      dispatch(PostDirectories(payload))
        .then((res) => {
          SetLoadingData(false);
          if (res.payload) {
            history.push("/admin/dashboard");
          }
        })
        .catch((err) => {
          SetLoadingData(false);
          notifyError(err?.payload?.response?.data?.message);
        });
    }
  };
  useImperativeHandle(ref, () => ({
    Submit,
  }));
  const handleChange = (e) => {
    const { value } = e.target;
    const selected = state.find((e) => e.id == value);
    setselectedDirectory((prev) => [...prev, selected]);
  };
  const handleDelete = (item) => {
    setselectedDirectory(selectedDirectory.filter((e) => e.id !== item.id));
  };

  return (
    <div className="directory">
      <h3>Directory</h3>

      <div className="info d-flex align-items-start">
        <img className="me-3" src="/assets/vectors/info-primary.svg" alt="info" />
        <p className="text-light-1 lh-15">
          If using a project template, contacts in the Directory of the project template will automatically be
          added to the project. If you have awarded contracts, the entries can be added here or you can add
          contacts through the project Directory tool later.
        </p>
      </div>

      <div className="custom-select-container mb-4 directory_select">
        <label htmlFor="">Directory</label>
        <select className="selected-text" style={{ background: "transparent" }} onChange={handleChange}>
          <option value="">Select Directory</option>
          {state?.map((item) => (
            <option value={item.id} key={item.id}>
              {item.name}
            </option>
          ))}
        </select>
      </div>

      {stepsComplete && (
        <div className="table-container">
          <table>
            <thead>
              <tr>
                <th>Name</th>
                <th>External ID</th>
                <th>Permission Template *</th>
                <th>Project Role</th>
                <th>Notify?</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {selectedDirectory &&
                selectedDirectory.map((item) => {
                  return (
                    <tr key={item.id}>
                      <td>{item.name}</td>
                      <td>{item.external_id || "-"}</td>
                      <td>{item.permission_template || "-"}</td>
                      <td>{item.project_role || "-"}</td>
                      <td>{item.notify}</td>
                      <td>
                        {
                          <img
                            src="/assets/vectors/cross.svg"
                            style={{ cursor: "pointer" }}
                            alt="no"
                            onClick={() => handleDelete(item)}
                          />
                        }
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
});

export default Directory;
